import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import StarRoundedIcon from '@material-ui/icons/StarRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DateRangeIcon from '@material-ui/icons/DateRange';

import moment from 'moment';

import { Salary, Incentives } from '../../components/SvgIcons';

import Image from '../../assets/company_logo_placeholder.png';
import { useHistory } from "react-router-dom";
import ApiHandler from '../../ApiHandler';
import AnalyticsHandler from '../../AnalyticsHandler';

const useStyles = makeStyles((theme) => ({
  listItemVertical: {
    flexDirection: 'column',
    cursor: 'pointer',
  },
  listItemHorizontal: {
    flexDirection: 'column',
    cursor: 'pointer',
    minWidth: 320, height: 145, marginRight: 5, marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      '&:first-child': {
        marginLeft: 10
      }
    },
  },
  actionText: {
    color: theme.palette.primary.dark,
  },
  imageTag: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: "none",
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 82,
    height: 82,
    overflow: 'hidden',
    margin: 0,
  },
  tileHeading: {
    ...theme.typography.tileHeading,
    color: theme.palette.common.blackDark,
    // width: 250,
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    // [theme.breakpoints.down('xs')]: {
    //   width: 169,
    // },
  },
  tileSubHeading: {
    ...theme.typography.tileSubHeading,
    color: theme.palette.common.grey,
    // maxWidth: 250,
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      width: 'unset',
      // maxWidth: 134
    },
    '@media (min-width: 600px)': {
      maxWidth: 'max-content'
    }
  },
  tileSubHeading2: {
    ...theme.typography.tileSubHeading,
    color: theme.palette.common.grey,
  },
  tileLocation: {
    ...theme.typography.tileSubHeading,
    color: theme.palette.common.blackLight,
    maxWidth: 250,
    marginRight: 2,
    // textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 87,
    },
  },
  tileRatingText: {
    ...theme.typography.tileRatingText,
    marginRight: 0,
  },
  tileRatingStar: {
    ...theme.typography.tileRatingStar,
    color: theme.palette.common.star,
    marginRight: 0,
    marginLeft: 2,
  },
  tileDistanceIcon: {
    fontSize: '0.815rem',
    color: theme.palette.primary.dark,
    marginRight: 0,
  },
  tileDistanceText: {
    ...theme.typography.tileRatingText,
    //marginRight: 12,
    // textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 57,
    },
  },
  tileSalary: {
    fontWeight: 500,
    fontSize: '0.875rem',
    // textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'right'
  },
  tileVacancy: {
    color: theme.palette.common.grey,
    fontWeight: 500,
    fontSize: '0.75rem',
    //width: 68,
    // textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  tileVacancy2: {
    color: theme.palette.common.grey,
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.3,
    //width: 68,
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  coloredChip: {
    fontSize: '0.75rem',
    fontWeight: 400,
    borderRadius: 4,
    lineHeight: 1.3,
    padding: 0,
    marginBottom: 1,
  },
  greenChip: {
    //background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
  },
  yellowChip: {
    //background: theme.palette.common.yellowLight,
    color: theme.palette.common.yellowDark,
  },
  redChip: {
    //background: theme.palette.common.redLight,
    color: theme.palette.common.redDark,
  },
  rightSectionTile: {
    width: '75%',
    [theme.breakpoints.down(360)]: {
      width: '70%',
    },
    minHeight: 75,
    padding: '5px 0 2px 16px'
  },
  rightSectionSalary: {
    width: '73%',
    padding: '2px 0 2px 7px',
    [theme.breakpoints.down(360)]: {
      width: '70%',
    },
  },
  displayNonemobile: {
    [theme.breakpoints.down(600)]: {
      display: 'none'
    },
  },
  displayNoneDesktop: {
    [theme.breakpoints.up(600)]: {
      display: 'none'
    },
  },

}));

export function JobsListItem1(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  // let job_detail_path = props.hit.job_title && props.hit.companies.company_name ? `/jobDetail/${props.hit.job_title.replace('-', ' ').replace(/ +/g, '-').replace("/", "-").replace("?", "-").replace("(", "").replace(")", "") + "-" + props.hit.companies.company_name.replace('-', ' ').replace(/ +/g, '-').replace('/', "-").replace('?', "-")}` : "";

  let regex = /[^\w ]/g; // non-digits, non-word characters, not underscores, not whitespace

  let jobTitle = props.hit.job_title ? props.hit.job_title.replace(regex, "").replace(/ +/g, '-') : "";

  let company_name_array = props.hit.companies.company_name ? props.hit.companies.company_name.split("-") : "";

  let companyNameFinal = company_name_array ? company_name_array[0].split(':')[0].split("(")[0].replace("Pvt. Ltd.", "").replace("Private Limited", "").replace("Pvt Ltd.", "").replace("Pvt. Ltd", "").replace("Pvt Ltd", "").replace("pvt ltd", "").replace("Ltd", "").replace("Ltd.", "").replace("Limited", "").replace("ltd", "").trim() : "";

  let jobLocation = props.hit.job_location ? props.hit.job_location.replace(/ +/g, '-') : "";

  let jobDetailPageUrl = `/jobDetail/${jobTitle}-${companyNameFinal.replace(/ +/g, "-")}${jobLocation ? "-" + jobLocation : ""}-${props.hit._id}`

  // let job_detail_path = props.hit.job_title && props.hit.companies.company_name ? `/jobDetail/${props.hit.job_title.replace(regex, "").replace(/ +/g, '-') + "-" + props.hit.companies.company_name.replace('-', ' ').replace(/ +/g, '-').replace('/', "-").replace('?', "-")}` : "";

  //console.log("job_detail_path= " + job_detail_path)

  // let job_detail_arr = job_detail_path ? job_detail_path.split(':') : "";

  // let job_detail_arr_final = (job_detail_arr[0] ? job_detail_arr[0] : "") + "-" + (props.hit.job_location ? props.hit.job_location.replace(/ +/g, '-') : "") + "-" + props.hit._id;

  let companyInitialsArr = companyNameFinal ? companyNameFinal.split(" ") : "";
  let companyInitial1 = companyInitialsArr && companyInitialsArr[0] ? companyInitialsArr[0].substring(0, 1) : "";
  let companyInitial2 = companyInitialsArr && companyInitialsArr[1] ? companyInitialsArr[1].substring(0, 1) : "";
  let companyInitials = companyInitial1 + companyInitial2;

  let resDist = props.hit.distance ? Math.round(props.hit.distance) : null;
  let companyDist = resDist ?
    resDist > 30 ?
      ""
      : " | " + resDist + " km"
    : null;

  return (
    <ListItem disableGutters
      onClick={() => {
        AnalyticsHandler.track('JobListItem_jobTitle_BtnClick');
        history.push({
          pathname: `${jobDetailPageUrl}`,
          state: {
            latitude: props.lat,
            longitude: props.long,
            //pageStateOnReturn: props.pageStateOnReturn,
          }
        });
        // localStorage.setItem("latitude", props.lat);
        // localStorage.setItem("longitude", props.long);
        sessionStorage.setItem("pageStateOnReturn", props.pageStateOnReturn);
        //window.location.href= jobDetailPageUrl;       
      }}>
      <ListItemAvatar className={classes.imgWrapper}>
        {props.hit.companies.company_image_url ?
          <img className={classes.imageTag} alt="company logo"
            onLoad={(event) => { event.target.style.opacity = 1 }}
            onError={(event) => { event.target.nextElementSibling.style.opacity = 1 }}
            src={props.hit.companies.company_image_url}
            style={{ opacity: 0 }} />
          :
          <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>
        }
        <Typography variant="h3" style={{ position: 'absolute', textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem', opacity: 0 }}>{companyInitials}</Typography>
      </ListItemAvatar>
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ width: '210%', padding: '5px 0 2px 7px' }}>
        <Typography className={classes.tileHeading}>{props.hit.job_title}</Typography>

        {props.hit.opportunity_type ?
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography
              className={classes.coloredChip}
              style={{
                color:
                  props.hit.opportunity_type === "Internship" ? theme.palette.primary.dark
                    : props.hit.opportunity_type === "Full-time" ? theme.palette.common.orangeDark
                      : props.hit.opportunity_type === "Apprenticeship" ? theme.palette.common.purpleDark
                        : props.hit.opportunity_type === "Part-time" ? theme.palette.common.blueDark
                          : theme.palette.primary.dark

              }}>{
                props.hit.opportunity_type
              }</Typography>
          </Grid>
          : null
        }

        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography
            className={classes.tileSubHeading}
          >{companyNameFinal}
          </Typography>

          {props.hit.companies.avg_rating && props.hit.avg_rating != 0 ?
            <><Typography color="secondary" className={classes.tileRatingText} style={{ paddingLeft: 4, fontWeight: 400 }}> | </Typography>
              <StarRoundedIcon className={classes.tileRatingStar} />
              <Typography className={classes.tileRatingText}>{props.hit.companies.avg_rating}</Typography>
            </>
            : null}

        </Grid>

        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          {/* {(!props.isWorkFromHome) ? <Typography className={classes.tileLocation}>{props.hit.job_location}</Typography> : null} */}

          {!props.isWorkFromHome ?
            <React.Fragment>
              <LocationOnRoundedIcon className={classes.tileDistanceIcon} />
              <Typography className={classes.tileLocation}>{props.hit.job_location}</Typography>
              {props.hit.distance && <Typography className={classes.tileDistanceText}>{companyDist}</Typography>}
            </React.Fragment> : null
          }

          {props.isWorkFromHome ?
            <React.Fragment>
              <LocationOnRoundedIcon className={classes.tileDistanceIcon} />
              <Typography className={classes.tileDistanceText} style={{ fontSize: '0.875rem' }}>घर से</Typography>
            </React.Fragment> : null
          }
        </Grid>

      </Grid>
      <Grid
        item
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-end"
        style={{ padding: '10px 5px 10px 2.5px', minHeight: 76 }}>
        <Typography color="primary" className={classes.tileSalary}>
          {props.hit.job_salary_amount && props.hit.job_salary_amount == 0 ? "Unpaid" : props.hit.job_salary_amount && props.hit.job_salary_amount.indexOf('-') > -1 ? `${props.hit.job_salary_amount.substring(0, props.hit.job_salary_amount.indexOf('-')).trim()}+` : props.hit.job_salary_amount}
        </Typography>
        <Typography color="primary" className={classes.tileVacancy} style={{ fontWeight: 400, marginTop: '-8px' }}>
          {props.hit.variable_salary_unit}
        </Typography>
        <Typography className={classes.tileVacancy}>{props.hit.no_of_vacancies} {props.hit.no_of_vacancies == 1 ? "opening" : "openings"}</Typography>
      </Grid>
    </ListItem>
  );
}

export default function JobsListItem(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  // let job_detail_path = props.hit.job_title && props.hit.companies.company_name ? `/jobDetail/${props.hit.job_title.replace('-', ' ').replace(/ +/g, '-').replace("/", "-").replace("?", "-").replace("(", "").replace(")", "") + "-" + props.hit.companies.company_name.replace('-', ' ').replace(/ +/g, '-').replace('/', "-").replace('?', "-")}` : "";

  let regex = /[^\w ]/g; // non-digits, non-word characters, not underscores, not whitespace

  let jobTitle = props.hit.job_title ? props.hit.job_title.replace(regex, "").replace(/ +/g, '-') : "";

  let company_name_array = props.hit.companies.company_name ? props.hit.companies.company_name.split("-") : "";

  let companyNameFinal = company_name_array ? company_name_array[0].split(':')[0].split("(")[0].replace("Pvt. Ltd.", "").replace("Private Limited", "").replace("Pvt Ltd.", "").replace("Pvt. Ltd", "").replace("Pvt Ltd", "").replace("pvt ltd", "").replace("Ltd", "").replace("Ltd.", "").replace("Limited", "").replace("ltd", "").trim() : "";

  let jobLocation = props.hit.job_location && props.hit.job_location[0] && props.hit.job_location[0].city ? props.hit.job_location[0].city.replace(/ +/g, '-') : "";

  let jobDetailPageUrl = `/jobDetail/${jobTitle}-${companyNameFinal.replace(/ +/g, "-")}${jobLocation ? "-" + jobLocation : ""}-${props.hit._id}`

  let jobLocationArray = props.hit.job_location && props.hit.job_location[0] && props.hit.job_location[0].city ? props.hit.job_location : null;

  // jobLocationArray = [{city: "Indore"}, {city: "Jaipur"}]

  // let job_detail_path = props.hit.job_title && props.hit.companies.company_name ? `/jobDetail/${props.hit.job_title.replace(regex, "").replace(/ +/g, '-') + "-" + props.hit.companies.company_name.replace('-', ' ').replace(/ +/g, '-').replace('/', "-").replace('?', "-")}` : "";

  //console.log("job_detail_path= " + job_detail_path)

  // let job_detail_arr = job_detail_path ? job_detail_path.split(':') : "";

  // let job_detail_arr_final = (job_detail_arr[0] ? job_detail_arr[0] : "") + "-" + (props.hit.job_location ? props.hit.job_location.replace(/ +/g, '-') : "") + "-" + props.hit._id;

  let companyInitialsArr = companyNameFinal ? companyNameFinal.split(" ") : "";
  let companyInitial1 = companyInitialsArr && companyInitialsArr[0] ? companyInitialsArr[0].substring(0, 1) : "";
  let companyInitial2 = companyInitialsArr && companyInitialsArr[1] ? companyInitialsArr[1].substring(0, 1) : "";
  let companyInitials = companyInitial1 + companyInitial2;

  let resDist = props.hit.job_location && props.hit.job_location[0] && props.hit.job_location[0].distance ? Math.round(props.hit.job_location[0].distance) : null; // get the distance of first job location from the user

  let companyDist = resDist ?
    resDist > 30 ?
      ""
      : " | " + resDist + " km"
    : null;

  return (
    <ListItem disableGutters
      className={classes.listItemVertical}
      onClick={() => {
        AnalyticsHandler.track('JobListItem_jobTitle_BtnClick');
        history.push({
          pathname: `${jobDetailPageUrl}`,
          state: {
            latitude: props.lat,
            longitude: props.long,
            //pageStateOnReturn: props.pageStateOnReturn,
          }
        });
        // localStorage.setItem("latitude", props.lat);
        // localStorage.setItem("longitude", props.long);
        sessionStorage.setItem("pageStateOnReturn", props.pageStateOnReturn);
        //window.location.href= job_detail_arr_final;       
      }}>
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="flex-start">

        <div className={classes.imgWrapper}>
          {props.hit.companies.company_image_url ?
            <img className={classes.imageTag} alt="company logo"
              onLoad={(event) => { event.target.style.opacity = 1 }}
              onError={(event) => { event.target.nextElementSibling.style.opacity = 1 }}
              src={props.hit.companies.company_image_url}
              style={{ opacity: 0 }} />
            :
            <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>
          }
          <Typography variant="h3" style={{ position: 'absolute', textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem', opacity: 0 }}>{companyInitials}</Typography>
        </div>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.rightSectionTile}
        >
          <Typography
            className={classes.tileHeading}
            style={{ maxWidth: '100%', width: '100%' }}
          >{props.hit.job_title}</Typography>

          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              className={classes.tileSubHeading}
              style={{ maxWidth: '100%' }}>{companyNameFinal}
            </Typography>

            {props.hit.companies.avg_rating && props.hit.avg_rating != 0 ?
              <><Typography color="secondary" className={classes.tileRatingText} style={{ paddingLeft: 4, fontWeight: 400 }}> | </Typography>
                <StarRoundedIcon className={classes.tileRatingStar} />
                <Typography className={classes.tileRatingText}>{props.hit.companies.avg_rating}</Typography>
              </>
              : null}

          </Grid>

          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            {/* show location1, location2 and then wfh if available */}
            {jobLocationArray ?
              <React.Fragment>
                <LocationOnRoundedIcon className={classes.tileDistanceIcon} />
                <Typography className={classes.tileLocation}>{jobLocationArray[0].city}</Typography>
                {props.hit.distance && <Typography className={classes.tileDistanceText}>{companyDist}</Typography>}

                {jobLocationArray[1] && jobLocationArray[1].city && (<Typography className={classes.tileLocation}>{", " + jobLocationArray[1].city}</Typography>)}

                {props.isWorkFromHome && <Typography className={classes.tileDistanceText} style={{ fontSize: '0.875rem', marginRight: 3 }}>, घर से </Typography>}

                {jobLocationArray[2] && jobLocationArray[2].city && (<Typography className={classes.tileLocation}> & more...</Typography>)}

              </React.Fragment> : null
            }

            {/* if job is wfh only */}
            {props.isWorkFromHome && !jobLocationArray ?
              <React.Fragment>
                <LocationOnRoundedIcon className={classes.tileDistanceIcon} />
                <Typography className={classes.tileDistanceText} style={{ fontSize: '0.875rem' }}>घर से</Typography>
              </React.Fragment> : null
            }
          </Grid>

          {props.hit.opportunity_type ?
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography
                className={classes.coloredChip}
                style={{
                  color:
                    props.hit.opportunity_type === "Internship" ? theme.palette.primary.dark
                      : props.hit.opportunity_type === "Full-time" ? theme.palette.common.orangeDark
                        : props.hit.opportunity_type === "Apprenticeship" ? theme.palette.common.purpleDark
                          : props.hit.opportunity_type === "Part-time" ? theme.palette.common.blueDark
                            : theme.palette.primary.dark

                }}>{props.hit.opportunity_type}</Typography>

              {(props.hit.opportunity_type && props.hit.isFlexible && props.hit.isFlexible === true) || (props.hit.opportunity_type && props.hit.no_of_vacancies) ? <span style={{ margin: '0 5px' }}>·</span> : null}

              {props.hit.isFlexible && props.hit.isFlexible === true ? <Typography className={classes.tileVacancy2}>Flexible Timings</Typography> : null}

              {props.hit.isFlexible && props.hit.isFlexible === true && props.hit.no_of_vacancies ? <span style={{ margin: '0 5px' }}>·</span> : null}

              {props.hit.no_of_vacancies ? <Typography className={classes.tileVacancy2}>{props.hit.no_of_vacancies} {props.hit.no_of_vacancies == 1 ? "Opening" : "Openings"} </Typography> : null}

            </Grid>
            : null
          }
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="center"
        style={{ borderTop: '1px solid', borderColor: theme.palette.common.greyLight, padding: '5px 0' }}
      >
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          style={{ width: 90, paddingLeft: 5 }}>
          <Salary style={{ color: theme.palette.common.grey, paddingRight: 5, fontSize: '1rem' }} />
          <Typography className={classes.tileSubHeading2}>{props.hit.opportunity_type === "Internship" || props.hit.opportunity_type === "Apprenticeship" ? "Stipend" : "Salary"}</Typography>
        </Grid>

        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          className={classes.rightSectionSalary}
        >
          <Typography
            className={classes.tileSubHeading}
            style={{ maxWidth: '100%' }}>
            {props.hit.job_salary_amount ?
              props.hit.job_salary_amount == 0 ? "Unpaid" :
                props.hit.job_salary_amount === "" ? "No fixed amount" :
                  props.hit.job_salary_amount : "No fixed amount"}
          </Typography>
        </Grid>
      </Grid>


      {props.hit.variable_salary_amount ?
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          style={{ borderTop: '1px solid', borderColor: theme.palette.common.greyLight, padding: '5px 0' }}
        >
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            style={{ width: 90, paddingLeft: 5 }}>
            <Incentives style={{ color: theme.palette.common.grey, paddingRight: 5, fontSize: '1rem' }} />
            <Typography className={classes.tileSubHeading2}>Incentives</Typography>
          </Grid>

          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            className={classes.rightSectionSalary}
          >
            <Typography
              className={classes.tileSubHeading}
              style={{ maxWidth: '100%' }}>{props.hit.variable_salary_amount} {props.hit.variable_salary_unit ? props.hit.variable_salary_unit : null}
            </Typography>
          </Grid>
        </Grid>
        : null
      }

    </ListItem >
  );
}

export function RecommendedJobsListItem(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  // let job_detail_path = props.hit.job_title && props.hit.companies.company_name ? `/jobDetail/${props.hit.job_title.replace('-', ' ').replace(/ +/g, '-').replace("/", "-").replace("?", "-").replace("(", "").replace(")", "") + "-" + props.hit.companies.company_name.replace('-', ' ').replace(/ +/g, '-').replace('/', "-").replace('?', "-")}` : "";

  let regex = /[^\w ]/g; // non-digits, non-word characters, not underscores, not whitespace

  let jobTitle = props.hit.job_title ? props.hit.job_title.replace(regex, "").replace(/ +/g, '-') : "";

  let company_name_array = props.hit.companies.company_name ? props.hit.companies.company_name.split("-") : "";

  let companyNameFinal = company_name_array ? company_name_array[0].split(':')[0].split("(")[0].replace("Pvt. Ltd.", "").replace("Private Limited", "").replace("Pvt Ltd.", "").replace("Pvt. Ltd", "").replace("Pvt Ltd", "").replace("pvt ltd", "").replace("Ltd", "").replace("Ltd.", "").replace("Limited", "").replace("ltd", "").trim() : "";

  let jobLocation = props.hit.job_location && props.hit.job_location[0] && props.hit.job_location[0].city ? props.hit.job_location[0].city.replace(/ +/g, '-') : "";

  let jobDetailPageUrl = `/jobDetail/${jobTitle}-${companyNameFinal.replace(/ +/g, "-")}${jobLocation ? "-" + jobLocation : ""}-${props.hit._id}`

  let jobLocationArray = props.hit.job_location && props.hit.job_location[0] && props.hit.job_location[0].city ? props.hit.job_location : null;

  // jobLocationArray = [{city: "Indore"}, {city: "Jaipur"}]

  // let job_detail_path = props.hit.job_title && props.hit.companies.company_name ? `/jobDetail/${props.hit.job_title.replace(regex, "").replace(/ +/g, '-') + "-" + props.hit.companies.company_name.replace('-', ' ').replace(/ +/g, '-').replace('/', "-").replace('?', "-")}` : "";

  //console.log("job_detail_path= " + job_detail_path)

  // let job_detail_arr = job_detail_path ? job_detail_path.split(':') : "";

  // let job_detail_arr_final = (job_detail_arr[0] ? job_detail_arr[0] : "") + "-" + (props.hit.job_location ? props.hit.job_location.replace(/ +/g, '-') : "") + "-" + props.hit._id;

  let companyInitialsArr = companyNameFinal ? companyNameFinal.split(" ") : "";
  let companyInitial1 = companyInitialsArr && companyInitialsArr[0] ? companyInitialsArr[0].substring(0, 1) : "";
  let companyInitial2 = companyInitialsArr && companyInitialsArr[1] ? companyInitialsArr[1].substring(0, 1) : "";
  let companyInitials = companyInitial1 + companyInitial2;

  let resDist = props.hit.job_location && props.hit.job_location[0] && props.hit.job_location[0].distance ? Math.round(props.hit.job_location[0].distance) : null; // get the distance of first job location from the user

  let companyDist = resDist ?
    resDist > 30 ?
      ""
      : " | " + resDist + " km"
    : null;

  return (
    <ListItem disableGutters
      className={classes.listItemHorizontal}
      onClick={() => {
        history.push({
          pathname: `${jobDetailPageUrl}`,
          state: {
            latitude: props.lat,
            longitude: props.long,
            //pageStateOnReturn: props.pageStateOnReturn,
          }
        });
        // localStorage.setItem("latitude", props.lat);
        // localStorage.setItem("longitude", props.long);
        sessionStorage.setItem("pageStateOnReturn", props.pageStateOnReturn);
        //window.location.href= job_detail_arr_final;       
      }}>
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="flex-start">


        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.rightSectionTile}
          style={{ width: '100%' }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="flex-start">

            <Grid
              item
              xs="8"
              container
              justifyContent="flex-start"
              alignItems="center"
            >

              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography
                  className={classes.tileHeading}
                  style={{ maxWidth: '100%', width: '100%' }}
                >{props.hit.job_title}</Typography>
                <Typography
                  className={classes.tileSubHeading}
                  style={{ maxWidth: '100%' }}>{companyNameFinal}
                </Typography>

                {props.hit.companies.avg_rating && props.hit.avg_rating != 0 ?
                  <><Typography color="secondary" className={classes.tileRatingText} style={{ paddingLeft: 4, fontWeight: 400 }}> | </Typography>
                    <StarRoundedIcon className={classes.tileRatingStar} />
                    <Typography className={classes.tileRatingText}>{props.hit.companies.avg_rating}</Typography>
                  </>
                  : null}

              </Grid>
            </Grid>

            <div className={classes.imgWrapper} style={{ width: 52, height: 52, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.15)', borderRadius: 4, margin: '0 5px 0' }}>
              {props.hit.companies.company_image_url ?
                <img className={classes.imageTag} alt="company logo"
                  onLoad={(event) => { event.target.style.opacity = 1 }}
                  onError={(event) => { event.target.nextElementSibling.style.opacity = 1 }}
                  src={props.hit.companies.company_image_url}
                  style={{ opacity: 0 }} />
                :
                <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>
              }
              <Typography variant="h3" style={{ position: 'absolute', textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem', opacity: 0 }}>{companyInitials}</Typography>
            </div>

          </Grid>


          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            {/* show location1, location2 and then wfh if available */}
            {jobLocationArray ?
              <React.Fragment>
                <LocationOnRoundedIcon className={classes.tileDistanceIcon} />
                <Typography className={classes.tileLocation}>{jobLocationArray[0].city}</Typography>
                {props.hit.distance && <Typography className={classes.tileDistanceText}>{companyDist}</Typography>}

                {jobLocationArray[1] && jobLocationArray[1].city && (<Typography className={classes.tileLocation}>{", " + jobLocationArray[1].city}</Typography>)}

                {props.isWorkFromHome && <Typography className={classes.tileDistanceText} style={{ fontSize: '0.875rem', marginRight: 3 }}>, घर से </Typography>}

                {jobLocationArray[2] && jobLocationArray[2].city && (<Typography className={classes.tileLocation}> & more...</Typography>)}

              </React.Fragment> : null
            }

            {/* if job is wfh only */}
            {props.isWorkFromHome && !jobLocationArray ?
              <React.Fragment>
                <LocationOnRoundedIcon className={classes.tileDistanceIcon} />
                <Typography className={classes.tileDistanceText} style={{ fontSize: '0.875rem' }}>घर से</Typography>
              </React.Fragment> : null
            }
          </Grid>

          {props.hit.opportunity_type ?
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography
                className={classes.coloredChip}
                style={{
                  color:
                    props.hit.opportunity_type === "Internship" ? theme.palette.primary.dark
                      : props.hit.opportunity_type === "Full-time" ? theme.palette.common.orangeDark
                        : props.hit.opportunity_type === "Apprenticeship" ? theme.palette.common.purpleDark
                          : props.hit.opportunity_type === "Part-time" ? theme.palette.common.blueDark
                            : theme.palette.primary.dark

                }}>{props.hit.opportunity_type}</Typography>

              {(props.hit.opportunity_type && props.hit.isFlexible && props.hit.isFlexible === true) || (props.hit.opportunity_type && props.hit.no_of_vacancies) ? <span style={{ margin: '0 5px' }}>·</span> : null}

              {props.hit.isFlexible && props.hit.isFlexible === true ? <Typography className={classes.tileVacancy2}>Flexible Timings</Typography> : null}

              {props.hit.isFlexible && props.hit.isFlexible === true && props.hit.no_of_vacancies ? <span style={{ margin: '0 5px' }}>·</span> : null}

              {props.hit.no_of_vacancies ? <Typography className={classes.tileVacancy2}>{props.hit.no_of_vacancies} {props.hit.no_of_vacancies == 1 ? "Opening" : "Openings"} </Typography> : null}

            </Grid>
            : null
          }
        </Grid>
      </Grid>

      {/* salary */}
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="center"
      // style={{ borderTop: '1px solid', borderColor: theme.palette.common.greyLight, padding: '2.5px 0' }}
      >
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          className={classes.rightSectionSalary}
          style={{ width: '100%', paddingLeft: 16 }}
        >
          <Salary style={{ color: theme.palette.common.grey, paddingRight: 5, fontSize: '1rem' }} />
          <Typography className={classes.tileSubHeading2}>{props.hit.opportunity_type === "Internship" || props.hit.opportunity_type === "Apprenticeship" ? "Stipend" : "Salary"}</Typography>
          <Typography
            className={classes.tileSubHeading}
            style={{ maxWidth: '100%', paddingLeft: 5 }}>
            {props.hit.job_salary_amount ?
              props.hit.job_salary_amount == 0 ? "Unpaid" :
                props.hit.job_salary_amount === "" ? "No fixed amount" :
                  props.hit.job_salary_amount : "No fixed amount"}
          </Typography>
        </Grid>
      </Grid>

      {/* incentive */}
      {props.hit.variable_salary_amount ?
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
        // style={{ borderTop: '1px solid', borderColor: theme.palette.common.greyLight, padding: '2.5px 0' }}
        >
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            className={classes.rightSectionSalary}
            style={{ width: '100%', paddingLeft: 16 }}
          >
            <Incentives style={{ color: theme.palette.common.grey, paddingRight: 5, fontSize: '1rem' }} />
            <Typography className={classes.tileSubHeading2}>Incentives</Typography>
            <Typography
              className={classes.tileSubHeading}
              style={{ maxWidth: '100%', paddingLeft: 5, }}>{props.hit.variable_salary_amount} {props.hit.variable_salary_unit ? props.hit.variable_salary_unit : null}
            </Typography>
          </Grid>
        </Grid>
        : null
      }

    </ListItem >
  );
}

export function JobsListItemOnCompanyPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  let regex = /[^\w ]/g; // non-digits, non-word characters, not underscores, not whitespace

  let jobTitle = props.hit.job_title ? props.hit.job_title.replace(regex, "").replace(/ +/g, '-') : "";

  let jobLocation = props.hit.job_location && props.hit.job_location[0] && props.hit.job_location[0].city ? props.hit.job_location[0].city.replace(/ +/g, '-') : "";

  let jobDetailPageUrl = `/jobDetail/${jobTitle}-${props.companyNameFinal.replace(/ +/g, "-")}${jobLocation ? "-" + jobLocation : ""}-${props.hit.job_id}`

  let jobLocationArray = props.hit.job_location && props.hit.job_location[0] && props.hit.job_location[0].city ? props.hit.job_location : null;

  // let resDist = props.hit.distance ? Math.round(props.hit.distance) : null;
  // let companyDist = resDist ?
  //   resDist > 30 ?
  //     ""
  //     : " | " + resDist + " km"
  //   : null;

  return (
    <ListItem disableGutters
      className={classes.listItemVertical}
      onClick={() => {
        //AnalyticsHandler.track('JobListItem_jobTitle_BtnClick');
        history.push({
          pathname: `${jobDetailPageUrl}`,
          state: {
            latitude: props.lat,
            longitude: props.long,
            //pageStateOnReturn: props.pageStateOnReturn,
          }
        });
        // localStorage.setItem("latitude", props.lat);
        // localStorage.setItem("longitude", props.long);
        sessionStorage.setItem("pageStateOnReturn", props.pageStateOnReturn);
        //window.location.href= job_detail_arr_final;       
      }}>
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="flex-start">

        <ListItemAvatar className={classes.imgWrapper}>
          {props.hit.illustration_img_url ?
            <img className={classes.imageTag} alt="company logo"
              onLoad={(event) => { event.target.style.opacity = 1 }}
              onError={(event) => { event.target.nextElementSibling.style.opacity = 1 }}
              src={props.hit.illustration_img_url}
              style={{ opacity: 0 }} />
            :
            <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{props.companyInitials}</Typography>
          }
          <Typography variant="h3" style={{ position: 'absolute', textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem', opacity: 0 }}>{props.companyInitials}</Typography>
        </ListItemAvatar>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.rightSectionTile}
        >
          <Typography
            className={classes.tileHeading}
            style={{ maxWidth: '100%', width: '100%' }}
          >{props.hit.job_title}</Typography>

          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            {/* show location1, location2 and then wfh if available */}
            {jobLocationArray ?
              <React.Fragment>
                <LocationOnRoundedIcon className={classes.tileDistanceIcon} />
                <Typography className={classes.tileLocation}>{jobLocationArray[0].city}</Typography>
                {/* {props.hit.distance && <Typography className={classes.tileDistanceText}>{companyDist}</Typography>} */}
                {jobLocationArray[1] && jobLocationArray[1].city && (<Typography className={classes.tileLocation}>{", " + jobLocationArray[1].city}</Typography>)}

                {props.isWorkFromHome && <Typography className={classes.tileDistanceText} style={{ fontSize: '0.875rem', marginRight: 3 }}>, घर से </Typography>}

                {jobLocationArray[2] && jobLocationArray[2].city && (<Typography className={classes.tileLocation}> & more...</Typography>)}

              </React.Fragment> : null
            }
            {/* if job is wfh only */}
            {props.isWorkFromHome && !jobLocationArray ?
              <React.Fragment>
                <LocationOnRoundedIcon className={classes.tileDistanceIcon} />
                <Typography className={classes.tileDistanceText} style={{ fontSize: '0.875rem' }}>घर से</Typography>
              </React.Fragment> : null
            }
          </Grid>

          {props.hit.opportunity_type ?
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography
                className={classes.coloredChip}
                style={{
                  color:
                    props.hit.opportunity_type === "Internship" ? theme.palette.primary.dark
                      : props.hit.opportunity_type === "Full-time" ? theme.palette.common.orangeDark
                        : props.hit.opportunity_type === "Apprenticeship" ? theme.palette.common.purpleDark
                          : props.hit.opportunity_type === "Part-time" ? theme.palette.common.blueDark
                            : theme.palette.primary.dark

                }}>{props.hit.opportunity_type}</Typography>

              {(props.hit.opportunity_type && props.hit.isFlexible && props.hit.isFlexible === true) || (props.hit.opportunity_type && props.hit.no_of_vacancies) ? <span style={{ margin: '0 5px' }}>·</span> : null}

              {props.hit.isFlexible && props.hit.isFlexible === true ? <Typography className={classes.tileVacancy2}>Flexible Timings</Typography> : null}

              {props.hit.isFlexible && props.hit.isFlexible === true && props.hit.no_of_vacancies ? <span style={{ margin: '0 5px' }}>·</span> : null}

              {props.hit.no_of_vacancies ? <Typography className={classes.tileVacancy2}>{props.hit.no_of_vacancies} {props.hit.no_of_vacancies == 1 ? "Opening" : "Openings"} </Typography> : null}

            </Grid>
            : null
          }
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="center"
        style={{ borderTop: '1px solid', borderColor: theme.palette.common.greyLight, padding: '5px 0' }}
      >
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          style={{ width: 90, paddingLeft: 5 }}>
          <Salary style={{ color: theme.palette.common.grey, paddingRight: 5, fontSize: '1rem' }} />
          <Typography className={classes.tileSubHeading2}>{props.hit.opportunity_type === "Internship" || props.hit.opportunity_type === "Apprenticeship" ? "Stipend" : "Salary"}</Typography>
        </Grid>

        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          className={classes.rightSectionSalary}
        >
          <Typography
            className={classes.tileSubHeading}
            style={{ maxWidth: '100%' }}>
            {props.hit.job_salary_amount ?
              props.hit.job_salary_amount == 0 ? "Unpaid" :
                props.hit.job_salary_amount === "" ? "No fixed amount" :
                  props.hit.job_salary_amount : "No fixed amount"}
          </Typography>
        </Grid>
      </Grid>


      {props.hit.variable_salary_amount ?
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          style={{ borderTop: '1px solid', borderColor: theme.palette.common.greyLight, padding: '5px 0' }}
        >
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            style={{ width: 90, paddingLeft: 5 }}>
            <Incentives style={{ color: theme.palette.common.grey, paddingRight: 5, fontSize: '1rem' }} />
            <Typography className={classes.tileSubHeading2}>Incentives</Typography>
          </Grid>

          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            className={classes.rightSectionSalary}
          >
            <Typography
              className={classes.tileSubHeading}
              style={{ maxWidth: '100%' }}>{props.hit.variable_salary_amount} {props.hit.variable_salary_unit ? props.hit.variable_salary_unit : null}
            </Typography>
          </Grid>
        </Grid>
        : null
      }

    </ListItem >
  );
}

export function AppliedJobsListItem(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  // let job_detail_path = props.hit.job_postings.job_title && props.hit.companies.company_name ? `/jobDetail/${props.hit.job_postings.job_title.replace('-', ' ').replace(/ +/g, '-').replace("/", "-").replace("?", "-").replace("(", "").replace(")", "") + "-" + props.hit.companies.company_name.replace('-', ' ').replace(/ +/g, '-').replace('/', "-").replace('?', "-")}` : "";

  // let job_detail_arr = job_detail_path ? job_detail_path.split(':') : "";

  // let job_detail_arr_final = (job_detail_arr[0] ? job_detail_arr[0] : "") + (props.hit.job_location ? props.hit.job_location.replace(/ +/g, '-') : "") + "-" + props.hit.job_postings._id;

  let regex = /[^\w ]/g; // non-digits, non-word characters, not underscores, not whitespace

  let jobTitle = props.hit && props.hit.job_postings.job_title ? props.hit.job_postings.job_title.replace(regex, "").replace(/ +/g, '-') : "";

  let company_name_array = props.hit && props.hit.companies.company_name ? props.hit.companies.company_name.split("-") : "";

  let companyNameFinal = company_name_array ? company_name_array[0].split(':')[0].split("(")[0].replace("Pvt. Ltd.", "").replace("Private Limited", "").replace("Pvt Ltd.", "").replace("Pvt. Ltd", "").replace("Pvt Ltd", "").replace("pvt ltd", "").replace("Ltd", "").replace("Ltd.", "").replace("Limited", "").replace("ltd", "").trim() : "";

  let jobLocation = props.hit && props.hit.job_location ? props.hit.job_location.replace(/ +/g, '-') : "";

  let jobDetailPageUrl = `/jobDetail/${jobTitle}-${companyNameFinal.replace(/ +/g, "-")}${jobLocation ? "-" + jobLocation : ""}-${props.hit.job_postings._id}`

  let companyInitialsArr = companyNameFinal ? companyNameFinal.split(" ") : "";
  let companyInitial1 = companyInitialsArr && companyInitialsArr[0] ? companyInitialsArr[0].substring(0, 1) : "";
  let companyInitial2 = companyInitialsArr && companyInitialsArr[1] ? companyInitialsArr[1].substring(0, 1) : "";
  let companyInitials = companyInitial1 + companyInitial2;

  var applicationDateRaw = moment(new Date()).diff(moment(new Date(props.hit.job_application_date)).format('DD-MMM-YYYY'), 'days');
  let applicationDate = applicationDateRaw === 0 ? "Today" : applicationDateRaw === 1 ? applicationDateRaw + " day ago" : applicationDateRaw > 0 && applicationDateRaw < 31 ? applicationDateRaw + " days ago" : applicationDateRaw > 30 ? "more than a month ago" : null

  let screeningFormLink = props.hit.job_postings.google_form_field

  return (
    <ListItem disableGutters
      style={{ cursor: 'pointer', }}
      id={props.hit.job_title}
      onClick={() => {
        //AnalyticsHandler.track('JobListItem_jobTitle_BtnClick');
        sessionStorage.setItem("pageStateOnReturn", "appliedJobs");//so that the ui back btn will navigate back to this page
        history.push({
          pathname: `${jobDetailPageUrl}`,
          // state: {
          //   latitude: props.lat,
          //   longitude: props.long
          // }
        })
        // localStorage.setItem("latitude", props.lat);
        // localStorage.setItem("longitude", props.long);
        // window.location.href=  `/jobDetail/${props.hit.job_title.replace('-', ' ').replace(/ +/g, '-') + "-" + props.hit.companies.company_name.replace('-', ' ').replace(/ +/g, '-').replace('-:-', "-") + "-" + props.hit._id}`;

      }}>
      <ListItemAvatar className={classes.imgWrapper}>
        {props.hit.companies.company_image_url ?
          <img className={classes.imageTag} alt="company logo"
            onLoad={(event) => { event.target.style.opacity = 1 }}
            onError={(event) => { event.target.nextElementSibling.style.opacity = 1 }}
            src={props.hit.companies.company_image_url}
            style={{ opacity: 0 }} />
          :
          <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>
        }
        <Typography variant="h3" style={{ position: 'absolute', textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem', opacity: 0 }}>{companyInitials}</Typography>
      </ListItemAvatar>
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ width: '210%', padding: '0 0 2px 18px' }}>
        <Typography className={classes.tileHeading}>{props.hit.job_postings.job_title}</Typography>
        <Typography className={classes.tileSubHeading}
          // style={{ maxWidth: 169 }}

        >{props.hit.companies.company_name}</Typography>
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ padding: '3px 5px 5px 0.5px' }}>
          {props.hit.job_postings.job_salary_amount ?
            <Typography color="primary" className={classes.tileSalary}><span style={{ color: theme.palette.text.disabled }}>Salary:</span> {props.hit.job_postings.job_salary_amount == 0 ? "Unpaid" : props.hit.job_postings.job_salary_amount.indexOf('-') > -1 ? `${props.hit.job_postings.job_salary_amount.substring(0, props.hit.job_postings.job_salary_amount.indexOf('-')).trim()}+` : props.hit.job_postings.job_salary_amount}</Typography>
            :
            <Typography color="primary" className={classes.tileSalary}>Unpaid</Typography>
          }
          {/* <Typography color="primary" className={classes.tileSalary}>{props.hit.job_postings.job_salary_amount == 0 ? "Unpaid" : props.hit.job_postings.job_salary_amount.indexOf('-') > -1 ? `${props.hit.job_postings.job_salary_amount.substring(0, props.hit.job_postings.job_salary_amount.indexOf('-')).trim()}+` : props.hit.job_postings.job_salary_amount}</Typography> */}

          {/*<Typography color="primary" className={classes.tileVacancy} style={{ fontWeight: 400, marginTop: '-8px' }}>
            {props.hit.job_postings.variable_salary_unit}
        </Typography>*/}
          <Typography className={classes.tileVacancy} style={{ color: "white" }}>
            {/* {props.hit.no_of_vacancies} {props.hit.no_of_vacancies == 1 ? "vacancy" : "vacancies"} */}
          </Typography>
        </Grid>
        <Grid
          item
          container
          // justifyContent="flex-end"
          alignItems="flex-start"
          style={{ margin: '0px 0px 10px 0px' }}
          className={classes.displayNoneDesktop}
        >
          {props.hit.status && props.hit.status === "Applied" ?
            <Grid item container alignItems='center' style={{ width: 'auto', marginTop: 2, borderRadius: 2, marginLeft: '-2px' }}>
              <DateRangeIcon fontSize='small' style={{ color: theme.palette.common.grey, marginRight: 3 }} />
              <Typography style={{ color: theme.palette.common.grey, fontWeight: 500, fontSize: '0.695rem' }}>Applied {applicationDate}</Typography>
            </Grid> : props.hit.status && (props.hit.status === "ShortlistWritten" || props.hit.status === "ShortlistInterview" || props.hit.status === "Waitlisted") ?
              <Grid item container style={{ width: 'auto', padding: '1px 6px 1px 0', marginTop: 2, background: '#FFF5D1', borderRadius: 2 }}>
                <StarRoundedIcon className={classes.tileRatingStar} />
                <Typography style={{ color: '#FAB116', fontWeight: 500, fontSize: '0.75rem' }}>Shortlisted</Typography>
              </Grid> : props.hit.status && props.hit.status === "Hired" ?
                <Grid item container style={{ width: 'auto', padding: '1px 6px 1px 3px', marginTop: 2, background: theme.palette.primary.light, borderRadius: 2 }}>
                  <CheckCircleRoundedIcon style={{ color: theme.palette.primary.dark, fontWeight: 500, fontSize: '1rem' }} />
                  <Typography style={{ color: theme.palette.primary.dark, fontWeight: 500, fontSize: '0.75rem' }}>Hired</Typography>
                </Grid> : props.hit.status && props.hit.status === "Rejected" ?
                  <Grid item container style={{ width: 'auto', padding: '1px 6px 1px 3px', marginTop: 2, background: theme.palette.common.redLight, borderRadius: 2 }}>
                    <CloseRoundedIcon style={{ color: theme.palette.common.redDark, fontWeight: 500, fontSize: '1rem' }} />
                    <Typography style={{ color: theme.palette.common.redDark, fontWeight: 500, fontSize: '0.75rem' }}>Rejected</Typography>
                  </Grid>
                  : null
          }

        </Grid>
        {props.hit.job_postings.google_form_field ?
          <Typography
            className={classes.tileSubHeading}
            style={{ padding: '0 8px', color: theme.palette.primary.main, border: `1px solid ${theme.palette.primary.main}`, borderRadius: 3, marginTop: 5 }}
            onClick={(e) => {
              e.stopPropagation(); // so that list item click is not triggered
              AnalyticsHandler.track('AppliedJobs_screeningForm_BtnClick');
              window.location.assign(screeningFormLink);
            }}>
            <a
            //href={props.hit.job_postings.google_form_field} 
            >Fill Screening Form <ArrowForwardIosIcon className={classes.tileRatingStar} style={{ color: theme.palette.primary.main, fontSize: 7 }}></ArrowForwardIosIcon></a>
          </Typography>
          : (!props.hit.job_postings.google_form_field && props.hit.is_screening_form) ? <Typography
            className={classes.tileSubHeading}
            style={{ padding: '0 8px', color: !props.hit.job_postings.enable_status && !props.hit.is_submitted ? theme.palette.common.red : theme.palette.primary.main, border: `1px solid ${theme.palette.primary.main}`, borderRadius: 3 }}
            onClick={(e) => {
              if (!props.hit.job_postings.enable_status && !props.hit.is_submitted) return; // return if job is closed and screening form was not submitted
              e.stopPropagation(); // so that list item click is not triggered
              AnalyticsHandler.track('AppliedJobs_screeningForm_BtnClick');
              props.setIsScreeningFormOpen(true);
              props.setJobPostID(props.hit.job_postings._id);
              props.setIsJobOpen(props.hit.job_postings.enable_status)
            }}>
            {props.hit.job_postings.enable_status && props.hit.is_submitted ? "Edit Screening Form" :
              !props.hit.job_postings.enable_status && props.hit.is_submitted ? "View Screening Form" :
                !props.hit.job_postings.enable_status && !props.hit.is_submitted ? "Closed" :
                  "Fill Screening Form"}
            {props.hit.job_postings.enable_status &&
              <ArrowForwardIosIcon className={classes.tileRatingStar} style={{ color: theme.palette.common.blueDark, fontSize: 7 }}></ArrowForwardIosIcon>
            }
          </Typography>
            : null
        }

      </Grid>
      <Grid
        item
        container
        justifyContent="flex-end"
        alignItems="flex-start"
        style={{ minHeight: 76, }}
        className={classes.displayNonemobile}
      >
        {/* {props.hit.companies.avg_rating && props.hit.avg_rating!=0 ? <StarRoundedIcon className={classes.tileRatingStar} /> : null }
          <Typography className={classes.tileRatingText}>{props.hit.companies.avg_rating == 0 ? "" : props.hit.companies.avg_rating}</Typography> */}
        {props.hit.status && props.hit.status === "Applied" ?
          <Grid item container style={{ width: 'auto', padding: '1px 6px', marginTop: 2, borderRadius: 2 }}>
            <Typography style={{ color: theme.palette.common.grey, fontWeight: 500, fontSize: '0.695rem' }}>Applied {applicationDate}</Typography>
          </Grid> : props.hit.status && (props.hit.status === "ShortlistWritten" || props.hit.status === "ShortlistInterview" || props.hit.status === "Waitlisted") ?
            <Grid item container style={{ width: 'auto', padding: '1px 6px 1px 0', marginTop: 2, background: '#FFF5D1', borderRadius: 2 }}>
              <StarRoundedIcon className={classes.tileRatingStar} />
              <Typography style={{ color: '#FAB116', fontWeight: 500, fontSize: '0.75rem' }}>Shortlisted</Typography>
            </Grid> : props.hit.status && props.hit.status === "Hired" ?
              <Grid item container style={{ width: 'auto', padding: '1px 6px 1px 3px', marginTop: 2, background: theme.palette.primary.light, borderRadius: 2 }}>
                <CheckCircleRoundedIcon style={{ color: theme.palette.primary.dark, fontWeight: 500, fontSize: '1rem' }} />
                <Typography style={{ color: theme.palette.primary.dark, fontWeight: 500, fontSize: '0.75rem' }}>Hired</Typography>
              </Grid> : props.hit.status && props.hit.status === "Rejected" ?
                <Grid item container style={{ width: 'auto', padding: '1px 6px 1px 3px', marginTop: 2, background: theme.palette.common.redLight, borderRadius: 2 }}>
                  <CloseRoundedIcon style={{ color: theme.palette.common.redDark, fontWeight: 500, fontSize: '1rem' }} />
                  <Typography style={{ color: theme.palette.common.redDark, fontWeight: 500, fontSize: '0.75rem' }}>Rejected</Typography>
                </Grid>
                : null
        }

      </Grid>
    </ListItem>
  );
}

export function JobInvitesListItem(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  // let job_detail_path = props.hit.job_role && props.hit.company_name ? `/companyProfile/${props.hit.job_role.replace('-', ' ').replace(/ +/g, '-').replace("/", "-").replace("?", "-").replace("(", "").replace(")", "") + "-" + props.hit.company_name.replace('-', ' ').replace(/ +/g, '-').replace('/', "-").replace('?', "-")}` : "";

  // let job_detail_arr = job_detail_path ? job_detail_path.split(':') : "";

  // let job_detail_arr_final = (job_detail_arr[0] ? job_detail_arr[0] : "") + (props.hit.job_location ? props.hit.location.replace(/ +/g, '-') : "") + "-" + props.hit.company_id;

  let regex = /[^\w ]/g; // non-digits, non-word characters, not underscores, not whitespace

  let jobTitle = props.hit.job_role ? props.hit.job_role.replace(regex, "").replace(/ +/g, '-') : "";

  let company_name_array = props.hit.company_name ? props.hit.company_name.split("-") : "";

  let companyNameFinal = company_name_array ? company_name_array[0].split(':')[0].split("(")[0].replace("Pvt. Ltd.", "").replace("Private Limited", "").replace("Pvt Ltd.", "").replace("Pvt. Ltd", "").replace("Pvt Ltd", "").replace("pvt ltd", "").replace("Ltd", "").replace("Ltd.", "").replace("Limited", "").replace("ltd", "").trim() : "";

  let jobLocation = props.hit.location ? props.hit.location.replace(/ +/g, '-') : "";

  let companyDetailPageUrl = `/companyProfile/${companyNameFinal.replace(/ +/g, "-")}-${props.hit.company_id}`

  let companyInitialsArr = companyNameFinal ? companyNameFinal.split(" ") : "";
  let companyInitial1 = companyInitialsArr && companyInitialsArr[0] ? companyInitialsArr[0].substring(0, 1) : "";
  let companyInitial2 = companyInitialsArr && companyInitialsArr[1] ? companyInitialsArr[1].substring(0, 1) : "";
  let companyInitials = companyInitial1 + companyInitial2;

  var applicationDateRaw = moment(new Date()).diff(moment(new Date(props.hit.invite_time)).format('DD-MMM-YYYY'), 'days');
  let applicationDate = applicationDateRaw === 0 ? "Today" : applicationDateRaw === 1 ? applicationDateRaw + " day ago" : applicationDateRaw > 0 && applicationDateRaw < 31 ? applicationDateRaw + " days ago" : applicationDateRaw > 30 ? "more than a month ago" : null

  return (
    <ListItem disableGutters
      style={{ cursor: 'pointer', }}
      id={props.hit.job_title}
      onClick={() => {
        //AnalyticsHandler.track('JobListItem_jobTitle_BtnClick');
        sessionStorage.setItem("pageStateOnReturn", "jobInvites");//so that the ui back btn will navigate back to this page
        history.push({
          pathname: `${companyDetailPageUrl}`,

        })
      }}
    >
      <ListItemAvatar className={classes.imgWrapper}>
        {props.hit.company_logo ?
          <img className={classes.imageTag} alt="company logo"
            onLoad={(event) => { event.target.style.opacity = 1 }}
            onError={(event) => { event.target.nextElementSibling.style.opacity = 1 }}
            src={props.hit.company_logo}
            style={{ opacity: 0 }} />
          :
          <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>
        }
        <Typography variant="h3" style={{ position: 'absolute', textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem', opacity: 0 }}>{companyInitials}</Typography>
      </ListItemAvatar>
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ width: '210%', padding: '5px 0 2px 10px' }}>
        <Typography className={classes.tileHeading}>{props.hit.job_role}</Typography>
        <Typography className={classes.tileSubHeading}
          // style={{ maxWidth: 169 }}
        >{props.hit.company_name}</Typography>

        {/* <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
        > */}
        {/* <Grid item container style={{ width: 'auto', padding: '2px 6px', marginTop: 4, background: theme.palette.common.greyLight, borderRadius: 2 }}>
            <Typography style={{ color: theme.palette.common.grey, fontWeight: 500, fontSize: '0.695rem' }}>Invited {applicationDate}</Typography>
          </Grid> */}

        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          <LocationOnRoundedIcon className={classes.tileDistanceIcon} />
          <Typography className={classes.tileLocation}>{props.hit.location}</Typography>
        </Grid>

        <Grid
          item
          container
          // justifyContent="space-between"
          alignItems="flex-end"
          className={classes.displayNoneDesktop}
          style={{ padding: '2px 5px 10px 0px' }}>
          <DateRangeIcon fontSize='small' style={{ color: theme.palette.common.grey, marginRight: 2 }} />
          <Typography color="primary" className={classes.tileVacancy} style={{ fontWeight: 400, marginTop: '-8px' }}>
            {applicationDate}
          </Typography>
          <Typography color="primary" className={classes.tileVacancy} style={{ fontWeight: 400, marginTop: '-8px' }}>
            { }
          </Typography>
          <Typography className={classes.tileVacancy} style={{ color: "white" }}> text</Typography>
        </Grid>

        {props.hit.hired_status ?
          <Grid item container style={{ width: 'auto', padding: '2px 6px', marginTop: 4, background: theme.palette.common.greyLight, borderRadius: 2 }}>
            <Typography style={{ color: theme.palette.common.grey, fontWeight: 500, fontSize: '0.695rem' }}>Hired</Typography>
          </Grid> : props.hit.shortlist_status ?
            <Grid item container style={{ width: 'auto', padding: '2px 6px 2px 0', marginTop: 4, background: '#FFF5D1', borderRadius: 2 }}>
              <StarRoundedIcon className={classes.tileRatingStar} />
              <Typography style={{ color: '#FAB116', fontWeight: 500, fontSize: '0.75rem' }}>Shortlisted</Typography>
            </Grid> : props.hit.invite_status ?
              <Grid item container style={{ width: 'auto', padding: '2px 6px 2px 3px', marginTop: 4, background: theme.palette.secondary.light, borderRadius: 2 }}>
                <CheckCircleRoundedIcon style={{ backgroundColor: theme.palette.secondary.light, fontWeight: 500, fontSize: '1rem', color: theme.palette.common.white, marginRight: 3 }} />
                <Typography style={{ color: theme.palette.common.white, fontWeight: 500, fontSize: '0.75rem' }}>Invited</Typography>
              </Grid> : null
        }
        {/* </Grid> */}

      </Grid>
      <Grid
        item
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-end"
        className={classes.displayNonemobile}
        style={{ padding: '10px 5px 10px 0px', minHeight: 76 }}>
        <Typography color="primary" className={classes.tileVacancy} style={{ fontWeight: 400, marginTop: '-8px' }}>
          {applicationDate}
        </Typography>
        <Typography color="primary" className={classes.tileVacancy} style={{ fontWeight: 400, marginTop: '-8px' }}>
          { }
        </Typography>
        <Typography className={classes.tileVacancy} style={{ color: "white" }}> text</Typography>
      </Grid>
    </ListItem>
  );
}

export function FollowedCompaniesListItem(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [isFollowed, setIsFollowed] = React.useState(true);
  const [gotResponse, setGotResponse] = React.useState(true);

  React.useEffect(() => {
    setIsFollowed(props.hit.liked);
  }, []);
  //to set Follow icon

  //Follow button functionality starts
  const handleFollow = () => {
    let payload = {
      // company_id,
      // invite_id: props.hit.invite_id,
      // shortlist_status: !selected,
      jobseeker_id: '6026825321bfab7c6e4302bb',
      liked: !isFollowed,
      company_id: props.hit.fav_companies.company_id,
    }
    // if (props.signInStatus) {
    setGotResponse(false);
    ApiHandler.followCompanyToggle(payload, response => {
      if (response.status == 200) {
        setIsFollowed(prev => !prev)
        setGotResponse(true);
      } else {
        var ErrorMessage = 'Oops! Something went wrong. Please try again.';
        if (response.response) {
          let statusCode = response.response.status;
          if (statusCode === 500) {
            ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
          } else if (statusCode === 404) {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          } else if (statusCode === 400) {
            ErrorMessage = response.response.data.message;
            //ErrorMessage = 'Oops! Something went wrong. Please try again.';
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
        } else if (response.message === 'Network Error') {
          ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
        } else {
          ErrorMessage = 'Oops! Something went wrong. Please try again.';
        }
        //handleAlert(ErrorMessage, 'error');
        console.log(ErrorMessage);
        setGotResponse(true);
      }
    })
    // }
  }

  return (
    <ListItem disableGutters
      style={{ cursor: 'pointer', }}
    // onClick={() => {
    //   history.push({
    //     pathname: `/jobDetail/:${props.hit._id}`,
    //     state: {
    //       latitude: props.lat,
    //       longitude: props.long
    //     }
    //   })
    // }}
    >
      <ListItemAvatar className={classes.imgWrapper}>
        <img className={classes.imageTag} onLoad={(event) => { event.target.style.opacity = 1 }} src={props.hit.fav_companies.company_image_url ? props.hit.fav_companies.company_image_url : Image} style={{ opacity: 0 }} />
      </ListItemAvatar>
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ width: '210%', padding: '10px 5px 10px 10px' }}>
        <Typography className={classes.tileHeading}>{props.hit.job_title}</Typography>
        <Typography className={classes.tileSubHeading}>{props.hit.fav_companies.company_name}</Typography>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          {props.hit.fav_companies.avg_rating ?
            <React.Fragment>
              <StarRoundedIcon className={classes.tileRatingStar} />
              <Typography className={classes.tileRatingText}>{props.hit.fav_companies.avg_rating == 0 ? "" : props.hit.fav_companies.avg_rating}</Typography>
            </React.Fragment>
            : null}
          <LocationOnRoundedIcon className={classes.tileDistanceIcon} />
          <Typography className={classes.tileDistanceText}>{Math.round(props.hit.fav_companies.distance)} km</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-end"
        style={{ padding: '10px 10px 10px 5px', minHeight: 76 }}>
        <IconButton id="follow_button" onClick={handleFollow}>
          {isFollowed ? <FavoriteRoundedIcon color="primary" />
            : <FavoriteBorderRoundedIcon color="primary" />}
        </IconButton>
      </Grid>
    </ListItem>
  );
}

export function CompaniesListItem(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  // let job_detail_path = props.hit.job_title && props.hit.companies.company_name ? `/jobDetail/${props.hit.job_title.replace('-', ' ').replace(/ +/g, '-').replace("/", "-").replace("?", "-").replace("(", "").replace(")", "") + "-" + props.hit.companies.company_name.replace('-', ' ').replace(/ +/g, '-').replace('/', "-").replace('?', "-")}` : "";
  let company_id = props.hit.company_id;

  let company_name_array = props.hit.company_name ? props.hit.company_name.split("-") : "";

  let companyNameFinal = company_name_array ? company_name_array[0].split(':')[0].split("(")[0].replace("Pvt. Ltd.", "").replace("Private Limited", "").replace("Pvt Ltd.", "").replace("Pvt. Ltd", "").replace("Pvt Ltd", "").replace("pvt ltd", "").replace("Ltd", "").replace("Ltd.", "").replace("Limited", "").replace("ltd", "").trim() : "";

  // let jobLocation = props.hit.job_location && props.hit.job_location[0] && props.hit.job_location[0].city ? props.hit.job_location[0].city.replace(/ +/g, '-') : "";

  // let jobDetailPageUrl = `/jobDetail/${jobTitle}-${companyNameFinal.replace(/ +/g, "-")}${jobLocation ? "-" + jobLocation : ""}-${props.hit._id}`


  let companyInitialsArr = companyNameFinal ? companyNameFinal.split(" ") : "";
  let companyInitial1 = companyInitialsArr && companyInitialsArr[0] ? companyInitialsArr[0].substring(0, 1) : "";
  let companyInitial2 = companyInitialsArr && companyInitialsArr[1] ? companyInitialsArr[1].substring(0, 1) : "";
  let companyInitials = companyInitial1 + companyInitial2;

  // let resDist = props.hit.job_location && props.hit.job_location[0] && props.hit.job_location[0].distance ? Math.round(props.hit.job_location[0].distance) : null; // get the distance of first job location from the user

  // let companyDist = resDist ?
  //   resDist > 30 ?
  //     ""
  //     : " | " + resDist + " km"
  //   : null;

  return (
    <ListItem disableGutters
      className={classes.listItemVertical}
      onClick={() => {
        if (company_id) {
          history.push({
            pathname: `/${"CompanyProfile/" + companyNameFinal + "-" + company_id}`,
          })
        }
        sessionStorage.setItem("pageStateOnReturn", props.pageStateOnReturn);
      }}
    >
      <Grid
        item
        container
        justifyContent="flex-start"
        alignItems="flex-start">

        <div className={classes.imgWrapper}>
          {props.hit.company_image_url ?
            <img className={classes.imageTag} alt="company logo"
              onLoad={(event) => { event.target.style.opacity = 1 }}
              onError={(event) => { event.target.nextElementSibling.style.opacity = 1 }}
              src={props.hit.company_image_url}
              style={{ opacity: 0 }} />
            :
            <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>
          }
          <Typography variant="h3" style={{ position: 'absolute', textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem', opacity: 0 }}>{companyInitials}</Typography>
        </div>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.rightSectionTile}
        >
          <Typography
            className={classes.tileHeading}
            style={{ maxWidth: '100%', width: '100%' }}
          >{companyNameFinal}</Typography>

          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
          >

            {props.hit.avg_rating && props.hit.avg_rating != 0 ?
              <>
                <StarRoundedIcon className={classes.tileRatingStar} />
                <Typography className={classes.tileRatingText}>{props.hit.avg_rating}</Typography>
              </>
              : null}

          </Grid>

          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            {/* show location1, location2 and then wfh if available */}
            {/* {jobLocationArray ?
              <React.Fragment>
                <LocationOnRoundedIcon className={classes.tileDistanceIcon} />
                <Typography className={classes.tileLocation}>{jobLocationArray[0].city}</Typography>
                {props.hit.distance && <Typography className={classes.tileDistanceText}>{companyDist}</Typography>}

                {jobLocationArray[1] && jobLocationArray[1].city && (<Typography className={classes.tileLocation}>{", " + jobLocationArray[1].city}</Typography>)}

                {props.isWorkFromHome && <Typography className={classes.tileDistanceText} style={{ fontSize: '0.875rem', marginRight: 3 }}>, घर से </Typography>}

                {jobLocationArray[2] && jobLocationArray[2].city && (<Typography className={classes.tileLocation}> & more...</Typography>)}

              </React.Fragment> : null
            } */}


          </Grid>

          {props.hit.sector_name ?
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography
                className={classes.coloredChip}
              >{props.hit.sector_name}</Typography>

              {props.hit.no_of_vacancies ? <span style={{ margin: '0 5px' }}>·</span> : null}

              {props.hit.no_of_vacancies ? <Typography className={classes.tileVacancy2}>{props.hit.no_of_vacancies} {props.hit.no_of_vacancies == 1 ? "Opening" : "Openings"} </Typography> : null}

            </Grid>
            : null
          }
        </Grid>
      </Grid>






    </ListItem >
  );
}