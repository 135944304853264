import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';

// import SwipeableViews from 'react-swipeable-views';
// import { virtualize } from 'react-swipeable-views-utils';

import ApiHandler from '../../ApiHandler';
import AnalyticsHandler from '../../AnalyticsHandler';
import NoResultsImage from '../../assets/no_results.png';
import nearMeStamp from '../../assets/near-me-stamp.png';
import placeholderStamp from '../../assets/placeholder-stamp.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
  },
  actionText: {
    color: theme.palette.primary.dark,
  },
  imageTag: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: "none",
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: 4,
    marginRight: 10,
    overflow: 'hidden',
    margin: 0,
  },
  dividerRoot: {
    backgroundColor: theme.palette.grey[100],
    margin: '0 16px',
  },
  tileHeading: {
    ...theme.typography.tileHeading,
    fontSize: '0.9375rem',
    color: theme.palette.common.blackDark,
  },
  tileSubHeading: {
    ...theme.typography.tileSubHeading,
    fontSize: '0.8125rem',
    color: theme.palette.common.blackLight,
  },
  panelWrapper: {
    width: '90%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  panelWrapperWithSlider: {
    width: '95%',
    alignSelf: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  panelHeading: {
    margin: '20px 0 15px',
    padding: '0',
    fontSize: 24,
    color: theme.palette.common.blackDark,
    [theme.breakpoints.down('xs')]: {
      margin: '0px 0 8px',
      padding: '0 10px',
      fontSize: 18,
    },
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 70,
    alignItems: 'center',
    flexDirection: 'column',
    // height: '60vh',
  },
  pageInfoText: {
    color: "#959595",
    lineHeight: '2em'
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 30,
  },
  pageButton_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  sliderContainer: {
    position: 'relative',
    display: 'flex',
    overflowX: 'scroll',
    marginBottom: 15,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  sliderContainerWith6Column: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: 'repeat(6, auto)',
    columnGap: 10,
    rowGap: 20,
    justifyContent: "space-between",
    marginBottom: 15,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(7, auto)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(4, auto)',
    },
  },
  sliderContainerWith5Column: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: 'repeat(5, auto)',
    columnGap: 10,
    rowGap: 20,
    justifyContent: "space-between",
    marginBottom: 15,
    background: 'white',
    padding: '20px 10px 20px 50px',
    borderRadius: 6,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(4, auto)',
    },
  },
  slide: {
    cursor: 'pointer',
    marginRight: 35,
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      marginRight: 15,
      '&:first-child': {
        marginLeft: 10,
        //marginRight: 10,
      }
    },
  },
  handCursor: {
    cursor: 'pointer',
  },
  stampSlide: {
    background: 'white',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
    borderRadius: 4,
    padding: 5,
    marginRight: 35,
    minWidth: 150,
    [theme.breakpoints.down('xs')]: {
      minWidth: 120,
      marginRight: 15,
      '&:first-child': {
        marginLeft: 10
      }
    },
  },
  stampSlideImgWrapper: {
    width: 130,
    height: 159,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: 100,
      height: 121,
    },
  },
  companySlide: {
    background: 'white',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
    borderRadius: 4,
    [theme.breakpoints.down('xs')]: {
      '&:first-child': {
        marginLeft: 10
      }
    },
  },
  imgSlide: {
    background: 'white',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
    borderRadius: 4,
    '&:last-child': {
      marginRight: 0
    },
    '&:first-child': {
      marginLeft: 0
    }
  },
  sliderImgWrapper: {
    width: 100,
    height: 121,
  },
  sliderImgWrapperWithBg: { //company logo images tile
    position: 'relative',
    width: 150,
    height: 140,
    overflow: 'hidden',
    background: 'white',
    borderRadius: 4,
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
    '& > img': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)'
    }
  },
  sliderWrapper: {
    width: '100%',
    position: 'relative'
  },
  slider: {
    display: 'flex'
  },
  sliderArrow: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    background: '#f6fffde3',
    color: '#555',
    position: 'absolute',
    top: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    fontSize: 30,
    '&:hover': {
      background: 'white',
      color: '#5bc2a8',
      boxShadow: '0 0 8px rgba(0,0,0,0.15)',
    },
  },
  sliderArrow_L: {
    left: 10,
  },
  sliderArrow_R: {
    right: 10,
  },
}));

export function JobRolesPanelOld(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [dataToDisplay, setDataToDisplay] = React.useState([]);
  const [showNoResults, setShowNoResults] = React.useState(false);
  const history = useHistory();
  // let rolesDataAll = props.roles_list;
  // let rolesData = props.roles_list.slice(0, 3);

  const [rolesDataAll, setRolesDataAll] = React.useState(props.roles_list);
  const [rolesData, setRolesData] = React.useState(props.roles_list.slice(0, 3));

  const matchesMinHeight = useMediaQuery('(min-height:500px)');

  React.useEffect(() => {
    if (props.displayAllJobs) {
      setRolesDataAll(props.roles_list)
      setDataToDisplay(props.roles_list);
    } else {
      setRolesData(props.roles_list.slice(0, 3));
      setDataToDisplay(props.roles_list.slice(0, 3));
    }

  }, [props.roles_list, props.displayAllJobs])


  React.useEffect(() => {
    if (props.searchTerm === "") {
      //console.log("searchterm is empty string")
      setShowNoResults(false);
      setDataToDisplay(rolesDataAll);
      //props.setStartedTyping(false); //commented out to fix a bug on clicking see all positions button which comes when no results are there for a search term
    } else if (props.searchTerm === null) {
      setShowNoResults(false);
    } else if (props.searchTerm !== "" && props.searchTerm != null) {
      var i, k = 0, temp_array = [];
      for (i = 0; i < rolesDataAll.length; i++) {
        if (rolesDataAll[i].job_role.toUpperCase().indexOf(props.searchTerm.toUpperCase()) > -1) {
          temp_array[k++] = rolesDataAll[i];
        }
      }
      if (temp_array.length == 0) {
        setShowNoResults(true);
        setDataToDisplay([{ job_role: "No Results" }]);
        //props.setDisplayAllJobs(false);
      }
      else {
        setShowNoResults(false);
        setDataToDisplay(temp_array);
        //props.setDisplayAllJobs(false);
      }
    }
  }, [props.searchTerm])


  return (
    <React.Fragment>
      {showNoResults &&
        (<div className={classes.pageInfo}
          style={{ paddingTop: matchesMinHeight ? '70px' : 0 }}
        >
          <img src={NoResultsImage} style={{ width: matchesMinHeight ? 200 : 100 }} />
          <Typography className={classes.pageInfoText} variant="subtitle1">Sorry! हमें आपकी ढूँढी हुई position नहीं मिली।</Typography>
          <Button
            id="Search_all_positions"
            variant="contained"
            className={`${classes.pageButton} ${classes.pageButton_pri}`}
            style={{ width: 170 }}
            onClick={() => {
              AnalyticsHandler.track('JobRolesPanel_searchAllPositions_BtnClick');
              props.setSearchTerm("");
              //props.showRoleSearchState(); 
            }}
          >
            सभी positions देखें
          </Button>
        </div>)
      }
      {/* title on main page when box is collapsed */}
      {!showNoResults && !props.displayAllJobs ?
        <div style={{ position: 'relative' }}>
          <Typography variant="h1" color="primary" className={classes.panelHeading} style={{ paddingLeft: 0, paddingRight: 0 }}>
            {"सबसे ज़्यादा openings वाले roles"}
          </Typography>

          <IconButton
            ref={props.refSeeAllJobsButton} edge="end" aria-label="see all jobs"
            id="see_all_positions"
            onClick={() => {
              // props.setDisplaySearchBar(true);
              AnalyticsHandler.track('JobsMain_seeAllPositions_BtnClick');
              setDataToDisplay(rolesDataAll);
              // props.setDisplayAllJobs(true);
              // props.setStartedTyping(false);
              props.showRoleSearchState();
              sessionStorage.setItem("pageStateOnReturn", "search") // to change the jobs page to search state
            }}
            style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 2, zIndex: 9, borderRadius: 30 }}
          >
            {/* <Typography variant="body1" color="primary">See All</Typography> */}
            <ChevronRightRoundedIcon color="primary" />
          </IconButton>
        </div>
        : null}

      {!showNoResults &&
        <List className={classes.root}>
          {
            dataToDisplay.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemAvatar className={classes.imgWrapper}>
                    {item.illustration_img_url ?
                      <img className={classes.imageTag} alt="company logo"
                        onLoad={(event) => { event.target.style.opacity = 1 }}
                        src={item.illustration_img_url}
                        style={{ opacity: 0 }} />
                      : null
                      // <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>
                    }
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.job_role}
                    secondary={item.no_of_vacancies && item.no_of_vacancies === 1 ? item.no_of_vacancies + " opening" : item.no_of_vacancies && item.no_of_vacancies > 1 ? item.no_of_vacancies + " openings" : null}
                    classes={{ primary: classes.tileHeading, secondary: classes.tileSubHeading }}
                    id={"jobs_in_" + item.job_role}
                    onClick={() => {
                      AnalyticsHandler.track('JobRolesPanel_role_BtnClick');
                      if (item.count) {
                        history.push({
                          //pathname: `/findwork/${item.job_role}`,
                          pathname: `/${item.job_role.split(" ").join("-")}`,
                          state: {
                            role: item.job_role,
                            modeOfWork: props.modeOfWork,
                            lat: props.lat,
                            long: props.long
                          }
                        })
                      }
                    }} />
                  {item.no_of_vacancies ?
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete"
                        id={"jobs_in_" + item.job_role + "_end"}
                        onClick={() => {
                          AnalyticsHandler.track('JobRolesPanel_roleEnd_BtnClick');
                          history.push({
                            pathname: `/${item.job_role.split(" ").join("-")}`,
                            state: {
                              role: item.job_role,
                              modeOfWork: props.modeOfWork,
                              lat: props.lat,
                              long: props.long
                            }
                          });
                        }
                        }
                      >
                        <ChevronRightRoundedIcon color="primary" />
                      </IconButton>
                    </ListItemSecondaryAction> : null}
                </ListItem>

                <Divider component="li" classes={{ root: classes.dividerRoot }} />
              </React.Fragment>
            ))
          }
          {/* see all positions btn */}
          {/* {!props.displayAllJobs ?
            <ListItem style={{ margin: '12px 0 4px' }}>
              <ListItemSecondaryAction >
                <IconButton id="see_all_positions" ref={props.refSeeAllJobsButton} edge="end" aria-label="see all positions"
                  onClick={() => {
                    // props.setDisplaySearchBar(true);
                    AnalyticsHandler.track('JobsMain_seeAllPositions_BtnClick');
                    setDataToDisplay(rolesDataAll);
                    // props.setDisplayAllJobs(true);
                    // props.setStartedTyping(false);
                    props.showRoleSearchState();
                  }}>
                  <Typography variant="subtitle2" className={classes.actionText}>सभी देखें 👉</Typography>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem> : null} */}

          {/* see less positions btn */}
          {/* {props.displayAllJobs ?
            <ListItem style={{ margin: '12px 0 4px' }}>
              <ListItemSecondaryAction >
                <IconButton edge="end" aria-label="See top positions"
                  onClick={() => {                   
                    setDataToDisplay(rolesData);
                    props.showDefaultJobsPageState();
                  }}>
                  <Typography variant="subtitle2" className={classes.actionText}>Top Positions</Typography>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem> : null} */}

        </List>
      }
    </React.Fragment>
  );
}
export default function JobRolesPanel(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [dataToDisplay, setDataToDisplay] = React.useState([]);
  const [showNoResults, setShowNoResults] = React.useState(false);
  const history = useHistory();

  const [rolesDataAll, setRolesDataAll] = React.useState(props.roles_list);
  const [rolesData, setRolesData] = React.useState(props.roles_list.slice(0, 6));

  const matchesMinHeight = useMediaQuery('(min-height:500px)');
  const matchesMinWidthAboveIpad = useMediaQuery('(min-width:1025px)');

  React.useEffect(() => {
    if (props.displayAllJobs) {
      setRolesDataAll(props.roles_list)
      setDataToDisplay(props.roles_list);
    } else {
      setRolesData(props.roles_list);
      setDataToDisplay(props.roles_list);
      // setRolesData(props.roles_list.slice(0, 9));
      // setDataToDisplay(props.roles_list.slice(0, 9));
    }

  }, [props.roles_list, props.displayAllJobs])


  React.useEffect(() => {
    if (props.searchTerm === "") {
      setShowNoResults(false);
      setDataToDisplay(rolesDataAll);
      //props.setStartedTyping(false);
    } else if (props.searchTerm === null) {
      setShowNoResults(false);
    } else if (props.searchTerm !== "" && props.searchTerm != null) {
      var i, k = 0, temp_array = [];
      for (i = 0; i < rolesDataAll.length; i++) {
        if (rolesDataAll[i].job_role.toUpperCase().indexOf(props.searchTerm.toUpperCase()) > -1) {
          temp_array[k++] = rolesDataAll[i];
        }
      }
      if (temp_array.length == 0) {
        setShowNoResults(true);
        setDataToDisplay([{ job_role: "No Results" }]);
        //props.setDisplayAllJobs(false);
      }
      else {
        setShowNoResults(false);
        setDataToDisplay(temp_array);
        //props.setDisplayAllJobs(false);
      }
    }
  }, [props.searchTerm])

  //slider with navigation arrows---->starts
  const sliderContainerRef = React.useRef(null);
  const sliderRef = React.useRef(null);
  const expArrowLRef = React.useRef(null);
  const expArrowRRef = React.useRef(null);

  let scrollableWidth = 0;
  const scrollslider = (e) => {
    //let slider = e.target.nextSibling;
    let slider = sliderRef.current;
    let sliderWidth = slider.scrollWidth;
    let w = sliderContainerRef.current.offsetWidth//width of the slidercontainer
    //console.log(w)
    scrollableWidth += w;
    if (scrollableWidth <= 0) {
      slider && slider.animate({ transform: 'translateX(' + scrollableWidth + 'px)' }, { duration: 250, fill: 'forwards' });
      expArrowRRef.current.style.display = "flex";
      if (scrollableWidth + w > 0) {
        e.target.style.display = "none"
      }
    }
  }
  const scrollsliderR = (e) => {
    //let slider = e.target.previousSibling;
    let slider = sliderRef.current;
    let sliderWidth = slider.scrollWidth;
    //console.log(sliderWidth)
    let w = sliderContainerRef.current.offsetWidth
    console.log(w)
    scrollableWidth -= w;

    if (scrollableWidth >= -sliderWidth) {
      let _scrollableWidth = scrollableWidth + 100
      slider && slider.animate({ transform: 'translateX(' + _scrollableWidth + 'px)' }, { duration: 250, fill: 'forwards' })
      expArrowLRef.current.style.display = "flex";
      if (scrollableWidth - w <= -sliderWidth) {
        e.target.style.display = "none"
      }
    }
  }

  React.useEffect(() => {
    if (sliderRef.current && expArrowRRef.current && sliderRef.current.scrollWidth > 612) {
      expArrowRRef.current.style.display = "flex";
    }

  }, []);
  //slider with navigation arrows---->ends

  return (
    <React.Fragment>
      {showNoResults &&
        (<div className={classes.pageInfo}
          style={{ paddingTop: matchesMinHeight ? '70px' : 0 }}
        >
          <img src={NoResultsImage} style={{ width: matchesMinHeight ? 200 : 100 }} />
          <Typography className={classes.pageInfoText} variant="subtitle1">Sorry! हमें आपकी ढूँढी हुई position नहीं मिली।</Typography>
          <Button
            id="Search_all_positions"
            variant="contained"
            className={`${classes.pageButton} ${classes.pageButton_pri}`}
            style={{ width: 170 }}
            onClick={() => {
              AnalyticsHandler.track('JobRolesPanel_searchAllPositions_BtnClick');
              props.setSearchTerm("");
              props.showRoleSearchState();
            }}
          >
            सभी positions देखें
          </Button>
        </div>)
      }
      {/* title on main page when box is collapsed */}
      {!showNoResults && !props.displayAllJobs ?
        <div style={{ position: 'relative' }}>
          <Typography variant="h1" color="primary" className={classes.panelHeading}>
            {"सबसे ज़्यादा openings वाले roles"}
          </Typography>

          <IconButton
            ref={props.refSeeAllJobsButton} edge="end" aria-label="see all jobs"
            id="see_all_positions"
            onClick={() => {
              // props.setDisplaySearchBar(true);
              AnalyticsHandler.track('JobsMain_seeAllPositions_BtnClick');
              setDataToDisplay(rolesDataAll);
              // props.setDisplayAllJobs(true);
              // props.setStartedTyping(false);
              props.showRoleSearchState();
            }}
            style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '5%', zIndex: 9, borderRadius: 30 }}
          >
            <Typography variant="body1" color="primary">See All</Typography> <ChevronRightRoundedIcon color="primary" />
          </IconButton>
        </div>
        : null}

      {!showNoResults && !props.started_typing &&
        // sliderwrapper
        <div className={classes.sliderWrapper}>

          {/* left navigation arrow */}
          {matchesMinWidthAboveIpad && <div ref={expArrowLRef} className={`${classes.sliderArrow} ${classes.sliderArrow_L}`} onClick={scrollslider} style={{ display: 'none' }}>&#8249;</div>}
          {/* slidercontainer */}
          <div className={classes.sliderContainer} style={{ justifyContent: "space-between" }} ref={sliderContainerRef}>
            {/* slider */}
            <div className={classes.slider} ref={sliderRef}>
              {dataToDisplay.map((item, index) => (
                <React.Fragment key={index}>
                  <div className={`${classes.slide} ${classes.imgSlide}`}
                    onClick={() => {
                      AnalyticsHandler.track('JobRolesPanel_role_BtnClick');
                      if (item.count) {
                        history.push({
                          pathname: `/${item.job_role.split(" ").join("-")}`,
                          state: {
                            role: item.job_role,
                            modeOfWork: props.modeOfWork,
                            lat: props.lat,
                            long: props.long
                          }
                        })
                      }
                    }}
                  >
                    <div
                      className={classes.sliderImgWrapper}
                      style={{ width: 150, height: 150, overflow: 'hidden', borderRadius: 4 }}
                    >
                      {item.illustration_img_url ?
                        <img className={classes.imageTag} alt="city image"
                          onLoad={(event) => { event.target.style.opacity = 1 }}
                          src={item.illustration_img_url}
                          style={{ opacity: 0 }} />
                        : null
                      }
                    </div>
                    <Typography variant="body2" style={{ padding: '5px 5px 0px' }}>{item.job_role}</Typography>
                    <Typography variant="body2" style={{ color: 'grey', padding: '0px 5px 5px' }}>{item.no_of_vacancies && item.no_of_vacancies === 1 ? item.no_of_vacancies + " opening" : item.no_of_vacancies && item.no_of_vacancies > 1 ? item.no_of_vacancies + " openings" : null}</Typography>
                  </div>
                </React.Fragment>
              ))
              }
            </div>
          </div>
          {/* right navigation arrow */}
          {matchesMinWidthAboveIpad && <div ref={expArrowRRef} className={`${classes.sliderArrow} ${classes.sliderArrow_R}`} onClick={scrollsliderR} style={{ display: 'flex' }}>&#8250;</div>}
        </div>
      }

      {!showNoResults && props.started_typing &&
        <div className={classes.sliderContainerWith5Column} style={{ justifyContent: "space-between" }} >

          {dataToDisplay.map((item, index) => (
            <React.Fragment key={index}>
              <div className={`${classes.slide} ${classes.imgSlide}`}
                onClick={() => {
                  AnalyticsHandler.track('JobRolesPanel_role_BtnClick');
                  if (item.count) {
                    history.push({
                      pathname: `/${item.job_role.split(" ").join("-")}`,
                      state: {
                        role: item.job_role,
                        modeOfWork: props.modeOfWork,
                        lat: props.lat,
                        long: props.long
                      }
                    })
                  }
                }}
              >
                <div
                  className={classes.sliderImgWrapper}
                  style={{ width: 150, height: 150, overflow: 'hidden', borderRadius: 4 }}
                >
                  {item.illustration_img_url ?
                    <img className={classes.imageTag} alt="city image"
                      onLoad={(event) => { event.target.style.opacity = 1 }}
                      src={item.illustration_img_url}
                      style={{ opacity: 0 }} />
                    : null
                  }
                </div>
                <Typography variant="body2" style={{ padding: '5px 5px 0px' }}>{item.job_role}</Typography>
                <Typography variant="body2" style={{ color: 'grey', padding: '0px 5px 5px' }}>{item.no_of_vacancies && item.no_of_vacancies === 1 ? item.no_of_vacancies + " opening" : item.no_of_vacancies && item.no_of_vacancies > 1 ? item.no_of_vacancies + " openings" : null}</Typography>
              </div>
            </React.Fragment>
          ))
          }
        </div>
      }
    </React.Fragment>
  );
}

export function JobsByCities() {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = React.useState([])
  const matchesMinWidth1 = useMediaQuery('(min-width:599.95px)');
  const matchesMaxWidth1 = useMediaQuery('(max-width:1279.95px)');
  const matchesMinWidth2 = useMediaQuery('(min-width:1280px)');
  const matchesMinWidthAboveIpad = useMediaQuery('(min-width:1025px)');

  const handleCitiesList = () => {
    ApiHandler.getJobsByCities(response => {
      if (response.status == 200) {
        //let arr_temp = response.data ? response.data.slice(0, 9) : response.data;
        let arr_temp = response.data
        setData(arr_temp);
        sessionStorage.setItem('jobsByCities', JSON.stringify(arr_temp))
        //setElemVisibleOnload(true); // for IXD. to not show the location bar before content load
      }
      else {
        console.log("error in getting a successful response")
      }
    })
  }

  React.useEffect(() => {
    let storedData = sessionStorage.getItem('jobsByCities')
    if (storedData) {
      setData(JSON.parse(storedData))
      return
    } else handleCitiesList()
  }, [])

  //slider with navigation arrows---->starts
  const sliderContainerRef = React.useRef(null);
  const sliderRef = React.useRef(null);
  const expArrowLRef = React.useRef(null);
  const expArrowRRef = React.useRef(null);

  let scrollableWidth = 0;
  const scrollslider = (e) => {
    //let slider = e.target.nextSibling;
    let slider = sliderRef.current;
    let sliderWidth = slider.scrollWidth;
    let w = sliderContainerRef.current.offsetWidth//width of the slidercontainer
    //console.log(w)
    scrollableWidth += w;
    if (scrollableWidth <= 0) {
      let _scrollableWidth = scrollableWidth - 0
      slider && slider.animate({ transform: 'translateX(' + _scrollableWidth + 'px)' }, { duration: 250, fill: 'forwards' });
      expArrowRRef.current.style.display = "flex";
      if (scrollableWidth + w > 0) {
        e.target.style.display = "none"
      }
    }
  }
  const scrollsliderR = (e) => {
    //let slider = e.target.previousSibling;
    let slider = sliderRef.current;
    let sliderWidth = slider.scrollWidth;
    //console.log(sliderWidth)
    let w = sliderContainerRef.current.offsetWidth
    console.log(w)
    scrollableWidth -= w;

    if (scrollableWidth >= -sliderWidth) {
      let _scrollableWidth = scrollableWidth + 100
      slider && slider.animate({ transform: 'translateX(' + _scrollableWidth + 'px)' }, { duration: 250, fill: 'forwards' })
      expArrowLRef.current.style.display = "flex";
      if (scrollableWidth - w <= -sliderWidth) {
        e.target.style.display = "none"
      }
    }
  }

  React.useEffect(() => {
    if (sliderRef.current && expArrowRRef.current && sliderRef.current.scrollWidth > 612) {
      expArrowRRef.current.style.display = "flex";
    }

  }, []);
  //slider with navigation arrows---->ends

  return (
    <div className={classes.panelWrapperWithSlider}>
      <Typography variant="h1" className={classes.panelHeading}>
        {"सबसे ज़्यादा jobs वाली locations"}
      </Typography>

      {/* <div className={matchesMinWidth1 && matchesMaxWidth1 ? classes.sliderContainerWith5Column : matchesMinWidth2 ? classes.sliderContainer : classes.sliderContainer}
        style={{ minHeight: 159, justifyContent: matchesMinWidth1 ? 'center' : 'space-between' }}
      >
        <div className={`${classes.slide} ${classes.stampSlide}`}
          onClick={() => {
            sessionStorage.setItem("modeOfWork", "nearMe");
            history.push({
              pathname: `/all-jobs`,
            });
          }
          }
        >
          <div className={classes.stampSlideImgWrapper}>
            <img className={classes.imageTag} alt="near me stamp illustration image"
              onLoad={(event) => { event.target.style.opacity = 1 }}
              src={nearMeStamp}
              style={{ opacity: 0 }} />
          </div>
          <Typography align="center" variant="body2" style={{ marginTop: 2 }}>Near Me</Typography>
        </div>
        {data.map((item, index) => (
          <div key={index} className={`${classes.slide} ${classes.stampSlide}`}
            onClick={() => {
              let loc = item.job_location;
              if (loc == "Work From Home") loc = "workfromhome"
              history.push({
                pathname: `/jobs/${loc}`,
              });
            }
            }
          >
            <div className={classes.stampSlideImgWrapper}>
              <img className={classes.imageTag} alt="city stamp illustration image"
                onLoad={(event) => { event.target.style.opacity = 1 }}
                src={item.illustration_img_url ? item.illustration_img_url : placeholderStamp}
                style={{ opacity: 0 }} />
            </div>
            <Typography align="center" variant="body2" style={{ marginTop: 2, color: item.color_code ? "#" + item.color_code : 'initial' }}>{item.job_location}</Typography>
            <Typography align="center" variant="body2" >{item.no_of_vacancies && item.no_of_vacancies === 1 ? item.no_of_vacancies + " opening" : item.no_of_vacancies && item.no_of_vacancies > 1 ? item.no_of_vacancies + " openings" : null}</Typography>
          </div>
        ))
        }
      </div> */}

      {/* sliderwrapper */}
      <div className={classes.sliderWrapper}>

        {/* left navigation arrow */}
        {matchesMinWidthAboveIpad && <div ref={expArrowLRef} className={`${classes.sliderArrow} ${classes.sliderArrow_L}`} onClick={scrollslider} style={{ display: 'none' }}>&#8249;</div>}
        {/* slidercontainer */}
        <div
          className={matchesMinWidth1 && matchesMaxWidth1 ? classes.sliderContainer : matchesMinWidth2 ? classes.sliderContainer : classes.sliderContainer}
          style={{ minHeight: 159, justifyContent: 'space-between' }}
          ref={sliderContainerRef}>
          {/* slider */}
          <div className={classes.slider} ref={sliderRef}>
            {/* first tile: near me */}
            {/* <div className={`${classes.slide} ${classes.stampSlide}`}
              onClick={() => {
                sessionStorage.setItem("modeOfWork", "nearMe");
                history.push({
                  pathname: `/all-jobs`,
                });
              }
              }
            >
              <div className={classes.stampSlideImgWrapper}>
                <img className={classes.imageTag} alt="near me stamp illustration image"
                  onLoad={(event) => { event.target.style.opacity = 1 }}
                  src={nearMeStamp}
                  style={{ opacity: 0 }} />
              </div>
              <Typography align="center" variant="body2" style={{ marginTop: 2 }}>Near Me</Typography>
            </div> */}
            {/* other location tiles */}
            {data.map((item, index) => (
              <div key={index} className={`${classes.slide} ${classes.stampSlide}`}
                onClick={() => {
                  let loc = item.job_location;
                  if (loc == "Work From Home") loc = "workfromhome"
                  history.push({
                    pathname: `/jobs/${loc}`,
                  });
                }
                }
              >
                <div className={classes.stampSlideImgWrapper}>
                  <img className={classes.imageTag} alt="city stamp illustration image"
                    onLoad={(event) => { event.target.style.opacity = 1 }}
                    src={item.illustration_img_url ? item.illustration_img_url : placeholderStamp}
                    style={{ opacity: 0 }} />
                </div>
                <Typography align="center" variant="body2" style={{ marginTop: 2, color: item.color_code ? "#" + item.color_code : 'initial' }}>{item.job_location}</Typography>
                <Typography align="center" variant="body2" >{item.no_of_vacancies && item.no_of_vacancies === 1 ? item.no_of_vacancies + " opening" : item.no_of_vacancies && item.no_of_vacancies > 1 ? item.no_of_vacancies + " openings" : null}</Typography>
              </div>
            ))
            }

          </div>
        </div>
        {/* right navigation arrow */}
        {matchesMinWidthAboveIpad && <div ref={expArrowRRef} className={`${classes.sliderArrow} ${classes.sliderArrow_R}`} onClick={scrollsliderR} style={{ display: 'flex' }}>&#8250;</div>}
      </div>
    </div>
  )
}

export function TopRatedCompanies() {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = React.useState([])
  const [refresh, doRefresh] = React.useState([])

  const matchesMinWidth = useMediaQuery('(min-width:599.95px)');
  const matchesMinWidthHD = useMediaQuery('(min-width:1279.95px)');

  const handleCompaniesList = () => {
    ApiHandler.getTopRatedCompanies(response => {
      if (response.status == 200) {
        let _response = response.data;
        let res = _response.map((item, index) => {
          let companyNameArray = item.company_name ? item.company_name.split("-") : "";
          // remove location after ':' and remove pvt ltd etc and remove content following '('
          let companyNameFinal = companyNameArray ? companyNameArray[0].split(':')[0].split("(")[0].replace("Pvt. Ltd.", "").replace("Private Limited", "").replace("Pvt Ltd.", "").replace("Pvt. Ltd", "").replace("Pvt Ltd", "").replace("pvt ltd", "").replace("Ltd", "").replace("Ltd.", "").replace("Limited", "").replace("ltd", "").trim() : "";
          item.company_name = companyNameFinal
          return item
        })

        //setElemVisibleOnload(true); // for IXD. to not show the location bar before content load
        // if (matchesMinWidth) {
        //   res = res.slice(0, 12)
        //   console.log("sliced")
        // }
        //console.log(matchesMinWidth)

        setData(res)
        doRefresh(res)
      }
      else {
        console.log("error in getting a successful response")
      }
    })
  }

  React.useEffect(() => {
    handleCompaniesList()
  }, [])

  React.useEffect(() => {
    if (matchesMinWidthHD) {
      setData(data.slice(0, 14))
    } else setData(data.slice(0, 12))
  }, [refresh])

  return (
    <div className={classes.panelWrapper}>
      <Typography variant="h1" className={classes.panelHeading}>
        {"Companies के employees का अनुभव"}
      </Typography>
      {/* show grids in column if on desktop else show in one line */}
      {matchesMinWidth ? <div className={classes.sliderContainerWith6Column}>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <div
              className={classes.handCursor}
              onClick={() => {
                if (item.company_id) {
                  sessionStorage.setItem("pageStateOnReturn", "goBack")
                  history.push({
                    pathname: `/${"CompanyProfile/" + item.company_name + "-" + item.company_id}`,
                  })
                }
              }}
            >
              <div className={`${classes.sliderImgWrapperWithBg}`}>
                {item.company_image_url ?
                  <img className={classes.imageTag} alt={`${item.company_name} logo image}`}
                    onLoad={(event) => { event.target.style.opacity = 1 }}
                    src={item.company_image_url}
                    style={{ opacity: 0 }} />
                  : null
                }
              </div>
              <Typography align="center" variant="body2" style={{ marginTop: 5, padding: 0, maxWidth: 150 }}>{item.company_name}</Typography>
            </div>
          </React.Fragment>
        ))
        }
      </div> :
        <div className={classes.sliderContainer} style={{marginBottom: 0}}>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <div
                className={classes.slide}
                onClick={() => {
                  if (item.company_id) {
                    sessionStorage.setItem("pageStateOnReturn", "goBack")
                    history.push({
                      pathname: `/${"CompanyProfile/" + item.company_name + "-" + item.company_id}`,
                    })
                  }
                }}
              >
                <div className={`${classes.sliderImgWrapperWithBg}`}>
                  {item.company_image_url ?
                    <img className={classes.imageTag} alt={`${item.company_name} logo image}`}
                      onLoad={(event) => { event.target.style.opacity = 1 }}
                      src={item.company_image_url}
                      style={{ opacity: 0 }} />
                    : null
                  }
                </div>
                <Typography align="center" variant="body2" style={{ marginTop: 5, padding: 0, maxWidth: 150 }}>{item.company_name}</Typography>
              </div>
            </React.Fragment>
          ))
          }
        </div>
      }
    </div>
  )
}

export function CompaniesByCities(props) {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = React.useState([])

  const matchesMinWidth1 = useMediaQuery('(min-width:599.95px)');
  const matchesMaxWidth1 = useMediaQuery('(max-width:1279.95px)');
  const matchesMinWidth2 = useMediaQuery('(min-width:1280px)');
  const matchesMinWidthAboveIpad = useMediaQuery('(min-width:1025px)');

  const handleCompaniesList = () => {
    ApiHandler.getCompaniesByCities(response => {
      if (response.status == 200) {
        let arr_temp = response.data[0].results;
        //console.log(arr_temp)
        setData(arr_temp);
        //setElemVisibleOnload(true); // for IXD. to not show the location bar before content load
      }
      else {
        console.log("error in getting a successful response")
      }
    })
  }

  React.useEffect(() => {
    handleCompaniesList()
  }, [])

  //slider with navigation arrows---->starts
  const sliderContainerRef = React.useRef(null);
  const sliderRef = React.useRef(null);
  const expArrowLRef = React.useRef(null);
  const expArrowRRef = React.useRef(null);

  let scrollableWidth = 0;
  const scrollslider = (e) => {
    //let slider = e.target.nextSibling;
    let slider = sliderRef.current;
    let sliderWidth = slider.scrollWidth;
    let w = sliderContainerRef.current.offsetWidth//width of the slidercontainer
    //console.log(w)
    scrollableWidth += w;
    if (scrollableWidth <= 0) {
      let _scrollableWidth = scrollableWidth - 0
      slider && slider.animate({ transform: 'translateX(' + _scrollableWidth + 'px)' }, { duration: 250, fill: 'forwards' });
      expArrowRRef.current.style.display = "flex";
      if (scrollableWidth + w > 0) {
        e.target.style.display = "none"
      }
    }
  }
  const scrollsliderR = (e) => {
    //let slider = e.target.previousSibling;
    let slider = sliderRef.current;
    let sliderWidth = slider.scrollWidth;
    //console.log(sliderWidth)
    let w = sliderContainerRef.current.offsetWidth
    console.log(w)
    scrollableWidth -= w;

    if (scrollableWidth >= -sliderWidth) {
      let _scrollableWidth = scrollableWidth + 100
      slider && slider.animate({ transform: 'translateX(' + _scrollableWidth + 'px)' }, { duration: 250, fill: 'forwards' })
      expArrowLRef.current.style.display = "flex";
      if (scrollableWidth - w <= -sliderWidth) {
        e.target.style.display = "none"
      }
    }
  }

  React.useEffect(() => {
    if (sliderRef.current && expArrowRRef.current && sliderRef.current.scrollWidth > 612) {
      expArrowRRef.current.style.display = "flex";
    }

  }, []);
  //slider with navigation arrows---->ends

  return (
    <div className={classes.panelWrapperWithSlider}>
      <Typography variant="h1" className={classes.panelHeading}>
        {"आपके शहर में companies"}
      </Typography>

      {/* sliderwrapper */}
      <div className={classes.sliderWrapper}>

        {/* left navigation arrow */}
        {matchesMinWidthAboveIpad && <div ref={expArrowLRef} className={`${classes.sliderArrow} ${classes.sliderArrow_L}`} onClick={scrollslider} style={{ display: 'none' }}>&#8249;</div>}
        {/* slidercontainer */}
        <div
          className={matchesMinWidth1 && matchesMaxWidth1 ? classes.sliderContainer : matchesMinWidth2 ? classes.sliderContainer : classes.sliderContainer}
          style={{ minHeight: 159, justifyContent: 'space-between' }}
          ref={sliderContainerRef}>
          {/* slider */}
          <div className={classes.slider} ref={sliderRef}>

            {/* other location tiles */}
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <div className={`${classes.slide} ${classes.companySlide}`}
                  onClick={() => {
                    // if (props.isSignedIn) {
                    history.push({
                      pathname: `/companies/${item.location}`,
                    })
                    // } else props.handleDrawer()

                  }
                  }
                >
                  <div
                    className={classes.sliderImgWrapper}
                    style={{ width: 150, height: 150, overflow: 'hidden', borderRadius: 4 }}
                  >
                    {item.illustration_image ?
                      <img className={classes.imageTag} alt="city image"
                        onLoad={(event) => { event.target.style.opacity = 1 }}
                        src={item.illustration_image}
                        style={{ opacity: 0 }} />
                      : null
                    }
                  </div>
                  <Typography variant="body2" style={{ padding: '5px 5px 0px' }}>{item.location}</Typography>
                  <Typography variant="body2" style={{ color: 'grey', padding: '0px 5px 5px' }}>{item.count && item.count === 1 ? item.count + " opening" : item.count && item.count > 1 ? item.count + " openings" : null}</Typography>
                </div>
              </React.Fragment>
            ))
            }

          </div>
        </div>
        {/* right navigation arrow */}
        {matchesMinWidthAboveIpad && <div ref={expArrowRRef} className={`${classes.sliderArrow} ${classes.sliderArrow_R}`} onClick={scrollsliderR} style={{ display: 'flex' }}>&#8250;</div>}
      </div>

    </div>
  )
}