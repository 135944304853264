import React, { useRef } from 'react';
import { Icon, Paper, TextField, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  bgOverlay: {
    background: 'rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  searchBarOuterWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    transform: 'scale(1)',
    zIndex: 9,
    marginTop: 60,
    marginBottom: 14,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  searchBarWrapper: {
    display: "flex",
    flexDirection: "row",
    background: "white",
    width: 400,
    height: 40,
    paddingLeft: 10,
    paddingRight: 5,
    borderRadius: 4,
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    "&.shrink": {
      height: 40,
    },
    [theme.breakpoints.down('md')]: {
      width: '94%',
      height: 36,
    },
  },
  searchBarIconWrapper: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
    height: '100%',
    width: 40,
    minWidth: 40,
    textTransform: "none",
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  icon: {
    //color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    color: '#999',
    fontSize: 16,
    marginTop: 5,
  },
  comboBoxRoot: {
    marginTop: 0,
    border: '0!important',
    "& .MuiFormLabel-root": {
      color: "black",
      fontWeight: "700",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#5BC2A8",
      fontWeight: "700",
    },
  },
  inputRoot: {
    padding: '0 10px 0 0!important',
    borderColor: 'transparent!important',
    fontSize: 14,
    //   "& .MuiIconButton-label": { 
    //     display: "flex",  
    // },
    "& *, &:hover > *": {
      borderColor: 'transparent!important',
    },
  },

  popupIndicator: {
    display: 'none'
  },

  input: {
    height: 40,
    maxWidth: '98%',
    "&::-webkit-input-placeholder": {
      color: 'rgba(0, 0, 0, 0.6)',
      opacity: 1,
    },
    [theme.breakpoints.down('md')]: {
      height: 36,
    },
  },
  popper: {
    marginTop: 16,
  },
  paper: {
    borderRadius: 10,
  },
  option: {
    textTransform: 'capitalize',
  },
  TfRoot: {
    width: '100%',
    marginBottom: 20,
    '& input': {
      padding: 10,
      border: 'none',
      //height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'none',
    },
  },
}));

export default function SearchBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.searchBarOuterWrapper}>
      <div className={`${classes.searchBarWrapper} ${classes.shrink}`} style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)' }}>
        <TextField
          id="job_role_search"
          type="search"
          variant="outlined"
          placeholder="Positions ढूंढें"
          value={props.role}
          onClick={props.setOnSearchClick}
          classes={{ root: classes.TfRoot }}
        />
        <div className={classes.searchBarIconWrapper}>
          <SearchIcon style={{ fontSize: 25 }} />
        </div>
      </div>
    </div>
  );
}

