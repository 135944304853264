import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import React from 'react'
import { useStyles } from '../views/jobs/styles';
import CloseIcon from '@material-ui/icons/Close';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useHistory } from 'react-router-dom';
import AnalyticsHandler from '../AnalyticsHandler';
import ApiHandler from '../ApiHandler';
import SnackBarGeneric from './SnackBarGeneric';
import Wallpaper from '../assets/Iconwallpaper2.png';
import SignInUpDrawer from './SignInDrawer';

const DeleteAccount = () => {

    const classes = useStyles();
    const theme = useTheme();

    let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
    const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
    const history = useHistory();

    let jobseekerID = localStorage.getItem("jobseeker_id");

    //dialog box for delete--starts
    const [isConfDialogOpen, setIsConfDialogOpen] = React.useState(false);

    const handleConfDialogClose = () => {
        setIsConfDialogOpen(false);
    }

    // drawer callback starts
    const [refresh, doRefresh] = React.useState(false);
    const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
    const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
    const handleDrawer = () => {
        setCanDrawerOpen(true)
        doRefresh(prev => !prev)
    }
    // drawer callback ends

    const handleSignedInStatus = (v) => {
        setIsSignedIn(v);
      }

    // snackbar callback starts
    const [snackBarMessage, setSnackBarMessage] = React.useState('')
    const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
    const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
    const [snackBarMessageSeverity, setSnackBarMessageSeverity] = React.useState("success");
    const handleSnackBar = (message, severity) => {
        if (severity) {
            setSnackBarMessageSeverity(severity);
        } else {
            setSnackBarMessageSeverity("success");
        }
        setCanSnackBarOpen(true);
        setSnackBarMessage(message);
        doRefreshSnackBar(prev => !prev);
    }
    // snackbar callback ends

    const clearLocalStorageAndSignOutFE = () => {
        localStorage.removeItem('is_logged_in');
        localStorage.removeItem('jobseeker_id');
        localStorage.removeItem('profile_completed');
        setIsSignedIn(false);
        setTimeout(() => { // to reset the form values if session expires while form filling
            window.location.reload();
        }, 1000)
    }

    const deleteAccount = () => {
        AnalyticsHandler.track('Profile_deleteAccount_2_BtnClick');
        let data = { jobseeker_id: jobseekerID }
        ApiHandler.deleteAccount(data, (response) => {
            if (response.status === 200) {
                AnalyticsHandler.track('Profile_deleteAccount_Success');
                handleLogout("onDeleteAccount");
            } else if (response.response) {
                if (response.response.data.message === "Session Expire") {
                    handleLogout("logoutOnSessionExpire");
                }
            } else {
                handleSnackBar("Something went wrong. Please try again.", "error");
            }
        })
    }

    const handleLogout = (caseParam) => {
        ApiHandler.logout(function (res) {
            if (res.status === 200) {
                AnalyticsHandler.track('Profile_logout_Success');
                AnalyticsHandler.reset();
                clearLocalStorageAndSignOutFE();
                if (caseParam === "onDeleteAccount") {
                    // handleDialogClose();
                    // props.handleSnackBar("Your account has been deleted successfully.");
                    handleSnackBar("Done");
                    return
                }
                handleConfDialogClose();
                handleSnackBar("You have logged out successfully.")
            } else if (res.response) { // on error
                if (res.response.data.message === "Session Expire") {
                    clearLocalStorageAndSignOutFE();
                    handleSnackBar("Your Session expired. Please login again.", "error")
                } else {
                    handleSnackBar("Something went wrong. Please try again.", "error")
                }
                if (process.env.REACT_APP_NODE_ENV === "development") { // for development env do the logout without auth on click
                    clearLocalStorageAndSignOutFE();
                    if (caseParam === "onDeleteAccount") {
                        handleSnackBar("Your account has been deleted successfully.");
                        history.goBack();
                        return
                    } else if (caseParam === "logoutOnSessionExpire") {
                        handleSnackBar("Your Session expired. Please login again.", "error");
                        return
                    }
                    handleConfDialogClose();
                    handleSnackBar("You have logged out successfully.")
                }
            }
        })
    }

    return (
        <>
            <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} severity={snackBarMessageSeverity} />
            {isSignedIn ? <Dialog
                aria-labelledby="delete-account-dialog"
                open
                maxWidth={'sm'}
                fullScreen
                style={{ overflow: 'hidden' }}
            >
                <IconButton aria-label="close" className={classes.closeButton}
                    onClick={() => history.goBack()}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers style={{ padding: 0, overflow: 'hidden' }}>
                    <Grid direction='column' alignItems='center' container justifyContent="center">
                        <IconButton aria-label="close" >
                            <WarningRoundedIcon style={{ fontSize: '8rem', color: theme.palette.common.yellowDark }} />
                        </IconButton>
                        <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5', textAlign: 'center' }}>
                            हम आपकी सहायता कैसे कर सकते हैं?
                        </Typography>

                        <Button
                            id="profile_whatsapp_button"
                            variant="contained"
                            className={`${classes.pageButton} ${classes.pageButton_pri}`}
                            style={{ textTransform: 'unset', width: 'auto' }}
                            onClick={() => {
                                window.location.href = "https://api.whatsapp.com/send?phone=919580899412&text=Hi Meet team"
                            }}
                        >
                            हमें WhatsApp पे 'Hi' भेजें
                        </Button>
                    </Grid>

                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5', textAlign: 'center' }}>
                            मुझे अपना Meeत account delete करना हैं. मैं समझता हूँ कि ऐसा करने से मुझे new opportunities और companies नहीं मिलेगी।
                        </Typography>
                        <Button
                            color="primary"
                            size="medium"
                            style={{ color: '#fff', backgroundColor: theme.palette.common.red, textTransform: 'unset', width: 'auto' }}
                            onClick={deleteAccount}
                        >
                            Delete Account
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog> :
                <div className={classes.pageInfo}
                    style={{
                        // paddingTop: matchesMinHeight ? '70px' : 0,
                        paddingTop: 0,
                        height: '100vh',
                        justifyContent: 'center',
                        backgroundImage: `url(${Wallpaper})`,
                        backgroundColor: '#fcfcfc'
                    }}
                >
                    {/* <img src={NoResultsImage} style={{ width: matchesMinHeight ? 200 : 100 }} /> */}
                    <Typography className={classes.pageInfoText} variant="subtitle1">Please sign in to delete or access your profile.</Typography>
                    {/* <Typography className={classes.pageInfoText} variant="h3">अगर आपका Meeत पे account है sign in करें।</Typography> */}
                    <Button
                        id="Search_all_positions"
                        variant="contained"
                        className={`${classes.pageButton} ${classes.pageButton_pri}`}
                        style={{ width: 170 }}
                        onClick={() => {
                            AnalyticsHandler.track('Profile_signIn_BtnClick');
                            handleDrawer();
                        }}
                    >
                        Sign in
                    </Button>

                    {/* <Typography className={classes.pageInfoText}
              style={{ paddingTop: 88 }}
              variant="h3">अपना account बनाने के लिए हमें contact करें।</Typography>
            <Button
              variant="contained"
              className={`${classes.pageButton} ${classes.pageButton_pri}`}
              style={{ width: 170 }}
              onClick={() => {
                window.location.href = "https://api.whatsapp.com/send?phone=919580899412&text=Hi Meet team"
              }}
            >
              Whatsapp करें
            </Button> */}

                </div>}

            <SignInUpDrawer
                canDrawerOpen={canDrawerOpen}
                refresh={refresh}
                isSignedIn={isSignedIn}
                setIsSignedIn={handleSignedInStatus}
                setForRefreshOnly={setForRefreshOnly}
                setCanDrawerOpen={setCanDrawerOpen}
                doRefresh={doRefresh}
            />
        </>
    )
}

export default DeleteAccount