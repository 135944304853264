import error from "../../assets/error.png"
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {withRouter} from 'react-router-dom';

export default function Error(props) {

    const nextPath = (path) =>{
        props.history.push(path);
    }

    return (
        <div style={{ width: 600, height: 600, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '0 auto', paddingTop: 30 }}>           
            <div style={{width: '100%', height: '65%', overflow: 'hidden'}}> 
            <img style={{width: '100%',}} src={error} /></div>
            <Typography variant="h1" style={{ marginTop: 10 }}>404</Typography>
            
            <Typography variant="body2" style={{ marginTop: 0, marginBottom: 40,fontWeight: 200, fontSize: 16, color: 'grey', textTransform: 'uppercase' }}>Page not found</Typography>

            <IconButton aria-label="back home" style={{background: 'rgb(20, 150, 123)', width: 200, height: 50, borderRadius: 10}}
                 
             onClick = { () => nextPath('/Login_2') } >
               <span style={{color: 'white', fontSize: 16, textDecoration: 'none'}}> Go back to Home Page </span>
              </IconButton>

        </div>
    )
}