import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function LinearGradient() {
  return (
    <linearGradient xmlns="http://www.w3.org/2000/svg" id="linear-gradient" x1="40" y1="80" x2="40" gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#fcbd01" stopOpacity="0" />
      <stop offset="0.28" stopColor="#fcbd01" stopOpacity="0.31" />
      <stop offset="0.63" stopColor="#fcbd01" stopOpacity="0.68" />
      <stop offset="0.88" stopColor="#fcbd01" stopOpacity="0.91" />
      <stop offset="1" stopColor="#fcbd01" />
    </linearGradient>
  )
}

function DropShadow() {
  return (
    <filter id="dropShadow" x="0" y="0" width="200%" height="200%">
      <feOffset result="offOut" in="SourceGraphic" dx="1" dy="1" />
      <feColorMatrix result="matrixOut" in="offOut" type="matrix"
        values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.5 0" />
      <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="2" />
      <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
    </filter>
  )
}

export function VacancyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10,13C9.65,13.59 9.36,14.24 9.19,14.93C6.5,15.16 3.9,16.42 3.9,17V18.1H9.2C9.37,18.78 9.65,19.42 10,20H2V17C2,14.34 7.33,13 10,13M10,4A4,4 0 0,1 14,8C14,8.91 13.69,9.75 13.18,10.43C12.32,10.75 11.55,11.26 10.91,11.9L10,12A4,4 0 0,1 6,8A4,4 0 0,1 10,4M10,5.9A2.1,2.1 0 0,0 7.9,8A2.1,2.1 0 0,0 10,10.1A2.1,2.1 0 0,0 12.1,8A2.1,2.1 0 0,0 10,5.9M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14Z" />
    </SvgIcon>
  );
}

export function EmployeesOutlinedIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12,5A3.5,3.5 0 0,0 8.5,8.5A3.5,3.5 0 0,0 12,12A3.5,3.5 0 0,0 15.5,8.5A3.5,3.5 0 0,0 12,5M12,7A1.5,1.5 0 0,1 13.5,8.5A1.5,1.5 0 0,1 12,10A1.5,1.5 0 0,1 10.5,8.5A1.5,1.5 0 0,1 12,7M5.5,8A2.5,2.5 0 0,0 3,10.5C3,11.44 3.53,12.25 4.29,12.68C4.65,12.88 5.06,13 5.5,13C5.94,13 6.35,12.88 6.71,12.68C7.08,12.47 7.39,12.17 7.62,11.81C6.89,10.86 6.5,9.7 6.5,8.5C6.5,8.41 6.5,8.31 6.5,8.22C6.2,8.08 5.86,8 5.5,8M18.5,8C18.14,8 17.8,8.08 17.5,8.22C17.5,8.31 17.5,8.41 17.5,8.5C17.5,9.7 17.11,10.86 16.38,11.81C16.5,12 16.63,12.15 16.78,12.3C16.94,12.45 17.1,12.58 17.29,12.68C17.65,12.88 18.06,13 18.5,13C18.94,13 19.35,12.88 19.71,12.68C20.47,12.25 21,11.44 21,10.5A2.5,2.5 0 0,0 18.5,8M12,14C9.66,14 5,15.17 5,17.5V19H19V17.5C19,15.17 14.34,14 12,14M4.71,14.55C2.78,14.78 0,15.76 0,17.5V19H3V17.07C3,16.06 3.69,15.22 4.71,14.55M19.29,14.55C20.31,15.22 21,16.06 21,17.07V19H24V17.5C24,15.76 21.22,14.78 19.29,14.55M12,16C13.53,16 15.24,16.5 16.23,17H7.77C8.76,16.5 10.47,16 12,16Z" />
    </SvgIcon>
  );
}

export function EmployeesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,17c0,0.55-0.45,1-1,1H7c-0.55,0-1-0.45-1-1l0-0.61 c0-1.18,0.68-2.26,1.76-2.73C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43L0,17 c0,0.55,0.45,1,1,1l3.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1 c0.4,0.68,0.63,1.46,0.63,2.29V18l3.5,0c0.55,0,1-0.45,1-1L24,16.43z M12,6c1.66,0,3,1.34,3,3c0,1.66-1.34,3-3,3s-3-1.34-3-3 C9,7.34,10.34,6,12,6z" />
    </SvgIcon>
  );
}

export function BenefitsIcon(props) {
  return (
    <SvgIcon {...props}>
      <polygon points="19,9 20.25,6.25 23,5 20.25,3.75 19,1 17.75,3.75 15,5 17.75,6.25" /><polygon points="19,15 17.75,17.75 15,19 17.75,20.25 19,23 20.25,20.25 23,19 20.25,17.75" /><path d="M11.5,9.5L9,4L6.5,9.5L1,12l5.5,2.5L9,20l2.5-5.5L17,12L11.5,9.5z M9.99,12.99L9,15.17l-0.99-2.18L5.83,12l2.18-0.99 L9,8.83l0.99,2.18L12.17,12L9.99,12.99z" />
    </SvgIcon>
  );
}

export function OtherRequirementsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M12,10.5A1.5,1.5 0 0,1 13.5,12A1.5,1.5 0 0,1 12,13.5A1.5,1.5 0 0,1 10.5,12A1.5,1.5 0 0,1 12,10.5M7.5,10.5A1.5,1.5 0 0,1 9,12A1.5,1.5 0 0,1 7.5,13.5A1.5,1.5 0 0,1 6,12A1.5,1.5 0 0,1 7.5,10.5M16.5,10.5A1.5,1.5 0 0,1 18,12A1.5,1.5 0 0,1 16.5,13.5A1.5,1.5 0 0,1 15,12A1.5,1.5 0 0,1 16.5,10.5Z" />
    </SvgIcon>
  );
}

export function ReviewsOutlinedIcon(props) {
  return (
    <SvgIcon {...props}>
      <g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><path d="M20,2H4C2.9,2,2,2.9,2,4v18l4-4h14c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M20,16H5.17L4,17.17V4h16V16z" /><polygon points="12,15 13.57,11.57 17,10 13.57,8.43 12,5 10.43,8.43 7,10 10.43,11.57" /></g></g>
    </SvgIcon>
  );
}

export function FemaleOutlinedIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 2C6.5 2 2 6.5 2 12V22H22V12C22 6.5 17.5 2 12 2M12 4C14.9 4 17.44 5.56 18.84 7.88C18.41 7.95 17.96 8 17.5 8C14.6 8 12.06 6.44 10.66 4.12C11.09 4.05 11.54 4 12 4M8.08 5.03C7.45 6.92 6.13 8.5 4.42 9.47C5.05 7.58 6.37 6 8.08 5.03M4 11.86C6.6 10.88 8.69 8.87 9.74 6.31C11.58 8.56 14.37 10 17.5 10C18.25 10 18.97 9.91 19.67 9.76C19.88 10.47 20 11.22 20 12C20 16.41 16.41 20 12 20S4 16.41 4 12V11.86M4 20V18C4.57 18.75 5.25 19.43 6 20H4M20 20H18C18.75 19.43 19.43 18.75 20 18V20M13.75 13C13.75 12.31 14.31 11.75 15 11.75S16.25 12.31 16.25 13 15.69 14.25 15 14.25 13.75 13.69 13.75 13M7.75 13C7.75 12.31 8.31 11.75 9 11.75S10.25 12.31 10.25 13 9.69 14.25 9 14.25 7.75 13.69 7.75 13Z" />
    </SvgIcon>
  );
}

export function GenderOutlinedIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M17.58,4H14V2H21V9H19V5.41L15.17,9.24C15.69,10.03 16,11 16,12C16,14.42 14.28,16.44 12,16.9V19H14V21H12V23H10V21H8V19H10V16.9C7.72,16.44 6,14.42 6,12A5,5 0 0,1 11,7C12,7 12.96,7.3 13.75,7.83L17.58,4M11,9A3,3 0 0,0 8,12A3,3 0 0,0 11,15A3,3 0 0,0 14,12A3,3 0 0,0 11,9Z" />
    </SvgIcon>
  );
}

export function SkillsOutlinedIcon(props) {
  return (
    <SvgIcon {...props}>
      <g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M21.67,18.17l-5.3-5.3h-0.99l-2.54,2.54v0.99l5.3,5.3c0.39,0.39,1.02,0.39,1.41,0l2.12-2.12 C22.06,19.2,22.06,18.56,21.67,18.17z M18.84,19.59l-4.24-4.24l0.71-0.71l4.24,4.24L18.84,19.59z" /></g><g><path d="M17.34,10.19l1.41-1.41l2.12,2.12c1.17-1.17,1.17-3.07,0-4.24l-3.54-3.54l-1.41,1.41V1.71L15.22,1l-3.54,3.54l0.71,0.71 h2.83l-1.41,1.41l1.06,1.06l-2.89,2.89L7.85,6.48V5.06L4.83,2.04L2,4.87l3.03,3.03h1.41l4.13,4.13l-0.85,0.85H7.6l-5.3,5.3 c-0.39,0.39-0.39,1.02,0,1.41l2.12,2.12c0.39,0.39,1.02,0.39,1.41,0l5.3-5.3v-2.12l5.15-5.15L17.34,10.19z M9.36,15.34 l-4.24,4.24l-0.71-0.71l4.24-4.24l0,0L9.36,15.34L9.36,15.34z" /></g></g></g>
    </SvgIcon>
  );
}

export function OverallRatingIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M18.09 11.77L19.56 18.1L14 14.74L8.44 18.1L9.9 11.77L5 7.5L11.47 6.96L14 1L16.53 6.96L23 7.5L18.09 11.77M2 12.43C2.19 12.43 2.38 12.37 2.55 12.26L5.75 10.15L4.18 8.79L1.45 10.59C.989 10.89 .861 11.5 1.16 12C1.36 12.27 1.68 12.43 2 12.43M1.16 21.55C1.36 21.84 1.68 22 2 22C2.19 22 2.38 21.95 2.55 21.84L6.66 19.13L7 17.76L7.31 16.31L1.45 20.16C.989 20.47 .861 21.09 1.16 21.55M1.45 15.38C.989 15.68 .861 16.3 1.16 16.76C1.36 17.06 1.68 17.21 2 17.21C2.19 17.21 2.38 17.16 2.55 17.05L7.97 13.5L8.24 12.31L7.32 11.5L1.45 15.38Z" />
    </SvgIcon>
  );
}

export function Salary(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M3,6H21V18H3V6M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M7,8A2,2 0 0,1 5,10V14A2,2 0 0,1 7,16H17A2,2 0 0,1 19,14V10A2,2 0 0,1 17,8H7Z" />
    </SvgIcon>
  )
}

export function Incentives(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M15 15V17H18V20H20V17H23V15H20V12H18V15M14.97 11.61C14.85 10.28 13.59 8.97 12 9C10.3 9.03 9 10.3 9 12C9 13.7 10.3 14.94 12 15C12.38 15 12.77 14.92 13.14 14.77C13.41 13.67 13.86 12.63 14.97 11.61M13 16H7C7 14.9 6.11 14 5 14V10C6.11 10 7 9.11 7 8H17C17 9.11 17.9 10 19 10V10.06C19.67 10.06 20.34 10.18 21 10.4V6H3V18H13.32C13.1 17.33 13 16.66 13 16Z" />
    </SvgIcon>
  )
}

export function BohotKharabIcon(props) {
  return (
    <SvgIcon {...props} style={{
      fontSize: '3.5rem',
      overflow: 'visible',
      height: '1rem'
    }}>
      <defs>
        {/* <radialGradient id="grad1" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
      <stop offset="0%" style={{stopColor:"#fcbd01",
      stopOpacity:1}} />
      <stop offset="100%" style={{stopColor:"#f02121",
      stopOpacity:'0.8'}} />
    </radialGradient> */}

        {/* <linearGradient xmlns="http://www.w3.org/2000/svg" id="linear-gradient" x1="40" y1="80" x2="40" gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#fcbd01" stopOpacity="0"/>
      <stop offset="0.28" stopColor="#fcbd01" stopOpacity="0.31"/>
      <stop offset="0.63" stopColor="#fcbd01" stopOpacity="0.68"/>
      <stop offset="0.88" stopColor="#fcbd01" stopOpacity="0.91"/>
      <stop offset="1" stopColor="#fcbd01"/>
      </linearGradient> */}
        {/* <LinearGradient/> */}
        <DropShadow />
      </defs>
      <circle fill="#fcbd01" cx="40" cy="40" r="40" filter="url(#dropShadow)" />
      {/* <circle fill="url(#linear-gradient)" cx="40" cy="40" r="40" /> */}
      <path fill="none" stroke="#e74d56" strokeWidth="3px" strokeLinecap="round" strokeMiterlimit="10" d="M51.75,56c0-6.08-5.26-11-11.75-11S28.25,49.92,28.25,56" />
      <path fill="none" stroke="#000" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M24,35.5a4.5,4.5,0,0,0,9,0" />
      <path fill="none" stroke="#000" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M47,35.5a4.5,4.5,0,0,0,9,0" />
      <line fill="none" stroke="#000" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" x1="44" y1="31" x2="58" y2="25" />
      <line fill="none" stroke="#000" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" x1="36" y1="31" x2="22" y2="25" />
    </SvgIcon>
  );
}

export function BohotKharabGreyIcon(props) {
  return (
    <SvgIcon {...props} style={{
      fontSize: '3.5rem',
      overflow: 'visible',
      height: '1rem'
    }}>
      <defs>
        <LinearGradient />
      </defs>
      {/* <circle fill="#fbd768" cx="40" cy="40" r="40" /> */}
      <circle fill="url(#linear-gradient)" cx="40" cy="40" r="40" />
      <path fill="none" stroke="#e74d56" strokeWidth="3px" strokeLinecap="round" strokeMiterlimit="10" d="M51.75,56c0-6.08-5.26-11-11.75-11S28.25,49.92,28.25,56" />
      <path fill="none" stroke="#333" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M24,35.5a4.5,4.5,0,0,0,9,0" />
      <path fill="none" stroke="#333" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M47,35.5a4.5,4.5,0,0,0,9,0" />
      <line fill="none" stroke="#333" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" x1="44" y1="31" x2="58" y2="25" />
      <line fill="none" stroke="#333" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" x1="36" y1="31" x2="22" y2="25" />
    </SvgIcon>
  );
}

export function KharabIcon(props) {
  return (
    <SvgIcon {...props} style={{
      fontSize: '3.5rem',
      overflow: 'visible',
      height: '1rem'
    }}>
      <defs>
        <DropShadow />
      </defs>
      <circle fill="#fcbd01" cx="40" cy="40" r="40" filter="url(#dropShadow)" />
      <path fill="none" stroke="#000" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M24.16,29.64a4.5,4.5,0,1,0,9-.08" />
      <path fill="none" stroke="#000" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M47.15,29.43a4.5,4.5,0,1,0,9-.08" />
      <path stroke="#e74d56" fill="none" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M28.89,55.1c3.92-8,18.92-8.17,23-.21" />
    </SvgIcon>
  );
}

export function KharabGreyIcon(props) {
  return (
    <SvgIcon {...props} style={{
      fontSize: '3.5rem',
      overflow: 'visible',
      height: '1rem'
    }}>
      <defs>
        <LinearGradient />
      </defs>
      {/* <circle fill="#fbd768" cx="40" cy="40" r="40" /> */}
      <circle fill="url(#linear-gradient)" cx="40" cy="40" r="40" />
      <path fill="none" stroke="#333" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M24.16,29.64a4.5,4.5,0,1,0,9-.08" />
      <path fill="none" stroke="#333" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M47.15,29.43a4.5,4.5,0,1,0,9-.08" />
      <path fill="none" stroke="#e74d56" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M28.89,55.1c3.92-8,18.92-8.17,23-.21" />
    </SvgIcon>
  );
}

export function ThikThakIcon(props) {
  return (
    <SvgIcon {...props} style={{
      fontSize: '3.5rem',
      overflow: 'visible',
      height: '1rem'
    }}>
      <defs>
        <DropShadow />
      </defs>
      <circle fill="#fcbd01" cx="40" cy="40" r="40" filter="url(#dropShadow)" />
      <circle fill="#000" cx="30.5" cy="34" r="3" />
      <circle fill="#000" cx="49.5" cy="34" r="3" />
      <line stroke="#e74d56" fill="none" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" x1="28.5" y1="50" x2="51.5" y2="50" />
    </SvgIcon>
  );
}

export function ThikThakGreyIcon(props) {
  return (
    <SvgIcon {...props} style={{
      fontSize: '3.5rem',
      overflow: 'visible',
      height: '1rem'
    }}>
      <defs>
        <LinearGradient />
      </defs>
      {/* <circle fill="#fbd768" cx="40" cy="40" r="40" /> */}
      <circle fill="url(#linear-gradient)" cx="40" cy="40" r="40" />
      <circle fill="#333" cx="30.5" cy="34" r="3" />
      <circle fill="#333" cx="49.5" cy="34" r="3" />
      <line stroke="#e74d56" fill="none" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" x1="28.5" y1="50" x2="51.5" y2="50" />
    </SvgIcon>
  );
}

export function AchhaIcon(props) {
  return (
    <SvgIcon {...props} style={{
      fontSize: '3.5rem',
      overflow: 'visible',
      height: '1rem'
    }}>
      <defs>
        <DropShadow />
      </defs>
      <circle fill="#fcbd01" cx="40" cy="40" r="40" filter="url(#dropShadow)" />
      <path fill="none" stroke="#000" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M24.25,32.75a4.5,4.5,0,0,1,9,0" />
      <path fill="none" stroke="#000" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M47.25,32.75a4.5,4.5,0,0,1,9,0" />
      <path stroke="#e74d56" fill="none" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M28.75,49.75c4,8,19,8,23,0" />
    </SvgIcon>
  );
}

export function AchhaGreyIcon(props) {
  return (
    <SvgIcon {...props} style={{
      fontSize: '3.5rem',
      overflow: 'visible',
      height: '1rem'
    }}>
      <defs>
        <LinearGradient />
      </defs>
      {/* <circle fill="#fbd768" cx="40" cy="40" r="40" /> */}
      <circle fill="url(#linear-gradient)" cx="40" cy="40" r="40" />
      <path fill="none" stroke="#333" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M24.25,32.75a4.5,4.5,0,0,1,9,0" />
      <path fill="none" stroke="#333" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M47.25,32.75a4.5,4.5,0,0,1,9,0" />
      <path stroke="#e74d56" fill="none" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M28.75,49.75c4,8,19,8,23,0" />
    </SvgIcon>
  );
}

export function BohotAchhaIcon(props) {
  return (
    <SvgIcon {...props} style={{
      fontSize: '3rem',
      overflow: 'visible',
      height: '1rem'
    }}>
      <defs>
        <DropShadow />
      </defs>
      <circle fill="#fcbd01" cx="40" cy="40" r="40" filter="url(#dropShadow)" />
      <path fill="none" stroke="#000" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M24,33a4.5,4.5,0,0,1,9,0" />
      <path stroke="#000" fill="none" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M47,33a4.5,4.5,0,0,1,9,0" />
      <path fill="#e74d56" stroke="#e74d56" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M55.47,46a1,1,0,0,1,1,1.09,16.5,16.5,0,0,1-32.92,0,1,1,0,0,1,1-1.09Z" />
    </SvgIcon>
  );
}

export function BohotAchhaGreyIcon(props) {
  return (
    <SvgIcon {...props} style={{
      fontSize: '3rem',
      overflow: 'visible',
      height: '1rem'
    }}>
      <defs>
        <LinearGradient />
      </defs>
      {/* <circle fill="#fbd768" cx="40" cy="40" r="40" /> */}
      <circle fill="url(#linear-gradient)" cx="40" cy="40" r="40" />
      <path fill="none" stroke="#333" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M24,33a4.5,4.5,0,0,1,9,0" />
      <path stroke="#333" fill="none" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M47,33a4.5,4.5,0,0,1,9,0" />
      <path fill="#e74d56" stroke="#e74d56" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M55.47,46a1,1,0,0,1,1,1.09,16.5,16.5,0,0,1-32.92,0,1,1,0,0,1,1-1.09Z" />
    </SvgIcon>
  );
}
