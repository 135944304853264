var AnalyticsHandler = {
    //This function takes eventname as a a parameter and uses it to send tracking info to Mixpanel;
    // track: function (eventname, propObj) {
    //   if(process.env.REACT_APP_API_ROOT.trim() === "https://api.meetworks.in")
    //   window.mixpanel.track(eventname, propObj);
    // },

    track: function (eventname) {
      if(process.env.REACT_APP_API_ROOT.trim() === "https://api.meetworks.in")
      window.mixpanel.track(eventname);
    },
  
    identify: function () {
      if(process.env.REACT_APP_API_ROOT.trim() === "https://api.meetworks.in")
      window.mixpanel.identify()
    },

    reset: function () {
      if(process.env.REACT_APP_API_ROOT.trim() === "https://api.meetworks.in")
      window.mixpanel.reset();
    }
  }
  
  export default AnalyticsHandler;