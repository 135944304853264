import React, { useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// import IconButton from '@material-ui/core/IconButton';
// import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
// import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
// import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';

import SearchBar from './SearchBarJobs';
import TopAppBar from '../../components/TopAppBar';
import BottomAppBar from '../../components/BottomAppBar';

import '../../style/App.css';
//import global_isSignedIn from '../../components/globalVars/global_isSignedIn';
import SignInUpDrawer from '../../components/SignInDrawer';
import ApiHandler from '../../ApiHandler';
import JobsListItem from './JobsListItem';
import ListLoader from '../errorsAndLoaders/ListLoader';
import InfiniteScroll from "react-infinite-scroll-component";
import SnackBarGeneric from './SnackBarGeneric';

import LogoLoader from '../../assets/logo-loader.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 110,
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  pageBar: {
    //width: 860,
    width: '100%',
    padding: '8px 43px',
    flexBasis: 50,
    backgroundColor: theme.palette.common.greyLight,
    marginTop: 0,
    zIndex: 8,
    transition: '0.2s',
    position: 'sticky',
    top: 59,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      top: 0,
      padding: 0
    },
    [theme.breakpoints.up('md')]: {
      display: "none",
    },
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em'
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 20,
  },
  pageButton_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  listRoot: {
    width: '100%',
    padding: '0 43px 30px',
    marginBottom: 0,
    '& .MuiListItem-root': {
      padding: 0,
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      marginBottom: 10,
      overflow: 'hidden',
      boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 10px 30px',
    },
  },
  panelHeadingSticky: {
    margin: 0,
    padding: '20px 43px 6px',
    fontSize: 18,
    position: 'sticky',
    top: 48,
    background: theme.palette.common.greyLight,
    color: theme.palette.common.blackDark,
    zIndex: 9,
    [theme.breakpoints.down('md')]: {
      padding: '6px 10px 6px',
    },
  },
  panelHeading: {
    marginBottom: 8,
    padding: '0 10px',
    fontSize: 18,
    color: theme.palette.common.blackDark,
  },
  rolesPanelPostTyping: {
    padding: '0px 10px 60px',
  },
  rolesPanel: {
    padding: '20px 43px 6px',
    [theme.breakpoints.down('md')]: {
      padding: '15px 10px 20px',
    },
  },
  // sectionDesktop: {
  //   display: 'none',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'flex',
  //   },
  // },
  // sectionMobile: {
  //   display: 'flex',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
  footerIcon: {
    padding: 10,
    opacity: '0.7'
  },
  sliderContainer: {
    position: 'relative',
    display: 'flex',
    overflowX: 'scroll',
    width: '100%',
    marginBottom: 15,
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
  slide: {
    padding: 5,
  },
  sliderImgWrapper: {
    width: 100,
    height: 121,
  },
}));

let count1 = 0, count2 = 0, count3 = 0;

export default function AllJobs(props) {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [navValue, setNavValue] = React.useState(0);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);
  const [roles_list, setRolesList] = React.useState([]);

  const [displayLocationBox, setDisplayLocationBox] = React.useState(true);
  const [displaySearchBar, setDisplaySearchBar] = React.useState(false);
  const [displayAllJobs, setDisplayAllJobs] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState(null);
  const [started_typing, setStartedTyping] = React.useState(false);
  const refSeeAllJobsButton = React.useRef(null);
  const [isLocationDenied, setIsLocationDenied] = React.useState(false);

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);

  const pageStateOnReturn = (sessionStorage.getItem("pageStateOnReturn")) || "default"; // sessionstorage to track the current state of page: default or search

  document.body.style.overflowY = "scroll";

  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  // when role search is ongoing 
  const showRoleSearchState = () => {
    setDisplayLocationBox(false);
    setDisplaySearchBar(true); // display the search bar
    setDisplayAllJobs(true); // display the whole list of roles
    setStartedTyping(true); // hide list of jobs
    executeScroll(); // scroll to top
  }
  // default reset function for jobspage
  const showDefaultJobsPageState = () => {
    setDisplayLocationBox(true);
    setDisplaySearchBar(false); // hide the search bar
    setSearchTerm(null) // empty the search box
    setDisplayAllJobs(false); // collapse list of roles
    setStartedTyping(false); // show list of jobs
    executeScroll(); // scroll to top
  }

  // get jobs list---> starts
  const [refresh, doRefresh] = React.useState(false);
  const [jobsList, setJobsList] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageOffset, setPageOffset] = React.useState(0);
  const [jobsCount, setJobsCount] = React.useState(null);
  const [vacanciesCount, setVacanciesCount] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);
  const [modeOfWork, setModeOfWork] = React.useState(sessionStorage.getItem("modeOfWork") || "everyWhere");
  const [latLong, setLatLong] = React.useState(location && location.state && location.state.latLong ? location.state.latLong : { lat: 26.8489293, long: 80.802425 });
  const [opportunityType, setOpportunityType] = React.useState('AllJobs');
  const [refreshInfiniteScroll, setRefreshInfiniteScroll] = React.useState(true);
  const [elemVisibleOnload, setElemVisibleOnload] = React.useState(false);
  const [showWFHOption, setShowWFHOption] = React.useState(false);

  const handleGeolocationDetection = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) { // on success
        setLatLong({ lat: position.coords.latitude, long: position.coords.longitude });
        // This will trigger the useeffect with a dependency for latLong
        setIsLocationDenied(false);
      }, function () { // on error
        setIsLocationDenied(true);
        handleSnackBar(`Showing results for everywhere as you have denied Location access permission.`)
      })
    }
  }

  const checkWFHRoles = () => {
    let payload = {
      location: 'Work From Home',
      //location: 'Lucknow',
      opportunity_type: 'AllJobs'
    }
    ApiHandler.getRolesAndOpeningsForLocation(payload, response => {
      if (response.status === 200) {
        let arr_temp = response.data;
        if (arr_temp.length !== 0) {
          setShowWFHOption(true);
        } else if (arr_temp.length === 0 && modeOfWork === "workFromHome") { //if by default wfh was set then switch to all
          setModeOfWork("everyWhere");
          sessionStorage.setItem("modeOfWork", "everyWhere");
        }

      }
    })
  }

  const handleRolesListForWFH = () => {
    let payload = {
      location: 'Work From Home',
      //location: 'Lucknow',
      opportunity_type: 'AllJobs'
    }
    ApiHandler.getRolesAndOpeningsForLocation(payload, response => {
      if (response.status === 200) {
        let arr_temp = response.data;
        setRolesList(arr_temp);
        setElemVisibleOnload(true); // for IXD. to not show the location bar before content load
      }
    })
  }

  const handleRolesListForAll = () => {
    let pathParam = "AllJobs";
    ApiHandler.getAllJobRolesandCount(pathParam, response => {
      if (response.status == 200) {
        let arr_temp = response.data;
        setRolesList(arr_temp);
        setElemVisibleOnload(true); // for IXD. to not show the location bar before content load
      }
      else {
        console.log("error in getting a successful response")
      }
    })
  }

  // get jobs list with infinitescroll as fetchMoreData is called and setRefreshInfiniteScroll triggers the api call---> starts
  const fetchMoreData = () => {
    let page = 1;
    page = currentPage + 1;
    setCurrentPage(page);
    setPageOffset((page - 1) * 10);
    setRefreshInfiniteScroll(prev => !prev);
  }

  React.useEffect(() => {
    // const handleMoreJobsList = () => {
    //   let payload = {
    //     limit: 10,
    //     offset: pageOffset,
    //     modeOfWork: modeOfWork,
    //     opportunity_type: opportunityType,
    //   }
    //   if (modeOfWork === "nearMe") {
    //     payload = {
    //       limit: 10,
    //       offset: pageOffset,
    //       modeOfWork: modeOfWork,
    //       latitude: latLong.lat,
    //       longitude: latLong.long,
    //       opportunity_type: opportunityType,
    //     }
    //   }

    //   ApiHandler.getAllJobPosts(payload, response => {
    //     if (response.status == 200) {
    //       //console.log(response.data[0].results);
    //       if (jobsList === null) {
    //         setJobsList(response.data[0].results);
    //       }
    //       else {
    //         setJobsList(prevState => prevState.concat(response.data[0].results))
    //         //console.log(jobsList.concat(response.data[0].results));
    //       }
    //       setJobsCount(response.data[0].totalCount[0].count);
    //       setVacanciesCount(response.data[0].total_vacancies[0].count);
    //     }
    //     else {
    //       console.log("error in getting a successful response for the list of jobs")
    //     }
    //   })
    // }

    const handleMoreJobsList = () => {
      let payload;
      let limit = 10, offset = pageOffset;

      //to reset offset at the time of new api call during scroll when the results from prev api get exhausted
      if (jobsList && jobsList.length === count1) {
        setCurrentPage(1);
        offset = 0;
      } else if (jobsList && jobsList.length === count1 + count2) {
        setCurrentPage(1);
        offset = 0;
      }

      if (modeOfWork === "nearMe") {
        payload = {
          limit: limit,
          offset: offset,
          //modeOfWork: modeOfWork,
          latitude: latLong.lat,
          longitude: latLong.long,
          // latitude: 19.07283,
          // longitude: 72.88261,
          //opportunity_type: opportunityType,
        }

        //case1: to call api1
        if (jobsList && jobsList.length < count1) {
          //console.log(1)
          ApiHandler.getNearestJobs(payload, response => {
            if (response.status == 200) {
              let result = response.data[0].results;

              if (jobsList === null) {
                setJobsList(result);
              }
              else {
                setJobsList(prevState => prevState.concat(result))
                //console.log(jobsList.concat(result));
              }
              setIsLoading(false);
            }
            else {
              console.log("error in getting a successful response")
            }
          })
        }

        //case2: to call api2
        if (jobsList && (jobsList.length === count1 || jobsList.length < count1 + count2)) {
          //console.log(2);
          ApiHandler.getWorkHomeJobs(offset, limit, response => {
            if (response.status == 200) {
              let result = response.data[0].results;
              count2 = response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0;

              if (jobsList === null) {
                setJobsList(result);
              }
              else {
                setJobsList(prevState => prevState.concat(result))
                //console.log(jobsList.concat(result));
              }
              setIsLoading(false);
            }
            else {
              console.log("error in getting a successful response")
            }
          })
        }

        //case3: to call api3
        if (jobsList && jobsList.length >= count1 + count2) {
          console.log(3);
          ApiHandler.getFarNearestJobs(payload, response => {
            if (response.status == 200) {
              let result = response.data[0].results;
              count3 = response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0;

              if (jobsList === null) {
                setJobsList(result);
              }
              else {
                setJobsList(prevState => prevState.concat(result))
              }
              setIsLoading(false);
            }
            else {
              console.log("error in getting a successful response")
            }
          })
        }

      } else {
        payload = {
          limit: 10,
          offset: pageOffset,
          modeOfWork: modeOfWork,
          opportunity_type: opportunityType,
        }
        ApiHandler.getAllJobPosts(payload, response => {
          if (response.status == 200) {
            if (jobsList === null) {
              setJobsList(response.data[0].results);
            } else {
              setJobsList(prevState => prevState.concat(response.data[0].results))
              //console.log(jobsList.concat(response.data[0].results));
            }
            setJobsCount(response.data[0].totalCount[0].count);
            setVacanciesCount(response.data[0].total_vacancies[0].count);
          }
          else {
            console.log("error in getting a successful response for the list of jobs")
          }
        })
      }
    }

    if (jobsList && jobsList.length >= jobsCount) {
      setHasMore(false);
    } else if (currentPage > 1) {
      handleMoreJobsList();
    }
  }, [refreshInfiniteScroll])

  // get jobs list after change in modeOfWork except nearMe. If mode of work is near me then detect geolocation which will change lat long and trigger another specific useeffect call---> starts
  const trackInitialRenderRef = React.useRef(true);

  React.useEffect(() => {
    if (trackInitialRenderRef.current) {
      trackInitialRenderRef.current = false;
      return;
    }

    if (modeOfWork == "workFromHome") {
      handleRolesListForWFH();
    } else {
      handleRolesListForAll();
    }

    const handleJobsListAfterModeChange = () => {
      setIsLoading(true);
      setCurrentPage(1);
      setPageOffset(0);
      setHasMore(true);
      //alert("mode of work change detected in jobs main")
      let payload = {
        limit: 10,
        offset: 0,
        modeOfWork: modeOfWork,
        opportunity_type: opportunityType,
      }

      if (modeOfWork == "nearMe") {
        handleGeolocationDetection();
      } else {
        ApiHandler.getAllJobPosts(payload, response => {
          if (response.status == 200) {
            let arr_temp = response.data[0].results;
            setJobsList(arr_temp);
            setJobsCount(response.data[0].totalCount[0].count);
            setVacanciesCount(response.data[0].total_vacancies[0].count);
            if (response.data[0].totalCount[0].count < 10) {
              setHasMore(false);
            }
            setIsLoading(false);
          }
          else {
            console.log("error in getting a successful response")
          }
        })
      }
    }
    executeScroll();
    handleJobsListAfterModeChange();
  }, [modeOfWork])
  // get jobs list after change in modeOfWork except nearMe---> ends

  // get jobs list after change in modeOfWork to nearMe which triggered latLong detection---> starts
  const trackInitialRenderRefForLatLong = React.useRef(true);

  React.useEffect(() => {
    //alert("on lat change")
    if (trackInitialRenderRefForLatLong.current) {
      trackInitialRenderRefForLatLong.current = false;
      return;
    }

    if (modeOfWork === "nearMe") {
      let offset = 0, limit = 10;
      let result1, result2, result3;

      let payload = {
        limit: limit,
        offset: offset,
        modeOfWork: modeOfWork,
        latitude: latLong.lat,
        longitude: latLong.long,
        // latitude: 19.07283,
        // longitude: 72.88261,
        opportunity_type: opportunityType,
      }


      ApiHandler.getNearestJobs(payload, response => {
        if (response.status == 200) {
          //console.log(response);
          result1 = response.data[0].results;
          count1 = response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0;

          if (count1 === 0 || count1 < 10) {
            ApiHandler.getWorkHomeJobs(offset, limit, response => {
              if (response.status == 200) {
                //console.log(response);
                result2 = result1.concat(response.data[0].results);
                count2 = response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0;

                if (count2 === 0 || count1 + count2 < 10) {
                  ApiHandler.getFarNearestJobs(payload, response => {
                    if (response.status == 200) {
                      //console.log(response);
                      result3 = result2.concat(response.data[0].results);
                      count3 = response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0;

                      setJobsList(result3);
                      //setJobsCount(count3);
                      //setVacanciesCount(response.data[0].total_vacancies[0] && response.data[0].total_vacancies[0].count ? response.data[0].total_vacancies[0].count : 0);
                      if (count3 < 10) {
                        setHasMore(false);
                      }
                      setIsLoading(false);

                    }
                    else {
                      console.log("error in getting a successful response")
                    }
                  })
                } else {
                  setJobsList(result2);
                  setIsLoading(false);
                }
              } else {
                console.log("error in getting a successful response")
              }
            })
          } else {
            setJobsList(result1);
            setIsLoading(false);
          }

        }
        else {
          console.log("error in getting a successful response")
        }
      })

      payload.modeOfWork = 'everyWhere'; // set to everyWhere as near me returns lesser  count
      // called to set the total count and total vacancies of jobs from all the 3 apis
      ApiHandler.getAllJobPosts(payload, response => {
        if (response.status == 200) {
          setJobsCount(response.data[0].totalCount[0].count);
          setVacanciesCount(response.data[0].total_vacancies[0].count);
        }
        else {
          console.log("error in getting a successful response")
        }
      })


      // ApiHandler.getAllJobPosts(payload, response => {
      //   if (response.status == 200) {
      //     let arr_temp = response.data[0].results;
      //     setJobsList(arr_temp);
      //     setJobsCount(response.data[0].totalCount[0].count);
      //     setVacanciesCount(response.data[0].total_vacancies[0].count);
      //     if (response.data[0].totalCount[0].count < 10) {
      //       setHasMore(false);
      //     }

      //     setIsLoading(false);
      //   }
      //   else {
      //     console.log("error in getting a successful response")
      //   }
      // })
    }
  }, [latLong])
  // get jobs list after change in modeOfWork to nearMe which triggered latLong detection---> ends

  // get jobs list and roles list on page load---> starts
  React.useEffect(() => {
    checkWFHRoles(); // check if wfh roles are present. if not then hide wfh location chip

    if (modeOfWork == "workFromHome") {
      handleRolesListForWFH();
    } else {
      handleRolesListForAll();
    }

    const handleJobsList = () => {
      if (modeOfWork == "nearMe") { //to avoid double api call for nearme
        return;
      }

      setIsLoading(true);

      let data = {
        limit: 10,
        offset: 0,
        modeOfWork: modeOfWork,
        opportunity_type: opportunityType,
      }

      // if (modeOfWork == "nearMe") {
      //   data['latitude'] = latLong.lat;
      //   data['longitude'] = latLong.long;
      // }

      ApiHandler.getAllJobPosts(data, response => {
        if (response.status == 200) {
          let arr_temp = response.data[0].results;
          setJobsList(arr_temp);
          setJobsCount(response.data[0].totalCount[0].count);
          setVacanciesCount(response.data[0].total_vacancies[0].count);

          setIsLoading(false);
        }
        else {
          console.log("error in getting a successful response")
        }
      })
    }
    handleJobsList();

  }, [])
  // get jobs list and roles list on page load---> ends

  // detect onload whether location access permission is denied and update the nearMe option in the drawer comp. In case of iOS detect location onload--->starts
  React.useEffect(() => {

    function handlePermission() {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state == 'granted' || result.state == 'prompt') {
          setIsLocationDenied(false);
          //if mode of work is near me by default then call gelocation api on load
          if (modeOfWork == "nearMe") {
            handleGeolocationDetection();
          }
        } else if (result.state == 'denied') {
          setIsLocationDenied(true);
        }
      });
    }

    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (iosPlatforms.indexOf(platform) !== -1 || macosPlatforms.indexOf(platform) !== -1) {
      //alert("iOS or Mac OS")
      // access location onload if iOS detected
      handleGeolocationDetection();
    } else { // use navigator permissions API for better UX in case OS is not iOS/ MAC OS
      if (navigator.permissions && navigator.permissions.query) {
        handlePermission();
      } else {
        console.log("case when navigator.permissions is not supported.")
      }
    }

  }, [])
  // detect onload whether location access permission is denied and update the nearMe option in the drawer comp. In case of iOS detect location onload--->ends

  React.useLayoutEffect(() => {
    if (location && location.state && location.state.see_all_roles) {
      showRoleSearchState();
    };

    if (pageStateOnReturn === "default") {
      showDefaultJobsPageState();
    }
  }, []);

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  //sort bar bg effect on scroll starts
  const pageBarRef = React.useRef(null);
  let lastScrollY = 0;
  let scrolling = false;

  const handleScrollChange = () => {
    lastScrollY = window.scrollY;
    if (lastScrollY > 60) {
      scrolling = true
    } else {
      scrolling = false
    }
    if (!scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        //pageBarRef.current.style.background = '#f5f5f5';
        pageBarRef.current.style.boxShadow = "none";
        //pageBarRef.current.style.flexBasis = "50px";
      });
    } else if (scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        //pageBarRef.current.style.background = '#f5f5f5';
        pageBarRef.current.style.boxShadow = "0px 5px 5px rgba(0,0,0,0.05)";
        //pageBarRef.current.style.flexBasis = "50px";
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);
  //sort bar bg effect on scroll ends

  // drawer callback starts
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);

  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const [snackBarMessageSeverity, setSnackBarMessageSeverity] = React.useState("success");
  const handleSnackBar = (message, severity) => {
    if (severity) {
      setSnackBarMessageSeverity(severity);
    } else {
      setSnackBarMessageSeverity("success");
    }
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev);
  }
  // snackbar callback ends

  useEffect(() => {
    return () => { // if the page is in role search state and users cliks the browser back button then redirect to jobs main page instead of navigating back
      if (displaySearchBar && !displayLocationBox && history.action === "POP") {
        history.push(
          { pathname: `/`, }
        )
      }
    }
  })

  // React.useEffect(() => {
  //   const unblock = history.block((location, action) => {
  //     if (displaySearchBar && !displayLocationBox && action === "POP") {
  //       return window.confirm("Navigating Back might take you outside the app?");
  //     }
  //     return true;
  //   });

  //   return () => {
  //     unblock();
  //   };
  // });

  return (
    <React.Fragment>
      <Helmet>
        <title>{"View jobs posted by verified employers | Meetworks"}</title>
        <meta name="description" content={"Apply to full time, part time, work from home, freelance jobs, apprenticeship and internships posted by verified employers in various industries"} />
        <meta
        name="keywords"
        content={"all jobs, job listings, latest jobs, current jobs"}/>
      </Helmet>
      <div className={classes.sectionMobile}>
        {/* location app bar */}
        {elemVisibleOnload &&
          <TopAppBar
            setDisplaySearchBar={setDisplaySearchBar}
            refSeeAllJobsButton={refSeeAllJobsButton}
            displaySearchBar={displaySearchBar}
            setModeOfWork={setModeOfWork}
            modeOfWork={modeOfWork}
            doRefresh={doRefresh}
            setJobsList={setJobsList}
            setJobsCount={setJobsCount}
            isLocationDenied={isLocationDenied}
            displayLocationBox={displayLocationBox}
            showDefaultJobsPageState={showDefaultJobsPageState}
            showWFHOption={showWFHOption}
            showBackBtn={true}
          >
          </TopAppBar>
        }

        <div className={classes.root} ref={scrollRef}>

          <Grid container
            alignItems="center" className={classes.pageBar} ref={pageBarRef}>
            <Grid item xs={12} container
              justifyContent="center"
              alignItems="center">
              {displaySearchBar ?
                <SearchBar
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  setStartedTyping={setStartedTyping}
                ></SearchBar> : null
              }
            </Grid>
          </Grid>

          <div ref={trackInitialRenderRefForLatLong} style={{ display: 'none' }}></div>

          {isLoading
            ? <div style={{
              width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
            }}>
              <ListLoader setListLoaderRef={setListLoaderRef} isText={"none"} />
            </div>
            : (!started_typing ?
              <React.Fragment>
                {jobsList && jobsList.length > 0 &&
                  <div style={{ width: '100%', marginBottom: 50 }}>

                    {vacanciesCount && vacanciesCount > 0 &&
                      <Grid container
                        alignItems="center"
                        className={classes.panelHeadingSticky}>
                          <Typography variant="h1" style={{fontSize: 16, padding: '0px 2px 0 0'}}>All Jobs:
                          </Typography>
                                                  
                          <Typography variant="h3" style={{fontSize: 16, padding: 0}}>
                            {vacanciesCount} {vacanciesCount === 1 ? "opening" : "openings"} {jobsCount && `in ${jobsCount}`} {jobsCount && jobsCount === 1 ? "posting" : "postings"}
                            {/* 👇 */}
                          </Typography>
                      </Grid>
                    }

                    <InfiniteScroll
                      dataLength={jobsList.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<div style={{
                        width: '100%', height: 80, display: 'flex', justifyContent: 'center', alignItems: 'flex-start'
                      }}>
                        {/* <CircularProgress style={{ color: '#ccc' }} /> */}
                        <img alt="meet animated logo loader" style={{ width: 40 }} src={LogoLoader} />
                      </div>}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          { }
                        </p>
                      }
                      scrollThreshold="70%"
                    >
                      <List className={classes.listRoot} disablePadding>
                        {jobsList.map((hit, index) =>
                        (<JobsListItem
                          hit={jobsList[index]}
                          signInStatus={isSignedIn}
                          key={index}
                          lat={latLong.lat}
                          long={latLong.long}
                          isWorkFromHome={jobsList[index].isWorkFromHome}
                          pageStateOnReturn={"goBack"}
                        />)
                        )
                        }
                      </List>
                    </InfiniteScroll>
                  </div>
                }
                {jobsList && jobsList.length === 0 &&
                  (<div className={classes.pageInfo}>
                    <Typography className={classes.pageInfoText} variant="h6">Sorry! हमें आपकी ढूंढी हुई job नहीं मिली। </Typography>
                    <Button
                      id="see_all_jobs_jobs_main"
                      variant="contained"
                      className={`${classes.pageButton} ${classes.pageButton_pri}`}
                      style={{ width: 200 }}>
                      See all jobs
                    </Button>
                  </div>)
                }
              </React.Fragment> : null)
          }
        </div>

        <BottomAppBar
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          handleDrawer={handleDrawer}
          navValue={navValue}
          setNavValue={setNavValue}
          setDisplaySearchBar={setDisplaySearchBar}
          setDisplayAllJobs={setDisplayAllJobs}
          executeScroll={executeScroll}
        >
        </BottomAppBar>

        <SignInUpDrawer
          canDrawerOpen={canDrawerOpen}
          refresh={refresh}
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          setForRefreshOnly={setForRefreshOnly}
          setCanDrawerOpen={setCanDrawerOpen}
          doRefresh={doRefresh}
        />

        <SnackBarGeneric id="snackBarMessage" canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />

      </div>
    </React.Fragment>
  )
}