import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import gplayLogo from '../assets/gplay.svg';

const useStyles = makeStyles((theme) => ({
    footer: {
        width: '100%',
        height: '250px',
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(6),
        color: '#fff'
    },
    footerBd: {
        borderRight: '1px solid',
        borderLeft: '1px solid',
        // paddingTop: 10,
    },
    footerElPd: {
        paddingLeft: '5em',
        paddingTop: 10,
    },
    my1: {
        margin: '0.5em 0'
    },
    mr1: {
        marginRight: '0.5em'
    },
    // copyright: {
    //     margin: '3em 0'
    // },
    socialLinks: {
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        textDecoration: 'none',

    }
}))

const Footer = () => {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <Grid container justifyContent='space-between'>
                <Grid item container sm={4} direction='column' wrap='nowrap'>
                    <Typography variant='h6'> Follow us on social media</Typography>
                    <a className={`${classes.socialLinks} ${classes.my1}`} target="_blank" href="https://www.instagram.com/meet_app/" rel="noopener noreferrer">
                        <span className={classes.mr1}><InstagramIcon /></span>
                        <span>Instagram</span>
                    </a>
                    <a className={classes.socialLinks} target="_blank" href="https://www.facebook.com/meetworks.in" rel="noopener noreferrer">
                        <span className={classes.mr1}><FacebookIcon /></span>
                        <span>Facebook</span>
                    </a>
                    <a className={`${classes.socialLinks} ${classes.my1}`} target="_blank" href="https://www.linkedin.com/company/meetworks" rel="noopener noreferrer">
                        <span className={classes.mr1}><LinkedInIcon /></span>
                        <span>LinkedIn</span>
                    </a>
                </Grid>
                <Grid className={`${classes.footerBd} ${classes.footerElPd}`} item container sm={4} direction='column' wrap='nowrap'>
                <a className={classes.socialLinks} target="_blank" href="./terms" rel="noopener noreferrer"><Typography variant='body1'>Terms & Conditions </Typography></a>
                <a className={classes.socialLinks} target="_blank" href="./privacy-policy" rel="noopener noreferrer"><Typography variant='body1'>Privacy Policy </Typography></a>
                </Grid>
                <Grid className={classes.footerElPd} item container sm={4} direction='column' wrap='nowrap' alignItems='flex-start'>
                    <Typography variant='body1'>Download our android app for unlimited jobs </Typography>
                    <a href='https://play.google.com/store/apps/details?id=com.meetjobseekerapp&hl=en' target='_blank' rel="noopener noreferrer">
                        <img src={gplayLogo} width={150} height={80} alt='gplay' />
                    </a>
                </Grid>
            </Grid>
            <Grid className={classes.copyright} container justifyContent='center'>
                <Typography variant='body1'>
                    © 2023 Meetworks | All rights reserved
                </Typography>
            </Grid>
        </footer>
    )
}

export default Footer