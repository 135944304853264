import React from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
//import Rating from '@material-ui/lab/Rating';
import Switch from '@material-ui/core/Switch';

//import StarRoundedIcon from '@material-ui/icons/StarRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
// import EqualizerIcon from '@material-ui/icons/Equalizer';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
// import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import WidgetsIcon from '@material-ui/icons/Widgets';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
// import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
// import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
// import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

//import SvgIcon from '@material-ui/core/SvgIcon';

import SwipeableViews from 'react-swipeable-views';

import '../../style/App.css';
import ListLoader from '../errorsAndLoaders/ListLoader';
import CustomRatings from './CustomRatings';
import { getAllReviewTags } from '../../ApiHandler';
import MediaUploader from './MediaUploader';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tab-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box p={3}>
        <div>{children}</div>
        // </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%', 
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 74,
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  tabBar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.14)',
  },
  navTabBar: {
    flexGrow: 1,
  },
  navTab: {
    textTransform: 'capitalize',
    padding: 0,
    margin: ' 0 15px',
    minHeight: 50,
    minWidth: 100,
    color: theme.palette.common.grey,
    ...theme.typography.h3,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      minHeight: 50,
      minWidth: 'auto',
    },
  },
  appBar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.16)',
    top: 'auto',
    bottom: 0,
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em'
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 20,
  },
  pageButton_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  button_sec: {
    backgroundColor: '#fff',
    color: theme.palette.primary.dark,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  button_ter: {
    backgroundColor: '#fff',
    color: theme.palette.common.blackLight,
    borderRadius: 4,
    boxShadow: '0px 0px 5px rgba(0,0,0,0.05)',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  section: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: '10px 0',
  },
  sectionItem: {
    padding: '20px 0px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[100],
    '&:last-child': {
      border: 'none',
    }
  },
  sectionItem_heading: {
    flexWrap: 'nowrap',
    marginBottom: '30px'
  },
  sectionItem_heading_icon: {
    marginRight: 5,
  },
  sectionItem_heading_icon2: {
    marginRight: 10,
    // color: theme.palette.common.grey,
    color: theme.palette.primary.main,
  },
  sectionItem_content: {
    flexWrap: 'wrap',
  },
  sectionItem_rating: {
    flexWrap: 'wrap',
    margin: '10px 0',
    '&:last-child': {
      marginBottom: 0,
    }
  },
  sectionItem_text_button: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  tileRatingText: {
    ...theme.typography.tileRatingText,
  },
  tileRatingStar: {
    fontSize: '1.2rem',
    color: theme.palette.common.star,
    marginRight: 4,
  },
  imageTag: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: "none",
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 140,
    height: 140,
    overflow: 'hidden',
    margin: 0,
    borderRadius: 6,
    //border: '1px solid #E5E5E5',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.14)',
  },
  img: {
    margin: '0 auto',
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: 'unset',
    //position: 'absolute',
  },

  TfRoot: {
    width: '100%',
    marginBottom: 30,
    background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  TfRootWithoutMB: {
    width: '100%',
    marginBottom: 0,
    background: theme.palette.common.greyLight,
    '& fieldset': {
      border: 'transparent',
    },
  },
  inputRootAc: {
    padding: '0 9px!important',
  },
  tfLabel: {
    ...theme.typography.h3,
    fontWeight: 600,
    color: theme.palette.common.blackDark,
    marginBottom: 12,
  },
  datePickerInput: {
    '& .MuiInputBase-input': {
      height: '3.429em',
      width: '100%',
      borderRadius: 4,
      background: theme.palette.common.greyLight,
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  selectRoot: {
    width: '100%',
    marginBottom: 30,
    background: theme.palette.common.greyLight,
    borderRadius: 25,
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 15px',
    },
    '& .MuiSelect-select:focus': {
      borderRadius: 25,
    },
    '& input': {
      height: 48,
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  fabBtnWrapper: {
    position: 'fixed',
    right: 0,
    width: 'auto',
    bottom: 70,
    paddingRight: 23,
  },
  fabDisabled: {
    minWidth: 70,
    height: 32,
    textTransform: 'capitalize',
    background: "white",
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
  },
  fabActive: {
    minWidth: 70,
    height: 32,
    textTransform: 'capitalize',
    background: theme.palette.primary.dark,
    color: "white",
  },
  sizeLarge: {
    fontSize: '2.5rem'
  },
  chip: {
    fontSize: '0.815rem',
    fontWeight: 500,
    width: 'auto',
    padding: '2px 8px',
    margin: '6px 10px 0 0',
    borderRadius: 4,
    border: '1px solid',
    backgroundColor: '#fff',
    color: theme.palette.common.grey,
    borderColor: theme.palette.common.grey,
    '&.active': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    }
  },
  imgDeleteBtn: {
    width: 30,
    height: 30,
    background: '#FFFFFF',
    // boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.14)',
    borderRadius: '50%',
    marginLeft: 'auto',
  },
  radioLabel: {
    fontWeight: 500
  },
  radioLabelHindi: {
    fontWeight: 600
  }
}));

const AntSwitch = withStyles((theme) => ({

  switchBase: {

    color: theme.palette.grey[500],
    '&$checked': {

      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },

  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function AddRatingsReviews(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [isLoading, setIsLoading] = React.useState(false);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);
  const [primaryTabValue, setPrimaryTabValue] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [reviewTagType, setReviewTagType] = React.useState(props.overallRating ? props.overallRating <= 3 ? 'bad' : props.overallRating > 3 ? 'good' : '' : '');
  const [guptOrPublic, setGuptOrPublic] = React.useState(props.isReviewPublic ? "public" : "gupt");

  const [reviewMedia, setReviewMedia] = React.useState("text");

  const [reviewTagsAll, setReviewTagsAll] = React.useState(null);
  const [reviewTagsList, setReviewTagsList] = React.useState(null);
  let tabsLength = props.isUnpaid ? 9 : 10;
  let tabIndices = !props.isUnpaid ? {
    overall: 0,
    env: 1,
    growth: 2,
    learning: 3,
    facilities: 4,
    location: 5,
    manager: 6,
    salary: 7,
    reviewTags: 8,
    mediaUploader: 9,
    identitySettings: 10,
  }
    : {
      overall: 0,
      env: 1,
      growth: 2,
      learning: 3,
      facilities: 4,
      location: 5,
      manager: 6,
      reviewTags: 7,
      mediaUploader: 8,
      identitySettings: 9,
      // goodReview: 8,
      // badReview: 9
    }

  // on click of ratings, navigate to next/prev slide
  const handleTabChange = (navDir, index) => {
    if (navDir === "next") {
      setTimeout(() => {
        setPrimaryTabValue(index + 1);
        // if (index === 0) highlightEarlierTags();
      }, 700)

    } else if (navDir === "back") {
      setPrimaryTabValue(index - 1);
    }
  }

  // on swipe left or right, set primary tab value as the index
  const handleChangeIndex = (index) => {
    if (index < tabsLength + 1) {
      setPrimaryTabValue(index);
      setIsDisabled(false);
    }
  };

  const highlightEarlierTags = () => {
    //console.log("highlight func called")
    if (props.reviewTags) console.log(props.reviewTags);
    if (props.reviewTags) console.log(props.index);

    if (props.reviewTags && props.reviewTags[props.index]) {
      //console.log("review tags found")
      props.reviewTags[props.index].map((item, index) => {
        if (reviewTagsList && reviewTagsList.includes(item.tag)) {
          if (document.getElementById(item.tag))
            document.getElementById(item.tag).classList.add('active');
        }
      })
    }
  }

  const NoIdeaBtn = () => {
    return (
      <Button
        className={classes.button_ter}
        onClick={() => {
          setPrimaryTabValue(prev => prev + 1);
        }}
        style={{ fontSize: '0.875rem', textTransform: 'capitalize', fontWeight: 400, marginTop: 30 }}>
        इसका कोई idea नहीं है। Next देखें <NavigateNextRoundedIcon fontSize="small" />
      </Button>
    )
  }

  React.useEffect(() => {
    getAllReviewTags((response) => {
      if (response.status === 200) {
        console.log(response.data);
        setReviewTagsAll(response.data);
        setReviewTagsList(response.data.map(item => item.tag));
      }
    })
  }, [])

  React.useEffect(() => {
    if (props.showOnePageRatings) highlightEarlierTags();
  }, [reviewTagsList]) //when the above async function returns reviewTagsList, this will be triggered

  // highlight the earlier selected tags when the primaryTabValue is equal to review tabs tabindex 
  React.useEffect(() => {
    if (primaryTabValue === tabIndices.reviewTags) highlightEarlierTags();
  }, [primaryTabValue])

  const emptyReviewTagsRef = React.useRef(true);
  React.useEffect(() => {
    if (emptyReviewTagsRef.current) {
      emptyReviewTagsRef.current = false
      return;
    }

    let _reviewTags = [...props.reviewTags]
    _reviewTags.splice(props.index, 1, []) //empty review tags at that index if the user changes their choice of overall ratings
    props.setReviewTags([..._reviewTags])

  }, [reviewTagType])

  React.useEffect(() => {
    console.log(props.reviewTags)
  }, [props.reviewTags])

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  return (
    <div style={{
      display: props.showOnePageRatings ? undefined : 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      left: 0,
      width: '100%',
      padding: '10px 15px',
      zIndex: 1202,
      background: 'white',
      top: 0,
      height: 'calc(100vh - 48px)',
      overflow: 'scroll'
    }}>
      <React.Fragment>
        <AppBar position="fixed" className={classes.tabBar} style={{ backgroundColor: "none", boxShadow: "none" }}>
          <Toolbar variant="dense" style={{ width: '100%' }} >

            <IconButton edge="start" className={classes.menuButton} aria-label="back button"
              onClick={() => {
                props.closeRatingsModal();
              }}>
              <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
            </IconButton>
            {/* <Typography variant="h4" className={classes.navTabBar} style={{ color: theme.palette.common.blackDark }}>
              {props.companyName ? props.companyName.split("-")[0] : null}
            </Typography>

            <Typography variant="h4" style={{ color: theme.palette.common.grey }}>{props.companyName ? props.companyName.split("-")[1] : null}</Typography> */}

            {/* <Button
              color="secondary"
              onClick={() => {
                props.setIsSaveBtnDisabled(false);
                props.showSaveCancelBtns();
                props.closeRatingsModal();
              }
              }
              style={{ fontSize: '0.875rem', textTransform: 'capitalize' }}>Save</Button> */}
          </Toolbar>
        </AppBar>

        {isLoading
          ? <div style={{
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
          }}>
            <ListLoader setListLoaderRef={setListLoaderRef} />
          </div>
          :
          <React.Fragment>
            {!props.showOnePageRatings && !props.isUnpaid ?

              <SwipeableViews
                resistance
                threshold={8}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={primaryTabValue}
                onChangeIndex={handleChangeIndex}
                disabled={isDisabled}
                style={{ width: '100%' }}
              >
                {/* sequence of the tabpanels should be the same as their index number for the swipeable to function properly*/}
                <TabPanel value={primaryTabValue} index={tabIndices.overall}>
                  <Grid
                    container
                    className={classes.section}
                    direction="column"
                    alignItems='center'
                  // style={{ marginTop: 10, paddingTop: 50 }}
                  >

                    {/* <div className={classes.imgWrapper} style={{ marginBottom: 20 }} id="imgWrapper">
                  <div style={{ fontSize: '3rem' }}>🏢</div>
                </div> */}

                    <Typography variant="h2" align="center" style={{ marginBottom: 10, color: theme.palette.common.blackDark }}>{props.companyName ? props.companyName.split("-")[0] : null}</Typography>

                    <Typography variant="h4" align="center" style={{ marginBottom: 30, color: theme.palette.common.grey }}>{props.companyName ? props.companyName.split("-")[1] : null}</Typography>

                    {props.companyName ?
                      <div style={{ marginBottom: 40, width: '70%', height: 2, backgroundColor: theme.palette.primary.main }}></div>
                      : null}

                    <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>आपका इस company में काम करने का अनुभव कैसा था? </Typography>

                    <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>

                      {/* <Rating
                      id="overallRating_id"
                      name="overall_rating"
                      value={props.overallRating ? props.overallRating : 0}
                      onChange={(event, newValue) => {
                        props.changeOverallRating(newValue, props.index);
                        handleTabChange("next", 0)
                      }}
                      precision={1}
                      icon={<StarRoundedIcon fontSize="large" style={{ fontSize: '3.5rem' }} />}
                    /> */}

                      <CustomRatings
                        type={"animated"}
                        size={"large"}
                        value={props.overallRating ? props.overallRating : 0}
                        onChange={(newValue) => {
                          props.changeOverallRating(newValue, props.index);
                          // handleTabChange("next", 0);
                          if (newValue <= 3) setReviewTagType('bad');
                          else if (newValue > 3) setReviewTagType('good');
                        }}
                      />

                    </Grid>

                    <Typography variant="h2" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600, minHeight: '18.4px' }}>
                      {props.overallRating ?
                        props.overallRating > 0 && props.overallRating <= 1 ? "बहुत ख़राब!"
                          : props.overallRating > 1 && props.overallRating <= 2 ? "ख़राब!"
                            : props.overallRating > 2 && props.overallRating <= 3 ? "ठीक ठाक!!"
                              : props.overallRating > 3 && props.overallRating <= 4 ? "अच्छा!"
                                : props.overallRating > 4 && props.overallRating <= 5 ? "बहुत अच्छा!"
                                  : null
                        : null}
                    </Typography>
                  </Grid>
                </TabPanel>
                <TabPanel value={primaryTabValue} index={tabIndices.env}>
                  <Grid
                    container
                    className={classes.section}
                    direction="column"
                    alignItems='center'
                  >
                    <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>हमें थोड़ा और बताएँ। जैसे...company में माहौल कैसा था?</Typography>

                    <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                      <CustomRatings
                        type={"static"}
                        size={"large"}
                        value={props.envRating ? props.envRating : 0}
                        onChange={(newValue) => {
                          props.changeEnvRating(newValue, props.index);
                          handleTabChange("next", tabIndices.env)
                        }}
                      />
                      {/* <Rating
                        id="companyEnv_id"
                        name="company_rating"
                        value={props.envRating ? props.envRating : 0}
                        onChange={(event, newValue) => {
                          props.changeEnvRating(newValue, props.index);
                          handleTabChange("next", 2)
                        }}
                        precision={1}
                        icon={<StarRoundedIcon fontSize="large" />}
                        classes={{ sizeLarge: classes.sizeLarge }}
                      /> */}
                    </Grid>

                    {NoIdeaBtn()}

                  </Grid>
                </TabPanel>
                <TabPanel value={primaryTabValue} index={tabIndices.growth}>
                  <Grid
                    container
                    className={classes.section}
                    direction="column"
                    alignItems='center'
                  >
                    <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>company में career growth के chances कैसे हैं?</Typography>

                    <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                      <CustomRatings
                        type={"static"}
                        size={"large"}
                        value={props.growthRating ? props.growthRating : 0}
                        onChange={(newValue) => {
                          props.changeGrowthRating(newValue, props.index);
                          handleTabChange("next", tabIndices.growth)
                        }}
                      />
                      {/* <Rating
                        id="careerRating_id"
                        name="career_rating"
                        value={props.growthRating ? props.growthRating : 0}
                        onChange={(event, newValue) => {
                          props.changeGrowthRating(newValue, props.index);
                          handleTabChange("next", 3)
                        }}
                        precision={1}
                        icon={<StarRoundedIcon fontSize="large" />}
                        classes={{ sizeLarge: classes.sizeLarge }}
                      /> */}
                    </Grid>

                    {NoIdeaBtn()}

                  </Grid>
                </TabPanel>
                <TabPanel value={primaryTabValue} index={tabIndices.learning}>
                  <Grid
                    container
                    className={classes.section}
                    direction="column"
                    alignItems='center'
                  >
                    <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>सीखने के मौकों से आप कितने खुश थे?</Typography>

                    <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                      <CustomRatings
                        type={"static"}
                        size={"large"}
                        value={props.learningRating ? props.learningRating : 0}
                        onChange={(newValue) => {
                          props.changeLearningRating(newValue, props.index);
                          handleTabChange("next", tabIndices.learning)
                        }}
                      />
                      {/* <Rating
                        id="learningRating_id"
                        name="learning_rating"
                        value={props.learningRating ? props.learningRating : 0}
                        onChange={(event, newValue) => {
                          props.changeLearningRating(newValue, props.index);
                          handleTabChange("next", 4)
                        }}
                        precision={1}
                        icon={<StarRoundedIcon fontSize="large" />}
                        classes={{ sizeLarge: classes.sizeLarge }}
                      /> */}
                    </Grid>

                    {NoIdeaBtn()}

                  </Grid>
                </TabPanel>
                <TabPanel value={primaryTabValue} index={tabIndices.facilities}>
                  <Grid
                    container
                    className={classes.section}
                    direction="column"
                    alignItems='center'
                  >
                    <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>आपको company में facilities और सुविधाएं कैसी लगी? </Typography>

                    <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                      <CustomRatings
                        type={"static"}
                        size={"large"}
                        value={props.facilityRating ? props.facilityRating : 0}
                        onChange={(newValue) => {
                          props.changeFacilityRating(newValue, props.index);
                          handleTabChange("next", tabIndices.facilities)
                        }}
                      />
                      {/* <Rating
                        id="facilityRating_id"
                        name="facility_rating"
                        value={props.facilityRating ? props.facilityRating : 0}
                        onChange={(event, newValue) => {
                          props.changeFacilityRating(newValue, props.index);
                          handleTabChange("next", 5)
                        }}
                        precision={1}
                        icon={<StarRoundedIcon fontSize="large" />}
                        classes={{ sizeLarge: classes.sizeLarge }}
                      /> */}
                    </Grid>

                    {NoIdeaBtn()}

                  </Grid>
                </TabPanel>
                <TabPanel value={primaryTabValue} index={tabIndices.location}>
                  <Grid
                    container
                    className={classes.section}
                    direction="column"
                    alignItems='center'
                  >
                    <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>आपकी office location के बारे में आपकी क्या राय है?</Typography>

                    <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                      <CustomRatings
                        type={"static"}
                        size={"large"}
                        value={props.locationRating ? props.locationRating : 0}
                        onChange={(newValue) => {
                          props.changeLocationRating(newValue, props.index);
                          handleTabChange("next", tabIndices.location)
                        }}
                      />
                      {/* <Rating
                        id="locationRating_id"
                        name="location_rating"
                        value={props.locationRating ? props.locationRating : 0}
                        onChange={(event, newValue) => {
                          props.changeLocationRating(newValue, props.index);
                          handleTabChange("next", 6)
                        }}
                        precision={1}
                        icon={<StarRoundedIcon fontSize="large" />}
                        classes={{ sizeLarge: classes.sizeLarge }}
                      /> */}
                    </Grid>

                    {NoIdeaBtn()}

                  </Grid>
                </TabPanel>
                <TabPanel value={primaryTabValue} index={tabIndices.manager}>
                  <Grid
                    container
                    className={classes.section}
                    direction="column"
                    alignItems='center'
                  >
                    <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>आपके manager या supervisor के व्यवहार के बारे में हमें अपनी राय बताएं।</Typography>

                    <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                      <CustomRatings
                        type={"static"}
                        size={"large"}
                        value={props.managerRating ? props.managerRating : 0}
                        onChange={(newValue) => {
                          props.changeManagerRating(newValue, props.index);
                          handleTabChange("next", tabIndices.manager)
                        }}
                      />
                      {/* <Rating
                        id="managerRating_id"
                        name="manager_rating"
                        value={props.managerRating ? props.managerRating : 0}
                        onChange={(event, newValue) => {
                          props.changeManagerRating(newValue, props.index);
                          handleTabChange("next", 7)
                        }}
                        precision={1}
                        icon={<StarRoundedIcon fontSize="large" />}
                        classes={{ sizeLarge: classes.sizeLarge }}
                      /> */}
                    </Grid>

                    {NoIdeaBtn()}

                  </Grid>
                </TabPanel>
                <TabPanel value={primaryTabValue} index={tabIndices.salary}>
                  <Grid
                    container
                    className={classes.section}
                    direction="column"
                    alignItems='center'
                  >
                    <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>इस company में अपनी salary को आप कितनी rating देंगे? </Typography>

                    <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                      <CustomRatings
                        type={"static"}
                        size={"large"}
                        value={props.salaryRating ? props.salaryRating : 0}
                        onChange={(newValue) => {
                          props.changeSalaryRating(newValue, props.index);
                          handleTabChange("next", tabIndices.salary)
                        }}
                      />
                    </Grid>

                    {NoIdeaBtn()}

                  </Grid>
                </TabPanel>
                <TabPanel value={primaryTabValue} index={tabIndices.reviewTags}>
                  <Grid
                    container
                    className={classes.section}
                    direction="column"
                    alignItems='center'
                  >
                    <Typography variant="h3" align="center" className={classes.tfLabel}>
                      {reviewTagType === 'good'
                        ? "आपको company के बारे मे क्या अच्छा लगा?"
                        : reviewTagType === 'bad'
                          ? "आपको company के बारे मे क्या बुरा लगा?"
                          : "आपको company के बारे मे क्या अच्छा लगा?"}
                    </Typography>

                    <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_content} >
                      {reviewTagsAll && reviewTagsAll.filter(item => item.type === (reviewTagType === '' ? 'good' : reviewTagType)).map((item, index) =>
                        <Typography
                          key={index}
                          id={item.tag}
                          className={classes.chip}
                          onClick={(event) => {
                            let thisElem = event.target.classList;
                            if (!thisElem.contains('active')) {
                              let tags = props.reviewTags && props.reviewTags[props.index] ? props.reviewTags[props.index] : [];
                              //console.log(tags);
                              //console.log(props.reviewTags);
                              tags.push({ tag: item.tag, type: reviewTagType === '' ? 'good' : reviewTagType });
                              //console.log(tags);

                              thisElem.add('active');
                              if (props.reviewTags.length === 0) {
                                console.log("add to the first array")
                                // props.setReviewTags([...props.reviewTags, props.reviewTags[props.index] = tags])
                                props.setReviewTags([tags])
                              } else {
                                let _a = ([...props.reviewTags], [props.reviewTags[props.index] = tags])
                                //console.log(_a)

                                props.setReviewTags([...props.reviewTags], [props.reviewTags[props.index] = tags]);
                              }

                            }
                            else {
                              thisElem.remove('active');
                              let _allExpTags = [...props.reviewTags];
                              let _thisExpTags = _allExpTags[props.index].filter(tag => tag.tag !== item.tag);
                              let tagIndex = _allExpTags[props.index].findIndex(obj => obj.tag == item.tag);
                              let newThis = _allExpTags[props.index].splice(tagIndex, 1);

                              //console.log(_allExpTags);
                              console.log(_thisExpTags);
                              if (_allExpTags.length === 1) {
                                //console.log(111)
                                props.setReviewTags([_thisExpTags]);
                              } else if (_allExpTags.length > 1) {
                                //console.log(222)
                                props.setReviewTags([...props.reviewTags], [props.reviewTags[props.index] = newThis])
                              }
                            }
                            //to enable save btn on profile main page
                            props.setIsSaveBtnDisabled(false);
                            props.showSaveCancelBtns();
                          }}
                        >
                          {item.tag}</Typography>
                      )}
                    </Grid>

                  </Grid>

                </TabPanel>
                <TabPanel value={primaryTabValue} index={tabIndices.mediaUploader}>

                  {/****** review media ******/}

                  {guptOrPublic === "gupt" ?
                    <>
                      <Grid item xs={12} style={{ marginBottom: 30 }}>
                        <Typography variant="h3" className={classes.tfLabel} > अपने experience के बारे मे थोड़ा और बताएं...
                        </Typography>
                      </Grid>

                      <Grid item xs={12} >
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                          <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन बातें जो आपको अच्छी लगी...</FormLabel>
                          <TextField
                            required
                            id="prosReview_id"
                            inputProps={{ maxLength: 250 }}
                            multiline
                            rows={4}
                            value={props.goodReview ? props.goodReview : ''}
                            onChange={(event) => { props.changeGoodReview(event.target.value, props.index) }}
                            placeholder="Example: Company में काम करने का बहुत ही अच्छा environment था, सभी staff के लोग supportive थे, अच्छी Salary के साथ अच्छे incentives मिलते थे और Free Breakfast मिलता था।"
                            variant="outlined"
                            classes={{ root: classes.TfRoot }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} >
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                          <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन चीज़ें जो सुधारी जा सकती हैं...</FormLabel>
                          <TextField
                            required
                            id="consReview_id"
                            inputProps={{ maxLength: 250 }}
                            multiline
                            rows={4}
                            value={props.badReview ? props.badReview : ''}
                            onChange={(event) => { props.changeBadReview(event.target.value, props.index) }}
                            placeholder="Example: यह एक टारगेट based Job थी; Manager का behaviour अच्छा नहीं था; और Salary late आती थी।"
                            variant="outlined"
                            classes={{ root: classes.TfRootWithoutMB }}
                          />
                        </FormControl>
                      </Grid>
                    </>
                    : <>

                      <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>

                        <Typography variant="h3" className={classes.tfLabel}
                        // style={{ marginBottom: 6 }}
                        >
                          अपने experience के बारे मे थोड़ा और बताएं...
                        </Typography>
                        {/* <Typography variant="body1">
                          आप चाहें तो अपना 1 minute का Video या Audio review भी दे सकते हैं।
                        </Typography>

                        <FormControl id="reviewMediaInput_id" component="fieldset">
                          
                          <RadioGroup row aria-label="Review Type" name="reviewMedia" value={reviewMedia} >
                            <FormControlLabel value="video" onClick={() => { setReviewMedia("video"); }} control={<Radio color="primary" />} label="Video" />
                            <FormControlLabel value="audio" onClick={() => { setReviewMedia("audio") }} control={<Radio color="primary" />} label="Audio" />
                            <FormControlLabel value="text" onClick={() => { setReviewMedia("text"); }} control={<Radio color="primary" />} label="Text" />
                          </RadioGroup>

                        </FormControl> */}
                      </Grid>

                      <MediaUploader
                        expId={props.expId}
                        index={props.index}
                        reviewMedia={reviewMedia}
                        reviewVideo={props.videoReview}
                        setReviewVideo={props.changeVideoReview}
                        reviewAudio={props.audioReview}
                        setReviewAudio={props.changeAudioReview}
                        handleSnackBar={props.handleSnackBar}
                      ></MediaUploader>

                      {reviewMedia && reviewMedia === "text" &&
                        <>
                          {/* <Typography variant="h3" className={classes.tfLabel} style={{ textAlign: "center", color: theme.palette.primary.main, marginBottom: 20 }}>
                            Text Review
                          </Typography> */}

                          <Grid item xs={12} >
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन बातें जो आपको अच्छी लगी...</FormLabel>
                              <TextField
                                required
                                id="prosReview_id"
                                inputProps={{ maxLength: 250 }}
                                multiline
                                rows={4}
                                value={props.goodReview ? props.goodReview : ''}
                                onChange={(event) => { props.changeGoodReview(event.target.value, props.index) }}
                                placeholder="Example: Company में काम करने का बहुत ही अच्छा environment था, सभी staff के लोग supportive थे, अच्छी Salary के साथ अच्छे incentives मिलते थे और Free Breakfast मिलता था।"
                                variant="outlined"
                                classes={{ root: classes.TfRoot }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} >
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                              <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन चीज़ें जो सुधारी जा सकती हैं...</FormLabel>
                              <TextField
                                required
                                id="consReview_id"
                                inputProps={{ maxLength: 250 }}
                                multiline
                                rows={4}
                                value={props.badReview ? props.badReview : ''}
                                onChange={(event) => { props.changeBadReview(event.target.value, props.index) }}
                                placeholder="Example: यह एक टारगेट based Job थी; Manager का behaviour अच्छा नहीं था; और Salary late आती थी।"
                                variant="outlined"
                                classes={{ root: classes.TfRootWithoutMB }}
                              />
                            </FormControl>
                          </Grid>
                        </>
                      }
                    </>
                  }

                </TabPanel>
                <TabPanel value={primaryTabValue} index={tabIndices.identitySettings}>

                  {/****** identity settings ******/}
                  <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>

                    <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                      इस company से related Ratings & Reviews के लिए आप अपनी पहचान कैसी रखना चाहेंगे?
                    </Typography>

                    <FormControl id="reviewMediaInput_id" component="fieldset">
                      <RadioGroup aria-label="Ratings and reviews identity setting" name="guptOrPublic" value={guptOrPublic} >
                        <div>
                          <FormControlLabel
                            value="gupt"
                            onClick={() => {
                              setGuptOrPublic("gupt");
                              props.changeIsReviewPublic(false, props.index)
                            }}
                            control={<Radio color="primary" />} label="गुप्त" classes={{ label: classes.radioLabelHindi }} />
                          <Typography variant="body1"
                            style={{ marginTop: '-8px', paddingLeft: 30, color: theme.palette.common.grey }} >
                            आपकी पहचान गुप्त रहेगी।
                          </Typography>
                        </div>
                        <div>
                          <FormControlLabel
                            value="public"
                            onClick={() => {
                              setGuptOrPublic("public");
                              props.changeIsReviewPublic(true, props.index)
                            }}
                            control={<Radio color="primary" />}
                            label="Public" classes={{ label: classes.radioLabel }} />
                          <Typography variant="body1"
                            style={{ marginTop: '-8px', paddingLeft: 30, color: theme.palette.common.grey }} >
                            Reviews और ratings के साथ आपका नाम और profile picture भी post होगा।
                            {/* आप आगे अपना video या audio भी post कर पाएंगे! */}
                          </Typography>
                        </div>

                      </RadioGroup>

                    </FormControl>
                  </Grid>

                </TabPanel>

              </SwipeableViews>
              : !props.showOnePageRatings && props.isUnpaid ?
                <SwipeableViews
                  resistance
                  threshold={8}
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={primaryTabValue}
                  onChangeIndex={handleChangeIndex}
                  disabled={isDisabled}
                  style={{ width: '100%' }}
                >
                  {/* sequence of the tabpanels should be the same as their index number for the swipeable to function properly*/}
                  <TabPanel value={primaryTabValue} index={tabIndices.overall}>
                    <Grid
                      container
                      className={classes.section}
                      direction="column"
                      alignItems='center'
                    // style={{ marginTop: 10, paddingTop: 50 }}
                    >

                      {/* <div className={classes.imgWrapper} style={{ marginBottom: 20 }} id="imgWrapper">
                  <div style={{ fontSize: '3rem' }}>🏢</div>
                </div> */}

                      <Typography variant="h2" align="center" style={{ marginBottom: 10, color: theme.palette.common.blackDark }}>{props.companyName ? props.companyName.split("-")[0] : null}</Typography>

                      <Typography variant="h4" align="center" style={{ marginBottom: 30, color: theme.palette.common.grey }}>{props.companyName ? props.companyName.split("-")[1] : null}</Typography>

                      {props.companyName ?
                        <div style={{ marginBottom: 40, width: '70%', height: 2, backgroundColor: theme.palette.primary.main }}></div>
                        : null}

                      <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>आपका इस company में काम करने का अनुभव कैसा था? </Typography>

                      <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>

                        {/* <Rating
                      id="overallRating_id"
                      name="overall_rating"
                      value={props.overallRating ? props.overallRating : 0}
                      onChange={(event, newValue) => {
                        props.changeOverallRating(newValue, props.index);
                        handleTabChange("next", 0)
                      }}
                      precision={1}
                      icon={<StarRoundedIcon fontSize="large" style={{ fontSize: '3.5rem' }} />}
                    /> */}

                        <CustomRatings
                          type={"animated"}
                          size={"large"}
                          value={props.overallRating ? props.overallRating : 0}
                          onChange={(newValue) => {
                            props.changeOverallRating(newValue, props.index);
                            // handleTabChange("next", 0);
                            if (newValue <= 3) setReviewTagType('bad');
                            else if (newValue > 3) setReviewTagType('good');
                          }}
                        />

                      </Grid>

                      <Typography variant="h2" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600, minHeight: '18.4px' }}>
                        {props.overallRating ?
                          props.overallRating > 0 && props.overallRating <= 1 ? "बहुत ख़राब!"
                            : props.overallRating > 1 && props.overallRating <= 2 ? "ख़राब!"
                              : props.overallRating > 2 && props.overallRating <= 3 ? "ठीक ठाक!!"
                                : props.overallRating > 3 && props.overallRating <= 4 ? "अच्छा!"
                                  : props.overallRating > 4 && props.overallRating <= 5 ? "बहुत अच्छा!"
                                    : null
                          : null}
                      </Typography>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={primaryTabValue} index={tabIndices.env}>
                    <Grid
                      container
                      className={classes.section}
                      direction="column"
                      alignItems='center'
                    >
                      <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>हमें थोड़ा और बताएँ। जैसे...company में माहौल कैसा था?</Typography>

                      <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                        <CustomRatings
                          type={"static"}
                          size={"large"}
                          value={props.envRating ? props.envRating : 0}
                          onChange={(newValue) => {
                            props.changeEnvRating(newValue, props.index);
                            handleTabChange("next", tabIndices.env)
                          }}
                        />
                        {/* <Rating
                        id="companyEnv_id"
                        name="company_rating"
                        value={props.envRating ? props.envRating : 0}
                        onChange={(event, newValue) => {
                          props.changeEnvRating(newValue, props.index);
                          handleTabChange("next", 2)
                        }}
                        precision={1}
                        icon={<StarRoundedIcon fontSize="large" />}
                        classes={{ sizeLarge: classes.sizeLarge }}
                      /> */}
                      </Grid>

                      {NoIdeaBtn()}

                    </Grid>
                  </TabPanel>
                  <TabPanel value={primaryTabValue} index={tabIndices.growth}>
                    <Grid
                      container
                      className={classes.section}
                      direction="column"
                      alignItems='center'
                    >
                      <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>company में career growth के chances कैसे हैं?</Typography>

                      <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                        <CustomRatings
                          type={"static"}
                          size={"large"}
                          value={props.growthRating ? props.growthRating : 0}
                          onChange={(newValue) => {
                            props.changeGrowthRating(newValue, props.index);
                            handleTabChange("next", tabIndices.growth)
                          }}
                        />
                      </Grid>

                      {NoIdeaBtn()}

                    </Grid>
                  </TabPanel>
                  <TabPanel value={primaryTabValue} index={tabIndices.learning}>
                    <Grid
                      container
                      className={classes.section}
                      direction="column"
                      alignItems='center'
                    >
                      <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>सीखने के मौकों से आप कितने खुश थे?</Typography>

                      <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                        <CustomRatings
                          type={"static"}
                          size={"large"}
                          value={props.learningRating ? props.learningRating : 0}
                          onChange={(newValue) => {
                            props.changeLearningRating(newValue, props.index);
                            handleTabChange("next", tabIndices.learning)
                          }}
                        />
                      </Grid>

                      {NoIdeaBtn()}

                    </Grid>
                  </TabPanel>
                  <TabPanel value={primaryTabValue} index={tabIndices.facilities}>
                    <Grid
                      container
                      className={classes.section}
                      direction="column"
                      alignItems='center'
                    >
                      <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>आपको company में facilities और सुविधाएं कैसी लगी? </Typography>

                      <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                        <CustomRatings
                          type={"static"}
                          size={"large"}
                          value={props.facilityRating ? props.facilityRating : 0}
                          onChange={(newValue) => {
                            props.changeFacilityRating(newValue, props.index);
                            handleTabChange("next", tabIndices.facilities)
                          }}
                        />
                      </Grid>

                      {NoIdeaBtn()}

                    </Grid>
                  </TabPanel>
                  <TabPanel value={primaryTabValue} index={tabIndices.location}>
                    <Grid
                      container
                      className={classes.section}
                      direction="column"
                      alignItems='center'
                    >
                      <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>आपकी office location के बारे में आपकी क्या राय है?</Typography>

                      <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                        <CustomRatings
                          type={"static"}
                          size={"large"}
                          value={props.locationRating ? props.locationRating : 0}
                          onChange={(newValue) => {
                            props.changeLocationRating(newValue, props.index);
                            handleTabChange("next", tabIndices.location)
                          }}
                        />
                      </Grid>

                      {NoIdeaBtn()}

                    </Grid>
                  </TabPanel>
                  <TabPanel value={primaryTabValue} index={tabIndices.manager}>
                    <Grid
                      container
                      className={classes.section}
                      direction="column"
                      alignItems='center'
                    >
                      <Typography variant="h3" align="center" style={{ marginBottom: 20, color: theme.palette.common.blackDark, fontWeight: 600 }}>आपके manager या supervisor के व्यवहार के बारे में हमें अपनी राय बताएं।</Typography>

                      <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>
                        <CustomRatings
                          type={"static"}
                          size={"large"}
                          value={props.managerRating ? props.managerRating : 0}
                          onChange={(newValue) => {
                            props.changeManagerRating(newValue, props.index);
                            handleTabChange("next", tabIndices.manager)
                          }}
                        />
                      </Grid>

                      {NoIdeaBtn()}

                    </Grid>
                  </TabPanel>
                  <TabPanel value={primaryTabValue} index={tabIndices.reviewTags}>
                    <Grid
                      container
                      className={classes.section}
                      direction="column"
                      alignItems='center'
                    >
                      <Typography variant="h3" align="center" className={classes.tfLabel}>
                        {reviewTagType === 'good'
                          ? "आपको company के बारे मे क्या अच्छा लगा?"
                          : reviewTagType === 'bad'
                            ? "आपको company के बारे मे क्या बुरा लगा?"
                            : "आपको company के बारे मे क्या अच्छा लगा?"}
                      </Typography>

                      <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_content} >
                        {reviewTagsAll && reviewTagsAll.filter(item => item.type === (reviewTagType === '' ? 'good' : reviewTagType)).map((item, index) =>
                          <Typography
                            key={index}
                            id={item.tag}
                            className={classes.chip}
                            onClick={(event) => {
                              let thisElem = event.target.classList;
                              if (!thisElem.contains('active')) {
                                let tags = props.reviewTags && props.reviewTags[props.index] ? props.reviewTags[props.index] : [];
                                console.log(tags);
                                console.log(props.reviewTags);
                                tags.push({ tag: item.tag, type: reviewTagType === '' ? 'good' : reviewTagType });
                                console.log(tags);

                                thisElem.add('active');
                                if (props.reviewTags.length === 0) {
                                  console.log("add to the first array")
                                  // props.setReviewTags([...props.reviewTags, props.reviewTags[props.index] = tags])
                                  props.setReviewTags([tags])
                                } else {
                                  let _a = ([...props.reviewTags], [props.reviewTags[props.index] = tags])
                                  console.log(_a)

                                  props.setReviewTags([...props.reviewTags], [props.reviewTags[props.index] = tags]);
                                }

                              }
                              else {
                                thisElem.remove('active');
                                let _allExpTags = [...props.reviewTags];
                                let _thisExpTags = _allExpTags[props.index].filter(tag => tag.tag !== item.tag);
                                let tagIndex = _allExpTags[props.index].findIndex(obj => obj.tag == item.tag);
                                let newThis = _allExpTags[props.index].splice(tagIndex, 1);

                                //console.log(_allExpTags);
                                console.log(_thisExpTags);
                                if (_allExpTags.length === 1) {
                                  //console.log(111)
                                  props.setReviewTags([_thisExpTags]);
                                } else if (_allExpTags.length > 1) {
                                  //console.log(222)
                                  props.setReviewTags([...props.reviewTags], [props.reviewTags[props.index] = newThis])
                                }

                              }

                              //to enable save btn on profile main page
                              props.setIsSaveBtnDisabled(false);
                              props.showSaveCancelBtns();

                            }}
                          >
                            {item.tag}</Typography>
                        )}
                      </Grid>

                    </Grid>

                  </TabPanel>
                  <TabPanel value={primaryTabValue} index={tabIndices.mediaUploader}>

                    {/* <Typography variant="h3" className={classes.tfLabel} style={{ marginTop: 300 }}>
                      {"आप चाहें तो अपना 1 minute का Video या Audio review भी दे सकतें हैं। (optional)"}
                    </Typography>

                    <MediaUploader
                      expId={props.expId}
                      index={props.index}
                      reviewMedia={reviewMedia}
                      reviewVideo={props.videoReview}
                      setReviewVideo={props.changeVideoReview}
                      reviewAudio={props.audioReview}
                      setReviewAudio={props.changeAudioReview}
                      handleSnackBar={props.handleSnackBar}
                    ></MediaUploader> */}

                    {guptOrPublic === "gupt" ?
                      <>
                        <Grid item xs={12} style={{ marginBottom: 30 }}>
                          <Typography variant="h3" className={classes.tfLabel} > अपने experience के बारे मे थोड़ा और बताएं...
                          </Typography>
                        </Grid>

                        <Grid item xs={12} >
                          <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन बातें जो आपको अच्छी लगी...</FormLabel>
                            <TextField
                              required
                              id="prosReview_id"
                              inputProps={{ maxLength: 250 }}
                              multiline
                              rows={4}
                              value={props.goodReview ? props.goodReview : ''}
                              onChange={(event) => { props.changeGoodReview(event.target.value, props.index) }}
                              placeholder="Example: Company में काम करने का बहुत ही अच्छा environment था, सभी staff के लोग supportive थे, अच्छी Salary के साथ अच्छे incentives मिलते थे और Free Breakfast मिलता था।"
                              variant="outlined"
                              classes={{ root: classes.TfRoot }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} >
                          <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन चीज़ें जो सुधारी जा सकती हैं...</FormLabel>
                            <TextField
                              required
                              id="consReview_id"
                              inputProps={{ maxLength: 250 }}
                              multiline
                              rows={4}
                              value={props.badReview ? props.badReview : ''}
                              onChange={(event) => { props.changeBadReview(event.target.value, props.index) }}
                              placeholder="Example: यह एक टारगेट based Job थी; Manager का behaviour अच्छा नहीं था; और Salary late आती थी।"
                              variant="outlined"
                              classes={{ root: classes.TfRootWithoutMB }}
                            />
                          </FormControl>
                        </Grid>
                      </>
                      : <>

                        <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>

                          <Typography variant="h3" className={classes.tfLabel}
                          // style={{ marginBottom: 6 }}
                          >
                            अपने experience के बारे मे थोड़ा और बताएं...
                          </Typography>
                          {/* <Typography variant="body1">
                            आप चाहें तो अपना 1 minute का Video या Audio review भी दे सकते हैं।
                          </Typography>

                          <FormControl id="reviewMediaInput_id" component="fieldset">
                            
                            <RadioGroup row aria-label="Review Type" name="reviewMedia" value={reviewMedia} >
                              <FormControlLabel value="video" onClick={() => { setReviewMedia("video"); }} control={<Radio color="primary" />} label="Video" />
                              <FormControlLabel value="audio" onClick={() => { setReviewMedia("audio") }} control={<Radio color="primary" />} label="Audio" />
                              <FormControlLabel value="text" onClick={() => { setReviewMedia("text"); }} control={<Radio color="primary" />} label="Text" />
                            </RadioGroup>

                          </FormControl> */}
                        </Grid>

                        <MediaUploader
                          expId={props.expId}
                          index={props.index}
                          reviewMedia={reviewMedia}
                          reviewVideo={props.videoReview}
                          setReviewVideo={props.changeVideoReview}
                          reviewAudio={props.audioReview}
                          setReviewAudio={props.changeAudioReview}
                          handleSnackBar={props.handleSnackBar}
                        ></MediaUploader>

                        {reviewMedia && reviewMedia === "text" &&
                          <>
                            {/* <Typography variant="h3" className={classes.tfLabel} style={{ textAlign: "center", color: theme.palette.primary.main, marginBottom: 20 }}>
                              Text Review
                            </Typography> */}

                            <Grid item xs={12} >
                              <FormControl component="fieldset" style={{ width: '100%' }}>
                                <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन बातें जो आपको अच्छी लगी...</FormLabel>
                                <TextField
                                  required
                                  id="prosReview_id"
                                  inputProps={{ maxLength: 250 }}
                                  multiline
                                  rows={4}
                                  value={props.goodReview ? props.goodReview : ''}
                                  onChange={(event) => { props.changeGoodReview(event.target.value, props.index) }}
                                  placeholder="Example: Company में काम करने का बहुत ही अच्छा environment था, सभी staff के लोग supportive थे, अच्छी Salary के साथ अच्छे incentives मिलते थे और Free Breakfast मिलता था।"
                                  variant="outlined"
                                  classes={{ root: classes.TfRoot }}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} >
                              <FormControl component="fieldset" style={{ width: '100%' }}>
                                <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन चीज़ें जो सुधारी जा सकती हैं...</FormLabel>
                                <TextField
                                  required
                                  id="consReview_id"
                                  inputProps={{ maxLength: 250 }}
                                  multiline
                                  rows={4}
                                  value={props.badReview ? props.badReview : ''}
                                  onChange={(event) => { props.changeBadReview(event.target.value, props.index) }}
                                  placeholder="Example: यह एक टारगेट based Job थी; Manager का behaviour अच्छा नहीं था; और Salary late आती थी।"
                                  variant="outlined"
                                  classes={{ root: classes.TfRootWithoutMB }}
                                />
                              </FormControl>
                            </Grid>
                          </>
                        }
                      </>
                    }

                  </TabPanel>
                  <TabPanel value={primaryTabValue} index={tabIndices.identitySettings}>

                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>

                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                        इस company से related Ratings & Reviews के लिए आप अपनी पहचान कैसी रखना चाहेंगे?
                      </Typography>
                      {/* <Typography variant="body1">
                      इस company से related आपके ratings & reviews के लिए आप अपनी पहचान गुप्त या public रख सकते हैं।
                    </Typography> */}

                      <FormControl id="reviewMediaInput_id" component="fieldset">
                        <RadioGroup aria-label="Ratings and reviews identity setting" name="guptOrPublic" value={guptOrPublic} >
                          <div>
                            <FormControlLabel
                              value="gupt"
                              onClick={() => {
                                setGuptOrPublic("gupt");
                                props.changeIsReviewPublic(false, props.index)
                              }}
                              control={<Radio color="primary" />} label="गुप्त" classes={{ label: classes.radioLabelHindi }} />
                            <Typography variant="body1"
                              style={{ marginTop: '-8px', paddingLeft: 30, color: theme.palette.common.grey }} >
                              आपकी पहचान गुप्त रहेगी।
                            </Typography>
                          </div>
                          <div>
                            <FormControlLabel
                              value="public"
                              onClick={() => {
                                setGuptOrPublic("public");
                                props.changeIsReviewPublic(true, props.index)
                              }}
                              control={<Radio color="primary" />}
                              label="Public" classes={{ label: classes.radioLabel }} />
                            <Typography variant="body1"
                              style={{ marginTop: '-8px', paddingLeft: 30, color: theme.palette.common.grey }} >
                              Reviews और ratings के साथ आपका नाम और profile picture भी post होगा।
                              {/* आप आगे अपना video या audio भी post कर पाएंगे! */}
                            </Typography>
                          </div>

                        </RadioGroup>

                      </FormControl>
                    </Grid>

                  </TabPanel>

                </SwipeableViews>
                :
                <React.Fragment>
                  <Grid
                    container
                    className={classes.section}
                    direction="column" style={{ marginTop: 10, paddingTop: 50 }}>
                    <Typography variant="h3" color="primary" style={{ marginBottom: 30 }}>Ratings</Typography>

                    <Grid
                      container
                      className={classes.section}
                      direction="column"
                      alignItems='center'
                    >

                      <Typography variant="h3" align="center" style={{ marginBottom: 10, color: theme.palette.common.blackDark, fontWeight: 600 }}>आपका इस company में काम करने का अनुभव कैसा था? </Typography>

                      <Grid item xs container justifyContent="center" alignItems="center" className={classes.sectionItem_heading} style={{ marginBottom: 0 }}>

                        <CustomRatings
                          type={"animated"}
                          size={"large"}
                          value={props.overallRating ? props.overallRating : 0}
                          onChange={(newValue) => {
                            props.changeOverallRating(newValue, props.index);
                            if (newValue <= 3) setReviewTagType('bad');
                            else if (newValue > 3) setReviewTagType('good');
                          }}
                        />

                      </Grid>

                      <Typography variant="h2" align="center" style={{ marginBottom: 40, color: theme.palette.common.blackDark, fontWeight: 600, minHeight: '18.4px' }}>
                        {props.overallRating ?
                          props.overallRating > 0 && props.overallRating <= 1 ? "बहुत ख़राब!"
                            : props.overallRating > 1 && props.overallRating <= 2 ? "ख़राब!"
                              : props.overallRating > 2 && props.overallRating <= 3 ? "ठीक ठाक!!"
                                : props.overallRating > 3 && props.overallRating <= 4 ? "अच्छा!"
                                  : props.overallRating > 4 && props.overallRating <= 5 ? "बहुत अच्छा!"
                                    : null
                          : null}
                      </Typography>
                    </Grid>

                    <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                      <Grid item xs={5} container justifyContent="flex-start" alignItems="center" >
                        {/* <EmployeesIcon fontSize="small" className={classes.sectionItem_heading_icon2} /> */}
                        <Typography variant="body1" style={{ paddingTop: 14 }}>Company का माहौल</Typography>
                      </Grid>
                      <Grid item xs={7} container justifyContent="flex-start" alignItems="center" >
                        <CustomRatings
                          type={"static"}
                          value={props.envRating ? props.envRating : 0}
                          onChange={(newValue) => {
                            props.changeEnvRating(newValue, props.index);
                          }}
                        />
                      </Grid>

                      {/* <Rating
                      id="companyEnv_id"
                      name="company_rating"
                      value={props.envRating ? props.envRating : 0}
                      onChange={(event, newValue) => { props.changeEnvRating(newValue, props.index) }}
                      precision={1}
                      icon={<StarRoundedIcon fontSize="medium" />}
                    /> */}
                    </Grid>

                    <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                      <Grid item xs={5} container justifyContent="flex-start" alignItems="center" >
                        {/* <EqualizerIcon fontSize="small" className={classes.sectionItem_heading_icon2} /> */}
                        <Typography variant="body1" style={{ paddingTop: 14 }}>Growth के chances </Typography>
                      </Grid>
                      <Grid item xs={7} container justifyContent="flex-start" alignItems="center" >
                        <CustomRatings
                          type={"static"}
                          value={props.growthRating ? props.growthRating : 0}
                          onChange={(newValue) => {
                            props.changeGrowthRating(newValue, props.index);
                          }}
                        /></Grid>

                      {/* <Rating
                      id="careerRating_id"
                      name="career_rating"
                      value={props.growthRating ? props.growthRating : 0}
                      onChange={(event, newValue) => { props.changeGrowthRating(newValue, props.index) }}
                      precision={1}
                      icon={<StarRoundedIcon fontSize="medium" />}
                    /> */}
                    </Grid>

                    <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                      <Grid item xs={5} container justifyContent="flex-start" alignItems="center" >
                        {/* <LocalLibraryIcon fontSize="small" className={classes.sectionItem_heading_icon2} /> */}
                        <Typography variant="body1" style={{ paddingTop: 14 }}>Learning का scope</Typography>
                      </Grid>
                      <Grid item xs={7} container justifyContent="flex-start" alignItems="center" >
                        <CustomRatings
                          type={"static"}
                          value={props.learningRating ? props.learningRating : 0}
                          onChange={(newValue) => {
                            props.changeLearningRating(newValue, props.index);
                          }}
                        />
                      </Grid>
                      {/* <Rating
                      id="learningRating_id"
                      name="learning_rating"
                      value={props.learningRating ? props.learningRating : 0}
                      onChange={(event, newValue) => { props.changeLearningRating(newValue, props.index) }}
                      precision={1}
                      icon={<StarRoundedIcon fontSize="medium" />}
                    /> */}
                    </Grid>

                    <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                      <Grid item xs={5} container justifyContent="flex-start" alignItems="center" >
                        {/* <WidgetsIcon fontSize="small" className={classes.sectionItem_heading_icon2} /> */}
                        <Typography variant="body1" style={{ paddingTop: 14 }}>Facilities </Typography>
                      </Grid>
                      <Grid item xs={7} container justifyContent="flex-start" alignItems="center" >
                        <CustomRatings
                          type={"static"}
                          value={props.facilityRating ? props.facilityRating : 0}
                          onChange={(newValue) => {
                            props.changeFacilityRating(newValue, props.index);
                          }}
                        />
                      </Grid>
                      {/* <Rating
                      id="facilityRating_id"
                      name="facility_rating"
                      value={props.facilityRating ? props.facilityRating : 0}
                      onChange={(event, newValue) => { props.changeFacilityRating(newValue, props.index) }}
                      precision={1}
                      icon={<StarRoundedIcon fontSize="medium" />}
                    /> */}
                    </Grid>

                    <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                      <Grid item xs={5} container justifyContent="flex-start" alignItems="center" >
                        {/* <LocationOnIcon fontSize="small" className={classes.sectionItem_heading_icon2} /> */}
                        <Typography variant="body1" style={{ paddingTop: 14 }}>Company की जगह</Typography>
                      </Grid>
                      <Grid item xs={7} container justifyContent="flex-start" alignItems="center" >
                        <CustomRatings
                          type={"static"}
                          value={props.locationRating ? props.locationRating : 0}
                          onChange={(newValue) => {
                            props.changeLocationRating(newValue, props.index);
                          }}
                        />
                      </Grid>

                      {/* <Rating
                      id="locationRating_id"
                      name="location_rating"
                      value={props.locationRating ? props.locationRating : 0}
                      onChange={(event, newValue) => { props.changeLocationRating(newValue, props.index) }}
                      precision={1}
                      icon={<StarRoundedIcon fontSize="medium" />}
                    /> */}
                    </Grid>

                    <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                      <Grid item xs={5} container justifyContent="flex-start" alignItems="center" >
                        {/* <SupervisorAccountIcon fontSize="small" className={classes.sectionItem_heading_icon2} /> */}
                        <Typography variant="body1" style={{ paddingTop: 14 }}>My Manager</Typography>
                      </Grid>
                      <Grid item xs={7} container justifyContent="flex-start" alignItems="center" >
                        <CustomRatings
                          type={"static"}
                          value={props.managerRating ? props.managerRating : 0}
                          onChange={(newValue) => {
                            props.changeManagerRating(newValue, props.index);
                          }}
                        />
                      </Grid>
                      {/* <Rating
                      id="managerRating_id"
                      name="manager_rating"
                      value={props.managerRating ? props.managerRating : 0}
                      onChange={(event, newValue) => { props.changeManagerRating(newValue, props.index) }}
                      precision={1}
                      icon={<StarRoundedIcon fontSize="medium" />}
                    /> */}
                    </Grid>

                    {!props.isUnpaid &&
                      <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                        <Grid item xs={5} container justifyContent="flex-start" alignItems="center" >
                          <Typography variant="body1" style={{ paddingTop: 14 }}>Salary</Typography>
                        </Grid>
                        <Grid item xs={7} container justifyContent="flex-start" alignItems="center" >
                          <CustomRatings
                            type={"static"}
                            value={props.salaryRating ? props.salaryRating : 0}
                            onChange={(newValue) => {
                              props.changeSalaryRating(newValue, props.index);
                            }}
                          />
                        </Grid>
                      </Grid>
                    }

                  </Grid>

                  <Grid
                    container
                    className={classes.section}
                    direction="column" style={{ paddingTop: 20 }}>
                    <Typography variant="h3" color="primary">Reviews</Typography>

                    {/* Review Tags */}
                    <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem} style={{ marginBottom: 20 }}>

                      <Typography variant="h3" className={classes.tfLabel}>
                        {reviewTagType === 'good'
                          ? "आपको company के बारे मे क्या अच्छा लगा?"
                          : reviewTagType === 'bad'
                            ? "आपको company के बारे मे क्या बुरा लगा?"
                            : "आपको company के बारे मे क्या अच्छा लगा ?"}
                      </Typography>

                      <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                        {/* {reviewTagsAll && reviewTagsAll.filter(item => item.type === (reviewTagType === '' ? 'good' : reviewTagType)).map((item, index) => */}
                        {/* onClick can be modularized later */}
                        {reviewTagsAll && (reviewTagType === "good" || reviewTagType === "") && reviewTagsAll.filter(item => item.type === "good" || item.type === "").map((item, index) =>
                          <Typography
                            key={index}
                            id={item.tag}
                            className={classes.chip}
                            onClick={(event) => {
                              let thisElem = event.target.classList;
                              if (!thisElem.contains('active')) {
                                let tags = props.reviewTags && props.reviewTags[props.index] ? props.reviewTags[props.index] : [];
                                console.log(tags);
                                console.log(props.reviewTags);
                                tags.push({ tag: item.tag, type: reviewTagType === '' ? 'good' : reviewTagType });
                                console.log(tags);

                                thisElem.add('active');
                                if (props.reviewTags.length === 0) {
                                  console.log("add to the first array")
                                  // props.setReviewTags([...props.reviewTags, props.reviewTags[props.index] = tags])
                                  props.setReviewTags([tags])
                                } else {
                                  let _a = ([...props.reviewTags], [props.reviewTags[props.index] = tags])
                                  console.log(_a)

                                  props.setReviewTags([...props.reviewTags], [props.reviewTags[props.index] = tags]);
                                }

                              }
                              else {
                                thisElem.remove('active');
                                let _allExpTags = [...props.reviewTags];
                                let _thisExpTags = _allExpTags[props.index].filter(tag => tag.tag !== item.tag);
                                let tagIndex = _allExpTags[props.index].findIndex(obj => obj.tag == item.tag);
                                let newThis = _allExpTags[props.index].splice(tagIndex, 1);

                                //console.log(_allExpTags);
                                console.log(_thisExpTags);
                                if (_allExpTags.length === 1) {
                                  //console.log(111)
                                  props.setReviewTags([_thisExpTags]);
                                } else if (_allExpTags.length > 1) {
                                  //console.log(222)
                                  props.setReviewTags([...props.reviewTags], [props.reviewTags[props.index] = newThis])
                                }
                                // props.setReviewTags([...props.reviewTags], [props.reviewTags[props.index].filter(tag => tag.tag !== item.tag)])
                              }

                              //to enable save btn on profile main page
                              props.setIsSaveBtnDisabled(false);
                              props.showSaveCancelBtns();

                            }}
                          >
                            {item.tag}</Typography>
                        )}

                        {reviewTagsAll && reviewTagType === "bad" && reviewTagsAll.filter(item => item.type === "bad").map((item, index) =>
                          <Typography
                            key={index}
                            id={item.tag}
                            className={classes.chip}
                            onClick={(event) => {
                              let thisElem = event.target.classList;
                              if (!thisElem.contains('active')) {
                                let tags = props.reviewTags && props.reviewTags[props.index] ? props.reviewTags[props.index] : [];
                                //console.log(tags);
                                //console.log(props.reviewTags);
                                tags.push({ tag: item.tag, type: reviewTagType === '' ? 'good' : reviewTagType });
                                //console.log(tags);

                                thisElem.add('active');
                                if (props.reviewTags.length === 0) {
                                  //console.log("add to the first array")
                                  // props.setReviewTags([...props.reviewTags, props.reviewTags[props.index] = tags])
                                  props.setReviewTags([tags])
                                } else {
                                  let _a = ([...props.reviewTags], [props.reviewTags[props.index] = tags])
                                  console.log(_a)

                                  props.setReviewTags([...props.reviewTags], [props.reviewTags[props.index] = tags]);
                                }

                              }
                              else {
                                thisElem.remove('active');
                                let _allExpTags = [...props.reviewTags];
                                let _thisExpTags = _allExpTags[props.index].filter(tag => tag.tag !== item.tag);
                                let tagIndex = _allExpTags[props.index].findIndex(obj => obj.tag == item.tag);
                                let newThis = _allExpTags[props.index].splice(tagIndex, 1);

                                //console.log(_allExpTags);
                                console.log(_thisExpTags);
                                if (_allExpTags.length === 1) {
                                  //console.log(111)
                                  props.setReviewTags([_thisExpTags]);
                                } else if (_allExpTags.length > 1) {
                                  //console.log(222)
                                  props.setReviewTags([...props.reviewTags], [props.reviewTags[props.index] = newThis])
                                }
                                // props.setReviewTags([...props.reviewTags], [props.reviewTags[props.index].filter(tag => tag.tag !== item.tag)])
                              }

                              //to enable save btn on profile main page
                              props.setIsSaveBtnDisabled(false);
                              props.showSaveCancelBtns();

                            }}
                          >
                            {item.tag}</Typography>
                        )}
                      </Grid>
                    </Grid>

                    {/* Video / Audio or Text Review */}
                    {guptOrPublic === "gupt" ?
                      <>
                        <Grid item xs={12} style={{ marginBottom: 15 }}>
                          <Typography variant="h3" className={classes.tfLabel} > अपने experience के बारे मे थोड़ा और बताएं...
                          </Typography>
                        </Grid>

                        <Grid item xs={12} >
                          <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन बातें जो आपको अच्छी लगी...</FormLabel>
                            <TextField
                              required
                              id="prosReview_id"
                              inputProps={{ maxLength: 250 }}
                              multiline
                              rows={4}
                              value={props.goodReview ? props.goodReview : ''}
                              onChange={(event) => { props.changeGoodReview(event.target.value, props.index) }}
                              placeholder="Example: Company में काम करने का बहुत ही अच्छा environment था, सभी staff के लोग supportive थे, अच्छी Salary के साथ अच्छे incentives मिलते थे और Free Breakfast मिलता था।"
                              variant="outlined"
                              classes={{ root: classes.TfRoot }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} >
                          <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन चीज़ें जो सुधारी जा सकती हैं...</FormLabel>
                            <TextField
                              required
                              id="consReview_id"
                              inputProps={{ maxLength: 250 }}
                              multiline
                              rows={4}
                              value={props.badReview ? props.badReview : ''}
                              onChange={(event) => { props.changeBadReview(event.target.value, props.index) }}
                              placeholder="Example: यह एक टारगेट based Job थी; Manager का behaviour अच्छा नहीं था; और Salary late आती थी।"
                              variant="outlined"
                              classes={{ root: classes.TfRootWithoutMB }}
                            />
                          </FormControl>
                        </Grid>
                      </>
                      : <>

                        <Grid item xs={12} style={{ width: '100%', marginBottom: 15 }}>

                          <Typography variant="h3" className={classes.tfLabel}
                          // style={{ marginBottom: 6 }}
                          >
                            अपने experience के बारे मे थोड़ा और बताएं...
                          </Typography>


                          {/* <FormControl id="reviewMediaInput_id" component="fieldset">
                           
                            <RadioGroup row aria-label="Review Type" name="reviewMedia" value={reviewMedia} >
                              <FormControlLabel value="video" onClick={() => { setReviewMedia("video"); }} control={<Radio color="primary" />} label="Video" />
                              <FormControlLabel value="audio" onClick={() => { setReviewMedia("audio") }} control={<Radio color="primary" />} label="Audio" />
                              <FormControlLabel value="text" onClick={() => { setReviewMedia("text"); }} control={<Radio color="primary" />} label="Text" />
                            </RadioGroup>

                          </FormControl> */}
                        </Grid>

                        <MediaUploader
                          expId={props.expId}
                          index={props.index}
                          reviewMedia={reviewMedia}
                          reviewVideo={props.videoReview}
                          setReviewVideo={props.changeVideoReview}
                          reviewAudio={props.audioReview}
                          setReviewAudio={props.changeAudioReview}
                          handleSnackBar={props.handleSnackBar}
                        ></MediaUploader>

                        {reviewMedia && reviewMedia === "text" &&
                          <>
                            <Grid item xs={12} >
                              <FormControl component="fieldset" style={{ width: '100%' }}>
                                <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन बातें जो आपको अच्छी लगी...</FormLabel>
                                <TextField
                                  required
                                  id="prosReview_id"
                                  inputProps={{ maxLength: 250 }}
                                  multiline
                                  rows={4}
                                  value={props.goodReview ? props.goodReview : ''}
                                  onChange={(event) => { props.changeGoodReview(event.target.value, props.index) }}
                                  placeholder="Example: Company में काम करने का बहुत ही अच्छा environment था, सभी staff के लोग supportive थे, अच्छी Salary के साथ अच्छे incentives मिलते थे और Free Breakfast मिलता था।"
                                  variant="outlined"
                                  classes={{ root: classes.TfRoot }}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} >
                              <FormControl component="fieldset" style={{ width: '100%' }}>
                                <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन चीज़ें जो सुधारी जा सकती हैं...</FormLabel>
                                <TextField
                                  required
                                  id="consReview_id"
                                  inputProps={{ maxLength: 250 }}
                                  multiline
                                  rows={4}
                                  value={props.badReview ? props.badReview : ''}
                                  onChange={(event) => { props.changeBadReview(event.target.value, props.index) }}
                                  placeholder="Example: यह एक टारगेट based Job थी; Manager का behaviour अच्छा नहीं था; और Salary late आती थी।"
                                  variant="outlined"
                                  classes={{ root: classes.TfRootWithoutMB }}
                                />
                              </FormControl>
                            </Grid>
                          </>
                        }
                      </>
                    }

                    {/* <Grid item xs={12}>
                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 25 }}>
                        अपने experience के बारे मे थोड़ा और बताएं...
                      </Typography>

                      <MediaUploader
                        expId={props.expId}
                        index={props.index}
                        reviewMedia={reviewMedia}
                        reviewVideo={props.videoReview}
                        setReviewVideo={props.changeVideoReview}
                        reviewAudio={props.audioReview}
                        setReviewAudio={props.changeAudioReview}
                        handleSnackBar={props.handleSnackBar}
                      ></MediaUploader>

                      <Grid item xs={12} >
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                          <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन बातें जो आपको अच्छी लगी...</FormLabel>
                          <TextField
                            required
                            id="prosReview_id"
                            inputProps={{ maxLength: 250 }}
                            multiline
                            rows={4}
                            value={props.goodReview ? props.goodReview : ''}
                            onChange={(event) => { props.changeGoodReview(event.target.value, props.index) }}
                            placeholder="Enter"
                            variant="outlined"
                            classes={{ root: classes.TfRoot }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} >
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                          <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>तीन चीज़ें जो सुधारी जा सकती हैं...</FormLabel>
                          <TextField
                            required
                            id="consReview_id"
                            inputProps={{ maxLength: 250 }}
                            multiline
                            rows={4}
                            value={props.badReview ? props.badReview : ''}
                            onChange={(event) => { props.changeBadReview(event.target.value, props.index) }}
                            placeholder="Example: यह एक टारगेट based Job थी; Manager का behaviour अच्छा नहीं था; और Salary late आती थी।"
                            variant="outlined"
                            classes={{ root: classes.TfRoot }}
                          />
                        </FormControl>
                      </Grid>

                    </Grid> */}

                  </Grid>
                </React.Fragment>
            }

            {!props.showOnePageRatings ?
              <AppBar position="fixed" className={classes.tabBar} style={{ top: 'auto', bottom: 0 }}>
                <Toolbar variant="dense" style={{ width: '100%' }} >
                  {primaryTabValue !== 0 ?
                    <Button
                      color="secondary"
                      onClick={() => {
                        // handleTabChange("back");
                        setPrimaryTabValue(prev => prev - 1);
                      }}
                      style={{ fontSize: '0.875rem', textTransform: 'capitalize' }}>
                      Back</Button>
                    : null}
                  {primaryTabValue !== tabsLength ?
                    <Button
                      color="secondary"
                      onClick={() => {
                        setPrimaryTabValue(prev => prev + 1);
                      }
                      }
                      style={{ fontSize: '0.875rem', textTransform: 'capitalize', marginLeft: 'auto' }}>
                      Next</Button>
                    :
                    <Button
                      color="secondary"
                      className={classes.button_sec}
                      onClick={() => {
                        //to enable save btn on profile main page and close this model
                        //props.setIsSaveBtnDisabled(false);
                        props.showSaveCancelBtns();
                        props.closeRatingsModal();
                      }
                      }
                      style={{ fontSize: '0.875rem', textTransform: 'capitalize', marginLeft: 'auto' }}>Submit</Button>}

                </Toolbar>
              </AppBar>
              :
              <AppBar position="fixed" className={classes.tabBar} style={{ top: 'auto', bottom: 0 }}>
                <Toolbar variant="dense" style={{ width: '100%' }} >

                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      {/* <Grid item><Typography variant="body1" color="primary">Off</Typography></Grid> */}
                      <Grid item>
                        <AntSwitch checked={guptOrPublic === "public" ? true : false} onChange={e => {
                          setGuptOrPublic(e.target.checked ? "public" : "gupt");
                          props.changeIsReviewPublic(e.target.checked, props.index)
                        }
                        } name="checkedC" />
                      </Grid>
                      <Grid item><Typography variant="body1" color="primary">{guptOrPublic === "public" ? "पहचान Public है। " : "पहचान गुप्त है।"}</Typography></Grid>
                    </Grid>
                  </Typography>

                  <Button
                    color="secondary"
                    onClick={() => { props.closeRatingsModal(); }}
                    style={{ fontSize: '0.875rem', textTransform: 'capitalize', marginLeft: 'auto' }}>
                    Done
                  </Button>
                </Toolbar>
              </AppBar>
            }

          </React.Fragment>
        }
      </React.Fragment>
    </div >
  )
}