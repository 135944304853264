import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
//import Typography from '@material-ui/core/Typography';
//import Button from '@material-ui/core/Button';
import { Icon, Paper, TextField, Button, Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Helmet } from 'react-helmet-async';

import { useStyles } from './styles';

import BottomAppBar from '../../components/BottomAppBar';
import SearchBar from './SearchBarDummy';
import TopAppBar from '../../components/TopAppBar';
import '../../style/App.css';
import SignInUpDrawer from '../../components/SignInDrawer';
import ApiHandler from '../../ApiHandler';
import JobsListItem from './JobsListItem';
import ListLoader from '../errorsAndLoaders/ListLoader';
import InfiniteScroll from "react-infinite-scroll-component";
import SnackBarGeneric from './SnackBarGeneric';

import NoResultsImage from '../../assets/no_results.svg';
import LogoLoader from '../../assets/logo-loader.gif';

let count1 = 0, count2 = 0, count3 = 0;

export default function Jobs_role({ match }) {
  const location = useLocation();
  const classes = useStyles();
  const [navValue, setNavValue] = React.useState(0);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);

  const refSeeAllJobsButton = React.useRef(null);
  const [isLocationDenied, setIsLocationDenied] = React.useState(false);

  const matchesMinHeight = useMediaQuery('(min-height:500px)');

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);

  let urlArray = match.params._role.split('-');
  let urlArray2 = urlArray.map(element => {
    const word = element.charAt(0).toUpperCase() + element.slice(1);
    //console.log(word);
    return word
  }) 
  let urlRole = urlArray2.join(" ");

  const checkSignedInStatus = () => {
    return isSignedIn
  }
  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const [displaySearchBar, setDisplaySearchBar] = React.useState(true);
  const [displayAllJobs, setDisplayAllJobs] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState(null);
  const [doRefreshJobs, setDoRefreshJobs] = React.useState(true);

  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends


  const setOnSearchClick = () => {
    history.push({
      pathname: `/`,
      state: {
        see_all_roles: true,
        // modeOfWork: modeOfWork,
        latLong: latLong,
      }
    });
    sessionStorage.setItem("pageStateOnReturn", "search") // to change the jobs page to search state
  }

  // get jobs list---> starts
  const [jobsList, setJobsList] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageOffset, setPageOffset] = React.useState(0);
  const [jobsCount, setJobsCount] = React.useState(null);
  const [vacanciesCount, setVacanciesCount] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);
  //const [role, setRole] = React.useState(location.state.role);
  const [role, setRole] = React.useState(urlRole);
  // const [modeOfWork, setModeOfWork] = React.useState(location.state.modeOfWork);
  const [modeOfWork, setModeOfWork] = React.useState(sessionStorage.getItem("modeOfWork") || "everyWhere");
  // const [lat, setLatitude] = React.useState(location.state.lat);
  // const [long, setLongitude] = React.useState(location.state.long);
  const [latLong, setLatLong] = React.useState({ lat: (location && location.state && location.state.lat ? location.state.lat : null), long: (location && location.state && location.state.long ? location.state.long : null) });
  const [refreshInfiniteScroll, setRefreshInfiniteScroll] = React.useState(true);
  const [opportunityType, setOpportunityType] = React.useState("AllJobs");
  const [showWFHOption, setShowWFHOption] = React.useState(false);

  const checkWFHRoles = () => {
    let payload = {
       location: 'Work From Home',
      //location: 'Lucknow',
      opportunity_type: 'AllJobs'
    }
    ApiHandler.getRolesAndOpeningsForLocation(payload, response => {
      if (response.status === 200){
        let arr_temp = response.data;
        if (arr_temp.length !== 0){
          setShowWFHOption(true);
        } else if (arr_temp.length === 0 && modeOfWork === "workFromHome" ) { //if by default wfh was set then switch to all
          setModeOfWork("everyWhere");
          sessionStorage.setItem("modeOfWork", "everyWhere");
        }
        
      }
    })
  }

  // get jobs list with infinitescroll as fetchMoreData is called and setRefreshInfiniteScroll triggers the api call---> starts
  const fetchMoreData = () => {
    let page = 1;
    page = currentPage + 1;
    setCurrentPage(page);
    setPageOffset((page - 1) * 10);
    setRefreshInfiniteScroll(prev => !prev);
  }

  React.useEffect(() => {
    //alert("offset change")
    // const handleMoreJobsListOld = () => {
    //   let payload = {
    //     limit: 10,
    //     offset: pageOffset,
    //     job_role: role,
    //     modeOfWork: modeOfWork,
    //     opportunity_type: opportunityType,
    //   }
    //   if (modeOfWork === "nearMe") {
    //     payload = {
    //       limit: 10,
    //       offset: pageOffset,
    //       job_role: role,
    //       modeOfWork: modeOfWork,
    //       latitude: latLong.lat,
    //       longitude: latLong.long,
    //       opportunity_type: opportunityType,
    //     }
    //   }

    //   ApiHandler.getJobPostForaRole(payload, response => {
    //     if (response.status == 200) {
    //       //console.log(response.data[0].results);
    //       if (jobsList === null) {
    //         setJobsList(response.data[0].results);
    //       }
    //       else {
    //         setJobsList(prevState => prevState.concat(response.data[0].results))
    //         //console.log(jobsList.concat(response.data[0].results));
    //       }
    //       setJobsCount(response.data[0].totalCount[0].count);
    //       setVacanciesCount(response.data[0].total_vacancies[0].count);
    //     }
    //     else {
    //       console.log("error in getting a successful response for the list of jobs")
    //     }
    //   })
    // }

    const handleMoreJobsList = () => {
      let payload;
      let limit = 10, offset = pageOffset;

      //to reset offset at the time of new api call during scroll when the results from prev api get exhausted
      if (jobsList && jobsList.length === count1) {
        setCurrentPage(1);
        offset = 0;
      } else if (jobsList && jobsList.length === count1 + count2) {
        setCurrentPage(1);
        offset = 0;
      }

      if (modeOfWork === "nearMe") {
        payload = {
          limit: limit,
          offset: offset,
          job_role: role,
          //modeOfWork: modeOfWork,
          latitude: latLong.lat,
          longitude: latLong.long,
          // latitude: 19.07283,
          // longitude: 72.88261,
          opportunity_type: opportunityType,
        }
        console.log("count1=" + count1)
        console.log("count2=" + count2)
        console.log("count3=" + count3)

        //case1: to call api1
        if (jobsList && jobsList.length < count1) {
          console.log(1)
          ApiHandler.getNearestJobsForJobRole(payload, response => {
            if (response.status == 200) {
              let result = response.data[0].results;

              if (jobsList === null) {
                setJobsList(result);
              }
              else {
                setJobsList(prevState => prevState.concat(result))
                //console.log(jobsList.concat(result));
              }
              setIsLoading(false);
            }
            else {
              console.log("error in getting a successful response")
            }
          })
        }

        //case2: to call api2
        if (jobsList && (jobsList.length === count1 || jobsList.length < count1 + count2)) {
          console.log(2);
          let payloadWFH = {
            limit: limit,
            offset: offset,
            job_role: role,
            opportunity_type: opportunityType,
          }
          ApiHandler.getWFHJobsForJobRole(payloadWFH, response => {
            if (response.status == 200) {
              let result = response.data[0].results;
              count2 = response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0;

              if (jobsList === null) {
                setJobsList(result);
              }
              else {
                setJobsList(prevState => prevState.concat(result))
                //console.log(jobsList.concat(result));
              }
              setIsLoading(false);
            }
            else {
              console.log("error in getting a successful response")
            }
          })
        }

        //case3: to call api3
        if (jobsList && jobsList.length >= count1 + count2) {
          console.log(3);
          ApiHandler.getFarNearestJobsForJobRole(payload, response => {
            if (response.status == 200) {
              let result = response.data[0].results;
              count3 = response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0;

              if (jobsList === null) {
                setJobsList(result);
              }
              else {
                setJobsList(prevState => prevState.concat(result))
              }
              setIsLoading(false);
            }
            else {
              console.log("error in getting a successful response")
            }
          })
        }

      } else {
        payload = {
          limit: 10,
          offset: pageOffset,
          job_role: role,
          modeOfWork: modeOfWork,
          opportunity_type: opportunityType,
        }

        ApiHandler.getJobPostForaRole(payload, response => {
          if (response.status == 200) {
            //console.log(response.data[0].results);
            if (jobsList === null) {
              setJobsList(response.data[0].results);
            }
            else {
              setJobsList(prevState => prevState.concat(response.data[0].results))
              //console.log(jobsList.concat(response.data[0].results));
            }
            setJobsCount(response.data[0].totalCount[0].count);
            setVacanciesCount(response.data[0].total_vacancies[0].count);
          }
          else {
            console.log("error in getting a successful response for the list of jobs")
          }
        })

      }
    }

    if (jobsList && jobsList.length >= jobsCount) {
      setHasMore(false);
    } else if (currentPage > 1) {
      handleMoreJobsList();
    }
  }, [refreshInfiniteScroll])

  // get jobs list on page load---> starts
  React.useEffect(() => {

    checkWFHRoles(); // check if wfh roles are present. if not then hide wfh location chip

    if (modeOfWork == "nearMe") {//to avoid double api call for nearme
      return;
    }

    var data = {
      limit: 10,
      offset: 0,
      job_role: role,
      modeOfWork: modeOfWork,
      opportunity_type: opportunityType,
    }

    // if (modeOfWork == "nearMe") {
    //   data['latitude'] = latLong.lat;
    //   data['longitude'] = latLong.long;
    // }

    ApiHandler.getJobPostForaRole(data, response => {
      if (response.status == 200) {
        // debugger;
        var arr_temp = response.data[0].results;
        setJobsList(arr_temp);
        // debugger;
        //console.log("Total jobs:::::" + response.data[0].totalCount[0].count);
        setJobsCount(response.data && response.data[0] && response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0);
        setVacanciesCount(response.data && response.data[0] && response.data[0].total_vacancies[0] && response.data[0].total_vacancies[0].count ? response.data[0].total_vacancies[0].count : null);
        if (response && response.data[0] && response.data[0].totalCount[0] && response.data[0].totalCount[0].count && response.data[0].totalCount[0].count< 10) {
          setHasMore(false);
        }
        setIsLoading(false);
      }
      else {
        console.log("error in getting a successful response")
      }
    })
  }, [])
  // get jobs list on page load---> ends

  // get jobs list after change in modeOfWork except nearMe. If mode of work is near me then detect geolocation which will change lat long and trigger another specific useeffect call---> starts
  const trackInitialRenderRef = React.useRef(true);

  React.useEffect(() => {
    if (trackInitialRenderRef.current) {
      trackInitialRenderRef.current = false;
      return;
    }
    //alert("not first render")
    const handleJobsListAfterModeChange = () => {
      setIsLoading(true);
      setCurrentPage(1);
      setPageOffset(0);
      setHasMore(true);

      //alert("mode of work change detected in jobs main")
      let payload = {
        limit: 10,
        offset: 0,
        job_role: role,
        modeOfWork: modeOfWork,
        opportunity_type: opportunityType,
      }

      if (modeOfWork == "nearMe") {
        navigator.geolocation.getCurrentPosition(function (position) {
          setLatLong({ lat: position.coords.latitude, long: position.coords.longitude })
          // This will trigger the useeffect below with a dependency for latLong
          setIsLocationDenied(false);
        }, function (err) {
          //console.log(err);
          setIsLocationDenied(true);
          handleSnackBar(`Showing results for everywhere as you have denied Location access permission.`)
        });
        // }
      } else {
        ApiHandler.getJobPostForaRole(payload, response => {
          if (response.status == 200) {
            let arr_temp = response.data[0].results;
            setJobsList(arr_temp);
            setJobsCount(response.data[0].totalCount[0].count);
            setVacanciesCount(response.data[0].total_vacancies[0].count);
            if (response.data[0].totalCount[0].count < 10) {
              setHasMore(false);
            }

            setIsLoading(false);
          }
          else {
            console.log("error in getting a successful response for list of jobs")
          }
        })
      }
    }
    executeScroll();
    handleJobsListAfterModeChange();
  }, [modeOfWork])
  // get jobs list after change in modeOfWork except nearMe---> ends

  // get jobs list after change in modeOfWork to nearMe which triggered latLong detection---> starts
  const trackInitialRenderRefForLatLong = React.useRef(true);

  React.useEffect(() => {
    //alert("on lat change")
    if (trackInitialRenderRefForLatLong.current) {
      trackInitialRenderRefForLatLong.current = false;
      return;
    }

    // if (modeOfWork === "nearMe") {
    //   let payload = {
    //     limit: 10,
    //     offset: 0,
    //     job_role: role,
    //     modeOfWork: modeOfWork,
    //     latitude: latLong.lat,
    //     longitude: latLong.long,
    //     opportunity_type: opportunityType,
    //   }
    //   ApiHandler.getJobPostForaRole(payload, response => {
    //     if (response.status == 200) {
    //       let arr_temp = response.data[0].results;
    //       setJobsList(arr_temp);
    //       setJobsCount(response.data[0].totalCount[0].count);
    //       setVacanciesCount(response.data[0].total_vacancies[0].count);
    //       if (response.data[0].totalCount[0].count < 10) {
    //         setHasMore(false);
    //       }

    //       setIsLoading(false);
    //     }
    //     else {
    //       console.log("error in getting a successful response")
    //     }
    //   })
    // }

    if (modeOfWork === "nearMe") {
      let offset = 0, limit = 10;
      let result1, result2, result3;

      let payload = {
        limit: limit,
        offset: offset,
        job_role: role,
        //modeOfWork: modeOfWork,
        modeOfWork: 'everyWhere',
        latitude: latLong.lat,
        longitude: latLong.long,
        opportunity_type: opportunityType,
      }

      ApiHandler.getNearestJobsForJobRole(payload, response => {
        if (response.status == 200) {
          //console.log(response);
          result1 = response.data[0].results;
          count1 = response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0;

          if (count1 === 0 || count1 < 10) {
            let payloadWFH = {
              limit: limit,
              offset: offset,
              job_role: role,
              opportunity_type: opportunityType,
            }

            ApiHandler.getWFHJobsForJobRole(payloadWFH, response => {
              if (response.status == 200) {
                //console.log(response);
                result2 = result1.concat(response.data[0].results);
                count2 = response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0;

                if (count2 === 0 || count1 + count2 < 10) {
                  ApiHandler.getFarNearestJobsForJobRole(payload, response => {
                    if (response.status == 200) {
                      //console.log(response);
                      result3 = result2.concat(response.data[0].results);
                      count3 = response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0;

                      setJobsList(result3);
                      //setJobsCount(count3);
                      //setVacanciesCount(response.data[0].total_vacancies[0] && response.data[0].total_vacancies[0].count ? response.data[0].total_vacancies[0].count : 0);
                      if (count3 < 10) {
                        setHasMore(false);
                      }
                      setIsLoading(false);

                    }
                    else {
                      console.log("error in getting a successful response")
                    }
                  })
                } else {
                  setJobsList(result2);
                  setIsLoading(false);
                }
              } else {
                console.log("error in getting a successful response")
              }
            })
          } else {
            setJobsList(result1);
            setIsLoading(false);
          }

        }
        else {
          console.log("error in getting a successful response")
        }
      })

      // called to set the total count and total vacancies of jobs from all the 3 apis
      ApiHandler.getJobPostForaRole(payload, response => {
        if (response.status == 200) {
          setJobsCount(response.data[0].totalCount[0].count);
          setVacanciesCount(response.data[0].total_vacancies[0].count);
        }
        else {
          console.log("error in getting a successful response")
        }
      })

    //   // ApiHandler.getJobPostForaRole(payload, response => {
    //   //   if (response.status == 200) {
    //   //     let arr_temp = response.data[0].results;
    //   //     setJobsList(arr_temp);
    //   //     setJobsCount(response.data[0].totalCount[0].count);
    //   //     setVacanciesCount(response.data[0].total_vacancies[0].count);
    //   //     if (response.data[0].totalCount[0].count < 10) {
    //   //       setHasMore(false);
    //   //     }

    //   //     setIsLoading(false);
    //   //   }
    //   //   else {
    //   //     console.log("error in getting a successful response")
    //   //   }
    //   // })

    }
  }, [latLong])
  // get jobs list after change in modeOfWork to nearMe which triggered latLong detection---> ends

  // detect onload whether location access permission is denied and update the nearMe option in the drawer comp--->starts
  React.useEffect(() => {
    function handlePermission() {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state == 'granted' || result.state == 'prompt') {
          setIsLocationDenied(false);
          //if mode of work is near me by default then call gelocation api on load irrespective of OS
          if (modeOfWork == "nearMe") {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(function (position) { // on success
                setLatLong({ lat: position.coords.latitude, long: position.coords.longitude });
                // This will trigger the useeffect with a dependency for latLong
                setIsLocationDenied(false);
              }, function () { // on error
                setIsLocationDenied(true);
              })
            }
          }
        } else if (result.state == 'denied') {
          setIsLocationDenied(true);
        }
      });
    }

    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (iosPlatforms.indexOf(platform) !== -1 || macosPlatforms.indexOf(platform) !== -1) {
      //alert("iOS or Mac OS")
      // access location onload if iOS detected
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) { // on success
          setLatLong({ lat: position.coords.latitude, long: position.coords.longitude });
          // This will trigger the useeffect with a dependency for latLong
          setIsLocationDenied(false);
        }, function () { // on error
          setIsLocationDenied(true);
        })
      }
    } else { // use navigator permissions API for better UX in case OS is not iOS/ MAC OS
      if (navigator.permissions && navigator.permissions.query) {
        handlePermission();
      }
    }
  }, [])
  // detect onload whether location access permission is denied and update the nearMe option in the drawer comp--->ends

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  //sort bar bg effect on scroll starts
  const pageBarRef = React.useRef(null);
  let lastScrollY = 0;
  let scrolling = false;

  const handleScrollChange = () => {
    lastScrollY = window.scrollY;
    if (lastScrollY > 60) {
      scrolling = true
    } else {
      scrolling = false
    }
    if (!scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        pageBarRef.current.style.background = '#f5f5f5';
        pageBarRef.current.style.boxShadow = "none";
        pageBarRef.current.style.flexBasis = "65px";
      });
    } else if (scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        pageBarRef.current.style.background = '#f5f5f5';
        pageBarRef.current.style.boxShadow = "0px 5px 5px rgba(0,0,0,0.05)";
        pageBarRef.current.style.flexBasis = "50px";
      });
    }
  };

  React.useEffect(() => {
    executeScroll();
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);
  //sort bar bg effect on scroll ends

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const handleSnackBar = (message) => {
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev)
  }
  // snackbar callback ends

  return (
    <React.Fragment>
      <Helmet>
        <title>{role + " jobs, apprenticeships and parttime work by Meeत"}</title>
        <meta name="description" content={"Find local job opportunities, internships, and apprenticeships near you with Meet App. Explore a vast database of listings and uncover valuable insights through ratings and reviews from employees in diverse companies. Find the ideal career path that matches your skills and aspirations while making informed decisions about potential employers."} />
      </Helmet>
      <TopAppBar
        setDisplaySearchBar={setDisplaySearchBar}
        refSeeAllJobsButton={refSeeAllJobsButton}
        displaySearchBar={displaySearchBar}
        setDoRefreshJobs={setDoRefreshJobs}
        setModeOfWork={setModeOfWork}
        modeOfWork={modeOfWork}
        role={role}
        setJobsList={setJobsList}
        setJobsCount={setJobsCount}
        isLocationDenied={isLocationDenied}
        displayLocationBox={true}
        showBackBtn={true}
        showWFHOption={showWFHOption}
      >
      </TopAppBar>

      <BottomAppBar
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        handleDrawer={handleDrawer}
        navValue={navValue}
        setNavValue={setNavValue}
        setDisplaySearchBar={setDisplaySearchBar}
        setDisplayAllJobs={setDisplayAllJobs}
        executeScroll={executeScroll}
      >
      </BottomAppBar>

      <div className={classes.root}
        ref={scrollRef}
      >
        <Grid container
          alignItems="center" className={classes.pageBar} ref={pageBarRef}>
          <Grid item xs={12} container
            justifyContent="flex-start"
            alignItems="center">
            <SearchBar setOnSearchClick={setOnSearchClick} role={role}></SearchBar>

            {vacanciesCount && vacanciesCount > 0 &&
              <Typography variant="h2" color="primary" className={classes.panelHeading}>
                {vacanciesCount} {vacanciesCount === 1 ? "opening" : "openings"} {jobsCount && `in ${jobsCount}`} {jobsCount && jobsCount === 1 ? "posting" : "postings"} 👇
              </Typography>
            }

          </Grid>
        </Grid>

        {/* For the purpose of skipping first render in useffect */}
        <div ref={trackInitialRenderRef} style={{ display: 'none' }}></div>
        <div ref={trackInitialRenderRefForLatLong} style={{ display: 'none' }}></div>

        {isLoading
          ? <div style={{
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
          }}>
            <ListLoader setListLoaderRef={setListLoaderRef} />
          </div>
          :
          <React.Fragment>
            {jobsList && jobsList.length > 0 &&
              <div style={{ width: '100%' }}>
                <InfiniteScroll
                  dataLength={jobsList.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<div style={{
                    width: '100%', height: 80, display: 'flex', justifyContent: 'center', alignItems: 'flex-start'
                  }}>
                    <img alt="meet animated logo loader" style={{ width: 40 }} src={LogoLoader} />
                  </div>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      { }
                    </p>
                  }
                  scrollThreshold="0.7"
                >

                  <List className={classes.listRoot} disablePadding>
                    {jobsList.map((hit, index) =>
                    (<JobsListItem
                      hit={jobsList[index]}
                      signInStatus={isSignedIn}
                      key={index}
                      lat={latLong.lat}
                      long={latLong.long}
                      isWorkFromHome={jobsList[index].isWorkFromHome}
                      pageStateOnReturn={"search"}
                    />)
                    )
                    }
                  </List>
                </InfiniteScroll>
              </div>
            }
            {jobsList && jobsList.length === 0 &&
              (<div className={classes.pageInfo}
                style={{ paddingTop: matchesMinHeight ? '70px' : 0 }}
              >
                <img src={NoResultsImage} style={{ width: matchesMinHeight ? 200 : 100 }} />
                <Typography className={classes.pageInfoText} variant="subtitle1">Sorry! हमें आपकी ढूंढी हुई position नहीं मिली। </Typography>
                <Button
                  variant="contained"
                  className={`${classes.pageButton} ${classes.pageButton_pri}`}
                  style={{ width: 170 }}
                  onClick={setOnSearchClick}
                >
                  See all positions
                </Button>
              </div>)
            }
          </React.Fragment>
        }

      </div>
      <SignInUpDrawer
        canDrawerOpen={canDrawerOpen}
        refresh={refresh}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshOnly}
        setCanDrawerOpen={setCanDrawerOpen}
        doRefresh={doRefresh}
      />

      <SnackBarGeneric id="snackBarMessage" canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />

    </React.Fragment>
  )
}