import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import MicRoundedIcon from '@material-ui/icons/MicRounded';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';

import ApiHandler from '../../ApiHandler';
import reviewPosterImage from '../../assets/review-poster-image.jpg';

const useStyles = makeStyles((theme) => ({
    imgPlaceholder: {
        width: '100%',
        height: '100%',
        padding: 33,
        backgroundColor: theme.palette.common.greyLight,
        border: '4px solid white',
        borderRadius: 6,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
    },
    animateImgPlaceholder: {
        animation: 'fadeInOut 1s infinite'
    },
    imgWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 144,
        height: 194,
        overflow: 'hidden',
        margin: 0,
        borderRadius: 6,
        border: '1px solid #E5E5E5',
        '&.animate': {
            animation: 'fadeInOut 3s infinite'
        },
        '&>img': {
            transition: 'opacity 0.25s ease-in-out',
            pointerEvents: 'none',
        },
    },
    img: {
        margin: '0 auto',
        display: 'block',
        width: 'auto',
        height: '100%',
        maxWidth: 'unset',
        //position: 'absolute',
    },
    imgUploaderBtn: {
        width: 46,
        height: 46,
        background: '#FFFFFF',
        boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.14)',
        borderRadius: '50%',
    },
    mediaDeleteBtn: {
        width: 30,
        height: 30,
        background: '#FFFFFF',
        position: 'absolute',
        boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.14)',
        borderRadius: '50%',
        right: 15,
        bottom: '-14px'
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 4,
        color: theme.palette.common.greyMedium,
    },
    button: {
        textTransform: "none",
        width: 140,
    },
    button_pri: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    button_sec: {
        backgroundColor: '#fff',
        color: theme.palette.primary.dark,
        border: "1px solid",
        borderColor: theme.palette.primary.dark,
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    audioPlayIcon: {
        fontSize: "4rem",
        color: theme.palette.primary.contrastText,
    },
    iconBtnLabel: {
        flexDirection: 'column'
    }

}));

function MediaUploader(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [selectedMedia, setSelectedMedia] = React.useState("video")
    const [videoUrl, setVideoUrl] = React.useState(props.reviewVideo ? props.reviewVideo : "");
    const [videoUrlPrev, setVideoUrlPrev] = React.useState(props.reviewVideo ? props.reviewVideo : "");
    const [isVideoLoading, setIsVideoLoading] = React.useState(props.reviewVideo ? true : false);
    const [fileToUpload, setFileToUpload] = React.useState(null);
    const [isVideoApprovalOpen, setIsVideoApprovalOpen] = React.useState(false);
    const [showChangeVideoBtn, setShowChangeVideoBtn] = React.useState(props.reviewVideo ? true : false);
    const [showChangeAudioBtn, setShowChangeAudioBtn] = React.useState(props.reviewAudio ? true : false);

    const [audioUrl, setAudioUrl] = React.useState(props.reviewAudio ? props.reviewAudio : "");
    const [audioUrlPrev, setAudioUrlPrev] = React.useState(props.reviewAudio ? props.reviewAudio : "");
    const [isAudioLoading, setIsAudioLoading] = React.useState(props.reviewAudio ? true : false);
    const [audioFileToUpload, setAudioFileToUpload] = React.useState(null);
    const [isAudioApprovalOpen, setIsAudioApprovalOpen] = React.useState(false);

    const ourText = "Hello दोस्तों! मेरा नाम प्रियंका है! मैंने Maruti Suzuki Lucknow में Relationship Manager की Post पर September 2019 से December 2021 तक Full-Time Job किया है। यहाँ पर मेरा Role - Showroom में आये हुए Customers को Cars की specifications, price आदि बताना और Test Drive देना, इसके साथ ही Car को खरीदने में उनकी मदद करना था। यहाँ पर काम करके कुछ बातें बहुत अच्छी लगी! जैसे Company में काम करने का बहुत ही अच्छा environment था, सभी staff के लोग supportive थे, अच्छी Salary के साथ अच्छे incentives मिलते थे और Free Breakfast मिलता था। Company में कुछ बातें अच्छी नहीं लगी। जैसे कि यह एक टारगेट based Job थी; Manager का behaviour अच्छा नहीं था; और Salary late आती थी। धन्यवाद!"
    const msg = new SpeechSynthesisUtterance();
    const synth = window.speechSynthesis;

    const speechHandler = (msg) => {
        msg.text = ourText;
        msg.lang = "hi-HI";
        msg.rate = 1.20;
        // synth.speak(msg);

        if (!synth.speaking) synth.speak(msg);
        else {
            synth.resume();
        }


        // var r = setInterval(function () {
        //     console.log(synth.speaking);
        //     if (!synth.speaking) clearInterval(r);
        //     else {
        //         changeAudioStatus(false);
        //         synth.resume();
        //     }
        // }, 14000);


    }

    // sound code starts
    const [audioStatus, changeAudioStatus] = React.useState(false);
    const myRef = React.useRef();

    const startAudio = () => {
        if (audioUrl) {
            myRef.current.play();
        } else {
            speechHandler(msg);
        }
        changeAudioStatus(true);

    };

    const pauseAudio = () => {
        if (audioUrl) {
            myRef.current.pause();
        } else {
            synth.pause();
        }
        changeAudioStatus(false);
    };

    const handleAudioEnded = () => {
        changeAudioStatus(false);
    }
    // sound code ends

    React.useEffect(() => {
        // console.log('component onMount');
        return () => {
            //   console.log('component onUnmount');
            synth.cancel();
        };
    }, []);

    React.useEffect(() => {
        synth.addEventListener('end', handleAudioEnded);
        return () => {
            synth.removeEventListener('end', handleAudioEnded);
        }
    }, [])

    React.useEffect(() => {
        myRef.current && myRef.current.addEventListener('ended', handleAudioEnded);
        return () => {
            myRef.current && myRef.current.removeEventListener('ended', handleAudioEnded);
        };
    }, []);


    // video select and upload ---> starts
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();

            setVideoUrl(""); //to show placeholder before the new selected video gets loaded

            reader.addEventListener('load', () => {
                //console.log(reader.result);
                setVideoUrl(reader.result);
                props.setReviewVideo(reader.result, props.index);
            });

            setIsVideoApprovalOpen(true);
            //handleCloseTipsPageAfterFileSelect();

            var blobFile = e.target.files[0];
            setFileToUpload(blobFile);
            reader.readAsDataURL(blobFile);

            // new Compressor(blobFile, {
            //     quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
            //     success: (compressedResult) => {
            //         // compressedResult has the compressed file.
            //         // Use the compressed file to upload the images to your server.   
            //         console.log(compressedResult)
            //         setCompressedFile(compressedResult);
            //         reader.readAsDataURL(compressedResult);

            //         //setIsCameraPhotoApprovalOpen(true);

            //     },
            // });

        }
    };

    const uploadVideo = (blobFile) => {
        let expId = props.expId;
        //   "62b47d236f711e4155e9bf53"
        var formData = new FormData();
        formData.append('video_file', blobFile);
        // document.getElementById('imgWrapper').classList.add('animate');

        ApiHandler.uploadReviewVideo(formData, expId, (res) => {
            if (res.status == 200) {
                console.log(res.data);
                setVideoUrlPrev(res.data.video_url);
                setIsVideoApprovalOpen(false);
                setShowChangeVideoBtn(true);
                props.handleSnackBar("Video review uploaded successfully")
                // document.getElementById('imgWrapper').classList.remove('animate');
            } else { // in case upload fails clear set image to prev and remove fade effect
                console.log(videoUrlPrev);
                setVideoUrlPrev(videoUrlPrev);
                props.setReviewVideo(videoUrlPrev, props.index);
                setIsVideoApprovalOpen(false);
                props.handleSnackBar("Please try to upload a video having less than 50MB file size.", "error");
                // document.getElementById('imgWrapper').classList.remove('animate');
            }
        })
    }
    // video select and upload ---> ends

    // audio select and upload ---> starts
    const onSelectAudioFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();

            setAudioUrl(""); //to show placeholder before the new selected audio gets loaded

            reader.addEventListener('load', () => {
                //console.log(reader.result);
                setAudioUrl(reader.result);
                props.setReviewAudio(reader.result, props.index);
            });

            setIsAudioApprovalOpen(true);
            //handleCloseTipsPageAfterFileSelect();

            var blobFile = e.target.files[0];
            setAudioFileToUpload(blobFile);
            reader.readAsDataURL(blobFile);

        }
    };

    const uploadAudio = (blobFile) => {
        let expId = props.expId;
        var formData = new FormData();
        formData.append('audio_file', blobFile);
        // document.getElementById('imgWrapper').classList.add('animate');

        ApiHandler.uploadReviewAudio(formData, expId, (res) => {
            if (res.status == 200) {
                console.log(res.data);
                setAudioUrlPrev(res.data.audio_url);
                setIsAudioApprovalOpen(false);
                setShowChangeAudioBtn(true);
                props.handleSnackBar("Audio review uploaded successfully")
                // document.getElementById('imgWrapper').classList.remove('animate');
            } else { // in case upload fails clear set image to prev and remove fade effect
                console.log(audioUrlPrev);
                setAudioUrl(audioUrlPrev);
                setAudioUrlPrev(audioUrlPrev);
                props.setReviewAudio(audioUrlPrev, props.index);
                setIsAudioApprovalOpen(false);
                props.handleSnackBar("Please try to upload an audio having less than 50MB file size.", "error");
                // document.getElementById('imgWrapper').classList.remove('animate');
            }
        })
    }
    // audio select and upload ---> ends

    const deleteVideo = () => {
        let expId = props.expId
        ApiHandler.deleteReviewVideo(expId, (res) => {
            if (res.status == 200) {
                //console.log(res);
                setVideoUrl("");
                setVideoUrlPrev("");
                handleConfDialogClose();
                props.setReviewVideo("", props.index);
                setShowChangeVideoBtn(false);
                props.handleSnackBar("Review video deleted successfully")
            } else {
                props.handleSnackBar("Oops something went wrong.", "error")
            }
        })
    }

    const deleteAudio = () => {
        let expId = props.expId
        ApiHandler.deleteReviewAudio(expId, (res) => {
            if (res.status == 200) {
                //console.log(res);
                setAudioUrl("");
                setAudioUrlPrev("");
                handleConfDialogClose();
                props.setReviewAudio("", props.index);
                setShowChangeAudioBtn(false);
                props.handleSnackBar("Review audio deleted successfully")
            } else {
                props.handleSnackBar("Oops something went wrong.", "error")
            }
        })
    }

    //dialog box for delete--starts
    const [isConfDialogOpen, setIsConfDialogOpen] = React.useState(false);
    const [isAudioConfDialogOpen, setIsAudioConfDialogOpen] = React.useState(false);

    const handleConfDialogOpen = () => {
        setIsConfDialogOpen(true);
    }

    const handleConfDialogClose = () => {
        setIsConfDialogOpen(false);
    }
    //dialog box for delete--ends

    const [donotuploadVideoRefresh, setDonotuploadVideoRefresh] = React.useState(false);
    const donotuploadVideo = () => {
        setDonotuploadVideoRefresh(prev => !prev)
        setVideoUrl("");
        props.setReviewVideo(videoUrlPrev, props.index);
    }
    const [donotuploadAudioRefresh, setDonotuploadAudioRefresh] = React.useState(false);
    const donotuploadAudio = () => {
        setDonotuploadAudioRefresh(prev => !prev)
        setAudioUrl("");
        props.setReviewAudio(audioUrlPrev, props.index);
    }

    React.useEffect(() => {
        setIsVideoApprovalOpen(false);
    }, [donotuploadVideoRefresh])

    React.useEffect(() => {
        setIsAudioApprovalOpen(false);
    }, [donotuploadAudioRefresh])

    return (
        <>
            {props.reviewMedia === "video" && <div>
                <Grid
                    item xs
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    style={{ position: 'relative', margin: "30px 0 35px", paddding: "0 10px" }}
                >

                    {/* <Typography variant="h3" color="primary" style={{ marginTop: 0, marginBottom: 10, fontWeight: 600 }} align="center">
                        Video Review
                    </Typography> */}

                    {!videoUrl &&
                        <>
                            <Typography variant="subtitle1" style={{ color: theme.palette.common.black, marginTop: 10 }} align="center">
                                अच्छा review video बनाना सीखें
                            </Typography>

                            <iframe style={{ borderRadius: 6, marginTop: 10, background: theme.palette.common.greyLight }} width="330" height="154" src="https://www.youtube.com/embed/ZbtqrNtCtOg?autoplay=1&mute=1" title="how to make a good review video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </>
                    }

                    {videoUrl &&
                        <video controls id="reviewVideo_id" width="100%" style={{ background: "black", borderRadius: 6, marginTop: 10 }} src={videoUrl} poster={videoUrl ? undefined : reviewPosterImage}>
                            Your browser doesn't support
                            the video tag
                        </video>
                    }

                    {!isVideoApprovalOpen && videoUrl &&
                        <IconButton
                            className={classes.mediaDeleteBtn}
                            id="reviewVideoDeleteBtn_id"
                            onClick={() => {
                                setSelectedMedia("video");
                                handleConfDialogOpen();
                            }}
                        >
                            <DeleteForeverRoundedIcon style={{ color: theme.palette.common.redDark }} />
                        </IconButton>
                    }

                </Grid>

                {!isVideoApprovalOpen && showChangeVideoBtn &&
                    <Grid item xs container
                        justifyContent="center"
                    >
                        <Button
                            size="medium"
                            className={classes.button_sec}
                            style={{ textTransform: 'none', width: 'auto', height: 36, margin: '0 10px' }}
                            onClick={() => setShowChangeVideoBtn(false)}
                        >
                            {/* <VideoCallRoundedIcon /> */}

                            <Typography>
                                Video Change करें
                            </Typography>
                        </Button>

                    </Grid>
                }

                {!isVideoApprovalOpen && !showChangeVideoBtn &&
                    <Grid item container
                        justifyContent="space-between"
                    >
                        <Grid item container xs={6} justifyContent="center">
                            <Button
                                size="medium"
                                className={classes.button_pri}
                                style={{ textTransform: 'none', width: 'auto', height: 36, margin: '0 10px' }}
                            >
                                <VideocamRoundedIcon />
                                <input
                                    id="videoRecordBtn_id"
                                    type="file"
                                    accept="video/*"
                                    capture="user"
                                    hidden
                                    onChange={onSelectFile} />
                                <label htmlFor="videoRecordBtn_id">
                                    Record करें
                                </label>
                            </Button>
                        </Grid>
                        <Grid item container xs={6} justifyContent="center">
                            <Button
                                size="medium"
                                className={classes.button_pri}
                                style={{ textTransform: 'none', width: 'auto', height: 36, margin: '0 10px' }}
                            ><PublishRoundedIcon />
                                <input
                                    id="videoUploadBtn_id"
                                    type="file"
                                    accept="video/*"
                                    hidden
                                    onChange={onSelectFile} />
                                <label htmlFor="videoUploadBtn_id">
                                    Upload करें
                                </label>
                            </Button>
                        </Grid>

                    </Grid>
                }

                {isVideoApprovalOpen &&
                    <Grid item container
                        justifyContent="space-around"
                    // style={{ position: "fixed", bottom: 40, width: '248px', }}
                    >
                        <Button
                            size="medium"
                            style={{ backgroundColor: theme.palette.common.red, color: theme.palette.primary.contrastText, border: '1px solid', borderColor: theme.palette.common.red, textTransform: 'capitalize', width: 'auto', height: 36, margin: '0' }}
                            onClick={donotuploadVideo}
                        >
                            <CloseRoundedIcon /> Cancel
                        </Button>
                        <Button
                            size="medium"
                            style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, border: '1px solid', borderColor: theme.palette.primary.dark, textTransform: 'capitalize', width: 'auto', height: 36, margin: '0' }}
                            onClick={() => { uploadVideo(fileToUpload) }}
                        >
                            <CheckRoundedIcon /> Save
                        </Button>
                    </Grid>
                }

            </div>}

            {/* audio review */}

            {props.reviewMedia === "audio" && <div>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    style={{ position: 'relative', margin: "30px 0 35px", paddding: "0 10px" }}
                >
                    {/* <Grid item container xs={12} alignItems="center" justifyContent="center">
                        <Typography variant="h3" color="primary" style={{ marginTop: 20, marginBottom: 20, fontWeight: 600 }} align="center">
                            Audio Review
                        </Typography>
                    </Grid> */}

                    <Grid item container xs={12} alignItems="center"
                        style={{ background: theme.palette.primary.main, boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.14)", padding: "30px 5px 30px", borderRadius: 4 }}>
                        <IconButton
                            id="audioControlBtn"
                            style={{ width: "100%", padding: 0 }}
                            onClick={audioStatus ? pauseAudio : startAudio}
                            classes={{ label: classes.iconBtnLabel }}>
                            {audioStatus
                                ? <PauseCircleFilledRoundedIcon fontSize="large" color="secondary" className={classes.audioPlayIcon} />
                                : <PlayCircleFilledWhiteRoundedIcon fontSize="large" color="secondary" className={classes.audioPlayIcon} />}
                            <Typography variant="subtitle1" style={{ color: theme.palette.primary.contrastText, paddingLeft: 5 }}>{audioUrl ? "अपना audio review सुनिये" : "Demo review सुनिये"} </Typography>
                        </IconButton>
                    </Grid>

                    <audio
                        ref={myRef}
                        src={audioUrl}
                        onEnded={handleAudioEnded}
                    />

                    {!isAudioApprovalOpen && audioUrl &&
                        <IconButton
                            className={classes.mediaDeleteBtn}
                            style={{ bottom: "-7%" }}
                            id="reviewAudioDeleteBtn_id"
                            onClick={() => {
                                setSelectedMedia("audio");
                                handleConfDialogOpen();
                            }}
                        >
                            <DeleteForeverRoundedIcon style={{ color: theme.palette.common.redDark }} />
                        </IconButton>
                    }

                </Grid>

                {!isAudioApprovalOpen &&
                    <Grid item container
                        justifyContent="center"
                    >
                        {/* <Grid item container xs={6} justifyContent="center">
                        <Button
                            size="medium"
                            className={classes.button_pri}
                            style={{ textTransform: 'none', width: 'auto', height: 36, margin: '0 10px' }}
                        >
                            <MicRoundedIcon />
                            <input
                                id="audioRecordBtn_id"
                                type="file"
                                accept="audio/*"
                                capture="user"
                                hidden
                                onChange={onSelectAudioFile} />
                            <label htmlFor="audioRecordBtn_id">
                                Record करें
                            </label>
                        </Button>
                    </Grid> */}
                        <Grid item container xs={6} justifyContent="center">
                            <Button
                                size="medium"
                                className={showChangeAudioBtn ? classes.button_sec : classes.button_pri}
                                style={{ textTransform: 'none', width: 'auto', height: 36, margin: '0 10px' }}
                            >
                                {!showChangeAudioBtn &&
                                    <PublishRoundedIcon />
                                }

                                <input
                                    id="audioUploadBtn_id"
                                    type="file"
                                    accept="audio/*"
                                    hidden
                                    onChange={onSelectAudioFile} />
                                <label htmlFor="audioUploadBtn_id">
                                    {showChangeAudioBtn ? "Audio change करें" : "Upload करें"}
                                </label>
                            </Button>
                        </Grid>

                    </Grid>
                }

                {isAudioApprovalOpen &&
                    <Grid item container
                        justifyContent="space-around"
                    >
                        <Button
                            size="medium"
                            style={{ backgroundColor: theme.palette.common.red, color: theme.palette.primary.contrastText, border: '1px solid', borderColor: theme.palette.common.red, textTransform: 'capitalize', width: 'auto', height: 36, margin: '0' }}
                            onClick={donotuploadAudio}
                        >
                            <CloseRoundedIcon /> Cancel
                        </Button>
                        <Button
                            size="medium"
                            style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, border: '1px solid', borderColor: theme.palette.primary.dark, textTransform: 'capitalize', width: 'auto', height: 36, margin: '0' }}
                            onClick={() => { uploadAudio(audioFileToUpload) }}
                        >
                            <CheckRoundedIcon /> Save
                        </Button>
                    </Grid>
                }

            </div>}

            {/* Confirmation Dialog box */}
            <Dialog onClose={handleConfDialogClose} aria-labelledby="delete-media-confirmation" open={isConfDialogOpen} maxWidth={'sm'}>

                <IconButton aria-label="close" className={classes.closeButton} onClick={handleConfDialogClose}>
                    <CloseRoundedIcon />
                </IconButton>

                <DialogContent dividers style={{ paddingTop: 50 }}>
                    <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5', textAlign: 'center' }}>
                        Are you sure you want to delete your {selectedMedia === "video" ? "video" : "audio"} review?
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        color="primary"
                        size="medium"
                        className={`${classes.button} ${classes.button_sec}`}
                        onClick={selectedMedia === "video" ? deleteVideo : deleteAudio}
                    >
                        Yes
                    </Button>

                    <Button autoFocus
                        color="primary"
                        size="medium"
                        className={`${classes.button} ${classes.button_pri}`}
                        onClick={handleConfDialogClose}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default MediaUploader;
