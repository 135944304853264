import React, { useState, useEffect, useRef } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import SvgIcon from '@material-ui/core/SvgIcon';

import SwipeableViews from 'react-swipeable-views';

import '../../style/App.css';
import ListLoader from '../errorsAndLoaders/ListLoader';
import ApiHandler from '../../ApiHandler';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      className={`${classes.tabPanel} ${value === index ? "active" : ""} `}
      id={`scrollable-force-tab-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box p={3}>
        <div className="outermost">{children}</div>
        // </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 74,
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  tabBar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.14)',
  },
  navTabBar: {
    flexGrow: 1,
  },
  navTab: {
    textTransform: 'capitalize',
    padding: 0,
    margin: ' 0 15px',
    minHeight: 50,
    minWidth: 100,
    color: theme.palette.common.grey,
    ...theme.typography.h3,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      minHeight: 50,
      minWidth: 'auto',
    },
  },
  appBar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.16)',
    top: 'auto',
    bottom: 0,
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em'
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 20,
  },
  pageButton_pri: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  button: {
    textTransform: "none",
    width: 140,
  },
  button_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  button_sec: {
    backgroundColor: '#fff',
    color: theme.palette.primary.dark,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  button_ter: {
    backgroundColor: '#fff',
    color: theme.palette.common.blackLight,
    borderRadius: 4,
    boxShadow: '0px 0px 5px rgba(0,0,0,0.05)',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  section: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: '10px 0',
    '@media (min-width: 600px)': {
      margin: 'auto',
      padding: 30,
      minHeight: '70vh',
      width: '60%',
      marginTop: '39px !important'
    }
  },
  sqDiv: {
    '@media (min-width: 600px)': {
      backgroundColor: '#f5f5f5 !important'
    }
  },
  sectionItem: {
    padding: '20px 0px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[100],
    '&:last-child': {
      border: 'none',
    }
  },
  sectionItem_heading: {
    flexWrap: 'nowrap',
    marginBottom: '30px'
  },
  sectionItem_heading_icon: {
    marginRight: 5,
  },
  sectionItem_heading_icon2: {
    marginRight: 10,
    // color: theme.palette.common.grey,
    color: theme.palette.primary.main,
  },
  sectionItem_content: {
    flexWrap: 'wrap',
  },
  sectionItem_rating: {
    flexWrap: 'wrap',
    margin: '10px 0',
    '&:last-child': {
      marginBottom: 0,
    }
  },
  sectionItem_text_button: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  tileRatingText: {
    ...theme.typography.tileRatingText,
  },
  tileRatingStar: {
    fontSize: '1.2rem',
    color: theme.palette.common.star,
    marginRight: 4,
  },
  imageTag: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: "none",
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 140,
    height: 140,
    overflow: 'hidden',
    margin: 0,
    borderRadius: 6,
    //border: '1px solid #E5E5E5',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.14)',
  },
  img: {
    margin: '0 auto',
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: 'unset',
    //position: 'absolute',
  },

  TfRoot: {
    width: '100%',
    marginBottom: 30,
    background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  TfRootWithoutMB: {
    width: '100%',
    marginBottom: 0,
    background: theme.palette.common.greyLight,
    '& fieldset': {
      border: 'transparent',
    },
  },
  inputRootAc: {
    padding: '0 9px!important',
  },
  tfLabel: {
    ...theme.typography.h3,
    fontWeight: 600,
    color: theme.palette.common.blackDark,
    marginBottom: 12,
  },
  datePickerInput: {
    '& .MuiInputBase-input': {
      height: '3.429em',
      width: '100%',
      borderRadius: 4,
      background: theme.palette.common.greyLight,
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  selectRoot: {
    width: '100%',
    marginBottom: 30,
    background: theme.palette.common.greyLight,
    borderRadius: 25,
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 15px',
    },
    '& .MuiSelect-select:focus': {
      borderRadius: 25,
    },
    '& input': {
      height: 48,
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  fabBtnWrapper: {
    position: 'fixed',
    right: 0,
    width: 'auto',
    bottom: 70,
    paddingRight: 23,
  },
  fabDisabled: {
    minWidth: 70,
    height: 32,
    textTransform: 'capitalize',
    background: "white",
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
  },
  fabActive: {
    minWidth: 70,
    height: 32,
    textTransform: 'capitalize',
    background: theme.palette.primary.dark,
    color: "white",
  },
  sizeLarge: {
    fontSize: '2.5rem'
  },
  chip: {
    fontSize: '0.815rem',
    fontWeight: 500,
    width: 'auto',
    padding: '2px 8px',
    margin: '6px 10px 0 0',
    borderRadius: 4,
    border: '1px solid',
    backgroundColor: '#fff',
    color: theme.palette.common.grey,
    borderColor: theme.palette.common.grey,
    '&.active': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    }
  },
  imgDeleteBtn: {
    width: 30,
    height: 30,
    background: '#FFFFFF',
    // boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.14)',
    borderRadius: '50%',
    marginLeft: 'auto',
  },
  radioLabel: {
    fontWeight: 500
  },
  radioLabelHindi: {
    fontWeight: 600
  },
  tabPanel: {
    marginTop: 100,
    transition: 'all 0.25s ease-in-out',
    transform: 'translate3d(100%, 0, 0)',
    '&.active': {
      transform: 'translate3d(0, 0, 0)!important',
    },
  }
}));

export default function AddRatingsReviews(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [isLoading, setIsLoading] = React.useState(false);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);
  const [primaryTabValue, setPrimaryTabValue] = React.useState(0);
  const [swipeDir, setSwipeDir] = React.useState('rtl');
  const [isSwipeDisabled, setIsSwipeDisabled] = React.useState(true);
  const [isNextDisabled, setIsNextDisabled] = React.useState(false);
  const [locationAgree, setLocationAgree] = React.useState(props.locationAgree === true || props.locationAgree === "yes" ? "yes" : props.locationAgree === false || props.locationAgree === "no" ? "no" : null);
  const [validLicense, setValidLicense] = React.useState(props.validLicense === true || props.validLicense === "yes" ? "yes" : props.validLicense === false || props.validLicense === "no" ? "no" : null);
  const [ownVehicle, setOwnVehicle] = React.useState(props.ownVehicle === true || props.ownVehicle === "yes" ? "yes" : props.ownVehicle === false || props.ownVehicle === "no" ? "no" : null);
  const [ownLaptop, setOwnLaptop] = React.useState(props.ownLaptop === true || props.ownLaptop === "yes" ? "yes" : props.ownLaptop === false || props.ownLaptop === "no" ? "no" : null);
  const [whyGoodFit, setWhyGoodFit] = React.useState(props.whyGoodFit ? props.whyGoodFit : "");
  const [salaryAgree, setSalaryAgree] = React.useState(props.salaryAgree === true || props.salaryAgree === "yes" ? "yes" : props.salaryAgree === false || props.salaryAgree === "no" ? "no" : null);
  const [salaryExpectation, setSalaryExpectation] = React.useState(props.salaryExpectation ? props.salaryExpectation : "");
  const [changeJobReason, setChangeJobReason] = React.useState(props.changeJobReason ? props.changeJobReason : "");

  const [customQ1, setCustomQ1] = React.useState(props.customQuestions && props.customQuestions[0] ? props.customQuestions[0] : "");
  const [customQ2, setCustomQ2] = React.useState(props.customQuestions && props.customQuestions[1] ? props.customQuestions[1] : "");
  const [customQ3, setCustomQ3] = React.useState(props.customQuestions && props.customQuestions[2] ? props.customQuestions[2] : "");

  const [customAns1, setCustomAns1] = React.useState(props.customAnswers && props.customAnswers[0] ? props.customAnswers[0] : "");
  const [customAns2, setCustomAns2] = React.useState(props.customAnswers && props.customAnswers[1] ? props.customAnswers[1] : "");
  const [customAns3, setCustomAns3] = React.useState(props.customAnswers && props.customAnswers[2] ? props.customAnswers[2] : "");

  //vars for profile applied jobs page
  const [profilePgIsCheckedObj, setProfilePgIsCheckedObj] = React.useState({});
  const [countOfQuestions, setCountOfQuestions] = React.useState();
  const [customQuestions, setCustomQuestions] = React.useState(null);

  // let tabsLength = props.isDefault ? props.countOfQuestions : 7;
  let tabsLength = props.countOfQuestions ? props.countOfQuestions : 0;
  let tabIndices = {
    start: 0,
    locationAgree: props.tabIndices && props.tabIndices.location_agree ? props.tabIndices.location_agree : null,
    validLicense: props.tabIndices && props.tabIndices.valid_dl_license ? props.tabIndices.valid_dl_license : null,
    ownVehicle: props.tabIndices && props.tabIndices.own_vehicle ? props.tabIndices.own_vehicle : null,
    ownLaptop: props.tabIndices && props.tabIndices.own_laptop ? props.tabIndices.own_laptop : null,
    whyGoodFit: props.tabIndices && props.tabIndices.why_good_fit ? props.tabIndices.why_good_fit : null,
    salaryAgree: props.tabIndices && props.tabIndices.salary_agree ? props.tabIndices.salary_agree : null,
    salaryExpectation: props.tabIndices && props.tabIndices.salary_expectation ? props.tabIndices.salary_expectation : null,
    changeJobReason: props.tabIndices && props.tabIndices.change_job_reason ? props.tabIndices.change_job_reason : null,
    custom1: props.tabIndices && props.tabIndices.custom1 ? props.tabIndices.custom1 : null,
    custom2: props.tabIndices && props.tabIndices.custom2 ? props.tabIndices.custom3 : null,
    custom3: props.tabIndices && props.tabIndices.custom3 ? props.tabIndices.custom3 : null,
  }

  let isCheckedObj = { // obj that saves which questions have been asked by the employer
    location_agree: props.isChecked && props.isChecked.location_agree ? props.isChecked.location_agree : false,
    valid_dl_license: props.isChecked && props.isChecked.valid_dl_license ? props.isChecked.valid_dl_license : false,
    own_vehicle: props.isChecked && props.isChecked.own_vehicle ? props.isChecked.own_vehicle : false,
    own_laptop: props.isChecked && props.isChecked.own_laptop ? props.isChecked.own_laptop : false,
    why_good_fit: props.isChecked && props.isChecked.why_good_fit ? props.isChecked.why_good_fit : false,
    salary_agree: props.isChecked && props.isChecked.salary_agree ? props.isChecked.salary_agree : false,
    salary_expectation: props.isChecked && props.isChecked.salary_expectation ? props.isChecked.salary_expectation : false,
    change_job_reason: props.isChecked && props.isChecked.change_job_reason ? props.isChecked.change_job_reason : false,
    custom1: props.isChecked && props.isChecked.custom1 ? props.isChecked.custom1 : false,
    custom2: props.isChecked && props.isChecked.custom2 ? props.isChecked.custom2 : false,
    custom3: props.isChecked && props.isChecked.custom3 ? props.isChecked.custom3 : false,
  }

  // console.log(tabsLength)
  // console.log(tabIndices)
  // console.log(isCheckedObj)

  let mandatoryTabIndices = [tabIndices.locationAgree]

  const SkipBtn = (btnText) => {
    return (
      <Button
        className={isNextDisabled ? classes.button_ter : classes.pageButton_pri}
        disabled={isNextDisabled}
        onClick={() => {
          setTimeout(() => { setPrimaryTabValue(prev => prev + 1); }, 150) //timeout added to fix the direction of first transition after direction change
          if (swipeDir === "ltr") {
            setSwipeDir("rtl");
            const nodeList = document.querySelectorAll('div[role="tabpanel"]')
            for (let i = 0; i < nodeList.length; i++) {
              nodeList[i].style.transform = "translate3d(100%, 0, 0)"
            }
          }

          if (btnText !== "start") postScreeningFormResponse(); // skip(okay) button will also call api and submit the form
        }}
        style={{ fontSize: '0.875rem', textTransform: 'capitalize', fontWeight: 400, marginTop: 30 }}>
        {btnText} {btnText === "start" && <NavigateNextRoundedIcon fontSize="small" />}
      </Button>
    )
  }

  const googleFormBtn = () => {
    return (
      <a href={props.googleFormURL} target="self" style={{ textDecoration: 'none' }}>
        <Button
          className={isNextDisabled ? classes.button_ter : classes.pageButton_pri}
          disabled={isNextDisabled}
          onClick={() => props.setIsScreeningFormOpen(false)} //to close the modal as the user navigates to google form site 
          style={{ fontSize: '0.875rem', textTransform: 'capitalize', fontWeight: 400, marginTop: 30 }}>
          Fill Screening Form
        </Button>
      </a>

    )
  }

  const SubmitBtn = (btnText) => {
    return (
      <Button
        className={btnText === "complete and submit" ? classes.pageButton_pri : classes.button_sec}
        disabled={isNextDisabled}
        onClick={() => {
          postScreeningFormResponse('submitAll');
        }}
        style={{ fontSize: '0.875rem', textTransform: 'capitalize', fontWeight: 400, marginTop: 30 }}>
        {btnText}
      </Button>
    )
  }

  const postScreeningFormResponse = (btnType) => {
    let jobseeker_id = localStorage.getItem("jobseeker_id");
    let _custom_questions = [
      { question: customQ1, answer: customAns1 },
      { question: customQ2, answer: customAns2 },
      { question: customQ3, answer: customAns3 }
    ]
    let data = {
      jobseeker_id,
      jobpost_id: props.jobID,
      salary_expectation: salaryExpectation ? salaryExpectation : null,
      salary_agree: salaryAgree === "yes" ? true : salaryAgree === "no" ? false : null,
      location_agree: locationAgree === "yes" ? true : locationAgree === "no" ? false : null,
      valid_dl_license: validLicense === "yes" ? true : validLicense === "no" ? false : null,
      own_vehicle: ownVehicle === "yes" ? true : ownVehicle === "no" ? false : null,
      own_laptop: ownLaptop === "yes" ? true : ownLaptop === "no" ? false : null,
      why_good_fit: whyGoodFit ? whyGoodFit : null,
      change_job_reason: changeJobReason ? changeJobReason : null,
      custom_questions: _custom_questions,
      is_submitted: true // sent true so that the FE can later show the btn state as Edit Screening Form
      // job_application_cancelled : false,
    }
    ApiHandler.postScreeningForm(data, (res) => {
      if (res.status === 200) {
        if (btnType === 'submitAll') {
          if (props.setRefreshScreening) props.setRefreshScreening(prev => !prev) //refresh the screening form parent state vars when form is submitted successfully
          props.setIsScreeningFormOpen(false);
        }

      } else if (res.response) {
        //console.log(res);
        props.handleSnackBar("Something went wrong. Please try again.")
      } else {
        props.handleSnackBar("Something went wrong. Please try again.")
      }
    })
  }

  const getScreeningFormResponse = () => {
    let jobseeker_id = localStorage.getItem("jobseeker_id");
    let jobpost_id = props.jobID;
    let _countOfQuestions = 0;
    let _isCheckedObj = { // obj that saves which questions have been asked by the employer
      location_agree: false,
      valid_dl_license: false,
      own_vehicle: false,
      own_laptop: false,
      why_good_fit: false,
      salary_agree: false,
      salary_expectation: false,
      change_job_reason: false,
      custom1: false,
      custom2: false,
      custom3: false,
    }

    ApiHandler.getScreeningFormResponse(jobseeker_id, jobpost_id, (res) => {
      if (res.status === 200) {
        //console.log(res);
        if (res.data && res.data[0]) {
          let data = res.data[0]
          setLocationAgree(data.location_agree.answer === true || data.location_agree.answer === "yes" ? "yes" : data.location_agree.answer === false || data.location_agree.answer === "no" ? "no" : null);
          setValidLicense(data.valid_dl_license.answer === true || data.valid_dl_license.answer === "yes" ? "yes" : data.valid_dl_license.answer === false || data.valid_dl_license.answer === "no" ? "no" : null);
          setOwnVehicle(data.own_vehicle.answer === true || data.own_vehicle.answer === "yes" ? "yes" : data.own_vehicle.answer === false || data.own_vehicle.answer === "no" ? "no" : null);
          setOwnLaptop(data.own_laptop.answer === true || data.own_laptop.answer === "yes" ? "yes" : data.own_laptop.answer === false || data.own_laptop.answer === "no" ? "no" : null);
          setWhyGoodFit(data.why_good_fit.answer ? data.why_good_fit.answer : "");
          setSalaryAgree(data.salary_agree.answer === true || data.salary_agree.answer === "yes" ? "yes" : data.salary_agree.answer === false || data.salary_agree.answer === "no" ? "no" : null);
          setSalaryExpectation(data.salary_expectation.answer ? data.salary_expectation.answer : "");
          setChangeJobReason(data.change_job_reason.answer ? data.change_job_reason.answer : "");
          props.setIsScreeningFormSubmitted(data.is_submitted);
          if (data.is_submitted !== null) { props.isShowOnePageRatings(true) } //is_submitted is null until the form is saved/submitted. it becomes true when saved/submitted.

          if (data.location_agree.isChecked) { //if the question is checked then add it to the total count of questions and turn the isChecked var to true
            _countOfQuestions++;
            _isCheckedObj.location_agree = true;
            tabIndices.locationAgree = _countOfQuestions;
          }
          if (data.salary_agree.isChecked) {
            _countOfQuestions++;
            _isCheckedObj.salary_agree = true;
            tabIndices.salaryAgree = _countOfQuestions;
          }
          if (data.why_good_fit.isChecked) {
            _countOfQuestions++;
            _isCheckedObj.why_good_fit = true;
            tabIndices.whyGoodFit = _countOfQuestions;
          }
          if (data.salary_expectation.isChecked) {
            _countOfQuestions++;
            _isCheckedObj.salary_expectation = true;
            tabIndices.salaryExpectation = _countOfQuestions;
          }
          if (data.change_job_reason.isChecked) {
            _countOfQuestions++;
            _isCheckedObj.change_job_reason = true;
            tabIndices.changeJobReason = _countOfQuestions;
          }
          if (data.own_laptop.isChecked) {
            _countOfQuestions++;
            _isCheckedObj.own_laptop = true;
            tabIndices.ownLaptop = _countOfQuestions;
          }
          if (data.own_vehicle.isChecked) {
            _countOfQuestions++;
            _isCheckedObj.own_vehicle = true;
            tabIndices.ownVehicle = _countOfQuestions;
          }
          if (data.valid_dl_license.isChecked) {
            _countOfQuestions++;
            _isCheckedObj.valid_dl_license = true;
            tabIndices.validLicense = _countOfQuestions;
          }

          if (data.custom_questions && data.custom_questions[0]) {
            _countOfQuestions++;
            if (data.custom_questions[0].isChecked === true) _isCheckedObj.custom1 = true;
            setCustomQ1(data.custom_questions[0].question)
            setCustomAns1(data.custom_questions[0].answer)
            tabIndices.custom1 = _countOfQuestions;
          }

          if (data.custom_questions && data.custom_questions[1]) {
            _countOfQuestions++;
            if (data.custom_questions[1].isChecked === true) _isCheckedObj.custom2 = true;
            setCustomQ2(data.custom_questions[1].question)
            setCustomAns2(data.custom_questions[1].answer)
            tabIndices.custom2 = _countOfQuestions;
          }

          if (data.custom_questions && data.custom_questions[2]) {
            _countOfQuestions++;
            if (data.custom_questions[2].isChecked === true) _isCheckedObj.custom3 = true;
            setCustomQ3(data.custom_questions[2].question)
            setCustomAns3(data.custom_questions[2].answer)
            tabIndices.custom3 = _countOfQuestions;
          }

          setProfilePgIsCheckedObj(_isCheckedObj)
          setCountOfQuestions(_countOfQuestions)

        }

      } else if (res.response) {
        console.log(res);
      } else {
        console.log("Something went wrong. Please try again.")
      }
    })
  }

  React.useEffect(() => {
    if (props.isApiCalledOnLoad) getScreeningFormResponse()
  }, [])

  // React.useEffect(() => {
  //   // let key = Object.keys(tabIndices)[primaryTabValue];

  //   // function convertToVariable(str) {
  //   //   eval(str);
  //   // }

  //   // if (key !== 'start') {
  //   //   //convertToVariable(key);
  //   //   alert(window[key])
  //   //   alert(locationAgree)
  //   // }


  //   // if (mandatoryTabIndices.includes(primaryTabValue) && window[key] == null) {
  //   //   setIsNextDisabled(true);
  //   //   // setIsSwipeDisabled(true);
  //   // }
  //   // else {
  //   //   setIsNextDisabled(false);
  //   //   // setIsSwipeDisabled(false);
  //   // }


  //   //commenting the below code to disable the mandatory question feature

  //   if (primaryTabValue === 1 && locationAgree == null) { //if location not provided
  //     setIsNextDisabled(true);
  //     // setIsSwipeDisabled(true);
  //   }
  //   else {
  //     setIsNextDisabled(false);
  //     // setIsSwipeDisabled(false);
  //   }
  // }, [primaryTabValue])// onclick of next, back btn and on swipe, disable the navigation for mandatory tabs

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  return (
    <div 
    className={classes.sqDiv}
    style={{
      display: props.showOnePageRatings ? undefined : 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      left: 0,
      width: '100%',
      padding: '10px 15px',
      zIndex: 1202,
      background: 'white',
      top: 0,
      height: 'calc(100vh - 48px)',
      overflow: 'scroll'
    }}>
      <React.Fragment>
        <AppBar position="fixed" className={classes.tabBar} style={{ backgroundColor: "none", boxShadow: "none" }}>
          <Toolbar variant="dense" style={{ width: '100%' }} >
            <IconButton edge="start" className={classes.menuButton} aria-label="back button"
              onClick={() => {
                if (props.setRefreshScreening) props.setRefreshScreening(prev => !prev) //refresh the screening form parent state vars when back button is pressed
                props.setIsScreeningFormOpen(false);
              }}>
              <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
            </IconButton>
            <Typography style={{ color: theme.palette.text.primary }} variant='h3'>Screening Questions</Typography>
            {/* <Typography variant="h4" className={classes.navTabBar} style={{ color: theme.palette.common.blackDark }}>
              Employer को अपने बारें में थोड़ा और बताएं!
            </Typography> */}
          </Toolbar>
        </AppBar>

        {isLoading
          ? <div style={{
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
          }}>
            <ListLoader setListLoaderRef={setListLoaderRef} />
          </div>
          :
          <React.Fragment>
            {!props.showOnePageRatings ?

              // <SwipeableViews
              //   resistance
              //   threshold={8}
              //   axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              //   index={primaryTabValue}
              //   onChangeIndex={handleChangeIndex}
              //   disabled={isSwipeDisabled}
              //   style={{ width: '100%' }}
              // >

              <>
                {/* sequence of the tabpanels need NOT be same as their index number for the one by one to function properly*/}
                {/****** start ******/}
                <TabPanel value={primaryTabValue} index={tabIndices.start}>
                  {props.googleFormURL ?
                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h2" style={{ lineHeight: '1.5', marginBottom: '0.5em' }}>
                        You've applied successfully!
                      </Typography>
                      <Typography className={classes.tfLabel}>
                        Now take the next steps to move ahead in the application process.
                      </Typography>
                      <Typography className={classes.tfLabel}>
                        Employer को अपने बारें में थोड़ा और बताएं 👉
                      </Typography>
                      {/* if the gogle form field exists, then show the btn that would navigate to google form site else show the btn that would start the in-built screening form */}
                      {googleFormBtn()}
                    </Grid>
                    : <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h2" style={{ lineHeight: '1.5', marginBottom: '0.5em' }}>
                        You've applied successfully!
                      </Typography>
                      <Typography className={classes.tfLabel}>
                        Now take the next steps to move ahead in the application process.
                      </Typography>
                      <Typography className={classes.tfLabel}>
                        Employer को अपने बारें में थोड़ा और बताएं 👉
                      </Typography>
                      {/* if the gogle form field exists, then show the btn that would navigate to google form site else show the btn that would start the in-built screening form */}
                      {SkipBtn("start")}
                    </Grid>
                  }
                </TabPanel>
                {/****** locationAgree ******/}
                {isCheckedObj.location_agree &&
                  <TabPanel value={primaryTabValue} index={tabIndices.locationAgree}>
                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                        क्या आप दी गई location पर काम करने के लिए ready हैं?
                        {/* <span style={{ color: theme.palette.common.red }}>*</span> */}
                      </Typography>
                      <Grid item xs={12}>
                        <FormControl id="locationAgree_id" component="fieldset">
                          <RadioGroup aria-label="agree with location" name="locationAgree" value={locationAgree} >
                            <div>
                              <FormControlLabel
                                value="yes"
                                onClick={() => {
                                  setLocationAgree("yes");
                                  setIsNextDisabled(false);
                                  // props.changeIsReviewPublic(false, props.index)
                                }}
                                control={<Radio color="primary" />} label="Yes" classes={{ label: classes.radioLabelHindi }} />
                            </div>
                            <div>
                              <FormControlLabel
                                value="no"
                                onClick={() => {
                                  setLocationAgree("no");
                                  setIsNextDisabled(false);
                                  // props.changeIsReviewPublic(true, props.index)
                                }}
                                control={<Radio color="primary" />}
                                label="No" classes={{ label: classes.radioLabel }} />

                            </div>

                          </RadioGroup>

                        </FormControl>
                      </Grid>
                      {primaryTabValue === tabsLength ? SubmitBtn("complete and submit") : SkipBtn("okay")}
                    </Grid>
                  </TabPanel>
                }
                {/****** validLicense ******/}
                {isCheckedObj.valid_dl_license &&
                  <TabPanel value={primaryTabValue} index={tabIndices.validLicense}>
                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                        क्या आप के पास valid driving license है?
                      </Typography>
                      <Grid item xs={12} >
                        <FormControl id="validLicense" component="fieldset">
                          <RadioGroup aria-label="valid driving license" name="validLicense" value={validLicense} >
                            <div>
                              <FormControlLabel
                                value="yes"
                                onClick={() => {
                                  setValidLicense("yes");
                                  // props.changeIsReviewPublic(false, props.index)
                                }}
                                control={<Radio color="primary" />} label="Yes" classes={{ label: classes.radioLabelHindi }} />
                            </div>
                            <div>
                              <FormControlLabel
                                value="no"
                                onClick={() => {
                                  setValidLicense("no");
                                  // props.changeIsReviewPublic(true, props.index)
                                }}
                                control={<Radio color="primary" />}
                                label="No" classes={{ label: classes.radioLabel }} />

                            </div>

                          </RadioGroup>

                        </FormControl>
                      </Grid>
                      {primaryTabValue === tabsLength ? SubmitBtn("complete and submit") : SkipBtn("okay")}
                    </Grid>
                  </TabPanel>
                }
                {/****** ownVehicle ******/}
                {isCheckedObj.own_vehicle &&
                  <TabPanel value={primaryTabValue} index={tabIndices.ownVehicle}>
                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                        क्या आपके पास अपनी bike/scooty/car है?
                      </Typography>
                      <Grid item xs={12} >
                        <FormControl id="ownVehicle" component="fieldset">
                          <RadioGroup aria-label="own Vehicle" name="ownVehicle" value={ownVehicle} >
                            <div>
                              <FormControlLabel
                                value="yes"
                                onClick={() => {
                                  setOwnVehicle("yes");
                                  // props.changeIsReviewPublic(false, props.index)
                                }}
                                control={<Radio color="primary" />} label="Yes" classes={{ label: classes.radioLabelHindi }} />
                            </div>
                            <div>
                              <FormControlLabel
                                value="no"
                                onClick={() => {
                                  setOwnVehicle("no");
                                  // props.changeIsReviewPublic(true, props.index)
                                }}
                                control={<Radio color="primary" />}
                                label="No" classes={{ label: classes.radioLabel }} />

                            </div>

                          </RadioGroup>

                        </FormControl>
                      </Grid>
                      {primaryTabValue === tabsLength ? SubmitBtn("complete and submit") : SkipBtn("okay")}
                    </Grid>
                  </TabPanel>
                }
                {/****** ownLaptop ******/}
                {isCheckedObj.own_laptop &&
                  <TabPanel value={primaryTabValue} index={tabIndices.ownLaptop}>
                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                        क्या आपके पास personal laptop है?
                      </Typography>
                      <Grid item xs={12} >
                        <FormControl id="ownLaptop" component="fieldset">
                          <RadioGroup aria-label="own Vehicle" name="ownLaptop" value={ownLaptop} >
                            <div>
                              <FormControlLabel
                                value="yes"
                                onClick={() => {
                                  setOwnLaptop("yes");
                                }}
                                control={<Radio color="primary" />} label="Yes" classes={{ label: classes.radioLabelHindi }} />
                            </div>
                            <div>
                              <FormControlLabel
                                value="no"
                                onClick={() => {
                                  setOwnLaptop("no");
                                }}
                                control={<Radio color="primary" />}
                                label="No" classes={{ label: classes.radioLabel }} />
                            </div>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {primaryTabValue === tabsLength ? SubmitBtn("complete and submit") : SkipBtn("okay")}
                    </Grid>
                  </TabPanel>
                }
                {/****** whyGoodFit ******/}
                {isCheckedObj.why_good_fit &&
                  <TabPanel value={primaryTabValue} index={tabIndices.whyGoodFit}>
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 600, lineHeight: '1.5' }}>आपका इस job role में क्यों selection होना चाहिए?
                          Detail में बताएं कि आपकी education and/or experience इस job में समझाए गए काम में आपको कैसे मदद करेंगे।</FormLabel>
                        <TextField
                          required
                          id="whyGoodFit"
                          inputProps={{ maxLength: 250 }}
                          multiline
                          rows={4}
                          value={whyGoodFit}
                          onChange={(event) => { setWhyGoodFit(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                        />
                      </FormControl>
                    </Grid>
                    {primaryTabValue === tabsLength ? SubmitBtn("complete and submit") : SkipBtn("okay")}
                  </TabPanel>
                }
                {/****** setSalaryAgree ******/}
                {isCheckedObj.salary_agree &&
                  <TabPanel value={primaryTabValue} index={tabIndices.salaryAgree}>
                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                        क्या आप mentioned salary में काम करने को ready हैं?
                      </Typography>
                      <Grid item xs={12}>
                        <FormControl id="salaryAgree" component="fieldset">
                          <RadioGroup aria-label="agree with salary" name="salaryAgree" value={salaryAgree} >
                            <div>
                              <FormControlLabel
                                value="yes"
                                onClick={() => {
                                  setSalaryAgree("yes");
                                }}
                                control={<Radio color="primary" />} label="Yes" classes={{ label: classes.radioLabelHindi }} />
                            </div>
                            <div>
                              <FormControlLabel
                                value="no"
                                onClick={() => {
                                  setSalaryAgree("no");
                                }}
                                control={<Radio color="primary" />}
                                label="No" classes={{ label: classes.radioLabel }} />
                            </div>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {primaryTabValue === tabsLength ? SubmitBtn("complete and submit") : SkipBtn("okay")}
                    </Grid>
                  </TabPanel>
                }

                {/****** salaryExpectation ******/}
                {isCheckedObj.salary_expectation &&
                  <TabPanel value={primaryTabValue} index={tabIndices.salaryExpectation}>
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 600 }}>इस role के लिए आपकी salary expectation क्या है ?</FormLabel>
                        <TextField
                          required
                          id="salaryExpectation"
                          inputProps={{ maxLength: 100 }}
                          rows={1}
                          value={salaryExpectation}
                          onChange={(event) => { setSalaryExpectation(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                        />
                      </FormControl>
                      {/* <Grid item container xs={12} justifyContent="space-between"> */}
                      {primaryTabValue === tabsLength ? SubmitBtn("complete and submit") : SkipBtn("okay")}
                      {/* {SubmitBtn("save")} */}
                      {/* </Grid> */}

                    </Grid>
                  </TabPanel>
                }

                {/****** changeJobReason ******/}
                {isCheckedObj.change_job_reason &&
                  <TabPanel value={primaryTabValue} index={tabIndices.changeJobReason}>
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 600 }}>आप अपना current job क्यों change करना चाहते हैं?</FormLabel>
                        <TextField
                          required
                          id="changeJobReason"
                          inputProps={{ maxLength: 250 }}
                          multiline
                          rows={4}
                          value={changeJobReason}
                          onChange={(event) => { setChangeJobReason(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                        />
                      </FormControl>
                      {/* <Grid item container xs={12} justifyContent="space-between"> */}
                      {primaryTabValue === tabsLength ? SubmitBtn("complete and submit") : SkipBtn("okay")}
                      {/* {SubmitBtn("save")} */}
                      {/* </Grid> */}

                    </Grid>
                  </TabPanel>
                }

                {/******custom1 ******/}
                {isCheckedObj.custom1 &&
                  <TabPanel value={primaryTabValue} index={tabIndices.custom1}>
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500, lineHeight: '1.5' }}>{customQ1}</FormLabel>
                        <TextField
                          required
                          id="custom1"
                          inputProps={{ maxLength: 250 }}
                          multiline
                          rows={4}
                          value={customAns1}
                          onChange={(event) => { setCustomAns1(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                        />
                      </FormControl>
                    </Grid>
                    {primaryTabValue === tabsLength ? SubmitBtn("complete and submit") : SkipBtn("okay")}
                  </TabPanel>
                }

                {/******custom2 ******/}
                {isCheckedObj.custom2 &&
                  <TabPanel value={primaryTabValue} index={tabIndices.custom2}>
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500, lineHeight: '1.5' }}>{customQ2}</FormLabel>
                        <TextField
                          required
                          id="custom2"
                          inputProps={{ maxLength: 250 }}
                          multiline
                          rows={4}
                          value={customAns2}
                          onChange={(event) => { setCustomAns2(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                        />
                      </FormControl>
                    </Grid>
                    {primaryTabValue === tabsLength ? SubmitBtn("complete and submit") : SkipBtn("okay")}
                  </TabPanel>
                }

                {/******custom3 ******/}
                {isCheckedObj.custom3 &&
                  <TabPanel value={primaryTabValue} index={tabIndices.custom3}>
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500, lineHeight: '1.5' }}>{customQ3}</FormLabel>
                        <TextField
                          required
                          id="custom3"
                          inputProps={{ maxLength: 250 }}
                          multiline
                          rows={4}
                          value={customAns3}
                          onChange={(event) => { setCustomAns3(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                        />
                      </FormControl>
                    </Grid>
                    {primaryTabValue === tabsLength ? SubmitBtn("complete and submit") : SkipBtn("okay")}
                  </TabPanel>
                }
              </>
              // </SwipeableViews>
              :
              <React.Fragment>
                <Grid
                  container
                  className={classes.section}
                  direction="column" style={{ marginTop: 10, paddingTop: 50 }}>

                  {/****** locationAgree ******/}
                  {(isCheckedObj.location_agree || profilePgIsCheckedObj.location_agree) &&
                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                        क्या आप दी गई location पर काम करने के लिए ready हैं?
                        {/* <span style={{ color: theme.palette.common.red }}>*</span> */}
                      </Typography>
                      <Grid item xs={12}>
                        <FormControl id="locationAgree_id" component="fieldset">
                          <RadioGroup aria-label="agree with location" name="locationAgree" value={locationAgree} >
                            <div>
                              <FormControlLabel
                                value="yes"
                                onClick={() => {
                                  if (props.isJobOpen) setLocationAgree("yes");
                                  // setIsNextDisabled(false);
                                }}
                                control={<Radio color="primary" />}
                                label="Yes"
                                classes={{ label: classes.radioLabelHindi }}
                                disabled={!props.isJobOpen}
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                value="no"
                                onClick={() => {
                                  if (props.isJobOpen) setLocationAgree("no");
                                }}
                                disabled={!props.isJobOpen}
                                control={<Radio color="primary" />}
                                label="No" classes={{ label: classes.radioLabel }} />
                            </div>

                          </RadioGroup>

                        </FormControl>
                      </Grid>

                    </Grid>
                  }
                  {/****** setSalaryAgree ******/}
                  {(isCheckedObj.salary_agree || profilePgIsCheckedObj.salary_agree) &&
                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                        क्या आप mentioned salary में काम करने को ready हैं?
                      </Typography>
                      <Grid item xs={12}>
                        <FormControl id="salaryAgree" component="fieldset">
                          <RadioGroup aria-label="agree with salary" name="salaryAgree" value={salaryAgree} >
                            <div>
                              <FormControlLabel
                                value="yes"
                                onClick={() => {
                                  if (props.isJobOpen) setSalaryAgree("yes");
                                }}
                                disabled={!props.isJobOpen}
                                control={<Radio color="primary" />} label="Yes" classes={{ label: classes.radioLabelHindi }} />
                            </div>
                            <div>
                              <FormControlLabel
                                value="no"
                                onClick={() => {
                                  if (props.isJobOpen) setSalaryAgree("no");
                                }}
                                disabled={!props.isJobOpen}
                                control={<Radio color="primary" />}
                                label="No" classes={{ label: classes.radioLabel }} />
                            </div>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  }
                  {/****** whyGoodFit ******/}
                  {(isCheckedObj.why_good_fit || profilePgIsCheckedObj.why_good_fit) &&
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 600, lineHeight: '1.5' }}>आपका इस job role में क्यों selection होना चाहिए?
                          Detail में बताएं कि आपकी education and/or experience इस job में समझाए गए काम में आपको कैसे मदद करेंगे।</FormLabel>
                        <TextField
                          required
                          id="whyGoodFit"
                          inputProps={{ maxLength: 250 }}
                          multiline
                          rows={4}
                          value={whyGoodFit}
                          onChange={(event) => { setWhyGoodFit(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                          disabled={!props.isJobOpen}
                        />
                      </FormControl>
                    </Grid>
                  }
                  {/****** salaryExpectation ******/}
                  {(isCheckedObj.salary_expectation || profilePgIsCheckedObj.salary_expectation) &&
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 600 }}>इस role के लिए आपकी salary expectation क्या है ?</FormLabel>
                        <TextField
                          required
                          id="salaryExpectation"
                          inputProps={{ maxLength: 100 }}
                          rows={1}
                          value={salaryExpectation}
                          onChange={(event) => { setSalaryExpectation(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                          disabled={!props.isJobOpen}
                        />
                      </FormControl>
                    </Grid>
                  }
                  {/****** changeJobReason ******/}
                  {(isCheckedObj.change_job_reason || profilePgIsCheckedObj.change_job_reason) &&
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 600 }}>आप अपना current job क्यों change करना चाहते हैं?</FormLabel>
                        <TextField
                          required
                          id="changeJobReason"
                          inputProps={{ maxLength: 250 }}
                          multiline
                          rows={4}
                          value={changeJobReason}
                          onChange={(event) => { setChangeJobReason(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                          disabled={!props.isJobOpen}
                        />
                      </FormControl>
                    </Grid>
                  }

                  {/****** ownLaptop ******/}
                  {(isCheckedObj.own_laptop || profilePgIsCheckedObj.own_laptop) &&
                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                        क्या आपके पास personal laptop है?
                      </Typography>
                      <Grid item xs={12} >
                        <FormControl id="ownLaptop" component="fieldset">
                          <RadioGroup aria-label="own Vehicle" name="ownLaptop" value={ownLaptop} >
                            <div>
                              <FormControlLabel
                                value="yes"
                                onClick={() => {
                                  if (props.isJobOpen) setOwnLaptop("yes");
                                }}
                                disabled={!props.isJobOpen}
                                control={<Radio color="primary" />} label="Yes" classes={{ label: classes.radioLabelHindi }} />
                            </div>
                            <div>
                              <FormControlLabel
                                value="no"
                                onClick={() => {
                                  if (props.isJobOpen) setOwnLaptop("no");
                                }}
                                control={<Radio color="primary" />}
                                label="No" classes={{ label: classes.radioLabel }}
                                disabled={!props.isJobOpen}
                              />
                            </div>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  }

                  {/****** ownVehicle ******/}
                  {(isCheckedObj.own_vehicle || profilePgIsCheckedObj.own_vehicle) &&
                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                        क्या आपके पास अपनी bike/scooty/car है?
                      </Typography>
                      <Grid item xs={12} >
                        <FormControl id="ownVehicle" component="fieldset">
                          <RadioGroup aria-label="own Vehicle" name="ownVehicle" value={ownVehicle} >
                            <div>
                              <FormControlLabel
                                value="yes"
                                onClick={() => {
                                  if (props.isJobOpen) setOwnVehicle("yes");
                                }}
                                disabled={!props.isJobOpen}
                                control={<Radio color="primary" />} label="Yes" classes={{ label: classes.radioLabelHindi }} />
                            </div>
                            <div>
                              <FormControlLabel
                                value="no"
                                onClick={() => {
                                  if (props.isJobOpen) setOwnVehicle("no");
                                }}
                                disabled={!props.isJobOpen}
                                control={<Radio color="primary" />}
                                label="No" classes={{ label: classes.radioLabel }} />
                            </div>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  }

                  {/****** validLicense ******/}
                  {(isCheckedObj.valid_dl_license || profilePgIsCheckedObj.valid_dl_license) &&
                    <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
                      <Typography variant="h3" className={classes.tfLabel} style={{ marginBottom: 6 }}>
                        क्या आप के पास valid driving license है?
                      </Typography>
                      <Grid item xs={12} >
                        <FormControl id="validLicense" component="fieldset">
                          <RadioGroup aria-label="valid driving license" name="validLicense" value={validLicense} >
                            <div>
                              <FormControlLabel
                                value="yes"
                                onClick={() => {
                                  if (props.isJobOpen) setValidLicense("yes");
                                }}
                                disabled={!props.isJobOpen}
                                control={<Radio color="primary" />} label="Yes" classes={{ label: classes.radioLabelHindi }} />
                            </div>
                            <div>
                              <FormControlLabel
                                value="no"
                                onClick={() => {
                                  if (props.isJobOpen) setValidLicense("no");
                                }}
                                disabled={!props.isJobOpen}
                                control={<Radio color="primary" />}
                                label="No" classes={{ label: classes.radioLabel }} />
                            </div>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  }

                  {/****** custom1 ******/}
                  {(isCheckedObj.custom1 || profilePgIsCheckedObj.custom1) &&
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>{customQ1}</FormLabel>
                        <TextField
                          required
                          id="custom1"
                          inputProps={{ maxLength: 250 }}
                          multiline
                          rows={4}
                          value={customAns1}
                          onChange={(event) => { setCustomAns1(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                          disabled={!props.isJobOpen}
                        />
                      </FormControl>
                    </Grid>
                  }
                  {/****** custom2 ******/}
                  {(isCheckedObj.custom2 || profilePgIsCheckedObj.custom2) &&
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>{customQ2}</FormLabel>
                        <TextField
                          required
                          id="custom2"
                          inputProps={{ maxLength: 250 }}
                          multiline
                          rows={4}
                          value={customAns2}
                          onChange={(event) => { setCustomAns2(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                          disabled={!props.isJobOpen}
                        />
                      </FormControl>
                    </Grid>
                  }
                  {/****** custom3 ******/}
                  {(isCheckedObj.custom3 || profilePgIsCheckedObj.custom3) &&
                    <Grid item xs={12} >
                      <FormControl component="fieldset" style={{ width: '100%' }}>
                        <FormLabel component="legend" className={classes.tfLabel} style={{ fontWeight: 500 }}>{customQ3}</FormLabel>
                        <TextField
                          required
                          id="custom3"
                          inputProps={{ maxLength: 250 }}
                          multiline
                          rows={4}
                          value={customAns3}
                          onChange={(event) => { setCustomAns3(event.target.value) }}
                          placeholder="अपना answer यहाँ type करें..."
                          variant="outlined"
                          classes={{ root: classes.TfRoot }}
                          disabled={!props.isJobOpen}
                        />
                      </FormControl>
                    </Grid>
                  }

                  {countOfQuestions === 0 &&
                    (<Grid container item xs={12} justifyContent="center" alignItems="center" style={{ flexBasis: '75vh' }}>
                      <Typography variant="h2">
                        There is no screening form to be filled for this opportunity!
                      </Typography>
                    </Grid>
                    )}

                </Grid>
              </React.Fragment>
            }

            {/* Footer next, back, submit buttons */}
            {!props.showOnePageRatings ?
              <AppBar position="fixed" className={classes.tabBar} style={{ top: 'auto', bottom: 0 }}>
                <Toolbar variant="dense" style={{ width: '100%' }} >
                  {primaryTabValue !== 0 ?
                    <Button
                      color="secondary"
                      onClick={() => {
                        // handleTabChange("back");
                        setTimeout(() => { setPrimaryTabValue(prev => prev - 1); }, 150)
                        if (swipeDir === "rtl") {
                          setSwipeDir("ltr")
                          const nodeList = document.querySelectorAll('div[role="tabpanel"]')
                          for (let i = 0; i < nodeList.length; i++) {
                            nodeList[i].style.transform = "translate3d(-100%, 0, 0)"
                          }
                        }
                      }}
                      style={{ fontSize: '0.875rem', textTransform: 'capitalize' }}>
                      Back</Button>
                    : null}
                  {primaryTabValue !== 0 && primaryTabValue !== tabsLength ?
                    <Button
                      color="secondary"
                      disabled={isNextDisabled}
                      onClick={() => {
                        setTimeout(() => { setPrimaryTabValue(prev => prev + 1); }, 150) //timeout added to fix the direction of first transition after direction change
                        if (swipeDir === "ltr") {
                          setSwipeDir("rtl");
                          const nodeList = document.querySelectorAll('div[role="tabpanel"]')
                          for (let i = 0; i < nodeList.length; i++) {
                            nodeList[i].style.transform = "translate3d(100%, 0, 0)"
                          }
                        }
                        postScreeningFormResponse();
                      }
                      }
                      style={{ fontSize: '0.875rem', textTransform: 'capitalize', marginLeft: 'auto' }}>
                      Save & Next</Button>
                    :
                    null
                    // <Button
                    //   color="secondary"
                    //   className={`${classes.button} ${classes.button_pri}`}
                    //   onClick={() => {
                    //     //close this modal
                    //     postScreeningFormResponse()
                    //     //props.setIsScreeningFormOpen(false);
                    //   }
                    //   }
                    //   style={{ fontSize: '0.875rem', textTransform: 'capitalize', marginLeft: 'auto' }}

                    // >Submit</Button>
                  }

                </Toolbar>
              </AppBar>
              :
              <AppBar position="fixed" className={classes.tabBar} style={{ top: 'auto', bottom: 0 }}>
                <Toolbar variant="dense" style={{ width: '100%' }} >
                  {countOfQuestions !== 0 && props.isJobOpen &&
                    <Grid container justifyContent='center'>
                      <Button
                        color="secondary"
                        onClick={() => {
                          if (props.setRefreshScreening) props.setRefreshScreening(prev => !prev) //refresh the screening form parent state vars when back button is pressed
                          props.setIsScreeningFormOpen(false);
                        }}
                        style={{ fontSize: '0.875rem', backgroundColor: '#fff', color: theme.palette.text.disabled, textTransform: 'capitalize', width: '100px', border: `1px solid ${theme.palette.text.disabled}` }}
                      >
                        Back
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => {
                          postScreeningFormResponse('submitAll');
                        }}
                        style={{ fontSize: '0.875rem', textTransform: 'capitalize', marginLeft: 39, width: 100 }}
                        className={classes.pageButton_pri}
                      >
                        Submit
                      </Button>
                    </Grid>
                  }

                  {!props.isJobOpen &&
                    <>
                      <Typography variant="h4" style={{ color: theme.palette.common.red }}>
                        This job is closed.
                        {/* <span style={{ color: theme.palette.common.red }}>*</span> */}
                      </Typography>
                      <Button
                        color="secondary"
                        onClick={() => {
                          props.setIsScreeningFormOpen(false);
                        }}
                        style={{ fontSize: '0.875rem', textTransform: 'capitalize', marginLeft: 'auto' }}
                        className={classes.pageButton_pri}
                      >
                        Okay
                      </Button>
                    </>
                  }

                </Toolbar>
              </AppBar>
            }

          </React.Fragment>
        }
      </React.Fragment>
    </div >
  )
}