import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { Helmet } from 'react-helmet-async';
import BottomAppBar from '../../components/BottomAppBar';
import SearchBar from './SearchBarJobs';
import { SearchBarJobs, SearchBarJobsAutocomplete } from './SearchBarJobs';
import TopAppBar from '../../components/TopAppBar';
import Footer from '../../components/Footer';
import '../../style/App.css';
//import global_isSignedIn from '../../components/globalVars/global_isSignedIn';
import SignInUpDrawer from '../../components/SignInDrawer';
import LogoutDrawer from '../../components/LogoutDrawer';
import ApiHandler from '../../ApiHandler';
import JobRolesPanel, { JobRolesPanelOld, JobsByCities, CompaniesByCities, TopRatedCompanies } from './SliderPanel';
import JobsListItem, { RecommendedJobsListItem } from './JobsListItem';
import ListLoader from '../errorsAndLoaders/ListLoader';
import InfiniteScroll from "react-infinite-scroll-component";
import SnackBarGeneric from './SnackBarGeneric';
import { updateLastSeenAt } from '../../Helpers';
import logo from '../../assets/logo_header.png';
import banner from '../../assets/home-banner.png';
import aboutImg from '../../assets/about.jpg';
import gplayLogo from '../../assets/gplay.svg';
import qr from '../../assets/qr.png';
import icon1 from '../../assets/icon1.svg';
import icon2 from '../../assets/icon2.svg';
import icon3 from '../../assets/icon3.svg';
import icon4 from '../../assets/icon4.svg';
import icon5 from '../../assets/icon5.svg';
import icon6 from '../../assets/icon6.svg';
import infoBanner from '../../assets/info_banner.png'
import nearMePoster from '../../assets/near-me-img.png'

import LogoLoader from '../../assets/logo-loader.gif';
import { Badge, Dialog, DialogContent } from '@material-ui/core';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Notifications from '../../components/Notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 55,
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('xs')]: {
      // paddingTop: 0,
    },
  },
  pageBar: {
    //width: 860,
    width: '100%',
    padding: '8px 43px',
    flexBasis: 50,
    backgroundColor: theme.palette.common.greyLight,
    marginTop: 0,
    zIndex: 8,
    transition: '0.2s',
    position: 'sticky',
    top: 59,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      top: 0,
      padding: 0
    },
  },
  pageTitle: {
    color: "white",
    fontSize: "48px",
    fontWeight: 'bold',
    textShadow: "4px 4px 4px rgba(144, 144, 144, 0.16)",
    margin: '2em 0 0.2em',
  },
  pageSubTitle: {
    color: theme.palette.common.white,
    width: '60%',
    fontSize: "32px",
  },
  bgOverlay: {
    background: `url(${banner}) no-repeat center/cover`,
    width: '100%',
    height: 420,
    paddingTop: '3em',
    position: 'relative',
    // marginBottom: '2.5em',
    zIndex: 99,
  },
  gradientOverlay: {
    position: 'absolute',
    zIndex: 9,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    padding: '10px 10px 5px',
    background: 'linear-gradient(180deg, rgba(91, 194, 168, 0.80) 13.54%, rgba(255, 165, 0, 0.38) 100%)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
  },
  downloadInfoPanel: {
    background: 'white',
    color: theme.palette.common.blackDark,
    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
      marginBottom: 20,
      flexBasis: 88
    },
  },
  downloadInfoPanelD: {
    padding: '40px 20px',
    background: 'white',
    color: theme.palette.common.blackDark,
  },
  searchBarWrapper: {
    height: '72px',
    zIndex: 999,
    backgroundColor: '#fff',
    padding: '0',
    margin: '48px 0 0',
    width: '70%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '15px'
  },
  tfRootForSearchBar: {
    width: '100%',
    //marginBottom: 30,
    borderRadius: 4,
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 15px',
      background: theme.palette.common.white,
      borderRadius: 4,
      fontSize: 16
    },
    '& .MuiSelect-select:focus': {
      borderRadius: 4,
    },
    '& input': {
      height: '100%',
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  searchBarIconButton: {
    marginTop: 0,
    height: '100%',
    width: 72,
    textTransform: "none !important",
    backgroundColor: "#5BC2A8 !important",
    borderRadius: '0 15px 15px 0 !important',
    color: 'white !important',
    "&:hover": {
      background: "#14967B !important",
    },
    "&.Mui-disabled": {
      backgroundColor: '#14967B !important',
      color: '#fff !important',
    }
  },
  searchBarIconButton_Active: {
    marginTop: 0,
    height: '100%',
    width: 72,
    textTransform: "none",
    backgroundColor: '#14967B !important',
    borderRadius: '0 15px 15px 0 !important',
    color: 'white',
    "&:hover": {
      backgroundColor: '#14967B !important',

    },
    "&.Mui-disabled": {
      backgroundColor: '#14967B',
      color: '#fff',
    }
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em'
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 20,
  },
  pageButton_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  listRoot: {
    width: '100%',
    padding: '0 43px 30px',
    marginBottom: 0,
    '& .MuiListItem-root': {
      padding: 0,
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      marginBottom: 10,
      overflow: 'hidden',
      boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 10px 30px',
    },
  },
  panelHeadingSticky: {
    margin: 0,
    padding: '20px 43px 6px',
    fontSize: 18,
    position: 'sticky',
    top: 50,
    background: theme.palette.common.greyLight,
    color: theme.palette.common.blackDark,
    zIndex: 9,
    [theme.breakpoints.down('md')]: {
      padding: '6px 10px 6px',
    },
  },
  panelHeading: {
    margin: '20px 0 15px',
    padding: 0,
    fontSize: 24,
    color: theme.palette.common.blackDark,
    [theme.breakpoints.down('xs')]: {
      margin: '0px 0 8px',
      padding: '0 10px',
      fontSize: 18,
    },
  },
  rolesPanelPostTyping: {
    padding: '0px 10px 60px',
  },
  rolesPanel: {
    padding: '20px 0px 6px',
    [theme.breakpoints.down('md')]: {
      padding: '5px 0px 20px',
    },
  },
  sectionDesktop: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column'
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  footerIcon: {
    padding: 10,
    opacity: '0.7'
  },
  panelWrapper: {
    width: '90%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  sliderContainer: {
    position: 'relative',
    display: 'flex',
    overflowX: 'scroll',
    width: '100%',
    marginBottom: 15,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  sliderContainerWithColumn: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: 'repeat(3, auto)',
    columnGap: 10,
    //marginBottom: 15,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  slide: {
    padding: 5,
    cursor: 'pointer',
  },
  sliderImgWrapper: {
    width: 100,
    height: 121,
  },
  lowerSectionDiv: {
    width: '90%',
    margin: 'auto',
    zIndex: '9'
  },
  aboutUsImg: {
    marginLeft: 'auto',
    width: '80%',
    height: 'auto',
  },
  infoSectionContainer: {
    height: 450,
    backgroundColor: '#55c6a9',
    padding: '3em 10em',
    background: `url(${infoBanner}) no-repeat center center/cover`,
  },
  infoSectionHeading: {
    color: '#fff',
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 30
  },
  infoSectionText: {
    color: '#fff',
    fontSize: 18,
    textAlign: 'center',
    width: '70%',
    '@media (max-width: 1200px)': {
      width: '56%'
    }
  },
  mobileNotificationIcon: {
    position: 'absolute',
    top: 0,
    right: 43,
    zIndex: 9,
    padding: '12px 0'
  },
  notificationDialogRoot: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0
    }
  },
}));

export default function JobsMain(props) {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [navValue, setNavValue] = React.useState(0);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);
  const [roles_list, setRolesList] = React.useState([]);

  const [displayLocationBox, setDisplayLocationBox] = React.useState(true);
  const [displaySearchBar, setDisplaySearchBar] = React.useState(false);
  const [displayAllJobs, setDisplayAllJobs] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState(null);
  const [started_typing, setStartedTyping] = React.useState(false);
  const refSeeAllJobsButton = React.useRef(null);
  const [isLocationDenied, setIsLocationDenied] = React.useState(false);

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);

  const pageStateOnReturn = (sessionStorage.getItem("pageStateOnReturn")) || "default"; // sessionstorage to track the current state of page: default or search

  document.body.style.overflowY = "scroll";

  const matchesMinWidthDesktop = useMediaQuery('(min-width:1280px)');

  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  // when role search is ongoing 
  const showRoleSearchState = () => {
    setDisplayLocationBox(false);
    setDisplaySearchBar(true); // display the search bar
    setDisplayAllJobs(true); // display the whole list of roles
    setStartedTyping(true); // hide list of jobs
    executeScroll(); // scroll to top
  }

  // default reset function for jobspage
  const showDefaultJobsPageState = () => {
    setDisplayLocationBox(true);
    setDisplaySearchBar(false); // hide the search bar
    setSearchTerm(null) // empty the search box
    setDisplayAllJobs(false); // collapse list of roles
    setStartedTyping(false); // show list of jobs
    executeScroll(); // scroll to top
  }

  // get jobs list---> starts
  const [refresh, doRefresh] = React.useState(false);
  const [jobsList, setJobsList] = React.useState(null);
  const [jobsCount, setJobsCount] = React.useState(null);
  const [vacanciesCount, setVacanciesCount] = React.useState(null);
  const [modeOfWork, setModeOfWork] = React.useState(sessionStorage.getItem("modeOfWork") || "everyWhere");
  const [latLong, setLatLong] = React.useState(location && location.state && location.state.latLong ? location.state.latLong : { lat: 26.8489293, long: 80.802425 });
  const [opportunityType, setOpportunityType] = React.useState('AllJobs');
  const [elemVisibleOnload, setElemVisibleOnload] = React.useState(false);
  const [showWFHOption, setShowWFHOption] = React.useState(false);

  const [recommendedJobs, setRecommendedJobs] = React.useState(null);
  //search functionality ---> starts
  const [searchString, setSearchString] = React.useState("");
  const [isSearchActive, setIsSearchActive] = React.useState(false);
  const handleSearchClick = () => {
    history.push({
      // pathname: `/search?q=${searchString}`,
      pathname: `/search`,
      state: {
        searchQuery: searchString
      }
    });
  }

  const handleSearchClickMobile = () => {
    history.push({
      // pathname: `/search?q=${searchString}`,
      pathname: `/search`,
      state: {
        searchQuery: searchString,
        mobile: true
      }
    });
  }

  const handleGeolocationDetection = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) { // on success
        setLatLong({ lat: position.coords.latitude, long: position.coords.longitude });
        // This will trigger the useeffect with a dependency for latLong
        setIsLocationDenied(false);
      }, function () { // on error
        setIsLocationDenied(true);
        handleSnackBar(`Showing results for everywhere as you have denied Location access permission.`)
      })
    }
  }

  const checkWFHRoles = () => {
    let payload = {
      location: 'Work From Home',
      //location: 'Lucknow',
      opportunity_type: 'AllJobs'
    }
    ApiHandler.getRolesAndOpeningsForLocation(payload, response => {
      if (response.status === 200) {
        let arr_temp = response.data;
        if (arr_temp.length !== 0) {
          setShowWFHOption(true);
        } else if (arr_temp.length === 0 && modeOfWork === "workFromHome") { //if by default wfh was set then switch to all
          setModeOfWork("everyWhere");
          sessionStorage.setItem("modeOfWork", "everyWhere");
        }

      }
    })
  }

  const handleRolesListForWFH = () => {
    let payload = {
      location: 'Work From Home',
      //location: 'Lucknow',
      opportunity_type: 'AllJobs'
    }
    ApiHandler.getRolesAndOpeningsForLocation(payload, response => {
      if (response.status === 200) {
        let arr_temp = response.data;
        setRolesList(arr_temp);
        setElemVisibleOnload(true); // for IXD. to not show the location bar before content load
      }
    })
  }

  const handleRolesListForAll = () => {
    let pathParam = "AllJobs";
    ApiHandler.getAllJobRolesandCount(pathParam, response => {
      if (response.status == 200) {
        let arr_temp = response.data;
        setRolesList(arr_temp);
        setElemVisibleOnload(true); // for IXD. to not show the location bar before content load
      }
      else {
        console.log("error in getting a successful response")
      }
    })
  }

  // get jobs list after change in modeOfWork except nearMe. If mode of work is near me then detect geolocation which will change lat long and trigger another specific useeffect call---> starts
  const trackInitialRenderRef = React.useRef(true);
  // get jobs list after change in modeOfWork except nearMe---> ends

  // get jobs list after change in modeOfWork to nearMe which triggered latLong detection---> starts
  const trackInitialRenderRefForLatLong = React.useRef(true);
  // get jobs list after change in modeOfWork to nearMe which triggered latLong detection---> ends

  // get jobs list and roles list on page load---> starts
  React.useEffect(() => {
    checkWFHRoles(); // check if wfh roles are present. if not then hide wfh location chip

    // if (modeOfWork == "workFromHome") { //commenting this out as no more used on new home page
    //   handleRolesListForWFH(); 
    // } 
    //else {

    handleRolesListForAll();
    //}

    const handleJobsList = () => {
      // if (modeOfWork == "nearMe") { //to avoid double api call for nearme
      //   return;
      // } // commenting this out as this is no more used in the new home page

      setIsLoading(true);

      let data = {
        limit: 6,
        offset: 0,
        //modeOfWork: modeOfWork,
        modeOfWork: "everyWhere", // as this page now does not have the location bar
        opportunity_type: opportunityType,
      }

      // if (modeOfWork == "nearMe") {
      //   data['latitude'] = latLong.lat;
      //   data['longitude'] = latLong.long;
      // }

      ApiHandler.getAllJobPosts(data, response => {
        if (response.status == 200) {
          let arr_temp = response.data[0].results;
          setJobsList(arr_temp);
          //sessionStorage.setItem('allJobs', JSON.stringify(arr_temp))
          setJobsCount(response.data[0].totalCount[0].count);
          setVacanciesCount(response.data[0].total_vacancies[0].count);

          setIsLoading(false);
        }
        else {
          console.log("error in getting a successful response")
        }
      })
    }
    handleJobsList();

  }, [])
  // get jobs list and roles list on page load---> ends

  //to update user's last seen at timestamp on load and at the time of login
  React.useEffect(() => {
    if (isSignedIn) {
      updateLastSeenAt(); //to update user's last seen at timestamp
    }
  }, [isSignedIn])

  // detect onload whether location access permission is denied and update the nearMe option in the drawer comp. In case of iOS detect location onload--->starts
  React.useEffect(() => {

    function handlePermission() {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state == 'granted' || result.state == 'prompt') {
          setIsLocationDenied(false);
          //if mode of work is near me by default then call gelocation api on load
          if (modeOfWork == "nearMe") {
            handleGeolocationDetection();
          }
        } else if (result.state == 'denied') {
          setIsLocationDenied(true);
        }
      });
    }

    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (iosPlatforms.indexOf(platform) !== -1 || macosPlatforms.indexOf(platform) !== -1) {
      //alert("iOS or Mac OS")
      // access location onload if iOS detected
      handleGeolocationDetection();
    } else { // use navigator permissions API for better UX in case OS is not iOS/ MAC OS
      if (navigator.permissions && navigator.permissions.query) {
        handlePermission();
      } else {
        console.log("case when navigator.permissions is not supported.")
      }
    }

  }, [])
  // detect onload whether location access permission is denied and update the nearMe option in the drawer comp. In case of iOS detect location onload--->ends

  React.useLayoutEffect(() => {
    if (location && location.state && location.state.see_all_roles) {
      showRoleSearchState();
    };

    if (pageStateOnReturn === "default") {
      showDefaultJobsPageState();
    }
  }, []);

  React.useEffect(() => {
    const handleRecommendedJobsList = () => {
      let payload = {
        limit: 10,
        offset: 0,
      }

      let jobseekerID = localStorage.getItem("jobseeker_id");

      ApiHandler.getRecommendedJobs(jobseekerID, payload, response => {
        if (response.status == 200) {
          if (response.data[0] && response.data[0].results) {
            //console.log(response.data[0].results);
            setRecommendedJobs(response.data[0].results);
          }

        }
        else {
          console.log("error in getting a successful response for the list of jobs")
        }
      })
    }

    if (isSignedIn) handleRecommendedJobsList();

  }, [isSignedIn])

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  //sort bar bg effect on scroll starts
  const pageBarRef = React.useRef(null);
  let lastScrollY = 0;
  let scrolling = false;

  const handleScrollChange = () => {
    lastScrollY = window.scrollY;
    if (lastScrollY > 60) {
      scrolling = true
    } else {
      scrolling = false
    }
    if (!scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        //pageBarRef.current.style.background = '#f5f5f5';
        pageBarRef.current.style.boxShadow = "none";
        //pageBarRef.current.style.flexBasis = "50px";
      });
    } else if (scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        //pageBarRef.current.style.background = '#f5f5f5';
        pageBarRef.current.style.boxShadow = "0px 5px 5px rgba(0,0,0,0.05)";
        //pageBarRef.current.style.flexBasis = "50px";
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);
  //sort bar bg effect on scroll ends

  // drawer callback starts
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);

  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // Settings drawer callback starts
  const [forRefreshSettingsOnly, setForRefreshSettingsOnly] = React.useState(false);
  const [canSettingsDrawerOpen, setCanSettingsDrawerOpen] = React.useState(false);

  const [refreshSettings, doRefreshSettings] = React.useState(false);

  const handleSettingsDrawer = () => {
    setCanSettingsDrawerOpen(true)
    doRefreshSettings(prev => !prev)
  }
  // Settings drawer callback ends

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const [snackBarMessageSeverity, setSnackBarMessageSeverity] = React.useState("success");
  const handleSnackBar = (message, severity) => {
    if (severity) {
      setSnackBarMessageSeverity(severity);
    } else {
      setSnackBarMessageSeverity("success");
    }
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev);
  }
  // snackbar callback ends

  useEffect(() => {
    return () => { // if the page is in role search state and users cliks the browser back button then redirect to jobs main page instead of navigating back
      if (displaySearchBar && !displayLocationBox && history.action === "POP") {
        history.push(
          { pathname: `/`, }
        )
      }
    }
  })

  // React.useEffect(() => {
  //   const unblock = history.block((location, action) => {
  //     if (displaySearchBar && !displayLocationBox && action === "POP") {
  //       return window.confirm("Navigating Back might take you outside the app?");
  //     }
  //     return true;
  //   });

  //   return () => {
  //     unblock();
  //   };
  // });

  //notification start
  const [openNotificationDialog, setOpenNotificationDialog] = React.useState(false);

  const closeNotificationDialog = () => {
    setOpenNotificationDialog(false);
  }

  const [deskNotifyBarDisplay, setDeskNotifyBarDisplay] = React.useState(false);
  const [notificationBadgeCount, setNotificationBadgeCount] = React.useState(0);

  //fetch all notifications
  React.useEffect(() => {
    let jobseekerID = localStorage.getItem("jobseeker_id");
    let payload = {
      "offset": 0,
      "limit": 100,
      "jobseeker_id": jobseekerID
    }
    ApiHandler.getAllNotifications(payload, res => {
      let unreadNotif = 0;
      if (res.status == 200) {
        unreadNotif = res.data[0].results.filter(val => !val.is_read).length;
        setNotificationBadgeCount(unreadNotif);
      }
    })
  }, []);

  // const [autocompleteList, setAutocompleteList] = React.useState([]);
  // const handleSearchAutocompleteList = () => {
  //   let queryParam = searchString;
  //   ApiHandler.getSearchAutocompleteList(queryParam, response => {
  //     if (response.status == 200) {
  //       let arr_temp = response.data;
  //       setAutocompleteList(arr_temp);
  //     }
  //     else {
  //       console.log("error in getting a successful response")
  //     }
  //   })
  // }

  // React.useEffect(() => {
  //   if (searchString === "") setAutocompleteList([])
  //   else handleSearchAutocompleteList()
  // }, [searchString])

  return (
    <React.Fragment>
      <Helmet>
        {/* displayAllJobs is for jobs by roles */}
        <title>{displayAllJobs ? "Explore jobs as per your desired job roles | Meetworks" : "Find job opportunities to start your career | Meetworks"}</title>
        <meta name="description" content={displayAllJobs ? "Search jobs in sales, marketing, operations, customer support, finance, logistics, human resource posted by verified employers" : "Search full time, part time, work from home and freelance job roles and internships of verified employers in desired streams and sectors"} />
        <meta
          name="keywords"
          content={displayAllJobs ? "sought-after-roles, part time jobs, work form home jobs, jobs for freelancers, hot jobs" : "Search jobs for free, job portal for freshers, careers for freshers, online job search sites"}
        />
      </Helmet>

      {/* MOBILE Section */}
      <div className={classes.sectionMobile}>
        {/* location app bar */}
        {elemVisibleOnload && started_typing &&
          <TopAppBar
            setDisplaySearchBar={setDisplaySearchBar}
            refSeeAllJobsButton={refSeeAllJobsButton}
            displaySearchBar={displaySearchBar}
            setModeOfWork={setModeOfWork}
            modeOfWork={modeOfWork}
            doRefresh={doRefresh}
            setJobsList={setJobsList}
            setJobsCount={setJobsCount}
            isLocationDenied={isLocationDenied}
            displayLocationBox={displayLocationBox}
            showDefaultJobsPageState={showDefaultJobsPageState}
            showWFHOption={showWFHOption}
          >
          </TopAppBar>
        }

        {/* Top App Bar */}
        {elemVisibleOnload && !started_typing &&
          <AppBar position="fixed" className={classes.tabBar} style={{ background: '#fff' }}>
            <Toolbar variant="dense" style={{ padding: 0 }}>
              <div className={classes.grow} style={{ width: '100%' }}>
                <div className={classes.sectionMobile} style={{ display: "flex" }}>
                  <Button
                    className={classes.logo}
                    id="meetLogo_id"
                    onClick={() => {
                      history.push({
                        pathname: '/'
                      });
                    }}>
                    <img alt="meet logo" style={{ width: 60, marginRight: '11%', paddingLeft: 10 }} src={logo} />
                  </Button>
                  <Grid
                    item xs={8}
                    container
                    direction='column'
                    justifyContent="center"
                    alignItems='flex-start'
                    style={{ maxWidth: '52vw' }}
                  >
                    <div onClick={handleSearchClickMobile}>
                      <SearchBarJobs
                        searchTerm={searchString}
                        setSearchTerm={setSearchString}
                        readOnly={true}
                        placeholder={"Jobs by Title, Role & sector"}
                      ></SearchBarJobs>
                    </div>
                  </Grid>
                  {isSignedIn && <IconButton className={classes.mobileNotificationIcon} aria-label="show 10 new notifications" color="inherit">
                    <Badge badgeContent={notificationBadgeCount} color="error">
                      <NotificationsOutlinedIcon color='action' onClick={() => setOpenNotificationDialog(true)} />
                    </Badge>
                  </IconButton>}
                  <IconButton
                    id="shareButton"
                    style={{ position: 'absolute', top: 0, right: 10, zIndex: 9, padding: '12px 0' }}
                    onClick={() => {
                      if (isSignedIn) handleSettingsDrawer()
                      else handleDrawer()
                    }}
                  >
                    <SettingsOutlinedIcon />
                  </IconButton>

                  {/* <IconButton
                  id="shareButton"
                  style={{ position: 'absolute', top: 0, right: 50, zIndex: 9, padding: '12px 0' }}
                // onClick={() => {

                // }}
                >
                  <NotificationsNoneOutlinedIcon />
                </IconButton> */}
                </div>
              </div>
            </Toolbar>
          </AppBar>
        }

        <div className={classes.root} ref={scrollRef} style={{ paddingBottom: 60, paddingTop: started_typing ? 0 : 0 }}>

          {/* Role search bar */}
          <Grid container
            alignItems="center" className={classes.pageBar} ref={pageBarRef}>
            <Grid item xs={12} container
              justifyContent="center"
              alignItems="center">
              {displaySearchBar ?
                <SearchBar
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  setStartedTyping={setStartedTyping}
                ></SearchBar> : null
              }
            </Grid>
          </Grid>

          {/* Banner Image */}
          {elemVisibleOnload && !started_typing && <div style={{ width: '100%', padding: '10px 10px 10px' }}>
            <div style={{ position: 'relative', width: '100%', height: 118, overflow: 'hidden', borderRadius: 4, boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)' }}>
              <img alt="banner image" style={{ width: '100%' }} src={banner} />
              <div style={{ position: 'absolute', zIndex: 9, left: 0, top: 0, width: '100%', height: '100%', padding: '10px 10px 5px', background: 'linear-gradient(90deg, rgba(255, 165, 0, 0.20) 45.36%, #55C6A9 99.93%)' }}>
                <Grid container alignItems="center" justifyContent='flex-end' style={{ height: '100%' }}>
                  <div>
                    <h1 style={{ textAlign: 'right', fontWeight: 700, fontSize: '1.15rem', color: 'white', margin: '0 0 5px' }}>Meetworks</h1>
                    <Typography variant="h1" align="right" style={{ color: theme.palette.common.white, marginLeft: 'auto', width: '60%', fontSize: '0.975rem' }}>Connecting jobseekers with verified opportunities
                    </Typography>
                  </div>
                </Grid>

              </div>
            </div>
          </div>}

          {/* Near Me */}
          {elemVisibleOnload && !started_typing &&
            <div style={{ position: 'relative', cursor: 'pointer' }}
              onClick={() => {
                sessionStorage.setItem('modeOfWork', 'nearMe')
                history.push({
                  pathname: `/all-jobs`,
                });
              }
              }>
              <img alt="banner image" style={{ width: '99%', marginBottom: 8 }} src={nearMePoster} />
              {/* <Button variant="contained"
                  style={{ position: 'absolute', top: '32%', right: '3%', zIndex: 9, padding: '2px', fontSize: 12, textTransform: 'capitalize', width: '6rem' }}
                  color='secondary'
                 
                >
                  Near Me
                  <ArrowForwardRoundedIcon color="white" fontSize="small" style={{marginLeft: 10}} />
                </Button> */}
            </div>
          }

          {/* Download our Android app */}
          {elemVisibleOnload && !started_typing &&
            <Grid item container xs={12} alignItems="center" justifyContent='space-between' className={classes.downloadInfoPanel}>
              <Grid item xs={6}>
                <Typography variant='h1' style={{ fontSize: 14, marginBottom: 5 }}>Download our Android app </Typography>
                <a href='https://play.google.com/store/apps/details?id=com.meetjobseekerapp&hl=en' target='_blank' rel="noopener noreferrer">
                  <img src={gplayLogo} width={120} height={50} alt='gplay' />
                </a>
              </Grid>
              <Grid item wrap='nowrap' container justifyContent='flex-end' xs={6}>

                <img src={qr} width={50} height={50} alt='gplay' />

              </Grid>

            </Grid>
          }

          {/* Jobs by cities */}
          {elemVisibleOnload && !started_typing &&
            <JobsByCities></JobsByCities>
          }

          {/* All Jobs */}
          {elemVisibleOnload && !started_typing &&
            <div style={{ width: '100%', height: 196 }}>
              {isLoading
                ? <div style={{
                  width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
                }}>
                  <ListLoader setListLoaderRef={setListLoaderRef} />
                </div> :
                <>
                  <div style={{ position: 'relative' }}>
                    <Typography variant="h1" color="primary" className={classes.panelHeading}>
                      {/* {"सभी jobs"} */}
                      {"Explore jobs"}
                    </Typography>

                    <IconButton edge="end" aria-label="see all jobs"
                      id={"jobs-see-all"}
                      onClick={() => {
                        history.push({
                          pathname: `/all-jobs`,
                        });
                      }
                      }
                      style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 12, zIndex: 9 }}
                    >
                      <ChevronRightRoundedIcon color="primary" />
                    </IconButton>
                  </div>
                  <List
                    className={`${classes.listRoot} ${classes.sliderContainer}`}
                    style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                    disablePadding>
                    {jobsList && jobsList.map((hit, index) =>
                    (<RecommendedJobsListItem
                      hit={jobsList[index]}
                      signInStatus={isSignedIn}
                      key={index}
                      lat={latLong.lat}
                      long={latLong.long}
                      isWorkFromHome={jobsList[index].isWorkFromHome}
                      pageStateOnReturn={"default"}
                    />)
                    )
                    }
                  </List>
                </>
              }
            </div>
          }

          {/* Jobs by most openings */}
          <div className={!started_typing ? classes.rolesPanel : classes.rolesPanelPostTyping} style={{ width: '94%', }} ref={trackInitialRenderRef}>
            {elemVisibleOnload &&
              <JobRolesPanelOld
                setDisplaySearchBar={setDisplaySearchBar}
                refSeeAllJobsButton={refSeeAllJobsButton}
                displayAllJobs={displayAllJobs}
                setDisplayAllJobs={setDisplayAllJobs}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                roles_list={roles_list}
                location={location.state}
                modeOfWork={modeOfWork}
                lat={latLong.lat}
                long={latLong.long}
                // executeScroll={executeScroll}
                setStartedTyping={setStartedTyping}
                showRoleSearchState={showRoleSearchState}
                showDefaultJobsPageState={showDefaultJobsPageState}
              />
            }
          </div>

          {/* Recommended Jobs */}
          {isSignedIn && recommendedJobs && recommendedJobs.length > 0 && elemVisibleOnload && !started_typing &&
            <div style={{ width: '100%' }}>
              {isLoading
                ? <div></div> :
                <>
                  <div style={{ position: 'relative' }}>
                    <Typography variant="h1" color="primary" className={classes.panelHeading}>
                      {"आपके लिए recommended jobs"}
                    </Typography>

                    <IconButton edge="end" aria-label="see all recommended jobs"
                      id={"recommended-jobs-see-all"}
                      onClick={() => {
                        history.push({
                          pathname: `/recommended-jobs`,
                        });
                      }
                      }
                      style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 12, zIndex: 9 }}
                    >
                      <ChevronRightRoundedIcon color="primary" />
                    </IconButton>
                  </div>
                  <List
                    className={`${classes.listRoot} ${classes.sliderContainer}`}
                    style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                    disablePadding>
                    {recommendedJobs && recommendedJobs.map((hit, index) =>
                    (<RecommendedJobsListItem
                      hit={recommendedJobs[index]}
                      signInStatus={isSignedIn}
                      key={index}
                      lat={latLong.lat}
                      long={latLong.long}
                      isWorkFromHome={recommendedJobs[index].isWorkFromHome}
                      pageStateOnReturn={"default"}
                    />)
                    )
                    }
                  </List>
                </>
              }
            </div>
          }

          {elemVisibleOnload && !started_typing &&
            <TopRatedCompanies></TopRatedCompanies>
          }
          {elemVisibleOnload && !started_typing &&
            <CompaniesByCities
            // isSignedIn={isSignedIn}
            // handleDrawer={handleDrawer}
            >
            </CompaniesByCities>
          }

          <div ref={trackInitialRenderRefForLatLong} style={{ display: 'none' }}></div>

        </div>

        <BottomAppBar
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          handleDrawer={handleDrawer}
          navValue={navValue}
          setNavValue={setNavValue}
          showDefaultJobsPageState={showDefaultJobsPageState}
        >
        </BottomAppBar>

        <SignInUpDrawer
          canDrawerOpen={canDrawerOpen}
          refresh={refresh}
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          setForRefreshOnly={setForRefreshOnly}
          setCanDrawerOpen={setCanDrawerOpen}
          doRefresh={doRefresh}
        />

        <LogoutDrawer
          canDrawerOpen={canSettingsDrawerOpen}
          refresh={refreshSettings}
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          setForRefreshOnly={setForRefreshSettingsOnly}
          setCanDrawerOpen={setCanSettingsDrawerOpen}
          doRefresh={doRefreshSettings}
          handleSnackBar={handleSnackBar}
        />

        <SnackBarGeneric id="snackBarMessage" canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />

      </div>

      {/* DESKTOP and iPad Section */}
      <div className={classes.sectionDesktop}>
        {/* location app bar */}
        {elemVisibleOnload && started_typing &&
          <TopAppBar
            setDisplaySearchBar={setDisplaySearchBar}
            refSeeAllJobsButton={refSeeAllJobsButton}
            displaySearchBar={displaySearchBar}
            setModeOfWork={setModeOfWork}
            modeOfWork={modeOfWork}
            doRefresh={doRefresh}
            setJobsList={setJobsList}
            setJobsCount={setJobsCount}
            isLocationDenied={isLocationDenied}
            displayLocationBox={displayLocationBox}
            showDefaultJobsPageState={showDefaultJobsPageState}
            showWFHOption={showWFHOption}
          >
          </TopAppBar>
        }

        {/* Top App Bar */}
        {/* {elemVisibleOnload && !started_typing &&
          <AppBar position="fixed" className={classes.tabBar} style={{ background: '#fff' }}>
            <Toolbar variant="dense" style={{ padding: 0 }}>
              <div className={classes.grow} style={{ width: '100%' }}>
                <div className={classes.sectionMobile} style={{ display: "flex" }}>
                  <Button
                    className={classes.logo}
                    id="meetLogo_id"
                    onClick={() => {
                      history.push({
                        pathname: '/'
                      });
                    }}>
                    <img alt="meet logo" style={{ width: 80, marginRight: '11%', paddingLeft: 10 }} src={logo} />
                  </Button>
                  <Grid
                    item xs={9} md={11}
                    container
                    direction='column'
                    justifyContent="center"
                    alignItems='flex-start'>
                    
                  </Grid>
                  <IconButton
                    id="shareButton"
                    style={{ position: 'absolute', top: 0, right: 10, zIndex: 9, padding: '12px 0' }}
                    onClick={() => {
                      if (isSignedIn) handleSettingsDrawer()
                      else handleDrawer()
                    }}
                  >
                    <SettingsOutlinedIcon />
                  </IconButton>

                </div>
              </div>
            </Toolbar>
          </AppBar>
        } */}

        <div className={classes.root} ref={scrollRef} style={{ paddingBottom: 60 }}>

          {/* Role search bar */}
          {displaySearchBar &&
            <Grid container
              alignItems="center" className={classes.pageBar}
            //ref={pageBarRef}
            >
              <Grid item xs={12} container
                justifyContent="center"
                alignItems="center">
                {displaySearchBar ?
                  <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    setStartedTyping={setStartedTyping}
                  ></SearchBar> : null
                }
              </Grid>
            </Grid>
          }

          {/* Banner Image and searchbar*/}
          {elemVisibleOnload && !started_typing &&
            <div className={`${classes.bgOverlay}`}>
              <div className={classes.gradientOverlay}>
                <Grid container direction="column" alignItems="center" justifyContent='center' style={{ flexGrow: 1 }}>
                  <h1 align="center" className={classes.pageTitle}>Meetworks</h1>
                  <Typography variant="h1" align="center" className={classes.pageSubTitle}>Connecting jobseekers with verified opportunities
                  </Typography>
                  <div className={classes.searchBarWrapper} style={{ paddingLeft: 10 }}>
                    {/****** search field ******/}
                    <Grid item xs={12} >
                      {/* <FormControl component="fieldset" style={{ width: '100%' }}>
                        <TextField
                          required
                          id={"searchBox_id"}
                          value={searchString}
                          autoComplete="off"
                          inputProps={{
                            maxLength: 100,
                            autoComplete: 'off',//to disable autofill
                          }}
                          onChange={(event) => {
                            let val = event.target.value
                            setSearchString(val);
                            if (val.length > 0 && !isSearchActive) setIsSearchActive(true);
                            else if (val.length == 0 && isSearchActive) setIsSearchActive(false);
                          }}
                          placeholder="Search Jobs by Title, Role & Sector..."
                          variant="outlined"
                          classes={{ root: classes.tfRootForSearchBar }}
                        />
                      </FormControl> */}


                      <SearchBarJobsAutocomplete
                        searchTerm={searchString}
                        setSearchTerm={setSearchString}
                        isSearchActive={isSearchActive}
                        setIsSearchActive={setIsSearchActive}
                        handleSearchClick={handleSearchClick}
                        //autocompleteList={autocompleteList}
                        readOnly={true}
                        placeholder={"Search Jobs by Title, Role & Sector..."}
                      ></SearchBarJobsAutocomplete>


                    </Grid>
                    <Button id="search_btn_id" size="small" variant="contained" className={isSearchActive ? classes.searchBarIconButton_Active : classes.searchBarIconButton}
                      onClick={() => { if (isSearchActive) handleSearchClick() }}

                    >
                      <SearchIcon fontSize="large" />
                    </Button>
                  </div>
                </Grid>
              </div>
            </div>
          }

          {/* Download our Android app */}
          {elemVisibleOnload && !started_typing &&
            <Grid item container xs={12} alignItems="flex-start" justifyContent='space-between' className={classes.downloadInfoPanelD}>
              <Grid item container xs={6} direction="column" alignItems="center" justifyContent='center' style={{borderRight: '1px solid #d9d9d9'}}>
                <Typography variant='h1' align="center" style={{ width: '75%', marginBottom: 10 }}>Download our Android app to explore more features</Typography>
                <Grid xs={6} item container justifyContent='center' alignItems='center'>
                  <img src={qr} width={50} height={50} alt='gplay' style={{ marginBottom: 5 }} />
                  <a href='https://play.google.com/store/apps/details?id=com.meetjobseekerapp&hl=en' target='_blank' rel="noopener noreferrer">
                    <img src={gplayLogo} width={150} height={80} alt='gplay' />
                  </a>
                </Grid>
              </Grid>

              <Grid item container xs={6} direction="column" alignItems="center" justifyContent='center'>
                <Typography variant='h1' align="center" style={{ width: '75%' }}>Share your current location and find your dream job in your area </Typography>
                <Button variant="contained"
                  style={{ padding: '8px 4px', marginTop: 22, fontSize: 18, textTransform: 'capitalize', width: '10rem' }}
                  color='secondary'
                  onClick={() => {
                    sessionStorage.setItem('modeOfWork', 'nearMe')
                    history.push({
                      pathname: `/all-jobs`,
                    });
                  }
                  }
                >
                  Near Me
                  <ArrowForwardRoundedIcon color="white" style={{ marginLeft: 10 }} />
                </Button>
              </Grid>
            </Grid>
          }



          {/*Info section */}
          {!isSignedIn && elemVisibleOnload && !started_typing && (<Grid className={classes.infoSectionContainer} justifyContent='center' container>
            <Typography className={classes.infoSectionHeading} component='h1' variant='body1'>Looking for a job?  जानिये अपने सवालों के जवाब
            </Typography>
            <Grid container item direction='column' >
              <Grid container item style={{ marginBottom: '2em' }}>
                <Grid xs={3} item container alignItems='center' direction='column'>
                  <img src={icon1} width={50} height={50} />
                  <Typography className={classes.infoSectionText} variant='body1' component='p'>ये Job fraud तो नहीं है?</Typography>
                </Grid>
                <Grid xs={3} item container alignItems='center' direction='column'>
                  <img src={icon2} width={50} height={50} />
                  <Typography className={classes.infoSectionText} variant='body1' component='p'>Working hours क्या है?</Typography>
                </Grid>
                <Grid xs={3} item container alignItems='center' direction='column'>
                  <img src={icon3} width={50} height={50} />
                  <Typography className={classes.infoSectionText} variant='body1' component='p'>What's the Salary?</Typography>
                </Grid>
                <Grid xs={3} item container alignItems='center' direction='column'>
                  <img src={icon4} width={50} height={50} />
                  <Typography className={classes.infoSectionText} variant='body1' component='p'>क्या ये Company verified है?</Typography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid xs={6} item container alignItems='center' direction='column'>
                  <img src={icon5} width={50} height={50} />
                  <Typography style={{ width: '40%' }} className={classes.infoSectionText} variant='body1' component='p'>इस Company का माहौल कैसा है?</Typography>
                </Grid>
                <Grid xs={6} item container alignItems='center' direction='column'>
                  <img src={icon6} width={50} height={50} />
                  <Typography className={classes.infoSectionText} variant='body1' component='p'>क्या इस Company में Female employees भी हैं?</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>)}



          {/* Jobs by cities */}
          {elemVisibleOnload && !started_typing &&
            <JobsByCities></JobsByCities>
          }

          {/* All Jobs */}
          {elemVisibleOnload && !started_typing &&
            <div style={{ width: '90%' }}>
              {isLoading
                ? <div></div> :
                <>
                  <div style={{ position: 'relative' }}>
                    <Typography variant="h1" color="primary" className={classes.panelHeading}>
                      {"Explore jobs"}
                    </Typography>

                    <IconButton edge="end" aria-label="see all jobs"
                      id={"jobs-see-all"}
                      onClick={() => {
                        history.push({
                          pathname: `/all-jobs`,
                        });
                      }
                      }
                      style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 12, zIndex: 9, borderRadius: 30 }}
                    >
                      <Typography variant="body1" color="primary">See All</Typography>
                      <ChevronRightRoundedIcon color="primary" />
                    </IconButton>
                  </div>
                  <List
                    className={`${classes.listRoot} ${matchesMinWidthDesktop ? classes.sliderContainerWithColumn : classes.sliderContainer}`}
                    style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                    disablePadding>
                    {jobsList && jobsList.map((hit, index) =>
                    (<RecommendedJobsListItem
                      hit={jobsList[index]}
                      signInStatus={isSignedIn}
                      key={index}
                      lat={latLong.lat}
                      long={latLong.long}
                      isWorkFromHome={jobsList[index].isWorkFromHome}
                      pageStateOnReturn={"default"}
                    />)
                    )
                    }
                  </List>
                </>
              }
            </div>
          }

          {/* Recommended Jobs */}
          {isSignedIn && recommendedJobs && recommendedJobs.length > 0 && elemVisibleOnload && !started_typing &&
            <div className={classes.panelWrapper}>
              {isLoading
                ? <div></div> :
                <>
                  <div style={{ position: 'relative' }}>
                    <Typography variant="h1" color="primary" className={classes.panelHeading}>
                      {"आपके लिए recommended jobs"}
                    </Typography>
                    {recommendedJobs && recommendedJobs.length > 3 &&
                      <IconButton edge="end" aria-label="see all recommended jobs"
                        id={"recommended-jobs-see-all"}
                        onClick={() => {
                          history.push({
                            pathname: `/recommended-jobs`,
                          });
                        }
                        }
                        style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 12, zIndex: 9, borderRadius: 30 }}
                      >
                        <Typography variant="body1" color="primary">See All</Typography>
                        <ChevronRightRoundedIcon color="primary" />
                      </IconButton>
                    }
                  </div>
                  <List
                    className={`${classes.listRoot} ${classes.sliderContainer}`}
                    style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                    disablePadding>
                    {recommendedJobs && recommendedJobs.slice(0, 3).map((hit, index) =>
                    (<RecommendedJobsListItem
                      hit={recommendedJobs[index]}
                      signInStatus={isSignedIn}
                      key={index}
                      lat={latLong.lat}
                      long={latLong.long}
                      isWorkFromHome={recommendedJobs[index].isWorkFromHome}
                      pageStateOnReturn={"default"}
                    />)
                    )
                    }
                  </List>
                </>
              }
            </div>
          }


          {/* Jobs by most openings */}
          <div className={!started_typing ? classes.rolesPanel : classes.rolesPanelPostTyping} style={{ width: !started_typing ? '95%' : '90%', alignSelf: !started_typing ? 'flex-end' : 'unset' }} ref={trackInitialRenderRef}>
            {elemVisibleOnload &&
              <JobRolesPanel
                setDisplaySearchBar={setDisplaySearchBar}
                refSeeAllJobsButton={refSeeAllJobsButton}
                displayAllJobs={displayAllJobs}
                setDisplayAllJobs={setDisplayAllJobs}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                roles_list={roles_list}
                location={location.state}
                modeOfWork={modeOfWork}
                lat={latLong.lat}
                long={latLong.long}
                // executeScroll={executeScroll}
                setStartedTyping={setStartedTyping}
                showRoleSearchState={showRoleSearchState}
                showDefaultJobsPageState={showDefaultJobsPageState}
                started_typing={started_typing}
              />
            }
          </div>



          {/* Top rated companies */}
          {elemVisibleOnload && !started_typing &&
            <TopRatedCompanies></TopRatedCompanies>
          }

          {/* Companies by Cities */}
          {elemVisibleOnload && !started_typing &&
            <CompaniesByCities
            // isSignedIn={isSignedIn}
            // handleDrawer={handleDrawer}
            >
            </CompaniesByCities>
          }

          {/* About Us */}
          {elemVisibleOnload && !started_typing && <div className={classes.lowerSectionDiv}>
            <Typography variant="h1" color="primary" className={classes.panelHeading}>
              {"About us"}
            </Typography>
            <Grid container justifyContent='space-between' alignItems='center' >
              <Grid item sm={6}>
                <Typography variant='body1' style={{ fontSize: 16 }}>
                  Meet is a job search portal incubated at Medha that enables Diploma and Graduate freshers passed out from government, aided and private colleges from Tier II and III cities to connect with verified job opportunities in their area, in pursuit of their dream career.
                  <br />
                  <br />
                  The platform caters to Hinglish (Hindi and English) speaking jobseekers of North India looking for full time, part time, work from home and freelance opportunities. Employers can post job listings for free on Meet job portal that ensures quality of employers who are listed on the platform through their ratings and reviews.
                </Typography>
              </Grid>
              <Grid container item sm={6}>
                <img src={aboutImg} alt='meetworks' className={classes.aboutUsImg} />
              </Grid>

            </Grid>

          </div>}

          <div ref={trackInitialRenderRefForLatLong} style={{ display: 'none' }}></div>

        </div>

        {/* Footer */}
        {elemVisibleOnload && !started_typing &&
          <Footer />}

        <BottomAppBar
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          handleDrawer={handleDrawer}
          handleSettingsDrawer={handleSettingsDrawer}
          navValue={navValue}
          setNavValue={setNavValue}
          showDefaultJobsPageState={showDefaultJobsPageState}
        >
        </BottomAppBar>

        {/* <SignInUpDrawer
          canDrawerOpen={canDrawerOpen}
          refresh={refresh}
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          setForRefreshOnly={setForRefreshOnly}
          setCanDrawerOpen={setCanDrawerOpen}
          doRefresh={doRefresh}
        />

        <LogoutDrawer
          canDrawerOpen={canSettingsDrawerOpen}
          refresh={refreshSettings}
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          setForRefreshOnly={setForRefreshSettingsOnly}
          setCanDrawerOpen={setCanSettingsDrawerOpen}
          doRefresh={doRefreshSettings}
          handleSnackBar={handleSnackBar}
        />

        <SnackBarGeneric id="snackBarMessage" canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} /> */}

        {/* Notification starts */}
        <Dialog
          open={openNotificationDialog}
          onClose={closeNotificationDialog}
          fullScreen
        >
          <DialogContent classes={{ root: classes.notificationDialogRoot }}>
            <Grid style={{ height: 60, position: 'fixed', top: 0, left: 0, backgroundColor: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.17)' }} alignItems='center' container>
              <ArrowBackIcon style={{ margin: '0 20px 0 10px' }} onClick={() => setOpenNotificationDialog(false)} />
              <Typography variant='body1' >Notifications</Typography>
            </Grid>
            <Notifications classes={classes} localIsLoggedIn={isSignedIn} setNotificationBadgeCount={setNotificationBadgeCount} setNotificationBar={setOpenNotificationDialog} variant='mobile' />
          </DialogContent>
        </Dialog>

      </div>

    </React.Fragment>
  )
}