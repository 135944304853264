import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import PersonIcon from '@material-ui/icons/Person';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import InsertEmoticonOutlinedIcon from '@material-ui/icons/InsertEmoticonOutlined';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';

import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Compressor from 'compressorjs';

import ApiHandler, { uploadProfilePic } from '../../ApiHandler';

let jobseekerID = localStorage.getItem("jobseeker_id")

const useStyles = makeStyles((theme) => ({
    imgPlaceholder: {
        width: '100%',
        height: '100%',
        padding: 33,
        backgroundColor: theme.palette.common.greyLight,
        border: '4px solid white',
        borderRadius: 6,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
    },
    animateImgPlaceholder: {
        animation: 'fadeInOut 1s infinite'
    },
    photoTitle: {
        marginTop: 40,
        marginBottom: 20,
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
          },
    },
    imgWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 144,
        height: 194,
        overflow: 'hidden',
        margin: 0,
        borderRadius: 6,
        border: '1px solid #E5E5E5',
        '&.animate': {
            animation: 'fadeInOut 3s infinite'
        },
        '&>img': {
            transition: 'opacity 0.25s ease-in-out',
            pointerEvents: 'none',
        },
    },
    img: {
        margin: '0 auto',
        display: 'block',
        width: 'auto',
        height: '100%',
        maxWidth: 'unset',
        //position: 'absolute',
    },
    imgUploaderBtn: {
        width: 46,
        height: 46,
        background: '#FFFFFF',
        boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.14)',
        borderRadius: '50%',
    },
    imgDeleteBtn: {
        width: 30,
        height: 30,
        background: '#FFFFFF',
        position: 'absolute',
        boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.14)',
        borderRadius: '50%',
        left: 15,
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 4,
        color: theme.palette.common.greyMedium,
    },
    button: {
        textTransform: "none",
        width: 140,
    },
    button_pri: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    button_sec: {
        backgroundColor: '#fff',
        color: theme.palette.primary.dark,
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    list: {
        '& > li': {
            marginBottom: 10,
        }
    },
    listItemColor: {
        '& > li': {
            color: theme.palette.common.blackDark
        }
    }
}));

export default function CameraCustom(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [dataUri, setDataUri] = useState('');
    const [isTipsPageOpen, setIsTipsPageOpen] = useState(false);
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const isFullscreen = true;
    const [galleryImg, setGalleryImg] = useState(props.profilePhoto ? props.profilePhoto : "");
    const [galleryImgPrev, setGalleryImgPrev] = useState(props.profilePhoto ? props.profilePhoto : "");
    const [isImgLoading, setIsImgLoading] = useState(props.profilePhoto ? true : false);
    const [isCameraPhotoApprovalOpen, setIsCameraPhotoApprovalOpen] = useState(false);
    const [compressedFile, setCompressedFile] = useState(null);
    const handleProfileImage = (event) => {
        let imageWidth = event.target.width;
        let imageHeight = event.target.height;

        if (imageWidth / imageHeight <= 0.75) {
            //event.target.style.height = (300 * (190.9 / imageWidth)) + "px"
            event.target.style.height = 'auto';
            event.target.style.width = '100%';
        }
        setIsImgLoading(false);
        event.target.style.opacity = 1
        //event.target.closest("#leftSectionId").style.backgroundColor = "rgba(0,0,0,0)";
    }

    //dialog box for delete--starts
    const [isConfDialogOpen, setIsConfDialogOpen] = React.useState(false);

    const handleConfDialogOpen = () => {
        setIsConfDialogOpen(true);
    }

    const handleConfDialogClose = () => {
        setIsConfDialogOpen(false);
    }
    //dialog box for delete--ends

    const openPhotoUploadOptions = () => {
        setIsTipsPageOpen(true);
    }

    const handleOpenCamera = () => {
        setIsCameraOpen(true);
        setIsTipsPageOpen(false);
    }

    function handleCameraError(error) {
        console.log('handleCameraError', error);
        setIsCameraOpen(false);
        props.handleSnackBar("You need to enable Camera access permission from your Browser settings.")
    }

    const handleCloseTipsPageAfterFileSelect = (img) => {
        setIsTipsPageOpen(false);
        setDataUri('');
    }

    const handleTakePhotoAnimationDone = (dataUri) => {
        setDataUri(dataUri);
        props.setProfilePhoto(dataUri);
        setIsCameraOpen(false);
        //setIsCameraPhotoApprovalOpen(true);
    }

    // const supports = navigator.mediaDevices.getSupportedConstraints();

    // React.useEffect(() => {
    //     console.log(1)
    //     if (supports['facingMode']) {
    //         console.log('This browser supports facingMode!');
    //     } else { console.log("does not support") }
    // }, [])

    const uploadPic = (blobFile) => {
        var formData = new FormData();
        formData.append('user_image', blobFile);
        formData.append('jobseeker_id', jobseekerID);
        document.getElementById('imgWrapper').classList.add('animate');
        setIsCameraPhotoApprovalOpen(false);

        ApiHandler.uploadProfilePic(formData, (res) => {
            if (res.status == 200) {
                console.log(res.data.image_url);
                setGalleryImgPrev(res.data.image_url);
                props.handleSnackBar("Profile photo uploaded successfully")
                document.getElementById('imgWrapper').classList.remove('animate');
            } else { // in case upload fails clear set image to prev and remove fade effect
                // console.log(galleryImgPrev);
                setGalleryImg(galleryImgPrev);
                setDataUri("");
                props.setProfilePhoto(galleryImgPrev);
                props.handleSnackBar("Please try to upload a photo having less than 9MB file size.");
                document.getElementById('imgWrapper').classList.remove('animate');
            }
        })
    }

    const deletePic = () => {
        let payload = {
            jobseeker_id: jobseekerID,
        }
        ApiHandler.deleteProfilePic(payload, (res) => {
            if (res.status == 200) {
                //console.log(res);
                setGalleryImg("");
                setGalleryImgPrev("");
                setDataUri("");
                handleConfDialogClose();
                props.setProfilePhoto("");
                props.handleSnackBar("Profile photo deleted successfully")
            } else {
                props.handleSnackBar("Oops something went wrong.")
            }
        })
    }

    const [donotUploadPicRefresh, setDonotUploadPicRefresh ] = React.useState(false);

    const donotUploadPic = () => {
        setDonotUploadPicRefresh(prev => !prev)
        setGalleryImg(galleryImgPrev);
        setDataUri("");
        props.setProfilePhoto(galleryImgPrev);
        // setIsCameraPhotoApprovalOpen(false);
    }

    React.useEffect(()=>{
        setIsCameraPhotoApprovalOpen(false);
    }, [donotUploadPicRefresh])

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();

            //new
            setGalleryImg(""); //to show placeholder before the new selected image gets loaded
            //end of new

            reader.addEventListener('load', () => {
                //console.log(reader.result);
                setGalleryImg(reader.result);
                props.setProfilePhoto(reader.result);
            });

            setIsCameraPhotoApprovalOpen(true);

            handleCloseTipsPageAfterFileSelect();

            var blobFile = e.target.files[0];

            // new change
            new Compressor(blobFile, {
                quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {
                    // compressedResult has the compressed file.
                    // Use the compressed file to upload the images to your server.   
                    console.log(compressedResult)
                    setCompressedFile(compressedResult);
                    reader.readAsDataURL(compressedResult);
                    //document.getElementById('imgWrapper').classList.add('animate');
                    setIsCameraPhotoApprovalOpen(true);
                    //uploadPic(compressedResult);
                },
            });
            //end of new change
        }
    };

    // React.useEffect(() => {
    //     if (galleryImg) {
    //         handleCloseTipsPageAfterFileSelect();
    //     }
    //     //console.log("galleryImg" + galleryImg)
    // }, [galleryImg])

    React.useEffect(() => {
        if (props.profilePhoto) {
            setGalleryImg(props.profilePhoto)
        }
        //console.log("galleryImg" + galleryImg)
    }, [props.profilePhoto])

    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    React.useEffect(() => {
        if (dataUri) {
            var blobFile = dataURLtoBlob(dataUri);
            uploadPic(blobFile);
        }
        //console.log("dataUri" + dataUri)
    }, [dataUri])

    React.useEffect(() => { // to refresh jobseekerID on sign in
        jobseekerID = localStorage.getItem("jobseeker_id")
    }, [props.isSignedIn])

    return (
        <Grid
            item 
            xs={12} md={6}
            container
            justifyContent="flex-start"
            alignItems="center"
            direction="column"
            style={{ position: 'relative', paddingTop: 10 }}
        >
            <Typography variant="h3" color="primary" className={classes.photoTitle} align="center">
                {(!dataUri && !galleryImg) ?
                    `Start करते हैं profile photo से`
                    : `Profile Photo`
                }
            </Typography>

            <div className={classes.imgWrapper} style={{ width: 248, height: 331 }} id="imgWrapper">
                {(dataUri) ? <img className={classes.img} src={dataUri} id="my-image" style={{ opacity: 0 }} onLoad={handleProfileImage} /> :
                    (galleryImg) ? <img className={classes.img} src={galleryImg} id="my-image" style={{ opacity: 0 }} onLoad={handleProfileImage} /> :
                        <div className={classes.imgPlaceholder}>
                            <Typography variant="h3" align="center" style={{ color: theme.palette.common.grey }} >मेरी Picture</Typography>
                            <InsertEmoticonOutlinedIcon style={{ color: theme.palette.common.grey, fontSize: '3rem' }} />
                        </div>
                }
                {isImgLoading && <CircularProgress color="primary" style={{ position: 'absolute' }} />}
            </div>
            {!isImgLoading &&
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ position: 'relative', marginTop: '-26px', width: 248 }}
                >
                    <IconButton
                        className={classes.imgUploaderBtn}
                        id="profilePhotoUploadBtn_id"
                        onClick={openPhotoUploadOptions}>
                        <AddAPhotoRoundedIcon color="primary" />
                    </IconButton>
                    {(dataUri || galleryImg) &&
                        <IconButton
                            className={classes.imgDeleteBtn}
                            id="profilePhotoDeleteBtn_id"
                            onClick={handleConfDialogOpen}>
                            <DeleteForeverRoundedIcon style={{ color: theme.palette.common.redDark }} />
                        </IconButton>
                    }
                </Grid>
            }

            {(isCameraOpen) ?
                <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 9999, width: '100%', height: '100vh', background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Camera
                        onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                        onCameraError={(error) => { handleCameraError(error); }}
                        imageType={IMAGE_TYPES.JPG}
                        isFullscreen={isFullscreen}
                        isSilentMode={true}
                        isMaxResolution={true}
                        imageCompression={0.6}
                        idealFacingMode={FACING_MODES.USER}//selfie mode
                        isImageMirror={false}
                        //isDisplayStartCameraError={true}
                        // idealResolution={{ width: 360, height: 640 }}
                        // idealResolution={{ width: 50, height: 50 * 4 / 3 }}
                        sizeFactor={1}
                    />
                </div>
                : (isTipsPageOpen) ?
                    <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 9999, width: '100%', height: '100vh', background: 'white', padding: '50px 23px' }}>
                        <Grid
                            container
                            id="tipsPage_id"
                            direction="column"
                            alignItems="center">
                            <IconButton
                                edge="start"
                                aria-label="back button"
                                style={{ position: "fixed", top: 0, left: 18 }}
                                onClick={() => setIsTipsPageOpen(false)}
                            >
                                <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
                            </IconButton>
                            {/* <div className={classes.imgWrapper} style={{ width: 248, height: 331 }}>
                                <div className={classes.imgPlaceholder}>
                                    <PersonIcon style={{ color: theme.palette.common.white, fontSize: '17rem' }} />
                                </div>
                            </div> */}
                            <Typography variant="h3" color="primary" style={{
                                //  marginTop: 30, 
                                marginBottom: 20
                            }} align="center">Tips for Profile Photo</Typography>
                            <ul className={`${classes.list} ${classes.listItemColor}`} style={{ marginTop: 10, marginBottom: 20 }}>
                                <li><Typography variant="h3">Mobile को  portrait mode 📱 में रखकर photo लें
                                </Typography></li>
                                <li><Typography variant="h3">Professional 👩‍💼 दिखें
                                    (sunglasses 😎 या colorful 🏳️‍🌈 कपड़े avoid करें )
                                </Typography></li>
                                <li><Typography variant="h3">
                                    Photo धुँधली ना हो
                                </Typography></li>
                                <li><Typography variant="h3">
                                    सिर्फ face 👨‍💼 include करें
                                </Typography></li>
                                <li><Typography variant="h3">
                                    Face पे confident smile 🙂 रखें
                                </Typography></li>
                                <li><Typography variant="h3">
                                    Background plain रखें
                                </Typography></li>
                            </ul>
                            <Grid item container justifyContent="center" style={{ position: "fixed", bottom: 40 }}>
                                {/* <Button
                                    size="medium"
                                    style={{ backgroundColor: 'white', color: theme.palette.primary.dark, border: '1px solid', borderColor: theme.palette.primary.dark, textTransform: 'capitalize', width: 'auto', height: 36, margin: '0 10px' }}
                                    onClick={handleOpenCamera}
                                >Camera से खींचे </Button> */}
                                <Button
                                    size="medium"
                                    style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, textTransform: 'none', width: 'auto', height: 36, margin: '0 10px' }}
                                >
                                    <input id="galleryImageUploadBtn_id" type="file" accept="image/*" onChange={onSelectFile} hidden />
                                    <label htmlFor="galleryImageUploadBtn_id">
                                        {/* Gallery से upload करें */}
                                        Photo खींचे/upload करें
                                    </label>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    : (isCameraPhotoApprovalOpen) ?
                        <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 9999, width: '100%', height: '100vh', background: 'white', padding: '50px 23px' }}>
                            <Grid
                                container
                                direction="column"
                                alignItems="center">
                                <div className={classes.imgWrapper} style={{ width: 248, height: 331 }}>
                                    {(galleryImg) ?
                                        <img src={galleryImg} className={classes.img} style={{ opacity: 0 }} onLoad={handleProfileImage} />
                                        : <div className={`${classes.imgPlaceholder} ${classes.animateImgPlaceholder}`}>
                                            <PersonIcon style={{ color: theme.palette.common.white, fontSize: '17rem' }} />
                                        </div>
                                    }
                                </div>

                                <Grid
                                    item
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ position: 'relative', marginTop: '-26px', width: 248 }}
                                >
                                    <IconButton
                                        className={classes.imgUploaderBtn}
                                        id="profilePhotoUploadBtn_id"
                                    // onClick={openPhotoUploadOptions}
                                    >
                                        {/* <AddAPhotoRoundedIcon color="primary" /> */}

                                        <input id="galleryImageUploadBtn2_id" type="file" accept="image/*" onChange={onSelectFile} hidden />
                                        <label htmlFor="galleryImageUploadBtn2_id">
                                            {/* Gallery से upload करें */}
                                            <RotateLeftRoundedIcon color="primary" />
                                        </label>
                                    </IconButton>
                                </Grid>

                                <Grid item container
                                    justifyContent="space-between"
                                    style={{ position: "fixed", bottom: 40, width: '248px', }}>
                                    <Button
                                        size="medium"
                                        style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, border: '1px solid', borderColor: theme.palette.primary.dark, textTransform: 'capitalize', width: 'auto', height: 36, margin: '0' }}
                                        onClick={donotUploadPic}
                                    >
                                        <CloseRoundedIcon />
                                    </Button>
                                    <Button
                                        size="medium"
                                        style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, border: '1px solid', borderColor: theme.palette.primary.dark, textTransform: 'capitalize', width: 'auto', height: 36, margin: '0' }}
                                        onClick={() => { uploadPic(compressedFile) }}
                                    >
                                        <CheckRoundedIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        : null
            }

            {/* Confirmation Dialog box */}
            <Dialog onClose={handleConfDialogClose} aria-labelledby="delete-image-confirmation" open={isConfDialogOpen} maxWidth={'sm'}>

                <IconButton aria-label="close" className={classes.closeButton} onClick={handleConfDialogClose}>
                    <CloseRoundedIcon />
                </IconButton>

                <DialogContent dividers style={{ paddingTop: 50 }}>
                    <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5', textAlign: 'center' }}>
                        Are you sure you want to delete your profile photo?
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        color="primary"
                        size="medium"
                        className={`${classes.button} ${classes.button_sec}`}
                        onClick={deletePic}
                    >
                        Yes
                    </Button>

                    <Button autoFocus
                        color="primary"
                        size="medium"
                        className={`${classes.button} ${classes.button_pri}`}
                        onClick={handleConfDialogClose}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

        </Grid>
    )
}