import React from 'react';
import axios from "axios";

function MediaRecorderCustom() {
    const [selectedMedia, setSelectedMedia] = React.useState("audio");
    let constraints =
        selectedMedia === "video" ? {
            audio: true,
            video: true
        } : selectedMedia === "audio" ? {
            audio: true,
            video: false
        } : null;

    // This array stores the recorded media data
    let chunks = [];

    const webCamContainer = document.getElementById("web-cam-container");

    const startRecording = () => {

        // audioContext = new window.AudioContext();
        // console.log("sample rate: " + audioContext.sampleRate);

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function (mediaStream) {
                console.log("initializing Recorder ...")

                // Create a new MediaRecorder 
                // instance that records the 
                // received mediaStream
                let mediaRecorder = new MediaRecorder(mediaStream);

                // Make the mediaStream global
                window.MediaStream = mediaStream;

                // Make the mediaRecorder global
                // New line of code
                window.MediaRecorder = mediaRecorder;

                mediaRecorder.start();

                // Whenever (here when the recorder
                // stops recording) data is available
                // the MediaRecorder emits a "dataavailable" 
                // event with the recorded media data.
                mediaRecorder.ondataavailable = (e) => {
                    // Push the recorded media data to
                    // the chunks array
                    chunks.push(e.data);
                };

                // When the MediaRecorder stops
                // recording, it emits "stop"
                // event
                mediaRecorder.onstop = () => {
                    console.log("onstop")
                   
                    /* A Blob is a File like object.
                    In fact, the File interface is 
                    based on Blob. File inherits the 
                    Blob interface and expands it to
                    support the files on the user's 
                    systemThe Blob constructor takes 
                    the chunk of media data as the 
                    first parameter and constructs 
                    a Blob of the type given as the 
                    second parameter*/
                    const blob = new Blob(
                        chunks, {
                        type: selectedMedia === "video" ?
                            "video/mp4" : "audio/mpeg"
                    });
                    
                    chunks = [];

                    console.log(blob)
                    // Create a video or audio element
                    // that stores the recorded media
                    const recordedMedia = document.createElement(
                        selectedMedia === "video" ? "video" : "audio");
                    recordedMedia.controls = true;

                    // You can not directly set the blob as 
                    // the source of the video or audio element
                    // Instead, you need to create a URL for blob
                    // using URL.createObjectURL() method.
                    const recordedMediaURL = URL.createObjectURL(blob);

                    // Now you can use the created URL as the
                    // source of the video or audio element
                    recordedMedia.src = recordedMediaURL;

                    // Create a download button that lets the 
                    // user download the recorded media
                    // const downloadButton = document.createElement("a");
                    // downloadButton.download = "Recorded-Media";
                    // downloadButton.href = recordedMediaURL;
                    // downloadButton.innerText = "Download it!";

                    // downloadButton.onclick = () => {
                    //     /* After download revoke the created URL
                    //     using URL.revokeObjectURL() method to 
                    //     avoid possible memory leak. Though, 
                    //     the browser automatically revokes the 
                    //     created URL when the document is unloaded,
                    //     but still it is good to revoke the created 
                    //     URLs */
                    //     URL.revokeObjectURL(recordedMedia);
                    // };

                    // document.getElementById(
                    //     `${selectedMedia}-recorder`).append(
                    //         recordedMedia, downloadButton);

                    document.getElementById(
                        `${selectedMedia}-recorder`).append(
                            recordedMedia);
                };

                if (selectedMedia === 'video') {
                    // Remember to use the "srcObject" 
                    // attribute since the "src" attribute 
                    // doesn't support media stream as a value
                    webCamContainer.srcObject = mediaStream;
                }

                // let input = audioContext.createMediaStreamSource(stream);
                // recorder = new window.Recorder(input, {
                //     numChannels: 1
                // })
                // recorder.record();
                // console.log("Recording started");

            }).catch(function (err) {
                console.log(err)
                //enable the record button if getUserMedia() fails
            });

    }

    const stopRecording = () => {
        console.log("stopButton clicked");

        // Stop the recording
        window.MediaRecorder.stop();

        // Stop all the tracks in the received 
        // media stream i.e. close the camera
        // and microphone
        window.MediaStream.getTracks().forEach(track => {
            track.stop();
        });

        // recorder.stop(); //stop microphone access
        // gumStream.getAudioTracks()[0].stop();

        // recorder.exportWAV(onStop);
    }

    const onStop = (blob) => {
        console.log("uploading...");

        let data = new FormData();

        data.append('text', "this is the transcription of the audio file");
        data.append('wavfile', blob, "recording.wav");

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post('http://localhost:8080/asr/', data, config);
    }

    return (
        <div>

            <video controls autoPlay id="web-cam-container" width="200px" style={{ background: "black" }}>
                Your browser doesn't support
                the video tag
            </video>

            <div id="video-recorder"></div>
            <div id="audio-recorder"></div>

            <button onClick={startRecording} type="button">Start</button>
            <button onClick={stopRecording} type="button">Stop</button>
        </div>
    );
}

export default MediaRecorderCustom;