// import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 74,
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  logo: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  tabBar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.14)',
  },
  tabBarSecondary: {
    top: 60,
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.greyLight,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      top: 48,
    },
  },
  navTabBar: {
    flexGrow: 1,
  },
  navTab: {
    textTransform: 'capitalize',
    padding: 0,
    margin: ' 0 15px',
    minHeight: 50,
    minWidth: 100,
    color: theme.palette.common.grey,
    ...theme.typography.h3,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      minHeight: 50,
      minWidth: 'auto',
    },
  },
  navTabBarSecondary: {
    minHeight: 40,
    width: '100%'
  },
  navTabSecondary: {
    textTransform: 'capitalize',
    padding: '0 10px',
    // margin: ' 0 10px',
    minHeight: 40,
    minWidth: 102,
    color: theme.palette.common.grey,
    ...theme.typography.body1,
    [theme.breakpoints.down('md')]: {
      minHeight: 40,
      minWidth: 60,
    },
  },
  indicator: {
    height: 2,
    left: 25
  },
  flexContainer: {
    justifyContent: 'space-evenly'
  },
  indicatorLess: {
    backgroundColor: theme.palette.common.greyLight,
  },
  labelIcon: {
    fontWeight: 400,
    color: "#AFAFAF",
    [theme.breakpoints.down('md')]: {
      color: theme.palette.grey[600],
      marginBottom: '0!important',
      fontSize: 12,
      '& .MuiTab-wrapper > *:first-child': {
        marginBottom: 0
      }
    },
  },
  selected: {
    fontWeight: 500,
    color: theme.palette.common.blackDark,
  },
  appBar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.16)',
    top: 'auto',
    bottom: 0,
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em'
  },
  buttonRed: {
    color: theme.palette.common.redDark
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 20,
  },
  pageButton_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  button: {
    textTransform: "none",
    width: 140,
  },
  button_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  button_sec: {
    backgroundColor: '#fff',
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  pageHeading: {
    width: '100%',
    background: 'white',
    [theme.breakpoints.up('md')]: {
      margin: '0 auto'
    },
  },
  pageHeadingEditSec: {
    [theme.breakpoints.up('md')]: {
      width: '60%'
    },
  },
  sectionContainer: {
    backgroundColor: theme.palette.primary.contrastText,
    width: '95%',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    margin: '5px auto',
    padding: '0px 10px 10px 10px',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },

  },
  sectionMain: {
    width: '100%',
    margin: '0 auto'
  },
  section: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: '5px 20px 5px',
    '&:last-child': {
      padding: '5px 20px 70px',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
      margin: '0 auto'
    },
  },
  sectionItem: {
    padding: '10px 0px',
    // borderBottom: '1px solid',
    // borderBottomColor: theme.palette.grey[100],
    '&:last-child': {
      border: 'none',
    }
  },
  sectionItem_heading: {
    flexWrap: 'nowrap',
    marginBottom: '20px'
  },
  sectionItem_heading_icon: {
    marginRight: 5,
  },
  sectionItem_heading_icon2: {
    marginRight: 10,
    color: theme.palette.common.grey,
  },
  sectionItem_content: {
    flexWrap: 'wrap',
  },
  sectionItem_rating: {
    flexWrap: 'wrap',
    margin: '10px 0',
    '&:last-child': {
      marginBottom: 0,
    }
  },
  sectionItem_text_button: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  tileRatingText: {
    ...theme.typography.tileRatingText,
  },
  tileRatingStar: {
    fontSize: '1.2rem',
    color: theme.palette.common.star,
    marginRight: 4,
  },
  imageTag: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: "none",
  },
  TfRoot: {
    width: '100%',
    marginBottom: 30,
    //background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  TfRootSimple: {
    width: '100%',
    marginBottom: 30,
    background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  textBefore: {
    '&:before': {
      content: '"per"',
      position: 'absolute',
      top: '31%',
      left: '-32px',
    }
  },
  inputRootAc: {
    padding: '0 9px!important',
    background: theme.palette.common.greyLight,
  },
  tfLabel: {
    ...theme.typography.body1,
    color: theme.palette.common.blackDark,
    marginBottom: 12,
  },
  tfLabelDark: {
    ...theme.typography.body1,
    color: theme.palette.common.blackDark,
    marginBottom: 8,
    fontWeight: 500
  },
  datePickerInput: {
    '& .MuiInputBase-input': {
      height: '3.429em',
      width: '100%',
      borderRadius: 4,
      background: theme.palette.common.greyLight,
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  selectRoot: {
    width: '100%',
    marginBottom: 30,

    borderRadius: 25,
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 15px',
      background: theme.palette.common.greyLight,
      borderRadius: 25,
    },
    '& .MuiSelect-select:focus': {
      borderRadius: 25,
    },
    '& input': {
      height: 48,
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  fabBtnWrapper: {
    position: 'fixed',
    right: 0,
    width: 'auto',
    bottom: 70,
    paddingRight: 23,
    zIndex: 99,
    '&.animate': {
      animation: 'shakeAndDeny 0.25s ease-in-out 2'
    }
  },
  fabDisabled: {
    minWidth: 70,
    height: 32,
    textTransform: 'capitalize',
    background: "white",
    border: "1px solid",
    // borderColor: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
  },
  fabActive: {
    minWidth: 70,
    height: 32,
    textTransform: 'capitalize',
    background: theme.palette.primary.dark,
    color: "white",
  },
  disabledFabBtn: {
    backgroundColor: "#e0e0e0!important",
  },
  busyFabBtn: {
    backgroundColor: theme.palette.primary.light + "!important",
    color: theme.palette.primary.main + "!important",
  },
  tfRoot2: {
    width: '100%',
    marginBottom: 30,
    borderRadius: 4,
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 15px',
      background: theme.palette.common.greyLight,
      borderRadius: 4,
    },
    '& .MuiSelect-select:focus': {
      borderRadius: 4,
    },
    '& input': {
      height: 48,
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  tfRoot2_withPL: {
    width: '100%',
    paddingLeft: 32,
    marginBottom: 0,
    borderRadius: 4,
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 15px',
      background: theme.palette.common.greyLight,
      borderRadius: 4,
    },
    '& .MuiSelect-select:focus': {
      borderRadius: 4,
    },
    '& input': {
      height: 48,
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  popperAC: {
    zIndex: 99999
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 4,
    color: theme.palette.common.greyMedium,
  },
  bottomBarMobile: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  imgPlaceholder: {
    width: '100%',
    height: '100%',
    padding: 33,
    backgroundColor: theme.palette.common.greyLight,
    border: '4px solid white',
    borderRadius: 6,
    display: 'flex',
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  animateImgPlaceholder: {
    animation: 'fadeInOut 1s infinite'
  },
  photoTitle: {
    marginTop: 40,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 144,
    height: 194,
    overflow: 'hidden',
    margin: 0,
    borderRadius: 6,
    border: '1px solid #E5E5E5',
    '&.animate': {
      animation: 'fadeInOut 3s infinite'
    },
    '&>img': {
      transition: 'opacity 0.25s ease-in-out',
      pointerEvents: 'none',
    },
  },
  img: {
    margin: '0 auto',
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: 'unset',
    //position: 'absolute',
  },
  imgUploaderBtn: {
    width: 46,
    height: 46,
    background: '#FFFFFF',
    boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.14)',
    borderRadius: '50%',
  },
  imgDeleteBtn: {
    width: 30,
    height: 30,
    background: '#FFFFFF',
    position: 'absolute',
    boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.14)',
    borderRadius: '50%',
    left: 15,
  },
  list: {
    '& > li': {
      marginBottom: 10,
    }
  },
  listItemColor: {
    '& > li': {
      color: theme.palette.common.blackDark
    }
  },
  mobileNotificationIcon: {
    position: 'absolute',
    top: 0,
    right: 43,
    zIndex: 9,
    padding: '12px 0'
  },
  favCompDiv: {
    margin: '120px auto 100px auto', 
    width: '80%',
    [theme.breakpoints.down(600)]: {
      width: '100%'
    },
  },
  favCompContainer: {
    backgroundColor: '#fff', 
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)', 
    borderRadius: 4, 
    width: '95%',
    margin: '13px auto',
    [theme.breakpoints.up('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px 10px 20px 10px',
      flexBasis: '47',
      maxWidth: '47%',
    },
  },
  imageTag: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: "none",
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 82,
    overflow: 'hidden',
    margin: 0,
    padding: 2
  },
  notificationDialogRoot: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0
    }
  },
}));

