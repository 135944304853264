import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FormControl, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import SearchIcon from '@material-ui/icons/Search';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { SearchBarJobs, SearchBarJobsAutocomplete } from './SearchBarJobs';
import TopAppBar from '../../components/TopAppBar';
import BottomAppBar from '../../components/BottomAppBar';

import '../../style/App.css';
import SignInUpDrawer from '../../components/SignInDrawer';
import ApiHandler from '../../ApiHandler';
import JobsListItem from './JobsListItem';
import ListLoader from '../errorsAndLoaders/ListLoader';
import InfiniteScroll from "react-infinite-scroll-component";
import SnackBarGeneric from './SnackBarGeneric';

import LogoLoader from '../../assets/logo-loader.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '10%',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 60,
    },
  },
  pageBar: {
    //width: 860,
    width: '100%',
    padding: '8px 43px',
    flexBasis: 50,
    backgroundColor: theme.palette.common.greyLight,
    marginTop: 0,
    zIndex: 8,
    transition: '0.2s',
    position: 'sticky',
    top: 59,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      top: 0,
      padding: 0
    },
    [theme.breakpoints.up('md')]: {
      display: "none",
    },
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em',
    fontSize: '1rem'
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 20,
  },
  pageButton_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  listRoot: {
    width: '100%',
    padding: '0 43px 30px',
    marginBottom: 0,
    '& .MuiListItem-root': {
      padding: 0,
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      marginBottom: 10,
      overflow: 'hidden',
      boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 10px 30px',
    },
  },
  panelHeadingSticky: {
    margin: 0,
    padding: '20px 43px 12px',
    fontSize: 18,
    position: 'sticky',
    top: 60,
    background: theme.palette.common.greyLight,
    color: theme.palette.common.blackDark,
    zIndex: 9,
    [theme.breakpoints.down('md')]: {
      padding: '6px 10px 6px',
      top: 48,
    },
  },
  panelHeading: {
    marginBottom: 8,
    padding: '0 10px',
    fontSize: 18,
    color: theme.palette.common.blackDark,
  },
  searchStr: {
    color: theme.palette.common.blackDark,
    textTransform: 'italic'
  },
  rolesPanelPostTyping: {
    padding: '0px 10px 60px',
  },
  rolesPanel: {
    padding: '20px 43px 6px',
    [theme.breakpoints.down('md')]: {
      padding: '15px 10px 20px',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  footerIcon: {
    padding: 10,
    opacity: '0.7'
  },
  backBtn: {
    position: 'fixed', left: 10, top: '10%', zIndex: 9
  },
  filterSidebarWrapper: {
    width: '28vw',
    height: '86vh',
    position: 'fixed',
    paddingTop: 10,
    left: '6%',
    top: '12%',
    backgroundColor: '#fff',
    borderRadius: '5px',
    zIndex: 99,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    [theme.breakpoints.down('md')]: {
      left: '5.5%',
    },
    [theme.breakpoints.down('xs')]: {
      top: '7%',
      left: 0,
      width: '100vw',
      display: 'none',
      '&.open': {
        display: 'block'
      }
    },
  },
  filterSidebar: {
    height: '72vh',
    padding: '1em',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '63vh',
    },
  },
  filterTypeText: {
    color: '#282828',
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0.1em'
  },
  filterBadge: {
    width: 8, height: 8, borderRadius: '50%', background: theme.palette.common.red,
    position: 'absolute', left: '-2px'
  },
  searchBarJobsOuterWrapper: {
    marginTop: 0,
    // width: '80vw'
  },
  searchBarWrapper: {
    display: "flex",
    flexDirection: "row",
    background: "white",
    width: 400,
    height: 40,
    // paddingLeft: 10,
    // paddingRight: 5,
    borderRadius: 4,
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    "&.shrink": {
      height: 40,
    },
    [theme.breakpoints.down('md')]: {
      width: '98%',
      height: 36,
    },
  },
  searchBarIconWrapper: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
    height: '100%',
    width: 45,
    minWidth: 25,
    textTransform: "none",
    color: theme.palette.primary.main,
    fontSize: 16,
  },
}));

export default function SearchFilterJobs() {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [navValue, setNavValue] = React.useState(0);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);

  const [displayLocationBox, setDisplayLocationBox] = React.useState(true);
  const [displaySearchBar, setDisplaySearchBar] = React.useState(false);
  const [displayAllJobs, setDisplayAllJobs] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState(null);
  const [started_typing, setStartedTyping] = React.useState(false);
  const refSeeAllJobsButton = React.useRef(null);
  const [isLocationDenied, setIsLocationDenied] = React.useState(false);

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);

  document.body.style.overflowY = "scroll";

  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  // default reset function for jobspage
  const showDefaultJobsPageState = () => {
    setDisplayLocationBox(true);
    setDisplaySearchBar(false); // hide the search bar
    setSearchTerm(null) // empty the search box
    setDisplayAllJobs(false); // collapse list of roles
    setStartedTyping(false); // show list of jobs
    executeScroll(); // scroll to top
  }

  const filterBarRef = useRef(null);
  const [showCloseBtn, setShowCloseBtn] = useState(false)
  const openFilterSection = () => {
    setShowCloseBtn(true);
    filterBarRef.current.classList.add('open');
  }
  const closeFilterSection = () => {
    setShowCloseBtn(false);
    filterBarRef.current.classList.remove('open');
  }

  const checkFilters = () => {
    let _isOpportunityTypeFilterApplied = opportunityType.some((item, i) => item.checked === true)
    let _isQualFilterApplied = qualification.some((item, i) => item.checked === true)
    let _isSalaryFilterApplied = salary.some((item, i) => item.checked === true)
    let _isPostedOnFilterApplied = postedOn.some((item, i) => item.checked === true)
    let _isWorkFromHome = isWorkFromHome ? true : false
    let _city = city ? true : false

    let filters = [_isOpportunityTypeFilterApplied, _isQualFilterApplied, _isSalaryFilterApplied, _isPostedOnFilterApplied, _isWorkFromHome, _city]

    let _isFilterApplied = filters.some((item, i) => item === true)
    setIsFilterApplied(_isFilterApplied)
  }

  // get jobs list---> starts
  const [refresh, doRefresh] = React.useState(false);
  const [jobsList, setJobsList] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageOffset, setPageOffset] = React.useState(0);
  const [jobsCount, setJobsCount] = React.useState(null);
  const [vacanciesCount, setVacanciesCount] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);
  const [modeOfWork, setModeOfWork] = React.useState(sessionStorage.getItem("modeOfWork") || "everyWhere");
  const [latLong, setLatLong] = React.useState(location && location.state && location.state.latLong ? location.state.latLong : { lat: 26.8489293, long: 80.802425 });
  const [refreshInfiniteScroll, setRefreshInfiniteScroll] = React.useState(true);
  const [showWFHOption, setShowWFHOption] = React.useState(false);

  const [searchString, setSearchString] = React.useState(location && location.state && location.state.searchQuery ? location.state.searchQuery : "");
  //vars for user selected filters
  const [opportunityType, setOpportunityType] = React.useState("");
  const [qualification, setQualification] = React.useState("");
  const [isWorkFromHome, setIsWorkFromHome] = React.useState("");
  const [city, setCity] = React.useState("");
  const [salary, setSalary] = React.useState("");
  const [postedOn, setPostedOn] = React.useState("");

  const [isFilterApplied, setIsFilterApplied] = React.useState(false);

  // vars for all filters
  const [jobTypeFilters, setJobTypeFilters] = React.useState([]);
  const [qualificationFilters, setQualificationFilters] = React.useState([]);
  const [locationFilters, setLocationFilters] = React.useState([]);
  const [salaryFilters, setSalaryFilters] = React.useState(["less Than 5000", "less Than 15000", "less Than 25000", "more Than 25000"]); //case sensitive values
  const [postedOnFilters, setPostedOnFilters] = React.useState(["last 24 Hours", "last 7 Days", "last 15 Days"]); //case sensitive values

  const [isFirstTimeLoad, setIsFirstTimeLoad] = React.useState(location && location.state && location.state.mobile ? false : true);

  const [triggerClearFilter, setTriggerClearFilter] = React.useState(false);
  const clearFilters = () => {
    createObj(jobTypeFilters, setJobTypeFilters, setOpportunityType);
    createObj(qualificationFilters, setQualificationFilters, setQualification);
    setIsWorkFromHome("");
    setCity("");
    createObj(salaryFilters, setSalaryFilters, setSalary);
    createObj(postedOnFilters, setPostedOnFilters, setPostedOn);

    setCurrentPage(1);
    //setPageOffset(0);
    setHasMore(true);
  }

  // get jobs list with infinitescroll as fetchMoreData is called and setRefreshInfiniteScroll triggers the api call---> starts
  const fetchMoreData = () => {
    let page = 1;
    page = currentPage + 1;
    setCurrentPage(page);
    setPageOffset((page - 1) * 10);
    setRefreshInfiniteScroll(prev => !prev);
  }

  React.useEffect(() => {
    const handleMoreJobsList = () => {
      let payload = {
        limit: 10,
        offset: pageOffset,
        search_text: searchString
      }

      // format the data for filters
      let _opportunityType = formatter(opportunityType);
      let _qualification = formatter(qualification);
      let _salary = formatter(salary);
      let _postedOn = formatter(postedOn)

      let queryParam = "?opportunity_type=" + _opportunityType + "&workFromHome=" + isWorkFromHome + "&city=" + city + "&course_type=" + _qualification + "&job_created_at=" + _postedOn + "&job_salary=" + _salary

      ApiHandler.searchAndFilterJobs(payload, queryParam, response => {
        if (response.status == 200 && response.data[0].results) {
          //console.log(response.data[0].results);
          if (jobsList === null) {
            setJobsList(response.data[0].results);
          }
          else {
            setJobsList(prevState => prevState.concat(response.data[0].results))
            //console.log(jobsList.concat(response.data[0].results));
          }
          setJobsCount(response.data[0].totalCount[0].count);
          setVacanciesCount(response.data[0].total_vacancies[0].count);
        }
        else {
          console.log("error in getting a successful response for the list of jobs")
        }
      })
    }

    if (jobsList && jobsList.length >= jobsCount) {
      setHasMore(false);
    } else if (currentPage > 1) {
      handleMoreJobsList();
    }
  }, [refreshInfiniteScroll])

  // get jobs list on page load---> starts

  const formatter = (filters) => { //convert array to comma seperated string
    let _a = [];
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].checked) _a.push(filters[i].filter_name)
    }
    return _a.join(",")
  }

  const handleJobsList = () => {
    setIsLoading(true);
    setHasMore(true);
    let payload = {
      offset: 0,
      limit: 10,
      search_text: searchString
    }

    // format the data for filters
    let _opportunityType = formatter(opportunityType);
    let _qualification = formatter(qualification);
    let _salary = formatter(salary);
    let _postedOn = formatter(postedOn)

    // set filters in the query parameters
    let queryParam = "?opportunity_type=" + _opportunityType + "&workFromHome=" + isWorkFromHome + "&city=" + city + "&course_type=" + _qualification + "&job_created_at=" + _postedOn + "&job_salary=" + _salary

    ApiHandler.searchAndFilterJobs(payload, queryParam, response => {
      if (response.status == 200) {
        let arr_temp = response.data[0].results;
        setJobsList(arr_temp);
        if (arr_temp.length > 0) {
          setJobsCount(response.data[0].totalCount[0].count);
          setVacanciesCount(response.data[0].total_vacancies[0].count);
          if (response.data[0].totalCount[0].count < 10) {
            setHasMore(false);
          }
        }
        setIsLoading(false);
      }
      else {
        console.log("error in getting a successful response.")
      }
    })

  }

  React.useEffect(() => {
    if (searchString) handleJobsList(); // when searchstring is empty on page load for mobile, this will not run and hence jobList will remain null
  }, [triggerClearFilter])
  // get jobs list on page load and when clear filter is clicked---> ends

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  //sort bar bg effect on scroll starts
  const pageBarRef = React.useRef(null);
  let lastScrollY = 0;
  let scrolling = false;

  const handleScrollChange = () => {
    lastScrollY = window.scrollY;
    if (lastScrollY > 60) {
      scrolling = true
    } else {
      scrolling = false
    }
    if (!scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        //pageBarRef.current.style.background = '#f5f5f5';
        pageBarRef.current.style.boxShadow = "none";
        //pageBarRef.current.style.flexBasis = "50px";
      });
    } else if (scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        //pageBarRef.current.style.background = '#f5f5f5';
        pageBarRef.current.style.boxShadow = "0px 5px 5px rgba(0,0,0,0.05)";
        //pageBarRef.current.style.flexBasis = "50px";
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);
  //sort bar bg effect on scroll ends

  // drawer callback starts
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);

  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const [snackBarMessageSeverity, setSnackBarMessageSeverity] = React.useState("success");
  const handleSnackBar = (message, severity) => {
    if (severity) {
      setSnackBarMessageSeverity(severity);
    } else {
      setSnackBarMessageSeverity("success");
    }
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev);
  }
  // snackbar callback ends

  const createObj = (getFilters, setFilters, setObj) => {
    let arr = getFilters;
    if (arr) {
      setFilters([...arr])
      let _a = []
      for (let i = 0; i < arr.length; i++) {
        _a.push({ ['filter_name']: arr[i], ['checked']: false })
      }
      setObj(_a)
    }
  }
  //Employment Types, course types starts
  useEffect(() => {
    ApiHandler.getEmploymentTypes((Result) => {
      if (Result.status == 200) {
        let res = Result.data.filter((item, index) => item !== "Self-Employed")
        createObj(res, setJobTypeFilters, setOpportunityType)
      }
    })

    ApiHandler.getCourseTypes_V2((Result) => {
      if (Result.status == 200) {
        let arr = Result.data.filter((item, i) => {
          return (item.course_type !== 'Ph.D' && item.course_type !== 'NA')
        });

        if (arr) {
          let _a = []
          let _course_types = []

          for (let i = 0; i < arr.length; i++) {
            _course_types.push(arr[i].course_type)
          }
          setQualificationFilters(_course_types)

          for (let i = 0; i < _course_types.length; i++) {
            _a.push({ ['filter_name']: _course_types[i], ['checked']: false })
          }

          setQualification(_a)
        }
      }
    })

    ApiHandler.getAllLocations((Result) => {
      let arr = Result.data;
      if (arr) {
        let _city = []
        for (let i = 0; i < arr.length; i++) {
          _city.push(arr[i].city)
        }
        setLocationFilters(_city)
      }
    })

    createObj(salaryFilters, setSalaryFilters, setSalary)
    createObj(postedOnFilters, setPostedOnFilters, setPostedOn)

  }, [])
  // Employment Types, course types ends  

  useEffect(() => {
    const close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];

    // Add a Click Event Listener to the clear button
    close.addEventListener("click", () => {
      setCity("")
    });
  }, [])//set city to empty string on click of clear button of autocomplete

  return (
    <React.Fragment>
      <Helmet>
        <title>{"Meeत for jobs | apprenticeships | parttime work opportunities"}</title>
        <meta name="description" content={"Find local job opportunities, internships, and apprenticeships near you with Meet App. Explore a vast database of listings and uncover valuable insights through ratings and reviews from employees in diverse companies. Find the ideal career path that matches your skills and aspirations while making informed decisions about potential employers."} />
      </Helmet>
      <div >

        {/* Top App Bar on Mobile */}
        <AppBar position="fixed" style={{ background: '#fff' }}>
          <Toolbar variant="dense" style={{ padding: 0 }}>
            <div style={{ width: '100%' }}>
              <div className={classes.sectionMobile}>
                <Button
                  style={{ display: 'flex', padding: 0 }}
                  id="meetLogo_id"
                  onClick={() => {
                    history.push({
                      pathname: '/'
                    });
                  }}>
                  <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
                </Button>
                <Grid
                  item xs={8}
                  container
                  justifyContent="center"
                  alignItems='flex-start'>
                  {/* <SearchBarJobs
                    searchTerm={searchString}
                    setSearchTerm={setSearchString}
                    triggerSearch={setTriggerClearFilter}
                  ></SearchBarJobs> */}
                  <div className={classes.searchBarJobsOuterWrapper} style={{width: '100%'}}>
                    <div className={`${classes.searchBarWrapper}`} style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)' }}
                    // onClick={()=>{props.setStartedTyping(true);}}
                    >
                    <SearchBarJobsAutocomplete
                      searchTerm={searchString}
                      setSearchTerm={setSearchString}
                      readOnly={true}
                      isMobile={true}
                      triggerSearch={setTriggerClearFilter}
                      placeholder={"Search Jobs by Title, Role & Sector..."}
                    ></SearchBarJobsAutocomplete>
                    {/* <div className={classes.searchBarIconWrapper}
                      >
                      <SearchIcon style={{ fontSize: 25, width: 30 }} />
                    </div> */}
                  </div>
              </div>

            </Grid>

            {showCloseBtn ?
              <IconButton
                id="closeFilterSection"
                style={{ position: 'absolute', top: 0, right: 10, zIndex: 9, padding: '12px 0' }}
                onClick={() => {
                  closeFilterSection()
                }}
              >
                <CloseIcon />
              </IconButton> :
              searchString.length > 2 ? 
              <IconButton
                id="openFilterSection"
                style={{ position: 'absolute', top: 0, right: 10, zIndex: 9, padding: '12px 0' }}
                onClick={() => {
                  openFilterSection()
                }}
              >
                <FilterListOutlinedIcon />
                {isFilterApplied && <div className={classes.filterBadge}>

                </div>}
              </IconButton>
              : null
            }
          </div>
      </div>
    </Toolbar>
        </AppBar >

        <Grid container>
          <div className={classes.sectionDesktop}>
            <Grid
              className={classes.backBtn}
              item
              xs={12}
              container
              alignItems="center"><IconButton id="backButton_jobsearch" label="Back"
                onClick={() => {
                  if (sessionStorage.getItem("pageStateOnReturn") === "default") { // if default then go to jobs page and reset the the page state to default 
                    history.push({
                      pathname: `/`,
                      // state: {
                      //   pageStateOnReturn: "default",
                      // }
                    });
                  } else { // else go to prev page which is jobs list page for specific roles
                    history.goBack();
                  }
                }}
              >
                <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />

              </IconButton>
            </Grid>
          </div>
          <Grid
            item
            xs={12} md={4}
            container
            alignItems="center">
            <div ref={filterBarRef} className={classes.filterSidebarWrapper}>
              {/* <div>
                <Typography className={`${classes.filterTypeText}`}>Role</Typography>
                  <TextField
                    id="jobRole"
                    value={searchString}
                    onChange={(e, newValue)=>{
                      setSearchString(newValue)
                    }}
                    label="Role"
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </div> */}

              <Typography style={{ textAlign: 'center' }} className={`${classes.filterTypeText}`}>Filters</Typography>
              <div className={classes.filterSidebar}>

                <div style={{ marginTop: '1em' }}>
                  <Typography className={`${classes.filterTypeText}`}>Location</Typography>
                  <Autocomplete
                    //   classes={{ root: classes.locationAutoComplete }}
                    //multiple
                    name="job_location"
                    options={locationFilters}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      } else if (typeof option == 'object') {
                        //console.log("case of ['']")
                        return ""
                      }
                      return option
                    }
                    }
                    renderOption={(option) => {
                      if (option !== 'Work From Home') {
                        return option
                      }
                    }}
                    filterSelectedOptions
                    getOptionSelected={(option, value) => option === value}
                    value={city || []}
                    onChange={(event, newValue) => {
                      event.preventDefault();
                      if (newValue) { // when value is selected from dropdown
                        setCity(newValue)
                      }
                    }}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder={'Select locations'}
                        classes={{ root: classes.TfRoot }}
                      />
                    )}
                  />

                  <FormControlLabel
                    style={{ color: '#00000091' }}
                    control={
                      <Checkbox
                        checked={isWorkFromHome}
                        onChange={(e) => {
                          setIsWorkFromHome(e.target.checked)
                        }
                        }
                        size='small'
                        name="job_type"
                        color="primary"
                        value={'isWorkFromHome'}
                      />
                    }
                    classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                    label={'isWorkFromHome'}
                  />
                </div>

                <div style={{ marginTop: '1em' }}>
                  <Typography className={`${classes.filterTypeText}`}>Job Type</Typography>
                  {jobTypeFilters && jobTypeFilters.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      style={{ color: '#00000091' }}
                      control={
                        <Checkbox
                          checked={opportunityType[index] ? opportunityType[index].checked : false}
                          onChange={(e) => {
                            let _arr = [...opportunityType]
                            _arr[index].checked = e.target.checked
                            setOpportunityType(_arr)
                          }
                          }
                          size='small'
                          name="job_type"
                          color="primary"
                          value={opportunityType[index] ? opportunityType[index].filter_name : ''}
                        />
                      }
                      classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                      label={opportunityType[index] ? opportunityType[index].filter_name : ''}
                    />))}
                </div>

                <div style={{ marginTop: '1em' }}>
                  <Typography className={`${classes.filterTypeText}`}>Qualification</Typography>
                  {qualificationFilters && qualificationFilters.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      style={{ color: '#00000091' }}
                      control={
                        <Checkbox
                          checked={qualification[index] ? qualification[index].checked : false}
                          onChange={(e) => {
                            let _arr = [...qualification]
                            _arr[index].checked = e.target.checked
                            setQualification(_arr)
                          }
                          }
                          size='small'
                          name="job_type"
                          color="primary"
                          value={qualification[index] ? qualification[index].filter_name : ''}
                        />
                      }
                      classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                      label={qualification[index] ? qualification[index].filter_name : ''}
                    />))}
                </div>

                {/* <div style={{ marginTop: '1em' }}>
                  <Typography className={`${classes.filterTypeText}`}>Salary</Typography>
                  {salaryFilters && salaryFilters.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      style={{ color: '#00000091', textTransform: 'lowercase' }}
                      control={
                        <Checkbox
                          checked={salary[index] ? salary[index].checked : false}
                          onChange={(e) => {
                            let _arr = [...salary]
                            _arr[index].checked = e.target.checked
                            setSalary(_arr)
                          }
                          }
                          size='small'
                          name="job_type"
                          color="primary"
                          value={salary[index] ? salary[index].filter_name : ''}
                        />
                      }
                      classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                      label={salary[index] ? salary[index].filter_name : ''}
                    />))}
                </div> */}

                <div style={{ marginTop: '1em' }}>
                  <Typography className={`${classes.filterTypeText}`}>Posted in</Typography>
                  {postedOnFilters && postedOnFilters.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      style={{ color: '#00000091', textTransform: 'lowercase' }}
                      control={
                        <Checkbox
                          checked={postedOn[index] ? postedOn[index].checked : false}
                          onChange={(e) => {
                            let _arr = [...postedOn]
                            _arr[index].checked = e.target.checked
                            setPostedOn(_arr)
                          }
                          }
                          size='small'
                          name="job_type"
                          color="primary"
                          value={postedOn[index] ? postedOn[index].filter_name : ''}
                        />
                      }
                      classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                      label={postedOn[index] ? postedOn[index].filter_name : ''}
                    />))}
                </div>

              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained"
                  style={{ padding: '4px', textTransform: 'capitalize', width: '50%' }}
                  color='primary'
                  onClick={() => {
                    setIsFirstTimeLoad(false)
                    setCurrentPage(1);
                    setTriggerClearFilter(prev => !prev)
                    closeFilterSection()//for mobile section
                    checkFilters()
                  }
                  }
                >
                  Apply
                </Button>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12} md={8}
            container
            alignItems="center">
            <div className={classes.root} ref={scrollRef}>
              {isLoading
                ? <div style={{
                  width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
                }}>
                  <ListLoader setListLoaderRef={setListLoaderRef} isText={"none"} />
                </div>
                :
                <React.Fragment>
                  {jobsList && jobsList.length > 0 &&
                    <div style={{ width: '100%', marginBottom: 50 }}>

                      {vacanciesCount && vacanciesCount > 0 &&
                        <Typography className={classes.panelHeadingSticky}>
                          {/* <span className={classes.searchStr}>{searchString + ": "}  </span> */}
                          {vacanciesCount} {vacanciesCount === 1 ? "opening" : "openings"} {jobsCount && `in ${jobsCount}`} {jobsCount && jobsCount === 1 ? "posting" : "postings"}
                          {/* 👇 */}
                        </Typography>
                      }

                      <InfiniteScroll
                        dataLength={jobsList.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<div style={{
                          width: '100%', height: 80, display: 'flex', justifyContent: 'center', alignItems: 'flex-start'
                        }}>
                          {/* <CircularProgress style={{ color: '#ccc' }} /> */}
                          <img alt="meet animated logo loader" style={{ width: 40 }} src={LogoLoader} />
                        </div>}
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            { }
                          </p>
                        }
                        scrollThreshold="70%"
                      >
                        <List className={classes.listRoot} disablePadding>
                          {jobsList.map((hit, index) =>
                          (<JobsListItem
                            hit={jobsList[index]}
                            signInStatus={isSignedIn}
                            key={index}
                            lat={latLong.lat}
                            long={latLong.long}
                            isWorkFromHome={jobsList[index].isWorkFromHome}
                            pageStateOnReturn={"goBack"}
                          />)
                          )
                          }
                        </List>
                      </InfiniteScroll>
                    </div>
                  }
                  {/* later for mobile apply condition that filter length is more than 0 */}
                  {jobsList && jobsList.length === 0 &&
                    (
                      <>
                        <div className={classes.sectionDesktop}>
                          <div className={classes.pageInfo}>
                            <Typography className={classes.pageInfoText}>Sorry! हमें आपकी ढूंढी हुई job नहीं मिली। कोई और job title या sector से search करें </Typography>
                            {isFirstTimeLoad ?
                              <Grid xs={12} container
                                direction="column"
                                alignItems="center">

                                <TextField
                                  id="jobRole"
                                  value={searchString}
                                  onChange={(e) => {
                                    setSearchString(e.target.value);
                                  }}
                                  style={{ marginTop: 10, width: '100%' }}
                                //label="Role"
                                // InputLabelProps={{
                                //   shrink: true,
                                // }}
                                />
                                <Button
                                  id="see_all_jobs_jobs_main"
                                  variant="contained"
                                  className={`${classes.pageButton} ${classes.pageButton_pri}`}
                                  style={{ width: 200 }}
                                  onClick={() => {
                                    setTriggerClearFilter(prev => !prev);
                                  }}
                                >Search</Button>
                              </Grid> :
                              <Button
                                id="see_all_jobs_jobs_main"
                                variant="contained"
                                className={`${classes.pageButton} ${classes.pageButton_pri}`}
                                style={{ width: 200 }}
                                onClick={() => {
                                  clearFilters();
                                  setTriggerClearFilter(prev => !prev);
                                }}
                              >

                                Clear Filters
                              </Button>
                            }
                          </div>
                        </div>

                        <div className={classes.sectionMobile}>
                          <div className={classes.pageInfo}>
                            <Typography className={classes.pageInfoText}>Sorry! हमें आपकी ढूंढी हुई job नहीं मिली। कोई और job title या sector से search करें... {isFilterApplied ? "या Filters clear करें..." : ""} </Typography>

                          </div>
                        </div>
                      </>
                    )
                  }
                  {/* case of first time land on page on mobile */}
                  {jobsList == null &&
                    (
                      <div className={classes.sectionMobile}>
                        <div className={classes.pageInfo}>
                          <Typography className={classes.pageInfoText}>Job title, role या sector से jobs search करें।  </Typography>
                        </div>
                      </div>
                    )
                  }


                </React.Fragment>
              }
            </div>
          </Grid>
        </Grid>

        <BottomAppBar
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          handleDrawer={handleDrawer}
          navValue={navValue}
          setNavValue={setNavValue}
          setDisplaySearchBar={setDisplaySearchBar}
          setDisplayAllJobs={setDisplayAllJobs}
          executeScroll={executeScroll}
        >
        </BottomAppBar>

        <SignInUpDrawer
          canDrawerOpen={canDrawerOpen}
          refresh={refresh}
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          setForRefreshOnly={setForRefreshOnly}
          setCanDrawerOpen={setCanDrawerOpen}
          doRefresh={doRefresh}
        />

        <SnackBarGeneric id="snackBarMessage" canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />

      </div >
    </React.Fragment >
  )
}