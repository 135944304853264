import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import moment from 'moment';

import Location from './Location';
import CameraCustom from './CameraCustom';
import { useStyles } from './styles';

import MediaRecorderCustom from './MediaRecorderCustom';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

const axios = require('axios');

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 74,
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em'
  },
  section: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: '5px 23px 5px',
    '&:last-child': {
      padding: '5px 23px 40px',
    }
  },
  imageTag: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: "none",
  },
  TfRoot: {
    width: '100%',
    marginBottom: 30,
    //background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  TfRootSimple: {
    width: '100%',
    marginBottom: 30,
    background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  inputRootAc: {
    padding: '0 9px!important',
    background: theme.palette.common.greyLight,
  },
  tfLabel: {
    ...theme.typography.body1,
    color: theme.palette.common.blackDark,
    marginBottom: 12,
  },
  tfRoot2: {
    width: '100%',
    marginBottom: 30,
    borderRadius: 4,
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 15px',
      background: theme.palette.common.greyLight,
      borderRadius: 4,
    },
    '& .MuiSelect-select:focus': {
      borderRadius: 4,
    },
    '& input': {
      height: 48,
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  popperAC: {
    zIndex: 99999
  },

}));

function FullPageAutocomplete({ children, isAutocompletePageOpen, setIsAutocompletePageOpen, label, classes }) {
  const theme = useTheme();
  return (
    <>
      {isAutocompletePageOpen ?
        <div style={{
          position: 'fixed',
          left: 0,
          width: '100%',
          padding: '10px 23px',
          zIndex: 1300,
          background: 'white',
          top: 0,
          height: '100vh',
          overflow: 'scroll'
        }}>

          <React.Fragment>
            <AppBar position="fixed" className={classes.tabBar}>
              <Toolbar variant="dense" style={{ width: '100%' }} >
                <IconButton edge="start" className={classes.menuButton} aria-label="back button"
                  onClick={() => {
                    setIsAutocompletePageOpen(false);
                  }
                  }>
                  <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
                </IconButton>
                <Typography variant="h4" className={classes.navTabBar} style={{ color: theme.palette.common.blackDark }}>
                  {label}
                </Typography>

              </Toolbar>
            </AppBar>
            <Grid item xs={12}>
              <FormControl component="fieldset" style={{ width: '100%', marginTop: 50 }}>
                {children}
              </FormControl>
            </Grid>
          </React.Fragment>
        </div> : null}
    </>
  )
}


export default function AboutMe({ profileObj, profilePhoto, setProfilePhoto, firstName, setFirstName, lastName, setLastName, dob, setDob, gender, setGender, locationValue, setLocationValue, latLong, setLatLong, medhaAlumni, setMedhaAlumni, email, setEmail, studentID, setStudentID, isErrorAboutMe, setIsErrorAboutMe, isSignedIn, setIsSaveBtnDisabled, showSaveCancelBtns, handleSnackBar }) {
  const classes = useStyles();
  const theme = useTheme();

  const aboutMeErrorReset = () => {
    setIsErrorAboutMe({ firstName: false, firstNameFormat: false, dob: false, gender: false, location: false, email: false, emailFormat: false, emailDuplicate: false, studentID: false, studentIDFormat: false })
  }
  const [isLocationAutocompletePageOpen, setIsLocationAutocompletePageOpen] = React.useState(false);
  const [isStudentIdAutocompletePageOpen, setIsStudentIdAutocompletePageOpen] = React.useState(false);

  //call back start to set student id for medha alumini
  const [openSid, setOpenSid] = React.useState(false);
  const [studentIdList, setStudentIdList] = React.useState([]);
  const [searchedStudentId, setSearchedStudentId] = React.useState('');
  // const loading = openSid && studentIdList.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!openSid) {
      return undefined;
    }
    (async () => {
      const response = await axios.get(`https://meetapi.medha.org.in/select/get_sis_student_id?search_text=${searchedStudentId}`);

      if (active) {
        setStudentIdList(response.data);
        setOpenSid(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [openSid]);

  // React.useEffect(() => {
  //   if (!openSid) {
  //     setStudentIdList([]);
  //   }
  // }, [openSid]);
  //call back ends to set student id for medha alumini

  return (
    <>
      <Grid
        item
        xs
        container>
        {/****** profile photo ******/}
        <CameraCustom profileObj={profileObj} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto} isSignedIn={isSignedIn} handleSnackBar={handleSnackBar} />

        <Grid
          item
          xs={12} md={6}
          container>
          {/****** user name ******/}
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            // direction="column"
            style={{ position: 'relative', marginTop: 40, marginBottom: 10 }}
          >
            <Grid item xs={12}>
              <TextField
                required
                id="firstInput_id"
                inputProps={{
                  maxLength: 30,
                  autoComplete: 'new-password'//to disable autofill
                }}
                variant="outlined"
                onChange={(e) => { setFirstName(e.target.value); aboutMeErrorReset(); setIsSaveBtnDisabled(false); showSaveCancelBtns(); }}
                placeholder="First Name*"
                value={firstName}
                classes={{ root: classes.tfRoot2 }}
                helperText={isErrorAboutMe.firstName ? "First name required" : isErrorAboutMe.firstNameFormat ? "Not a valid name" : ""}
                error={isErrorAboutMe.firstName || isErrorAboutMe.firstNameFormat}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                autoComplete="off"
                id="lastInput_id"
                inputProps={{
                  maxLength: 40,
                  autoComplete: 'new-password'//to disable autofill
                }}
                onChange={(e) => { setLastName(e.target.value); setIsSaveBtnDisabled(false); showSaveCancelBtns(); }}
                value={lastName}
                variant="outlined"
                placeholder="Last Name"
                classes={{ root: classes.tfRoot2 }}
              />
            </Grid>
          </Grid>

          {/****** datepicker ******/}
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="flex-start"
            direction="row"
            style={{ position: 'relative', marginBottom: 30 }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12}>
                <FormControl component="fieldset" style={{ width: '100%' }}>
                  <FormLabel component="legend" className={classes.tfLabel}>Date of Birth*</FormLabel>
                  <DatePicker
                    autoOk
                    disableFuture
                    openTo="date"
                    variant="inline"
                    format="dd MMM yyyy"
                    views={["date", "month", "year"]}
                    id={"dob_id"}
                    value={dob ? dob : null}
                    // minDate={new Date("2000-01-01")}
                    onChange={(date) => {
                      let newItem = '';
                      if (dob != null) {
                        newItem = moment(date).format('DD-MMM-YYYY');
                        setDob(newItem)
                        aboutMeErrorReset();
                        setIsSaveBtnDisabled(false);
                        showSaveCancelBtns();
                      }
                      aboutMeErrorReset();
                    }}
                    inputVariant="outlined"
                    className={classes.datePickerInput}
                    helperText={isErrorAboutMe.dob ? "required" : ""}
                    error={isErrorAboutMe.dob}
                  />
                </FormControl>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>

          {/****** user gender ******/}
          <Grid item xs={12} style={{ width: '100%', marginBottom: 30 }}>
            <FormControl id="genderInput_id" component="fieldset" error={isErrorAboutMe.gender}>
              <FormLabel component="legend" className={classes.tfLabel} style={{ marginBottom: 8 }}>Gender</FormLabel>
              <RadioGroup row aria-label="position" name="gender" value={gender} >
                <FormControlLabel value="Male" onClick={() => { setGender("Male"); setIsSaveBtnDisabled(false); showSaveCancelBtns(); }} control={<Radio color="primary" />} label="Male" />
                <FormControlLabel value="Female" onClick={() => { setGender("Female"); setIsSaveBtnDisabled(false); showSaveCancelBtns(); }} control={<Radio color="primary" />} label="Female" />
                <FormControlLabel value="Others" onClick={() => { setGender("Others"); setIsSaveBtnDisabled(false); showSaveCancelBtns(); }} control={<Radio color="primary" />} label="Others" />
              </RadioGroup>
              <FormHelperText>{isErrorAboutMe.gender ? "Please select a gender" : ""}</FormHelperText>
            </FormControl>
          </Grid>

          {/****** Location ******/}
          {/* <Grid item xs={12}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormLabel component="legend" className={classes.tfLabel}>Location</FormLabel>
          <Location
            profileObj={profileObj}
            locationValue={locationValue}
            setLocationValue={setLocationValue}
            latLong={latLong}
            setLatLong={setLatLong}
            isErrorAboutMe={isErrorAboutMe}
            setIsSaveBtnDisabled={setIsSaveBtnDisabled}
            showSaveCancelBtns={showSaveCancelBtns}
          />
        </FormControl>
      </Grid> */}

          {/****** Location ******/}
          <Grid item xs={12}>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <FormLabel component="legend" className={classes.tfLabel}>Location</FormLabel>
              <TextField
                variant="outlined"
                placeholder="Search your location"
                classes={{ root: classes.tfRoot2 }}
                value={locationValue ? locationValue : ''}
                onClick={() => {
                  setIsLocationAutocompletePageOpen(true);
                }}
                helperText={isErrorAboutMe.location ? "required" : ""}
                error={isErrorAboutMe.location}
              />
            </FormControl>
          </Grid>

          {/****** Email ******/}
          <Grid item xs={12} style={{ width: '100%' }}>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <FormLabel component="legend" className={classes.tfLabel} style={{ marginBottom: 8 }}>Email</FormLabel>
              <TextField
                required
                autoComplete="off"
                id="eInput_id"
                variant="outlined"
                onChange={(e) => { setEmail(e.target.value); aboutMeErrorReset(); setIsSaveBtnDisabled(false); showSaveCancelBtns(); }}
                //placeholder="Email" // removed so that autofill is not detected by the browser
                value={email}
                classes={{ root: classes.tfRoot2 }}
                inputProps={{
                  maxLength: 50,
                  autoComplete: 'off'//to disable autofill
                }}
                helperText={isErrorAboutMe.email ? "Email required" : isErrorAboutMe.emailFormat ? "Email format invalid" : isErrorAboutMe.emailDuplicate ? "Account with this email ID already exists, please use a different one" : ""}
                error={isErrorAboutMe.email || isErrorAboutMe.emailFormat || isErrorAboutMe.emailDuplicate}
              />
            </FormControl>
          </Grid>

          {/****** is medha alumnus ******/}
          <Grid item xs={12} style={{ width: '100%', marginBottom: 10 }}>
            <FormControlLabel id="isMedhaAlumnus_id"
              control={<Checkbox name="checkedMedhaAlumnus" checked={medhaAlumni} onChange={(e) => { setMedhaAlumni(e.target.checked); setIsSaveBtnDisabled(false); showSaveCancelBtns(); }} color="primary" />}
              label="मैं Medha Alumnus हूँ।" />
          </Grid>

          {/****** Medha student ID ******/}
          {medhaAlumni &&
            <Grid item xs={12} style={{ width: '100%' }}>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <FormLabel component="legend" className={classes.tfLabel} style={{ marginBottom: 8 }}>Student ID</FormLabel>

                <TextField
                  required
                  autoComplete="off"
                  id="studentID_id"
                  variant="outlined"
                  // onChange={(e) => { setStudentID(e.target.value.toUpperCase()); aboutMeErrorReset(); setIsSaveBtnDisabled(false); showSaveCancelBtns(); }}
                  onClick={() => setIsStudentIdAutocompletePageOpen(true)}
                  placeholder="e.g. STU123456"
                  value={studentID}
                  classes={{ root: classes.tfRoot2 }}
                // inputProps={{
                //   maxLength: 9,
                //   autoComplete: 'off'//to disable autofill
                // }}
                helperText={isErrorAboutMe.studentID ? "Student ID is required for Medha Alumni" : isErrorAboutMe.studentIDFormat ? "Student ID format should be like STU123456" : ""}
                error={isErrorAboutMe.studentID || isErrorAboutMe.studentIDFormat}
                />
              </FormControl>
            </Grid>
          }

        </Grid>
      </Grid>
      {/* Full page autocomplete */}
      <FullPageAutocomplete label='Location' setIsAutocompletePageOpen={setIsLocationAutocompletePageOpen} isAutocompletePageOpen={isLocationAutocompletePageOpen} classes={classes} >
        <Location
          profileObj={profileObj}
          locationValue={locationValue}
          setLocationValue={setLocationValue}
          latLong={latLong}
          setLatLong={setLatLong}
          isErrorAboutMe={isErrorAboutMe}
          setIsSaveBtnDisabled={setIsSaveBtnDisabled}
          showSaveCancelBtns={showSaveCancelBtns}
          setIsLocationAutocompletePageOpen={setIsLocationAutocompletePageOpen}
        />
      </FullPageAutocomplete>

      {/* Full page autocomplete for student Id */}
      <FullPageAutocomplete label='Student Id' setIsAutocompletePageOpen={setIsStudentIdAutocompletePageOpen} isAutocompletePageOpen={isStudentIdAutocompletePageOpen} classes={classes} >
        <Grid item xs={12} style={{ width: '100%' }}>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <Autocomplete
              fullWidth
              id="medha-alumini_id"
              // style={{ width: 300 }}
              // open={openSid}
              value={studentID || ''}
              onChange={(event, newValue) => {
                if (newValue) {
                  setStudentID(newValue);
                  setIsStudentIdAutocompletePageOpen(false);
                  aboutMeErrorReset();
                  setIsSaveBtnDisabled(false);
                  showSaveCancelBtns();
                } else {
                  setStudentID('');
                }
              }}
              onInputChange={(event, value) => {
                setSearchedStudentId(value);
                setTimeout(() => {
                  setOpenSid(true);
                }, 2000);
              }}
              onClose={() => {
                setOpenSid(false);
              }}
              getOptionLabel={(option) => option}
              options={studentIdList}
              // loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="e.g. STU123456"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </FullPageAutocomplete>

    </>
  )
}