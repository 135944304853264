import React from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

let bgColor = "#14967B";

const useStyles = makeStyles((theme) => ({
  snackBar: {
    zIndex: 99999,
    bottom: 90,
    '& > div': {
      backgroundColor: bgColor,
      borderRadius: 5,
      minWidth: 100,
    },
    '& .MuiSnackbarContent-root': {
      flexGrow: 0
    }
  },
  snackBarError: {
    zIndex: 99999,
    bottom: 90,
    '& > div': {
      backgroundColor: theme.palette.common.red,
      borderRadius: 5,
      minWidth: 100,
    },
    '& .MuiSnackbarContent-root': {
      flexGrow: 0
    }
  }
}));

function SnackBarGeneric(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [selected, setSelected] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState({
    open: false,
    Transition: Slide,
  });

  const handleSnackBarOpen = () => {
    setOpenSnackBar({
      open: true,
      Transition: Slide,
    });
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar({
      ...openSnackBar,
      open: false,
    });
  }

  React.useEffect(() => {
    if (props.canSnackBarOpen === true) {
      handleSnackBarOpen();
    }
  }, [props.refresh])

  return (
    <Snackbar
      open={openSnackBar.open}
      autoHideDuration={4000}
      onClose={handleSnackBarClose}
      TransitionComponent={openSnackBar.Transition}
      key={openSnackBar.Transition.name}
      message={props.message}
      action={
        <IconButton id="handle_snack_bar" size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      classes={{ root: props.severity && props.severity === "error" ? classes.snackBarError : classes.snackBar }}
    >
    </Snackbar>
  );
}

export default SnackBarGeneric;