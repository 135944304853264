import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import BottomAppBar from '../../components/BottomAppBar';
import SignInUpDrawer from '../../components/SignInDrawer';
import LogoutDrawer from '../../components/LogoutDrawer';
import { AppBar, Badge, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import { useStyles } from './styles';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import logo from '../../assets/logo_header.png';
import Notifications from '../../components/Notifications';
import ApiHandler from '../../ApiHandler';
import SnackBarGeneric from '../../components/SnackBarGeneric';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import { RWebShare } from "react-web-share";

const Newsfeed = () => {
  const history = useHistory();
  const location = useLocation();
  const [navValue, setNavValue] = React.useState(3);
  const classes = useStyles();
  const [allNews, setAllNews] = useState([]);
  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  //let sessionCookie = document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
  const [isNewsContentRevealed, setIsNewsContentRevealed] = React.useState(false);
  const [openNewsDetails, setOpenNewsDetails] = useState(false);
  const [currentNews, setCurrentNews] = useState({});

  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  // drawer callback starts
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const [refresh, doRefresh] = React.useState(false);

  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // Settings drawer callback starts
  const [forRefreshSettingsOnly, setForRefreshSettingsOnly] = React.useState(false);
  const [canSettingsDrawerOpen, setCanSettingsDrawerOpen] = React.useState(false);

  const [refreshSettings, doRefreshSettings] = React.useState(false);

  const handleSettingsDrawer = () => {
    setCanSettingsDrawerOpen(true)
    doRefreshSettings(prev => !prev)
  }
  // Settings drawer callback ends

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const [snackBarMessageSeverity, setSnackBarMessageSeverity] = React.useState("success");
  const handleSnackBar = (message, severity) => {
    if (severity) {
      setSnackBarMessageSeverity(severity);
    } else {
      setSnackBarMessageSeverity("success");
    }
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev);
  }
  // snackbar callback ends

  //notification start
  const [openNotificationDialog, setOpenNotificationDialog] = React.useState(false);
  const [notificationBadgeCount, setNotificationBadgeCount] = React.useState(0);

  const closeNotificationDialog = () => {
    setOpenNotificationDialog(false);
  }

  //fetch all notifications
  React.useEffect(() => {
    let jobseekerID = localStorage.getItem("jobseeker_id");
    let payload = {
      "offset": 0,
      "limit": 100,
      "jobseeker_id": jobseekerID
    }
    ApiHandler.getAllNotifications(payload, res => {
      let unreadNotif = 0;
      if (res.status == 200) {
        unreadNotif = res.data[0].results.filter(val => !val.is_read).length;
        setNotificationBadgeCount(unreadNotif);
      }
    })
  }, []);

  React.useEffect(() => {
    document.body.classList.add('wallpaper--icon');
    return () => {
      document.body.classList.remove('wallpaper--icon');

    }
  }, []);

  useEffect(() => {
    fetchNewsFeeds();
  }, []);

  const fetchNewsFeeds = () => {
    let payload = 'limit=20&offset=0';
    ApiHandler.getAllNewsFeeds(payload, res => {
      setAllNews(res.data.data.newsFeeds);
    })
  }

  const formatDate = (date) => {
    let postedDate = new Date(date);
    var relativeTime = moment(postedDate).fromNow();
    return relativeTime;
  }

  return (
    <>
      {/* Top App Bar on Mobile */}
      <AppBar position="fixed" style={{ background: '#fff' }}>
        <Toolbar variant="dense" style={{ padding: 0 }}>
          <div style={{ width: '100%' }}>
            <div className={classes.sectionMobile}>
              <Button
                style={{ display: 'flex' }}
                id="meetLogo_id"
                onClick={() => {
                  history.push({
                    pathname: '/'
                  });
                }}>
                <img alt="meet logo" style={{ width: 80, marginRight: '11%', paddingLeft: 10 }} src={logo} />
              </Button>
              <Grid
                item xs={9} md={11}
                container
                direction='column'
                justifyContent="center"
                alignItems='flex-start'>

              </Grid>
              {isSignedIn && <IconButton className={classes.mobileNotificationIcon} aria-label="show 10 new notifications" color="inherit">
                <Badge badgeContent={notificationBadgeCount} color="error">
                  <NotificationsOutlinedIcon color='action' onClick={() => setOpenNotificationDialog(true)} />
                </Badge>
              </IconButton>}
              <IconButton
                id="shareButton"
                style={{ position: 'absolute', top: 0, right: 10, zIndex: 9, padding: '12px 0' }}
                onClick={() => {
                  if (isSignedIn) handleSettingsDrawer()
                  else handleDrawer()
                }}
              >
                <SettingsOutlinedIcon />
              </IconButton>

              {/* <IconButton
                  id="shareButton"
                  style={{ position: 'absolute', top: 0, right: 50, zIndex: 9, padding: '12px 0' }}
                // onClick={() => {

                // }}
                >
                  <NotificationsNoneOutlinedIcon />
                </IconButton> */}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Grid className={classes.newsFeedsContainer} container>
        {allNews?.map((news, index) => {
          return <Grid key={news._id} className={`${classes.postContainer} ${classes.mb2}`} item container direction='column'>
            <Grid className='newsHeader' wrap='nowrap' item container alignItems='center' justifyContent='space-between'>
              <Grid xs={8} item container>
                <Typography variant='h3'>{news?.newsTitle}</Typography>

              </Grid>
              <RWebShare
                data={{
                  title: news?.newsTitle,
                  text: news?.news_desc,
                  url: window.location.origin + window.location.pathname + '/' + news._id,
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <Button color='primary' className={`${classes.ttc} ${classes.shareBtn}`} startIcon={<ShareOutlinedIcon fontSize='small' />}>
                  Share
                </Button>
              </RWebShare>
            </Grid>
            <Grid container justifyContent='flex-end' alignItems='center'>
              <Typography className={classes.mr1} variant='caption'>Posted: {formatDate(news?.createdAt)}</Typography>
            </Grid>
            <div className={classes.horizontalLineBreak}></div>
            <Grid className='newsContent' item container direction='column'>
            <Typography className={classes.my1} variant='body2'>{news?.newsTitle}</Typography>
              <Grid item container direction='column'>
                {news?.news_image_url && <div className={classes.bannerImgContainer} >
                  <img width='100%' src={news?.news_image_url} style={{ width: 'fit-content' }} />
                </div>}
                <Typography className={classes.my1} variant='body2'>{news?.news_desc.substr(0, 42)}....<span className={classes.cursor_pointer} style={{ color: '#55c6a9' }}
                  onClick={() => {
                    history.push({
                      pathname: 'newsfeeds' + '/' + news._id,
                      state: news
                    })

                  }}
                >See more</span></Typography>
              </Grid>
              <Typography className={classes.my1} variant='body2'><span style={{ color: '#55c6a9' }}>{news?.registration_url}</span></Typography>
            </Grid>
          </Grid>
        })}
      </Grid>
      <BottomAppBar
        navValue={navValue}
        setNavValue={setNavValue}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        handleDrawer={handleDrawer}
        handleSettingsDrawer={handleSettingsDrawer}
      > </BottomAppBar>
      <SignInUpDrawer
        canDrawerOpen={canDrawerOpen}
        refresh={refresh}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshOnly}
        setCanDrawerOpen={setCanDrawerOpen}
        doRefresh={doRefresh}
      />

      <LogoutDrawer
        canDrawerOpen={canSettingsDrawerOpen}
        refresh={refreshSettings}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshSettingsOnly}
        setCanDrawerOpen={setCanSettingsDrawerOpen}
        doRefresh={doRefreshSettings}
        handleSnackBar={handleSnackBar}
      />
      <SnackBarGeneric id="snackBarMessage" canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
      {/* Notification dialog */}
      <Dialog
        open={openNotificationDialog}
        onClose={closeNotificationDialog}
        fullScreen
      >
        <DialogContent classes={{ root: classes.notificationDialogRoot }}>
          <Grid style={{ height: 60, position: 'fixed', top: 0, left: 0, backgroundColor: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.17)' }} alignItems='center' container>
            <ArrowBackIcon style={{ margin: '0 20px 0 10px' }} onClick={() => setOpenNotificationDialog(false)} />
            <Typography variant='body1' >Notifications</Typography>
          </Grid>
          <Notifications classes={classes} localIsLoggedIn={isSignedIn} setNotificationBadgeCount={setNotificationBadgeCount} setNotificationBar={setOpenNotificationDialog} variant='mobile' />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Newsfeed