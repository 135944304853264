import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import WorkOutlineRoundedIcon from '@material-ui/icons/WorkOutlineRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import StarsOutlinedIcon from '@material-ui/icons/StarsOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import MoreOutlinedIcon from '@material-ui/icons/MoreOutlined';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import SvgIcon from '@material-ui/core/SvgIcon';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

import Popover from '@material-ui/core/Popover';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet-async';

import { useStyles } from './styles';

import '../../style/App.css';
//import global_isSignedIn from '../../components/globalVars/global_isSignedIn';
import SignInUpDrawer from '../../components/SignInDrawer';
import ApiHandler from '../../ApiHandler';
import AnalyticsHandler from '../../AnalyticsHandler';
import ListLoader from '../errorsAndLoaders/ListLoader';
import Image from '../../assets/logo_header.png';
// import Image from '../../assets/logo-loader.gif';
import bgImage from '../../assets/company_logo_placeholder.png';
import { ThemeConsumer } from 'styled-components';
import SnackBarGeneric from './SnackBarGeneric';
import ShareDrawer from '../../components/ShareDrawer';
import SeeAllReviews from './SeeAllReviews';
import ScreeningForm from './ScreeningForm';

import { VacancyIcon, EmployeesOutlinedIcon, EmployeesIcon, BenefitsIcon, OtherRequirementsIcon, ReviewsOutlinedIcon, FemaleOutlinedIcon, GenderOutlinedIcon, SkillsOutlinedIcon, OverallRatingIcon } from '../../components/SvgIcons';

import jobAudioFile from "../../assets/jobAudio.mpeg";


function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function JobDetail({ match }) {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const [navValue, setNavValue] = React.useState(0);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);
  const [response, setResponse] = React.useState([]);
  const [googleFormURL, setGoogleFormURL] = React.useState("");
  const [companyID, setCompanyID] = React.useState(null);
  const [isJobOpen, setIsJobOpen] = React.useState(null);
  const [jobsCountForARole, setJobsCountForARole] = useState(0);
  const [isBottomBarVisible, setIsBottomBarVisible] = useState(false)
  const [reviewsList, setReviewsList] = useState(null);
  const [reviewsCount, setReviewsCount] = useState(0);
  const companyRef = useRef(null);
  const jobDetailRef = useRef(null);
  // screening form vars
  const [locationAgree, setLocationAgree] = React.useState(null);
  const [validLicense, setValidLicense] = React.useState(null);
  const [ownVehicle, setOwnVehicle] = React.useState(null);
  const [ownLaptop, setOwnLaptop] = React.useState(null);
  const [whyGoodFit, setWhyGoodFit] = React.useState("");
  const [salaryAgree, setSalaryAgree] = React.useState(null);
  const [salaryExpectation, setSalaryExpectation] = React.useState("");
  const [changeJobReason, setChangeJobReason] = React.useState("");
  const [favCompanies, setFavCompanies] = React.useState([]);
  const [isFavCompany, setIsFavCompany] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  let jobAudio = new Audio(jobAudioFile);

  document.body.style.overflowY = "scroll";

  // const playPauseSound = () => {
  //   if(isPlaying){
  //     jobAudio.pause();
  //   } else {
  //     jobAudio.play();
  //   }
  //   setIsPlaying(prev => !prev);
  // }

  // const playSound = () => {
  //   jobAudio.play();
  //   setIsPlaying(true);
  // }
  // const pauseSound = () => {
  //   jobAudio.pause();
  //   setIsPlaying(false);
  // }
  // sound code starts
  // const [audioStatus, changeAudioStatus] = useState(false);
  // const myRef = useRef();

  // const startAudio = () => {
  //   myRef.current.play();
  //   changeAudioStatus(true);
  // };

  // const pauseAudio = () => {
  //   myRef.current.pause();
  //   changeAudioStatus(false);
  // };

  // const handleAudioEnded = () => {
  //   changeAudioStatus(false);
  // }
  // sound code ends

  // React.useEffect(() => {
  //   myRef.current && myRef.current.addEventListener('ended', handleAudioEnded);
  //   return () => {
  //     myRef.current && myRef.current.removeEventListener('ended', handleAudioEnded);
  //   };
  // }, []);
  const [isRatingsOpen, setIsRatingsOpen] = React.useState(false);

  const closeRatingsModal = () => {
    setIsRatingsOpen(false);
  }

  const handleSalaryInfoClick = (event) => {
    AnalyticsHandler.track('JobDetail_infoSalary_BtnClick');
    setAnchorEl(event.currentTarget);
  };

  const handleSalaryInfoClose = () => {
    setAnchorEl(null);
  };

  const [doFollow, setDoFollow] = React.useState(true);

  const open = Boolean(anchorEl);
  //const id = open ? 'simple-popover' : undefined;

  const scrollToCompany = () => companyRef.current.scrollIntoView();
  const scrollToJobDetail = () => jobDetailRef.current.scrollIntoView();

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);

  let urlArray = match.params._id.split('-');
  let jobID = urlArray[urlArray.length - 1];

  const checkSignedInStatus = () => {
    return isSignedIn
  }

  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
  };

  const [isApplied, setIsApplied] = React.useState(false);
  const [isScreeningFormSubmitted, setIsScreeningFormSubmitted] = React.useState(false);
  const [isScreeningFormEnabled, setIsScreeningFormEnabled] = React.useState(false);
  const pcVal = localStorage.getItem('profile_completed') === "true" ? true : false;
  const [isProfileCompleted, setIsProfileCompleted] = React.useState(pcVal);
  const [isChecked, setIsChecked] = React.useState({}); // whether the question is asked by the employer
  const [countOfQuestions, setCountOfQuestions] = React.useState(0);
  const [customQuestions, setCustomQuestions] = React.useState(null);
  const [customAnswers, setCustomAnswers] = React.useState(null);
  const [tabIndices, setTabIndices] = React.useState({});

  let isTriggerFormOpenAfterApply = false // to trigger screening form opening after one applies to job

  const getScreeningFormResponse = () => {
    let jobseeker_id = localStorage.getItem("jobseeker_id");
    let jobpost_id = jobID;
    let _countOfQuestions = 0;
    let isCheckedObj = { // obj that saves which questions have been asked by the employer
      location_agree: false,
      valid_dl_license: false,
      own_vehicle: false,
      own_laptop: false,
      why_good_fit: false,
      salary_agree: false,
      salary_expectation: false,
      change_job_reason: false,
      custom1: false,
      custom2: false,
      custom3: false,
    }

    let tabIndices = { // obj that saves the indices of the question screens
      location_agree: null,
      valid_dl_license: null,
      own_vehicle: null,
      own_laptop: null,
      why_good_fit: null,
      salary_agree: null,
      salary_expectation: null,
      change_job_reason: null,
      custom1: null,
      custom2: null,
      custom3: null,
    }

    let _customQuestions = []; // array of custom questions asked
    let _customAnswers = []; // array of answers for custom questions given by the jobseeker

    ApiHandler.getScreeningFormResponse(jobseeker_id, jobpost_id, (res) => {
      if (res.status === 200) {
        //console.log(res);
        if (res.data && res.data[0]) {
          let data = res.data[0]

          setLocationAgree(data.location_agree.answer);
          setValidLicense(data.valid_dl_license.answer);
          setOwnVehicle(data.own_vehicle.answer);
          setOwnLaptop(data.own_laptop.answer);
          setWhyGoodFit(data.why_good_fit.answer);
          setSalaryAgree(data.salary_agree.answer);
          setSalaryExpectation(data.salary_expectation.answer);
          setChangeJobReason(data.change_job_reason.answer);
          setIsScreeningFormSubmitted(data.is_submitted);

          // sequence of the following is important to set the sequence of questions in the screening form UI
          if (data.location_agree.isChecked) { //if the question is checked then add it to the total count of questions and turn the isChecked state var to true
            _countOfQuestions++;
            isCheckedObj.location_agree = true;
            tabIndices.location_agree = _countOfQuestions;
          }
          if (data.salary_agree.isChecked) {
            _countOfQuestions++;
            isCheckedObj.salary_agree = true;
            tabIndices.salary_agree = _countOfQuestions;
          }
          if (data.why_good_fit.isChecked) {
            _countOfQuestions++;
            isCheckedObj.why_good_fit = true;
            tabIndices.why_good_fit = _countOfQuestions;
          }
          if (data.salary_expectation.isChecked) {
            _countOfQuestions++;
            isCheckedObj.salary_expectation = true;
            tabIndices.salary_expectation = _countOfQuestions;
          }
          if (data.change_job_reason.isChecked) {
            _countOfQuestions++;
            isCheckedObj.change_job_reason = true;
            tabIndices.change_job_reason = _countOfQuestions;
          }
          if (data.own_laptop.isChecked) {
            _countOfQuestions++;
            isCheckedObj.own_laptop = true;
            tabIndices.own_laptop = _countOfQuestions;
          }
          if (data.own_vehicle.isChecked) {
            _countOfQuestions++;
            isCheckedObj.own_vehicle = true;
            tabIndices.own_vehicle = _countOfQuestions;
          }
          if (data.valid_dl_license.isChecked) {
            _countOfQuestions++;
            isCheckedObj.valid_dl_license = true;
            tabIndices.valid_dl_license = _countOfQuestions;
          }

          if (data.custom_questions && data.custom_questions[0]) {
            _countOfQuestions++;
            if (data.custom_questions[0].isChecked === true) isCheckedObj.custom1 = true;
            _customQuestions[0] = data.custom_questions[0].question;
            _customAnswers[0] = data.custom_questions[0].answer;
            tabIndices.custom1 = _countOfQuestions;
          }

          if (data.custom_questions && data.custom_questions[1]) {
            _countOfQuestions++;
            if (data.custom_questions[1].isChecked === true) isCheckedObj.custom2 = true;
            _customQuestions[1] = data.custom_questions[1].question;
            _customAnswers[1] = data.custom_questions[1].answer;
            tabIndices.custom2 = _countOfQuestions;
          }

          if (data.custom_questions && data.custom_questions[2]) {
            _countOfQuestions++;
            if (data.custom_questions[2].isChecked === true) isCheckedObj.custom3 = true;
            _customQuestions[2] = data.custom_questions[2].question;
            _customAnswers[2] = data.custom_questions[2].answer;
            tabIndices.custom3 = _countOfQuestions;
          }

          //console.log(tabIndices);

          setIsChecked(isCheckedObj);
          setTabIndices(tabIndices);
          setCountOfQuestions(_countOfQuestions);
          setCustomQuestions(_customQuestions);
          setCustomAnswers(_customAnswers);

          if (data.is_submitted !== null) { isShowOnePageRatings(true) } //is_submitted is null until the form is saved/submitted. it becomes true when saved/submitted.

          if (isTriggerFormOpenAfterApply && _countOfQuestions > 0) { // open screening form if questions are more than 0
            setIsScreeningFormOpen(true);
            isTriggerFormOpenAfterApply = false; //so that this loop is entered only one-time i.e. after apply
            //console.log(" getScreeningFormResponse() called;")
          }

          if (isTriggerFormOpenAfterApply && _countOfQuestions === 0) {// do not open screening form if no questions
            isTriggerFormOpenAfterApply = false; //so that this loop is entered only one-time i.e. after apply
            //console.log(" getScreeningFormResponse() not called as no questions;")
          }

          if (_countOfQuestions === 0) {
            setIsScreeningFormEnabled(false);
          } else if (_countOfQuestions > 0) {
            setIsScreeningFormEnabled(true);
          }

        }

      } else if (res.response) {
        console.log(res);
      } else {
        console.log("Something went wrong. Please try again.")
      }
    })
  }

  const handleIsAppliedJob = () => {
    let jobseeker_id = localStorage.getItem("jobseeker_id");
    let jobpost_id = jobID;
    ApiHandler.isAppliedJob(jobseeker_id, jobpost_id, (res) => {
      if (res.status === 200) {
        //console.log(res);
        setIsApplied(res.data);
        if (res.data) { // call the screening form responses after isApplied is found to be true
          getScreeningFormResponse();
        }
      } else if (res.response) {
        console.log(res);
      } else {
        console.log("Something went wrong. Please try again.")
      }
    })
  }

  const handleApply = () => {
    // AnalyticsHandler.track('JobDetail_Apply_BtnClick');
    if (!isSignedIn) { // if not signed then show login drawer
      handleDrawer();
    } else if (isSignedIn && isProfileCompleted) { //if signed in and profile is completed then call api to apply to job
      let payload = {
        jobseeker_id: localStorage.getItem("jobseeker_id"),
        jobpost_id: jobID,
        company_id: companyID,
      }

      ApiHandler.applyToJob(payload, (res) => {
        if (res.status === 200) {
          AnalyticsHandler.track('JobDetail_Apply_Success');
          setIsApplied(true);
          handleDialogClose();//to close the apply confirmation dialog
          isTriggerFormOpenAfterApply = true
          getScreeningFormResponse();// call api after apply to load the screening form questions

          // setIsScreeningFormOpen(true);
          // console.log(" getScreeningFormResponse() called;")

          // if (response && response.google_form_field) {
          //   handleDialogOpen();
          // } else {
          //   handleSnackBar("You have applied to the job successfully.")
          // }
        } else if (res.response) {
          handleSnackBar("Something went wrong. Please try again.")
        }
      })
    } else if (isSignedIn && !isProfileCompleted) {
      handleDialogOpen();
    }
  }

  const handleOpenScreeningForm = () => {
    setIsScreeningFormOpen(true);
  }

  //dialog box for create profile button--starts
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {//on click of apply btn the dialog opens to confirm
    AnalyticsHandler.track('JobDetail_Apply_BtnClick');
    setIsDialogOpen(true);
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  }
  //dialog box for create profile button--ends

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  React.useLayoutEffect(() => {
    // AnalyticsHandler.track('JobDetail_PageLoad', {
    //   'jobpost_id' : jobID
    // });
    AnalyticsHandler.track('JobDetail_PageLoad');
    setIsLoading(true);

    // let idArray = match.params._id.split('-');
    let payload = {
      //_id: match.params._id.substr(1, match.params._id.length - 1),
      _id: jobID,
      //jobseeker_id: localStorage.getItem("jobseeker_id")
    }

    if (location && location.state && location.state.latitude && location.state.longitude) {
      payload['latitude'] = location.state.latitude;
      payload['longitude'] = location.state.longitude;
    }

    // if(localStorage.getItem('latitude') && localStorage.getItem('longitude'))
    //     {
    //          payload['latitude'] = localStorage.getItem('latitude');
    //          payload['longitude'] = localStorage.getItem('longitude');
    //     }

    ApiHandler.getJobDetails(payload, response => {
      if (response.status == 200) {
        let data = response.data;
        let company_id = data && data.companies && data.companies.company_id ? data.companies.company_id : null;
        setResponse(data);
        setGoogleFormURL(data.google_form_field);
        setCompanyID(company_id);
        //let jobID = jobID
        setIsJobOpen(data.enable_status);

        setIsLoading(false);

        ApiHandler.getFavCompaniesList(0, 10, localStorage.getItem("jobseeker_id"), res => {
          if (res.status == 200) {
            setFavCompanies(res.data[0].results);
            if (res.data[0].results.length > 0) {
              let isFav = res.data[0].results.some(value => {
                if (value.company_id == company_id) {
                  return true
                } else {
                  return false
                }
              });
              setIsFavCompany(isFav)
            }
          }
        })

        ApiHandler.getJobCountforARole(jobID, res => {
          if (res.status === 200) {
            let resData = res.data;
            setJobsCountForARole(resData.count);
            setIsBottomBarVisible(true);
          } else {
            setIsBottomBarVisible(true);
          }
        });

        ApiHandler.getReviewsByCompany(0, 10, company_id, (response) => {
          if (response.status === 200) {
            setReviewsList(response.data[0].results);
            if (response.data[0].totalCount[0] && response.data[0].totalCount[0].count) {
              setReviewsCount(response.data[0].totalCount[0].count);
            }

          } else {
            console.log("some error occurred.");
          }
        })

      }
      else {
        console.log("error in getting a successful response");
        history.push({
          pathname: `/error`,
        });
      }
    });

  }, []);

  const [shareableFile, setShareableFile] = React.useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setShareableFile(reader.result);
      });

      var blobFile = e.target.files[0];

      var file = new File([blobFile], "picture.jpg", { type: 'image/jpeg' });
      var filesArray = [file];

      // let imageToBeShared = response.illustration_img_url ? response.illustration_img_url.blob() : null;

      //console.log(response.illustration_img_url);

      // var file = new File([imageToBeShared], "picture.jpg", { type: 'image/jpeg' });
      // var filesArray = [file];

      if (navigator.canShare
        && navigator.canShare({ files: filesArray })
      ) {
        navigator.share({
          text: 'Meeत works',
          files: filesArray,
          title: `Hi! Check out this job of ${response.job_title && response.job_title} 👉`,
          url: window.location.href
        });
      }

    }
  };

  const urlToBlob = () => {

    // let imageToBeShared = response.companies.company_image_url ? response.companies.company_image_url : null;

    let imageToBeShared = Image // local file
    //console.log(imageToBeShared)

    if (imageToBeShared === null) return;

    fetch(imageToBeShared)
      .then(res => res.blob()) // Get the response and return it as a blob
      .then(blob => {
        // Here's where you get access to the blob
        // And you can use it for whatever you want
        // Like calling ref().put(blob)

        var file = new File([blob], "picture.png", { type: 'image/png' });
        var filesArray = [file];
        //console.log(filesArray)

        if (navigator.canShare
          && navigator.canShare({ files: filesArray })
        ) {
          navigator.share({
            text: `Hi👋 Check out this job of ${response.job_title && response.job_title + " at " + companyNameFinal} 👉`,
            files: filesArray,
            title: `Hi👋 Check out this job of ${response.job_title && response.job_title + " at " + companyNameFinal} 👉`,
            url: window.location.href
          });
        }

      });
  }

  const convertImgToBase64URL = (url, callback, outputFormat) => {
    //let img = document.getElementById('hiddenImgForBase64URL');
    let img = document.createElement('img');
    let _role = response.job_title ? response.job_title : "";
    let role = _role ? _role.slice(0, 35) + (_role.length > 35 ? "..." : "") : "";
    let location = response.isWorkFromHome && !jobLocationArray ? "🏠घर से" : jobLocationArray && !jobLocationArray[1] ? "📍" + jobLocationArray[0].city : jobLocationArray && jobLocationArray[1] && !jobLocationArray[2] ? "📍" + jobLocationArray[0].city + ", " + jobLocationArray[1].city :
      jobLocationArray && jobLocationArray[1] ? "📍" + jobLocationArray[0].city + ", " + jobLocationArray[1].city + " and multiple locations" : ""

    let sector = response.companies.sector_name ? response.companies.sector_name + " industry" : "";
    // let _salary = response.job_salary_amount ? response.job_salary_amount + " " + (response.variable_salary_unit ? response.variable_salary_unit : "") : " ";
    let _salary = response.job_salary_amount ?
      response.job_salary_amount === 0 ? "Unpaid" :
        response.job_salary_amount === "" ? "No fixed amount" :
          response.job_salary_amount + "/m" : "No fixed amount";
    let _incentives = response.variable_salary_amount ? " + " + response.variable_salary_amount : "";
    let variableUnit = response.variable_salary_unit ? " " + response.variable_salary_unit : "";
    _salary = _salary + _incentives + variableUnit;
    let salary = _salary ? "💰 " + _salary.slice(0, 50) + (_salary.length > 50 ? "..." : "") : "";

    img.src = url;
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      let canvas = document.createElement('CANVAS'), ctx = canvas.getContext('2d'), dataURL;
      canvas.height = 400;
      canvas.width = 600;
      //draw bg rect
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, 600, 400);

      // define gradient
      var gradient = ctx.createLinearGradient(0, 0, 600, 0);
      gradient.addColorStop("0", "#14967B");
      gradient.addColorStop("0.5", "#5BC2A8");
      gradient.addColorStop("1.0", "#14967B");

      // Fill with gradient
      ctx.strokeStyle = gradient;
      ctx.lineWidth = 10;
      ctx.strokeRect(0, 0, 600, 400);

      //draw logo image
      // ctx.drawImage(img, 240, 15, 121, 50); //(imagenode, x, y, w, h)
      //ctx.drawImage(img, 410, 310, 121, 50); //(imagenode, x, y, w, h)
      ctx.drawImage(img, 240, 330, 121, 50); //(imagenode, x, y, w, h)


      // write text
      // ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';

      ctx.fillStyle = 'black';
      ctx.font = "bold 30px Arial";
      //ctx.fillText(role, canvas.width / 2, 150);
      ctx.fillText(role, canvas.width / 2, 140);

      ctx.font = "22px Arial";
      ctx.fillText("@" + companyNameFinal, canvas.width / 2, 190);

      // ctx.fillStyle = '#282828';
      // ctx.font = "15px Arial";
      // ctx.fillText(location, canvas.width / 2, 220);

      ctx.fillStyle = '#797979';
      ctx.font = "20px Arial";
      ctx.fillText(location, canvas.width / 2, 230);

      ctx.fillStyle = '#14967B';
      ctx.font = "25px Arial";
      ctx.fillText(salary, canvas.width / 2, 270);

      // ctx.beginPath();
      // ctx.lineCap = "round";
      // ctx.moveTo(250, 320);
      // ctx.lineTo(350, 320);
      // ctx.lineWidth = 1;
      // ctx.stroke();

      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
      canvas = null;
    };

  }

  const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev);
  }
  // drawer callback ends

  // share drawer callback starts
  const [refreshShareDrawer, doRefreshShareDrawer] = React.useState(false);
  const [forShareRefreshOnly, setForShareRefreshOnly] = React.useState(false);
  const [canShareDrawerOpen, setCanShareDrawerOpen] = React.useState(false);
  const handleShareDrawer = () => {
    setCanShareDrawerOpen(true)
    doRefreshShareDrawer(prev => !prev);
  }
  // share drawer callback ends

  //screening form
  const [isScreeningFormOpen, setIsScreeningFormOpen] = React.useState(false);
  const [isDefault, setIsDefault] = React.useState(true);
  const [showOnePageRatings, isShowOnePageRatings] = React.useState(false);

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const handleSnackBar = (message) => {
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev)
  }
  // snackbar callback ends

  //sort bar bg effect on scroll starts
  let lastScrollY = 0;
  let scrolling = false;

  const handleScrollChange = () => {
    lastScrollY = window.scrollY;
    if (jobDetailRef && jobDetailRef.current && lastScrollY > jobDetailRef.current.clientHeight - 120) {
      scrolling = true;
    } else {
      scrolling = false
    }
    if (!scrolling && jobDetailRef && jobDetailRef.current) {
      window.requestAnimationFrame(() => {
        setNavValue(0);
      });
    } else if (scrolling && jobDetailRef && jobDetailRef.current) {
      window.requestAnimationFrame(() => {
        setNavValue(1);
      });
    }
  };

  React.useEffect(() => {
    if (response && response.companies && response.companies.is_favourite)
      setDoFollow(!response.companies.is_favourite);
  }, [response]);
  //setting follow button 

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);
  //sort bar bg effect on scroll ends

  let working_days_temp = response.working_days, i = 0,
    working_days = [{
      day: "M",
      present: false
    },
    {
      day: "T",
      present: false
    },
    {
      day: "W",
      present: false
    },
    {
      day: "T",
      present: false
    },
    {
      day: "F",
      present: false
    },
    {
      day: "S",
      present: false
    },
    {
      day: "S",
      present: false
    }
    ]
  if (response.working_days) {
    for (; i < working_days_temp.length; i++) {
      if (working_days_temp[i] == "MON")
        working_days[0].present = true;
      if (working_days_temp[i] == "TUE")
        working_days[1].present = true;
      if (working_days_temp[i] == "WED")
        working_days[2].present = true;
      if (working_days_temp[i] == "THU")
        working_days[3].present = true;
      if (working_days_temp[i] == "FRI")
        working_days[4].present = true;
      if (working_days_temp[i] == "SAT")
        working_days[5].present = true;
      if (working_days_temp[i] == "SUN")
        working_days[6].present = true;
    }
    // debugger;
  }

  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  let responseDate = response.job_created_at ? new Date(response.job_created_at) : null;
  let jobUpdatedDate = response.job_created_at ? responseDate.getDate() + " " + months[responseDate.getMonth()] + ", " + responseDate.getFullYear() : null

  let companyNameArray = response.companies && response.companies.company_name ? response.companies.company_name.split("-") : "";
  // remove location after ':' and remove pvt ltd etc and remove content following '('
  let companyNameFinal = companyNameArray ? companyNameArray[0].split(':')[0].split("(")[0].replace("Pvt. Ltd.", "").replace("Private Limited", "").replace("Pvt Ltd.", "").replace("Pvt. Ltd", "").replace("Pvt Ltd", "").replace("pvt ltd", "").replace("Ltd", "").replace("Ltd.", "").replace("Limited", "").replace("ltd", "") : "";

  let companyInitialsArr = companyNameFinal ? companyNameFinal.split(" ") : "";
  let companyInitial1 = companyInitialsArr && companyInitialsArr[0] ? companyInitialsArr[0].substring(0, 1) : "";
  let companyInitial2 = companyInitialsArr && companyInitialsArr[1] ? companyInitialsArr[1].substring(0, 1) : "";
  let companyInitials = companyInitial1 + companyInitial2;

  let jobLocationArray = response.job_location && response.job_location[0] && response.job_location[0].city ? response.job_location : null;

  let percentageOfWomen = response.companies && response.companies.number_of_female_employees && response.companies.number_of_employees ? Math.round((response.companies.number_of_female_employees / response.companies.number_of_employees) * 100) : null;

  let ratingsText = response.companies && response.companies.ratings_count ? response.companies.ratings_count === 1 ? "rating" : "ratings" : null;
  let reviewsText = response.companies && response.companies.reviews_count ? response.companies.reviews_count === 1 ? "review" : "reviews" : null;

  //Follow button functionality starts
  const handleFollow = () => {
    //  setDoFollow(!response.companies.is_favourite);    

    if (doFollow)
      response.companies.no_of_followers = response.companies.no_of_followers + 1;
    else
      response.companies.no_of_followers = response.companies.no_of_followers > 0 ? response.companies.no_of_followers - 1 : 0;

    let payload = {
      // company_id,
      // invite_id: props.hit.invite_id,
      // shortlist_status: !selected,
      jobseeker_id: localStorage.getItem("jobseeker_id"),
      liked: !isFavCompany,
      company_id: response.companies.company_id
    }
    // if (props.signInStatus) {
    //  setGotResponse(false);
    ApiHandler.followCompanyToggle(payload, response => {
      if (response.status == 200) {
        setIsFavCompany(prev => !prev);
        setIsUnfollowDialogOpen(false);
      } else {
        var ErrorMessage = 'Oops! Something went wrong. Please try again.';
        if (response.response) {
          let statusCode = response.response.status;
          if (statusCode === 500) {
            ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
          } else if (statusCode === 404) {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          } else if (statusCode === 400) {
            ErrorMessage = response.response.data.message;
            //ErrorMessage = 'Oops! Something went wrong. Please try again.';
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
        } else if (response.message === 'Network Error') {
          ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
        } else {
          ErrorMessage = 'Oops! Something went wrong. Please try again.';
        }
        //handleAlert(ErrorMessage, 'error');
        console.log(ErrorMessage);
        // setGotResponse(true);
      }
    })
    // }
  }

  const [isUnfollowDialogOpen, setIsUnfollowDialogOpen] = React.useState(false);

  const handleUnfollowDialogClose = () => {
    setIsUnfollowDialogOpen(false);
  }

  const [isBackIconDisplayed, setIsBackIconDisplayed] = React.useState(true);
  const [refreshScreening, setRefreshScreening] = React.useState(false)

  let screeningFormRefreshRef = React.useRef(true)
  React.useEffect(() => {
    //console.log('entered use effect')
    if (screeningFormRefreshRef.current) {
      screeningFormRefreshRef.current = false;
      return;
    }
    //console.log('crossed loop')
    getScreeningFormResponse() //call this to check the applied status when form is submitted successfully
  }, [refreshScreening]) // refresh the screening form parent state vars when form is submitted from child

  React.useEffect(() => {
    if (isSignedIn) handleIsAppliedJob(); //call this to check the applied status onload and then after every sign in
    let pcVal = localStorage.getItem('profile_completed') === "true" ? true : false;
    setIsProfileCompleted(pcVal);
  }, [isSignedIn]) // if user logs in from job detail page then isProfileCompleted to be set so that on click of apply, correct action happens

  //if pageStateOnReturn is null then set it to default so that ui back btn click will navigate to jobs main page. This handles the case when user lands directly on this page via a link.  
  React.useEffect(() => {
    let isBrowserHistory = sessionStorage.getItem("pageStateOnReturn");
    if (!isBrowserHistory) {
      sessionStorage.setItem("pageStateOnReturn", "default");
      setIsBackIconDisplayed(false);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{response.job_title ? response.job_title : "Job Title"}</title>
        <meta name="description" content={response.job_description ? response.job_description : "details about this job by Meetworks"} />
      </Helmet>
      <div className={classes.root} style={{ paddingTop: 0 }}>
        {/* 
      <div>{`The URL is "${match.url}"`}</div>
      <div>{`It matched the path "${match.path}"`}</div>
      <div>{`The parameter is "${match.params._id}"`}</div>
    </div> */}

        {isLoading
          ? <div style={{
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
          }}>
            <ListLoader setListLoaderRef={setListLoaderRef} isText={"none"} />
          </div>
          :
          <>
            {/* Top App Bar */}
            <AppBar position="fixed" className={classes.tabBar}>
              <Toolbar variant="dense" style={{ padding: 0 }}>
                <div className={classes.grow} style={{ width: '100%' }}>
                  <div className={classes.sectionMobile} style={{ display: "flex", justifyContent: 'space-evenly' }}>
                    <IconButton id="backButton_jobDetail" label="Back"
                      onClick={() => {
                        AnalyticsHandler.track('JobDetail_back_BtnClick');
                        if (sessionStorage.getItem("pageStateOnReturn") === "default") { // if default then go to jobs page and reset the the page state to default 
                          history.push({
                            pathname: `/`,
                            // state: {
                            //   pageStateOnReturn: "default",
                            // }
                          });
                        } else { // else go to prev page which is jobs list page for specific roles
                          history.goBack();
                        }
                      }}
                      style={{ position: 'relative', zIndex: 9, paddingLeft: 10 }}>
                      {isBackIconDisplayed // if user lands directly on this page via link then don't show back arrow
                        ? <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
                        : null
                      }
                    </IconButton>
                    <Grid
                      item xs={9} md={11}
                      container
                      direction='column'
                      justifyContent="center"
                      alignItems='flex-start'>
                      <Typography
                        variant="subtitle1"
                        className={classes.ellipsisPost2Lines}
                        style={{ color: theme.palette.common.blackDark, lineHeight: '1.2', textAlign: 'center', width: '100%' }}>
                        {response.job_title && response.job_title} <span style={{ color: theme.palette.common.grey }}>{response.job_title && "at"}</span> {companyNameFinal}
                      </Typography>
                    </Grid>
                    <CopyToClipboard className={classes.hideOnDesktop} style={{ padding: 0 }} text={window.location.origin + match.url}>
                      <IconButton
                        id="shareButton"
                        // style={{ position: 'absolute', top: 0, right: 10, padding: '12px 0' }}
                        onClick={() => {
                          AnalyticsHandler.track('JobDetail_shareURL_BtnClick');
                          //handleSnackBar(`Link copied. Share Now!!`);
                          //handleShareDrawer();
                          //urlToBlob();

                          // let imageToBeShared = response.companies.company_image_url ? response.companies.company_image_url : null;

                          // console.log(imageToBeShared)

                          //commenting the below

                          convertImgToBase64URL(Image, function (base64Img) {
                            //console.log(base64Img);

                            var blobFile = dataURLtoBlob(base64Img);
                            var file = new File([blobFile], "picture.jpeg", { type: 'image/jpeg' });
                            var filesArray = [file];

                            if (
                              // navigator.canShare && navigator.canShare({ files: filesArray })
                              navigator.share
                            ) {
                              navigator.share({
                                title: `Interesting job opportunity`,
                                // title: `Hi👋 Check out this job of ${response.job_title && response.job_title + " at " + companyNameFinal} 👉`,
                                text: `Hi👋 Check out this job of ${response.job_title && response.job_title + " at " + companyNameFinal} 👉`,
                                //url: "https:findwork.meetworks.in",
                                files: filesArray,
                                url: window.location.href

                              });
                            } else {
                              handleSnackBar(`Link copied. Share Now!!`);
                            }

                          }, 'image/jpeg');

                          // if (navigator.share) {
                          //   navigator.share({
                          //     title: 'Meeत works',
                          //     text: 'Meeत पर ढूंढें अपनी location के पास best opportunities',
                          //     url: `${window.location.origin + match.url}`,
                          //   })
                          //     .then(() => console.log('Successful share'))
                          //     .catch((error) => console.log('Error sharing', error));
                          // }
                        }}>

                        {/* <input id="galleryImageUploadBtn_id1" type="file" accept="image/*" onChange={onSelectFile} hidden />
                      <label htmlFor="galleryImageUploadBtn_id1">
                      </label> */}

                        <ShareOutlinedIcon color="primary" />
                      </IconButton>
                    </CopyToClipboard>

                    {/* <img id="hiddenImgForBase64URL" 
                  hidden 
                  src={Image}
                  style={{height: 30}}
                  /> */}
                  </div>
                </div>
              </Toolbar>
            </AppBar>

            {/* tabbar */}
            <AppBar position="fixed" className={classes.tabBar} style={{ zIndex: 1200, top: 48 }}>
              <Toolbar variant="dense" style={{ minHeight: 40 }}>
                <div className={classes.grow} style={{ width: '100%' }}>
                  <div className={classes.sectionMobile} style={{ display: "flex" }}>
                    <Tabs
                      value={navValue}
                      onChange={handleNavChange}
                      scrollButtons="on"
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="navigation header bar"
                      className={classes.navTabBar}
                      classes={{ flexContainer: classes.flexContainer, indicator: classes.indicator }}
                    >
                      <Tab id="jobs_tab_mobile_id" label="Work Details" onClick={() => { AnalyticsHandler.track('JobDetail_workDetails_BtnClick'); scrollToJobDetail() }} className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }}  {...a11yProps(0)} />
                      <Tab id="profile_mobile_tab_id" label="Company" onClick={() => { AnalyticsHandler.track('JobDetail_companyDetails_BtnClick'); scrollToCompany() }} className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yProps(1)} />
                    </Tabs>
                  </div>
                </div>
              </Toolbar>
            </AppBar>

            {/* About Job */}
            <div id="jobDetailRef_id" ref={jobDetailRef} className={classes.section} style={{ position: 'relative', paddingBottom: 20 }}>

              {/* intro tile */}
              <Grid
                container
                direction="column"
                alignItems="flex-start"
                className={classes.cardFrame}>
                {response.job_title ? <Grid
                  item xs
                  container
                  wrap='nowrap'
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginBottom: 12, padding: '0 10px', position: 'relative' }}>
                  <Grid
                    item xs={6}
                    container
                    justifyContent="flex-start"
                    alignItems="center">
                    <Typography variant="h1" className={classes.fontLgMobile}>{response.job_title} </Typography>
                  </Grid>
                  <div
                    className={`${classes.imgWrapper} ${classes.imgWrapperTop}`}>
                    {response.companies.company_image_url ?
                      <img className={classes.imageTag} alt="company logo"
                        onLoad={(event) => { event.target.style.opacity = 1 }}
                        onError={(event) => { event.target.nextElementSibling.style.opacity = 1 }}
                        src={response.companies.company_image_url}
                        style={{ opacity: 0 }} />
                      :
                      <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>
                    }
                    <Typography variant="h3" style={{ position: 'absolute', textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem', opacity: 0 }}>{companyInitials}</Typography>
                  </div>
                  {/* <CopyToClipboard text={window.location.host + match.url} style={{ position: 'absolute', right: 10, top: 15, padding: 0 }}>
                  <IconButton id="shareButton" onClick={() => { AnalyticsHandler.track('JobDetail_shareURL_BtnClick'); handleSnackBar(`Link copied. Share Now!!`) }}>
                    <ShareOutlinedIcon color="primary" />
                  </IconButton>
                </CopyToClipboard> */}
                </Grid> : null}
                {/* show location1, location2 and then wfh if available */}
                {jobLocationArray ?
                  <Grid
                    item xs={12}
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginBottom: 5, padding: '0 10px' }}>
                    <LocationOnOutlinedIcon fontSize="small" className={classes.icon} style={{ width: 'auto' }} />
                    <Typography className={classes.cardText} style={{ width: '90%' }}>{
                      jobLocationArray.map((location, index) =>
                        (<span>{response.isWorkFromHome && index == 0 ? "घर से, " + location.city + ", " : index !== (jobLocationArray.length - 1) ? location.city + ", " : location.city}</span>)

                      )
                    }</Typography>

                    {/* {jobLocationArray[1] && jobLocationArray[1].city && (<Typography className={classes.cardText}>{", " + jobLocationArray[1].city}</Typography>)}

                {response.isWorkFromHome && <Typography className={classes.cardText} style={{ fontSize: '0.875rem', marginRight: 3 }}>, घर से </Typography>}

                {jobLocationArray[2] && jobLocationArray[2].city && (<Typography className={classes.cardText}> & more...</Typography>)} */}
                  </Grid> : null}

                {/* if job is wfh only */}
                {response.isWorkFromHome && !jobLocationArray ? <Grid
                  item xs={12}
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ marginBottom: 5, padding: '0 10px' }}>
                  <LocationOnOutlinedIcon fontSize="small" className={classes.icon} />
                  <Typography className={classes.cardText}>घर से</Typography>

                </Grid> : null}



                {/* {response.variable_salary_amount || response.variable_salary_unit ?
                <Grid
                  item xs={12}
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ marginBottom: 12, padding: '0 10px' }}>
                  <Grid
                    item xs={12}
                    container
                    justifyContent="flex-start"
                    alignItems="center">
                    <Typography
                      className={`${classes.cardTag} ${classes.ellipsis}`}
                      style={{ maxWidth: '90%', width: 'unset', marginRight: 0, backgroundColor: theme.palette.primary.light, borderRadius: 4, color: theme.palette.common.blackDark, fontWeight: 400 }}>{response.variable_salary_amount ? response.variable_salary_amount : " "}{" "}
                      <span style={{ fontWeight: 400, fontSize: 12, }}>{response.variable_salary_unit ? response.variable_salary_unit : ""}</span>
                    </Typography>
                  </Grid>
                </Grid> : null} */}

                <Grid
                  item xs={10}
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ marginBottom: 10, padding: '0 10px' }}>
                  <WorkOutlineRoundedIcon fontSize="small" className={classes.icon} />
                  {response.opportunity_type ?
                    <Typography className={classes.cardText} >
                      {response.opportunity_type === "Job" ? "Full-time"
                        : response.opportunity_type === "Freelance" ? "Part-time"
                          : response.opportunity_type}</Typography>
                    : null}
                  {/* <HourglassEmptyRoundedIcon fontSize="small" className={classes.icon} style={{ marginLeft: 15 }} /> */}
                  {(response.opportunity_type && response.internship_duration) || (response.opportunity_type && response.no_of_vacancies) ? <span style={{ margin: '0 10px' }}>·</span> : null}
                  {response.internship_duration ? <Typography className={classes.cardText}>{response.internship_duration}</Typography> : null}
                  {response.internship_duration && response.no_of_vacancies ? <span style={{ margin: '0 10px' }}>·</span> : null}
                  {response.no_of_vacancies ? <Typography className={classes.cardText}>{response.no_of_vacancies} {response.no_of_vacancies == 1 ? "Opening" : "Openings"} </Typography> : null}
                </Grid>
                <Grid
                  item xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginBottom: 12, padding: '0 10px' }}>
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{
                      borderRadius: 4,
                      // background: theme.palette.primary.dark, 
                      width: 'auto'
                    }}>
                    <Typography
                      // className={`${classes.cardTag} ${classes.ellipsis}`}
                      className={`${classes.cardTag} ${classes.fontMdMobile}`}
                      style={{ maxWidth: '90%', width: 'unset', marginRight: 0, height: 25 }}>
                      {/* {response.job_salary_amount ? response.job_salary_amount : " "} */}
                      {response.job_salary_amount ?
                        response.job_salary_amount === 0 ? "Unpaid" :
                          response.job_salary_amount === "" ? "No fixed amount" :
                            response.job_salary_amount : "No fixed amount"}
                      <span
                      // style={{ fontWeight: 400, fontSize: 12, }}
                      >{response.job_salary_amount ? "/month" : null}{response.variable_salary_amount ? " + " + response.variable_salary_amount : " "}{" "}{response.variable_salary_unit ? response.variable_salary_unit : ""} </span>
                    </Typography>
                    <IconButton className={classes.infoIcon} id="infoSalaryButton" aria-describedby="infoSalary_id" variant="contained" color="primary" onClick={handleSalaryInfoClick}>
                      <InfoOutlinedIcon style={{ fontSize: '1rem', color: 'white' }}></InfoOutlinedIcon>
                    </IconButton>

                    <Popover
                      id="infoSalary_id"
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleSalaryInfoClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Typography className={classes.cardinfoPopoverText}>{response.job_salary ? response.job_salary : ""}</Typography>
                    </Popover>
                    {/* <WorkOutlineRoundedIcon fontSize="small" className={classes.icon} /> */}
                  </Grid>
                  <CopyToClipboard className={classes.hideOnMobile} style={{ padding: 0 }} text={window.location.origin + match.url}>
                    <IconButton
                      id="shareButton"
                      // style={{ position: 'absolute', top: 0, right: 10, padding: '12px 0' }}
                      onClick={() => {
                        AnalyticsHandler.track('JobDetail_shareURL_BtnClick');
                        //handleSnackBar(`Link copied. Share Now!!`);
                        //handleShareDrawer();
                        //urlToBlob();

                        // let imageToBeShared = response.companies.company_image_url ? response.companies.company_image_url : null;

                        // console.log(imageToBeShared)

                        //commenting the below

                        convertImgToBase64URL(Image, function (base64Img) {
                          //console.log(base64Img);

                          var blobFile = dataURLtoBlob(base64Img);
                          var file = new File([blobFile], "picture.jpeg", { type: 'image/jpeg' });
                          var filesArray = [file];

                          if (
                            // navigator.canShare && navigator.canShare({ files: filesArray })
                            navigator.share
                          ) {
                            navigator.share({
                              title: `Interesting job opportunity`,
                              // title: `Hi👋 Check out this job of ${response.job_title && response.job_title + " at " + companyNameFinal} 👉`,
                              text: `Hi👋 Check out this job of ${response.job_title && response.job_title + " at " + companyNameFinal} 👉`,
                              //url: "https:findwork.meetworks.in",
                              files: filesArray,
                              url: window.location.href

                            });
                          } else {
                            handleSnackBar(`Link copied. Share Now!!`);
                          }

                        }, 'image/jpeg');

                        // if (navigator.share) {
                        //   navigator.share({
                        //     title: 'Meeत works',
                        //     text: 'Meeत पर ढूंढें अपनी location के पास best opportunities',
                        //     url: `${window.location.origin + match.url}`,
                        //   })
                        //     .then(() => console.log('Successful share'))
                        //     .catch((error) => console.log('Error sharing', error));
                        // }
                      }}>

                      {/* <input id="galleryImageUploadBtn_id1" type="file" accept="image/*" onChange={onSelectFile} hidden />
                      <label htmlFor="galleryImageUploadBtn_id1">
                      </label> */}

                      <ShareOutlinedIcon color="primary" />
                    </IconButton>
                  </CopyToClipboard>
                </Grid>
                {response.job_created_at ?
                  <Typography variant="caption" style={{ marginLeft: 10, color: '#737373' }}>Updated: <span>{jobUpdatedDate}</span></Typography>
                  : null}

                {/* <Grid
                item xs={12}
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{ marginBottom: 10 }}>
                <VacancyIcon fontSize="small" className={classes.icon} />
                <Typography className={classes.cardText}>20 Vacancies</Typography>
              </Grid> */}

                {response.job_created_at ? <Grid
                  item xs={12}
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    //padding: '8px 10px 0',
                    padding: '0 10px 0',
                    // borderTop: '1px solid',
                    // borderTopColor: theme.palette.primary.main
                  }}>
                  {/* <IconButton style={{ padding: 0 }} onClick={playPauseSound}>
                    {isPlaying ?
                      <PauseCircleFilledRoundedIcon fontSize="medium" color="secondary" className={classes.sectionItem_heading_icon} />
                      : <PlayCircleFilledWhiteRoundedIcon fontSize="medium" color="secondary" className={classes.sectionItem_heading_icon} />
                    }
                    <Typography variant="subtitle1" style={{ color: theme.palette.primary.dark }}>Job के बारे में सुनिये </Typography>
                  </IconButton> */}
                  {/* {isPlaying ?
                    <IconButton style={{ padding: 0 }} onClick={pauseSound}>
                      <PauseCircleFilledRoundedIcon fontSize="small" color="secondary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="subtitle1" style={{ color: theme.palette.primary.dark }}>Job के बारे में सुनिये </Typography>
                    </IconButton>
                    :
                    <IconButton style={{ padding: 0 }} onClick={playSound}>
                      <PlayCircleFilledWhiteRoundedIcon fontSize="small" color="secondary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="subtitle1" style={{ color: theme.palette.primary.dark }}>Job के बारे में सुनिये </Typography>
                    </IconButton>
                  } */}

                  {/* <audio
                    ref={myRef}
                    src={jobAudioFile}
                    onEnded={handleAudioEnded}
                  />
                  {audioStatus ? (
                    <IconButton style={{ padding: 0 }} onClick={pauseAudio}>
                      <PauseCircleFilledRoundedIcon fontSize="small" color="secondary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="subtitle1" style={{ color: theme.palette.primary.dark }}>Job के बारे में सुनिये </Typography>
                    </IconButton>
                  ) : (
                    <IconButton style={{ padding: 0 }} onClick={startAudio}>
                      <PlayCircleFilledWhiteRoundedIcon fontSize="small" color="secondary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="subtitle1" style={{ color: theme.palette.primary.dark }}>Job के बारे में सुनिये </Typography>
                    </IconButton>
                  )} */}

                  {/* 
                  <Typography variant="caption">Updated: <span>{new Date(response.job_created_at).getDate() + " " + months[new Date(response.job_created_at).getMonth() - 1] + ", " + new Date(response.job_created_at).getFullYear()}</span></Typography> */}

                </Grid> : null}
              </Grid>


              <div style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', padding: '10px 0' }}>
                <Grid container className={classes.flexDirectionColMobile} >
                  {/* Working Days */}
                  <Grid item xs={10} sm={4} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <DateRangeOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography className={classes.sectionItem_heading_title} variant="h4" color="primary">Working Days</Typography>
                    </Grid>
                    {response.working_days && response.working_days[0] && response.working_days[0] !== "" ?
                      <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                        <Typography className={(working_days && working_days[0].present) == true ? classes.dayChip : classes.dayChip_inactive}>M</Typography>
                        <Typography className={(working_days && working_days[1].present) == true ? classes.dayChip : classes.dayChip_inactive}>T</Typography>
                        <Typography className={(working_days && working_days[2].present) ? classes.dayChip : classes.dayChip_inactive}>W</Typography>
                        <Typography className={(working_days && working_days[3].present) ? classes.dayChip : classes.dayChip_inactive}>T</Typography>
                        <Typography className={(working_days && working_days[4].present) ? classes.dayChip : classes.dayChip_inactive}>F</Typography>
                        <Typography className={(working_days && working_days[5].present) ? classes.dayChip : classes.dayChip_inactive}>S</Typography>
                        <Typography className={(working_days && working_days[6].present) == true ? classes.dayChip : classes.dayChip_inactive}>S</Typography>
                      </Grid> : <Typography variant="body1">Flexible</Typography>
                      // response.working_days && response.working_days.length === 0 ?
                      //   <Typography variant="body1">Flexible</Typography> : null
                    }
                  </Grid>

                  {/* Timings */}
                  <Grid item xs={10} sm={4} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <ScheduleOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">Timings</Typography>
                    </Grid>
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                      {/* <Typography variant="body1"><span className={classes.highlight}>Shift A</span>: 7:30 am - 4:00 pm</Typography>
                 */}
                      <Typography variant="body1">{response.working_time ? response.working_time : "Flexible"}</Typography>
                    </Grid>
                  </Grid>
                  {/* Salary Description */}
                  {response.job_salary ?
                    <Grid item xs={10} sm={4} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                      <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                        <AccountBalanceWalletOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                        <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">Salary details</Typography>
                      </Grid>
                      <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                        <Typography variant="body1" style={{ listStyle: "circle", whiteSpace: "pre-line" }}>{response.job_salary}
                        </Typography>
                      </Grid>
                    </Grid>
                    : null}
                </Grid>
                <Grid container className={classes.flexDirectionColMobile} >
                  {/* Incentive Description */}
                  {response.variable_salary_amount ?
                    <Grid item xs={10} sm={4} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                      <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                        <AccountBalanceWalletOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                        <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">Incentive</Typography>
                      </Grid>
                      <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                        <Typography variant="body1" style={{ listStyle: "circle", whiteSpace: "pre-line" }}>{response.variable_salary_amount + '/' + response.variable_salary_unit}
                        </Typography>
                      </Grid>
                    </Grid>
                    : null}
                  {/* Experience */}
                  {response.experience_required ?
                    <Grid item xs={10} sm={4} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                      <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                        <WorkOutlineRoundedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                        <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">Experience</Typography>
                      </Grid>
                      <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                        <Typography variant="body1">{response.experience_required}</Typography>
                      </Grid>
                    </Grid> : null}
                  {/* Gender */}
                  {(response.gender_pref && response.gender_pref[0] != "") ?
                    <Grid item xs={10} sm={4} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                      <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                        <GenderOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                        <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">Gender Preference</Typography>
                      </Grid>
                      <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} ><Typography variant="body1">
                        {response.gender_pref.map((item, index) => (
                          <span key={index}>{item}{index != (response.gender_pref.length - 1) ? "," : ""} {index == (response.gender_pref.length - 1) && index == 0 && item !== "Any" ? "only." : ""}</span>
                        ))}
                        <span style={{ opacity: '0.75' }}>{response.gender_pref_reason ? " (" + response.gender_pref_reason + ")" : null}</span></Typography>
                      </Grid>
                    </Grid> : null}
                </Grid>
              </div>


              {/* Description */}
              {response.job_description ?
                <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                  <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                    <FormatListBulletedRoundedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                    <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">काम की details</Typography>
                  </Grid>
                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                    <Typography variant="body1" style={{ listStyle: "circle", whiteSpace: "pre-line" }}>{response.job_description}
                    </Typography>
                  </Grid>
                </Grid>
                : null}

              {/* Skills */}
              {response.skills_required && response.skills_required[0] && response.skills_required[0] !== "" && Object.keys(response.skills_required[0]).length !== 0 ?
                <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                  <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                    <SkillsOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                    <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">
                      {/* क्या आना चाहिए  */}
                      कौन से skills चाहिए
                    </Typography>
                  </Grid>
                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                    <ul style={{
                      margin: 0,
                      color: theme.palette.primary.light,
                      listStyleType: "square",
                      paddingInlineStart: 15
                    }}>
                      {response.skills_required.map((item, index) =>
                        // <Typography key={index} className={classes.chip}>{item}</Typography>
                        <li key={index}>
                          <Typography className={classes.chip}
                            style={{
                              fontWeight: "400",
                              color: theme.palette.common.blackDark,
                              padding: 0,
                              backgroundColor: "transparent"
                            }}>
                            {item.skill ?
                              <span style={{ fontWeight: "500" }}>{item.skill}</span>
                              : null}
                            {typeof item === 'string' ? item : null}
                            {item.sector ?
                              <> in
                                <span style={{ fontWeight: "500" }}> {item.sector} </span> industry
                              </> : null}
                            {item.no_of_experience ?
                              <> -
                                <span style={{ fontWeight: "500" }}> {item.no_of_experience}{item.no_of_experience === "1" ? " yr" : " yrs"} </span> exp
                              </>
                              : null}
                          </Typography>
                        </li>
                      )}
                    </ul>
                  </Grid>
                </Grid> : null}

              {/* Qualification */}
              {response.qualification_req && response.qualification_req[0] && response.qualification_req[0] !== "" && Object.keys(response.qualification_req[0]).length !== 0 ?
                <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                  <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                    <SchoolOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                    <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">Preferred Qualification(s)</Typography>
                  </Grid>

                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                    {response.qualification_req && response.qualification_req.map((item, index) =>
                      <Typography key={index} variant="body1">{index === 0 ? item : ", " + item}
                        {/* {index != response.qualification_req.length - 1 ? ", " : ""}  */}
                      </Typography>
                    )}
                  </Grid>
                </Grid> : null}

              {/* Other Requirements */}
              {response.candidate_requirement ?
                <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                  <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                    <OtherRequirementsIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                    <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">अन्य requirements </Typography>
                  </Grid>
                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                    {/* <ul style={{listStyle: 'circle', margin: 0, paddingLeft: 20}}> */}
                    <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>{response.candidate_requirement}.</Typography>
                    {/* <Typography variant="body1" component="li">Character Certificate.</Typography> */}
                    {/* </ul> */}
                  </Grid>
                </Grid> : null}

              {/* Benefits */}
              {response.work_benefits && response.work_benefits[0] && response.work_benefits[0] != "" ?
                <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                  <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                    <BenefitsIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                    <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">
                      सुविधाएँ
                    </Typography>
                  </Grid>
                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                    {response.work_benefits.map((item, index) =>
                      <Typography key={index} className={classes.chip}>{item}</Typography>
                    )}
                  </Grid>
                </Grid> : null}

              {/* to set scroll ref for company section tab */}
              <div style={{ position: 'absolute', bottom: 80 }} ref={companyRef}></div>
            </div>

            {/* About Company */}
            {response.companies ?
              <div className={classes.section} style={{ marginTop: 5, paddingTop: 20, paddingBottom: 70 }}>
                <Grid container wrap='nowrap' justifyContent="space-between" alignItems='center' style={{ marginBottom: 25 }}>
                  <Typography variant="h1" style={{ color: theme.palette.common.grey, fontWeight: 600, fontSize: 16 }}>About Company </Typography>
                  {response && response.companies && response.companies.company_mobile ?
                    <Grid container justifyContent='flex-end' xs={4}>
                      {isFavCompany ? <FavoriteIcon color="primary" onClick={() => setIsUnfollowDialogOpen(true)} /> : <FavoriteBorderIcon color="primary" onClick={handleFollow} />}
                      <a href={"tel:" + response.companies.company_mobile} target="self" style={{ textDecoration: 'none' }}>
                        <Button
                          id="CallButton"
                          size="medium"
                          style={{ backgroundColor: '#fff', color: theme.palette.primary.dark, textTransform: 'capitalize', width: 'auto', padding: 0, fontSize: '0.815rem' }}
                          onClick={() => { AnalyticsHandler.track('JobDetail_CallHR_BtnClick') }}
                        >
                          Call HR
                        </Button>
                      </a>
                    </Grid>
                    : null}

                </Grid>

                {/* <div style={{ width: '170px', height: 1, marginBottom: 20, borderTop: '1px solid', borderColor: theme.palette.primary.main }}></div> */}

                {response.companies ? <Grid
                  item xs
                  container
                  justifyContent="center"
                  alignItems="flex-start"
                  direction="row"
                  className={classes.sectionItem}
                  style={{ marginBottom: 0, border: 'none' }}>
                  {response.companies ?
                    <Grid
                      item xs={9}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={3}
                    >
                      <Typography variant="h1" className={classes.fontXlMobile} style={{ paddingBottom: 5 }}>{companyNameFinal}</Typography>

                      {response.companies.avg_rating ?
                        <Grid
                          item xs
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{
                            padding: '0 0 5px',
                            // marginTop: response.companies.avg_rating ? 0 : '-5px' 
                          }}
                        >

                          {/* <StarRoundedIcon className={classes.tileRatingStar} />
                        <Typography variant="h3" > 4
                          <span style={{ color: theme.palette.primary.dark, fontSize: 12 }}> (1 rating)</span></Typography> */}

                          <StarRoundedIcon className={classes.tileRatingStar} />

                          <Typography variant="h3" >
                            {response.companies.avg_rating ? response.companies.avg_rating : ""}
                            <span style={{ color: theme.palette.primary.dark, fontSize: 12 }}>
                              {response.companies.ratings_count ? " (" + response.companies.ratings_count + " " + ratingsText + ")" : null}
                            </span>
                            {/* <span style={{ color: theme.palette.primary.dark, fontSize: 12 }}>
                            {response.companies.ratings_count && response.companies.reviews_count ?
                              " (" + response.companies.ratings_count + " " + ratingsText + " and " + response.companies.reviews_count + " " + reviewsText + ")" : null}

                            {response.companies.ratings_count && !response.companies.reviews_count
                              ? (response.companies.ratings_count === 1 ? " (" + response.companies.ratings_count + " rating)" : " (" + response.companies.ratings_count + " ratings)")
                              : response.companies.reviews_count && !response.companies.ratings_count
                                ? (response.companies.reviews_count === 1 ? " (" + response.companies.reviews_count + " review)" : " (" + response.companies.reviews_count + " reviews)")
                                : null}
                          </span> */}
                          </Typography>
                        </Grid> : null}

                      <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}
                        style={{ padding: '0 0 5px' }}
                      >
                        {/* location commented */}
                        {/* <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                        <LocationOnOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} style={{ color: "#797979", fontSize: '1rem', marginBottom: 2 }} />
                        <Typography variant="subtitle1">{response.companies.distric ? response.companies.distric : (response.companies.city ? response.companies.city : "")}, {response.companies.state ? response.companies.state : ""}

                          <span>·</span>
                            {response.companies.distance ? Math.trunc(response.companies.distance) + "  km away" : ""}

                        </Typography>
                      </Grid> */}

                        {response.companies.number_of_employees ?
                          <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                            <EmployeesOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} style={{ color: "#797979", fontSize: '1rem', marginBottom: 2 }} />
                            <Typography variant="subtitle1">{response.companies.number_of_employees ? response.companies.number_of_employees + " employees" : ""}</Typography>
                          </Grid> : null}

                        {response.companies.number_of_employees && response.companies.number_of_female_employees ?
                          <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                            <FemaleOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} style={{ color: "#797979", fontSize: '1rem', marginBottom: 2 }} />
                            <Typography variant="subtitle1">
                              {/* wherever the % of women employees is 1 or less than 1%, we don’t show the text in brackets and just write # women/ woman employees/employee */}
                              {response.companies.number_of_female_employees === 1 ? response.companies.number_of_female_employees + " woman"
                                : response.companies.number_of_female_employees + " women"}
                              {percentageOfWomen && percentageOfWomen > 1 ? ` (${percentageOfWomen}% of employees)` : response.companies.number_of_female_employees === 1 ? ` employee` : ` employees`}</Typography>
                          </Grid> : null}

                        {response.companies.sector_name ?
                          <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                            <CategoryOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} style={{ color: "#797979", fontSize: '1rem', marginBottom: 2 }} />
                            <Typography variant="subtitle1" style={{ color: "#797979", fontWeight: 400 }}> industry:
                              <span style={{ color: response.sector_color_code ? "#" + response.sector_color_code : "inherit", fontWeight: 500 }}> {" " + response.companies.sector_name}</span></Typography>
                          </Grid> : null}

                        {/* {response && response.companies && response.companies.company_mobile ?
                          <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                            <CallOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} style={{ color: "#797979", fontSize: '1rem', marginBottom: 2 }} />

                            <a href={"tel:" + response.companies.company_mobile} target="self" style={{ textDecoration: 'none' }}>
                              <Button
                                id="CallButton"
                                size="medium"
                                style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, textTransform: 'capitalize', width: '82px', padding: 0, fontSize: '0.815rem' }}
                                onClick={() => { AnalyticsHandler.track('JobDetail_CallHR_BtnClick') }}
                              >
                                Call HR
                              </Button>
                            </a>
                          </Grid>
                          : null} */}
                      </Grid>
                    </Grid> : null}

                  {/* Company Logo  */}
                  <Grid
                    item xs={3}
                    container
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    style={{ marginTop: '-10px' }}
                  >
                    <div className={classes.imgWrapper} style={{ marginRight: '-12px' }}>
                      {response.companies.company_image_url ?
                        <img className={classes.imageTag} alt="company logo"
                          onLoad={(event) => { event.target.style.opacity = 1 }}
                          onError={(event) => { event.target.nextElementSibling.style.opacity = 1 }}
                          src={response.companies.company_image_url}
                          style={{ opacity: 0 }} />
                        :
                        <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>
                      }
                      <Typography variant="h3" style={{ position: 'absolute', textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem', opacity: 0 }}>{companyInitials}</Typography>

                      {/* {response.companies.company_image_url ?
                      <img className={classes.imageTag} src={response.companies.company_image_url} alt="Company Logo" />
                      : <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>} */}
                    </div>
                  </Grid>
                </Grid> : null}

                {/* <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}
                  style={{ padding: !response.companies.number_of_employees || response.companies.number_of_female_employees ? 0 : '20px 0' }} // for mvp in which follow is disabled
                >

                  {response.companies.number_of_employees ?
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <EmployeesOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="body1">{response.companies.number_of_employees ? response.companies.number_of_employees + " Employees" : ""}</Typography>
                    </Grid> : null}

                  {response.companies.number_of_employees && response.companies.number_of_female_employees ?
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <FemaleOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="body1">{response.companies.number_of_female_employees === 1 ? response.companies.number_of_female_employees + " Woman ("
                        : response.companies.number_of_female_employees + " Women ("}{Math.round(response.companies.number_of_female_employees / response.companies.number_of_employees) * 100}% of Employees)</Typography>
                    </Grid> : null} */}

                {/* follow unfollow */}
                {/* <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                    {response.companies.no_of_followers
                      ? <Grid item xs container justifyContent="flex-start" alignItems="center" >
                        <FavoriteBorderOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                        <Typography variant="body1">{response.companies.no_of_followers} followers</Typography>
                      </Grid>
                      : <Grid item xs container justifyContent="flex-start" alignItems="center" >
                        <FavoriteBorderOutlinedIcon fontSize="small" className={classes.sectionItem_heading_icon} style={{ color: theme.palette.common.grey }} />
                        <Typography variant="body1" style={{ color: theme.palette.common.grey }}>Be the first one to follow.</Typography>
                      </Grid>
                    }

                    <IconButton id="followButton" label="follow" onClick={!isSignedIn ? handleDrawer : handleFollow}>
                      <Typography variant="body1" className={classes.sectionItem_text_button}>{doFollow ? "Follow" : "Unfollow"}</Typography>
                    </IconButton>
                  </Grid> */}

                {/* </Grid> */}

                {/* Company Description */}
                {response.companies.company_description ?
                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <DescriptionOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">Company का Work </Typography>
                    </Grid>
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                      <Typography variant="body1">{response.companies.company_description}</Typography>
                      {/* <Typography variant="body2" style={{ marginTop: 10 }}><b>Established in</b> - 2015 (FILL IN HERE)</Typography> */}
                    </Grid>
                  </Grid> : null}

                {/* Company Ratings */}
                {response.companies.ratings_count ?
                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    {response.companies.ratings_count ?
                      <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                        <StarsOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                        <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">Ratings</Typography>
                      </Grid> : null}
                    {response.companies.avg_rating ?
                      <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                        <Grid item xs container justifyContent="flex-start" alignItems="center" >
                          <OverallRatingIcon fontSize="small" className={classes.sectionItem_heading_icon} style={{ color: theme.palette.common.blackDark, marginRight: 10, }} />
                          <Typography variant="subtitle1">Overall</Typography>
                        </Grid>

                        <Grid
                          item xs
                          container
                          justifyContent="flex-end"
                          alignItems="center"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <StarRoundedIcon className={classes.tileRatingStar} />
                          <Typography variant="subtitle1">{response.companies.avg_rating}</Typography>
                        </Grid>
                      </Grid> : null}
                    {response.companies.environment_rating ?
                      <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                        <Grid item xs container justifyContent="flex-start" alignItems="center" >
                          <EmployeesIcon fontSize="small" className={classes.sectionItem_heading_icon2} />
                          <Typography variant="body1">Company का माहौल</Typography>
                        </Grid>
                        <Typography variant="body2" style={{ color: theme.palette.common.grey }}>{response.companies.environment_rating}</Typography>
                      </Grid> : null}
                    {response.companies.growth_rating ?
                      <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                        <Grid item xs container justifyContent="flex-start" alignItems="center" >
                          <EqualizerIcon fontSize="small" className={classes.sectionItem_heading_icon2} />
                          <Typography variant="body1">Career का विकास </Typography>
                        </Grid>
                        <Typography variant="body2" style={{ color: theme.palette.common.grey }}>{response.companies.growth_rating}</Typography>
                      </Grid> : null}
                    {response.companies.salary_rating ?
                      <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                        <Grid item xs container justifyContent="flex-start" alignItems="center" >
                          <AccountBalanceWalletIcon fontSize="small" className={classes.sectionItem_heading_icon2} />
                          <Typography variant="body1">Salary </Typography>
                        </Grid>
                        <Typography variant="body2" style={{ color: theme.palette.common.grey }}>{response.companies.salary_rating}</Typography>
                      </Grid> : null}

                    {/* <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                <Grid item xs container justifyContent="flex-start" alignItems="center" >
                  <LocalLibraryIcon fontSize="small" className={classes.sectionItem_heading_icon2} />
                  <Typography variant="body1">Learning Opportunities</Typography>
                </Grid>
                <Typography variant="body2" style={{ color: theme.palette.common.grey }}>4.1 (FILL IN HERE)</Typography>
              </Grid>
              <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                <Grid item xs container justifyContent="flex-start" alignItems="center" >
                  <SupervisorAccountIcon fontSize="small" className={classes.sectionItem_heading_icon2} />
                  <Typography variant="body1">My Manager</Typography>
                </Grid>
                <Typography variant="body2" style={{ color: theme.palette.common.grey }}>4.1 (FILL IN HERE)</Typography>
              </Grid> */}
                  </Grid>
                  : null}

                {/* -------Reviews-------*/}
                {response.companies && response.companies.reviews_count ?
                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>

                    <Grid item xs container justifyContent="space-between" alignItems="center" >
                      <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                        <ReviewsOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                        <Typography variant="h4" color="primary">यहाँ के employees का अनुभव
                          {/* <span style={{ color: theme.palette.common.grey }}>
                      {response.companies.reviews_count >= 5 ? "(5 reviews)" : response.companies.reviews_count === 1 ? "(1 review)" : "(" + response.companies.reviews_count + " reviews)"}</span> */}
                        </Typography>
                      </Grid>

                      {reviewsList && reviewsList.length > 5 ?
                        <div>
                          <Button
                            id="CallButton"
                            size="medium"
                            style={{ backgroundColor: '#fff', color: theme.palette.primary.dark, width: 'auto', padding: 0, fontSize: '0.815rem', justifyContent: 'flex-end', textTransform: 'unset' }}
                            onClick={() => { setIsRatingsOpen(true) }}
                          >
                            See all
                          </Button>
                        </div>
                        : null}
                    </Grid>

                    {reviewsList && reviewsList.length > 0 && reviewsList.filter((item, index) => index < 5).map((item, index) =>
                      <Grid item xs container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem_rating} key={index}>
                        {/* {item.quality_score ?
                        <Rating
                          name="company_rating"
                          defaultValue={item.quality_score}
                          precision={0.5}
                          icon={<StarRoundedIcon fontSize="small" />}
                          readOnly
                          style={{ marginBottom: 10 }}
                        /> : null} */}

                        {item.review_data && item.review_data.jobRole ?
                          <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>💼 मेरा काम </span> {item.review_data.jobRole}
                          </Typography> : null
                        }

                        {item.review_data && item.review_data.goodThing ?
                          <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>🙂 मुझे अच्छा लगा </span>
                            {item.review_data.goodThing}
                          </Typography> : null
                        }

                        {item.review_data && item.review_data.badThing ?
                          <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>🙁 मुझे ठीक नहीं लगा </span>
                            {item.review_data.badThing}
                          </Typography> : null}

                        {item.review ?
                          <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>
                            👓 मेरा अनुभव
                            {/* ✍️ मेरा अनुभव:  */}
                          </span>
                            {item.review}
                          </Typography> : null}

                        {/* Button for more reviews */}
                        {index === 4 && reviewsCount > 5 ?
                          <Grid item xs={12} container justifyContent="center">
                            <Button
                              id="MoreReviewsButton"
                              size="medium"
                              className={classes.moreButton}
                              onClick={() => { setIsRatingsOpen(true) }}
                            >
                              see all
                              <ChevronRightRoundedIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                            </Button>
                            {isRatingsOpen &&
                              <SeeAllReviews
                                companyID={companyID}
                                closeRatingsModal={closeRatingsModal}
                              />}
                          </Grid>
                          : null}
                      </Grid>)
                    }
                  </Grid> : null}

                {/* -------Address-------*/}
                {response.companies && response.companies.company_address ?
                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <MarkunreadMailboxOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="h4" className={classes.sectionItem_heading_title} color="primary">Company Headquarter Address</Typography>
                    </Grid>
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                      <Typography variant="body1">{response.companies.company_address}</Typography>
                    </Grid>
                  </Grid> : null}

              </div> : null}

            {isScreeningFormOpen &&
              <ScreeningForm
                jobID={jobID}
                isJobOpen={isJobOpen}
                googleFormURL={googleFormURL}
                isDefault={isDefault}
                isChecked={isChecked}
                tabIndices={tabIndices}
                countOfQuestions={countOfQuestions}
                locationAgree={locationAgree}
                validLicense={validLicense}
                ownVehicle={ownVehicle}
                ownLaptop={ownLaptop}
                whyGoodFit={whyGoodFit}
                salaryAgree={salaryAgree}
                salaryExpectation={salaryExpectation}
                changeJobReason={changeJobReason}
                customQuestions={customQuestions}
                customAnswers={customAnswers}
                isScreeningFormSubmitted={isScreeningFormSubmitted}
                setIsScreeningFormOpen={setIsScreeningFormOpen}
                showOnePageRatings={showOnePageRatings}
                handleSnackBar={handleSnackBar}
                setRefreshScreening={setRefreshScreening}
              />}

            {/* Button for Similar jobs in this role */}
            {/* <div className={classes.section} style={{ marginTop: 0, paddingTop: 0 }}>
            {response && response.mapped_role && jobsCountForARole && jobsCountForARole > 1 ?
              <Grid item xs={12} container justifyContent="center">
                <Button
                  id="MoreButton"
                  size="medium"
                  style={{
                    backgroundColor: '#fff', fontSize: '0.9375rem',
                    color: theme.palette.common.blackDark, textTransform: 'unset', width: '100%'
                  }}
                  onClick={() => {//send role and back button flag via location object to role specific jobs list page
                    history.push({
                      pathname: `/${response.mapped_role}`,
                      state: {
                        role: response.mapped_role,
                        goBackToPrev: true,
                      }
                    });
                  }
                  }
                >
                  और {response.mapped_role} openings देखें
                  <ChevronRightRoundedIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                </Button>
              </Grid>
              : null}
          </div> */}

            {/* Bottom App Bar */}
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar variant="dense" style={{ width: '100%', justifyContent: 'center', display: isBottomBarVisible ? undefined : 'none' }}>
                {isSignedIn
                  // && response && response.google_form_field 
                  ?
                  <>
                    {googleFormURL && isApplied ? <a href={googleFormURL} target="self" style={{ textDecoration: 'none' }}>
                      <Button
                        id="ApplyButton1"
                        size="medium"
                        className={`${classes.button} ${classes.button_pri}`}
                        style={{ marginRight: 20 }}
                      >
                        {"Fill Screening Form"}
                      </Button>
                    </a>
                      :
                      <Button
                        id="ApplyButton1"
                        size="medium"
                        onClick={
                          (isApplied && !isScreeningFormEnabled) || (!isApplied && !isJobOpen) || (isApplied && !isJobOpen && !isScreeningFormSubmitted) ? undefined :
                            (isApplied && isScreeningFormEnabled) ? handleOpenScreeningForm :
                              handleDialogOpen
                        }
                        className={
                          isApplied && isJobOpen && !isScreeningFormEnabled ? `${classes.button} ${classes.button_pri_inactive}` :
                            (!isApplied && !isJobOpen) || (isApplied && !isJobOpen && !isScreeningFormSubmitted) ? `${classes.button} ${classes.button_pri_inactive_red}` :
                              isApplied && !isJobOpen && isScreeningFormSubmitted && isScreeningFormEnabled ? `${classes.button} ${classes.button_pri_red}` :
                                `${classes.button} ${classes.button_pri}`
                        }
                        style={{ padding: isApplied && isScreeningFormSubmitted && isScreeningFormEnabled ? 1 : "6px 8px", marginRight: 20 }}
                      >
                        {/* {isApplied && isScreeningFormSubmitted ? <DoneRoundedIcon /> : null}  */}
                        {!isApplied && isJobOpen ? "Apply" :
                          !isApplied && !isJobOpen ? "Closed" :

                            isApplied && isJobOpen && !isScreeningFormSubmitted && isScreeningFormEnabled ? "Fill Screening Form" :
                              isApplied && !isJobOpen && !isScreeningFormSubmitted && isScreeningFormEnabled ? "Closed" :

                                isApplied && isJobOpen && !isScreeningFormEnabled ? "Applied" :
                                  isApplied && !isJobOpen && !isScreeningFormEnabled ? "Closed" :
                                    null}
                        {/* if job is open and form was filled */}
                        {isApplied && isJobOpen && isScreeningFormSubmitted && isScreeningFormEnabled && (
                          <>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <span>Applied</span>
                              <span style={{ fontSize: 11, marginTop: '-6px' }}>Edit Screening Form</span>
                            </div>
                          </>
                        )}
                        {/* if job is closed and form was filled */}
                        {isApplied && !isJobOpen && isScreeningFormSubmitted && isScreeningFormEnabled && (
                          <>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <span>Closed</span>
                              <span style={{ fontSize: 11, marginTop: '-6px' }}>View Screening Form</span>
                            </div>
                          </>
                        )}

                      </Button>
                    }

                  </>
                  : !isSignedIn
                    // && response && response.google_form_field 
                    ?
                    <Button
                      id="ApplyButton2"
                      size="medium"
                      onClick={handleApply}
                      className={`${classes.button} ${classes.button_pri}`}
                      style={{ marginRight: 20 }}
                    >
                      Apply
                    </Button> :
                    null
                }

                {response && response.mapped_role && jobsCountForARole && jobsCountForARole > 1 ?
                  <Button
                    id="AllButton"
                    size="medium"
                    style={{ backgroundColor: '#fff', color: theme.palette.primary.dark, textTransform: 'capitalize', width: '140px', border: `1px solid ${theme.palette.secondary.main}` }}
                    onClick={() => {//send role and back button flag via location object to role specific jobs list page
                      history.push({
                        //pathname: `/findwork`,
                        pathname: `/${response.mapped_role}`,
                        state: {
                          role: response.mapped_role,
                          goBackToPrev: true,
                        }
                      });
                    }
                    }
                  >
                    समान openings
                  </Button>
                  :
                  <Button
                    id="AllButton"
                    size="medium"
                    style={{ backgroundColor: '#fff', color: theme.palette.primary.dark, textTransform: 'capitalize', width: '140px' }}
                    onClick={(() => {
                      history.push({
                        pathname: `/`,
                      });
                    })}
                  >
                    All Opportunities
                  </Button>
                }

              </Toolbar>
            </AppBar>
          </>
        }

        {/* Dialog box to prompt to complete profile in order to apply to job if profile is not completed else to confirm if the user wants to apply  */}
        <Dialog onClose={handleDialogClose} aria-labelledby="customized-dialog-title" open={isDialogOpen} maxWidth={'sm'}>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
          {isProfileCompleted ?
            <>
              <DialogContent dividers style={{ paddingTop: 50 }}>
                <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5', textAlign: 'center' }}>
                  Are you sure you want to apply to this opportunity?
                </Typography>
              </DialogContent>

              <DialogActions style={{ justifyContent: 'center' }}>

                <Button
                  color="primary"
                  size="medium"
                  onClick={handleDialogClose}
                  style={{ backgroundColor: '#fff', color: theme.palette.primary.dark, textTransform: 'capitalize', width: '140px' }}>
                  No
                </Button>

                {/* <a
                  href={googleFormURL}
                  onClick={() => { AnalyticsHandler.track('JobDetail_screeningForm_BtnClick'); }}
                  style={{ textDecoration: 'none' }}> */}
                <Button autoFocus
                  color="primary"
                  size="medium"
                  onClick={handleApply}
                  className={`${classes.button} ${classes.button_pri}`}>
                  Yes
                </Button>
                {/* </a> */}
              </DialogActions>
            </>
            :
            <>
              <DialogContent dividers style={{ paddingTop: 50 }}>
                <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5', textAlign: 'center' }}>
                  Please complete your profile to apply to this job.
                </Typography>
              </DialogContent>

              <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                  color="primary"
                  size="medium"
                  onClick={handleDialogClose}
                  style={{ backgroundColor: '#fff', color: theme.palette.primary.dark, textTransform: 'capitalize', minWidth: '120px' }}>
                  Later
                </Button>
                <Button autoFocus
                  color="primary"
                  size="medium"
                  className={`${classes.button} ${classes.button_pri}`}
                  style={{ width: 'auto' }}
                  onClick={(() => {
                    handleDialogClose();
                    history.push({
                      pathname: `/profile`,
                    });
                  })}
                >
                  Complete profile now
                </Button>
              </DialogActions>
            </>
          }

        </Dialog>
        {/* Unfollow confirmation dialog */}

        <Dialog onClose={handleUnfollowDialogClose} aria-labelledby="customized-dialog-title" open={isUnfollowDialogOpen} maxWidth={'sm'}>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleUnfollowDialogClose}>
            <CloseIcon />
          </IconButton>

          <DialogContent dividers style={{ paddingTop: 50 }}>
            <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5' }}>
              क्या आप sure हैं आप इस company को unfollow करना चाहते हैं? ऐसा करने से आपके पास इस company में नई openings के बारे में notification नहीं आएंगे
            </Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button autoFocus
              color="primary"
              size="medium"
              style={{ backgroundColor: theme.palette.primary.contrastText, color: theme.palette.primary.dark, textTransform: 'capitalize', width: '140px' }}
              onClick={handleFollow}
            >
              Unfollow
            </Button>
            <Button autoFocus
              color="primary"
              size="medium"
              style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, textTransform: 'capitalize', width: '140px' }}
              onClick={handleUnfollowDialogClose}
            >
              Keep Following
            </Button>
          </DialogActions>
        </Dialog>

        <SnackBarGeneric id="snackBarMessage" canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />

        <SignInUpDrawer
          canDrawerOpen={canDrawerOpen}
          refresh={refresh}
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          setForRefreshOnly={setForRefreshOnly}
          setCanDrawerOpen={setCanDrawerOpen}
          doRefresh={doRefresh}
        />

        <ShareDrawer
          canShareDrawerOpen={canShareDrawerOpen}
          refreshShareDrawer={refreshShareDrawer}
          setForShareRefreshOnly={setForShareRefreshOnly}
          setCanShareDrawerOpen={setCanShareDrawerOpen}
          doRefreshShareDrawer={doRefreshShareDrawer}
          jobUrl={window.location.origin + match.url}
        />

      </div>
    </>
  )
}