// import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tabBar: {
    alignItems: 'left',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.14)',
  },
  navTabBar: {
    flexGrow: 1,
    minHeight: 40,
  },
  navTab: {
    textTransform: 'capitalize',
    padding: 0,
    margin: ' 0 10px',
    minHeight: 40,
    minWidth: 100,
    [theme.breakpoints.down('md')]: {
      minHeight: 40,
      minWidth: 90,
    },
  },
  indicator: {
    height: 2,
  },
  labelIcon: {
    fontWeight: 400,
    color: "#AFAFAF",
    [theme.breakpoints.down('md')]: {
      color: theme.palette.grey[600],
      marginBottom: '0!important',
      fontSize: 12,
      '& .MuiTab-wrapper > *:first-child': {
        marginBottom: 0
      }
    },
  },
  selected: {
    fontWeight: 700,
    color: theme.palette.primary.dark,
  },

  appBar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.16)',
    top: 'auto',
    bottom: 0,
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em'
  },
  button: {
    textTransform: "none",
    width: 'auto',
    minWidth: 140,
    maxWidth: 150,
  },
  button_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  button_pri_red: {
    backgroundColor: theme.palette.common.red,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.common.red,
    },
  },
  button_pri_inactive: {
    backgroundColor: '#fff',
    color: theme.palette.primary.dark,
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  button_pri_inactive_red: {
    backgroundColor: '#fff',
    color: theme.palette.common.red,
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.common.red,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  section: {
    backgroundColor: theme.palette.background.paper,
    width: '80%',
    padding: '100px 15px 20px',
    margin: ' 0 10%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '100px 15px 20px',
      margin: 0,
    },
  },
  sectionItem: {
    padding: '8px 0px',
    // borderBottom: '1px solid',
    // borderBottomColor: theme.palette.grey[100],
    '&:last-child': {
      border: 'none',
    }
  },
  sectionItem_heading: {
    flexWrap: 'nowrap',
    marginBottom: '5px'
  },
  sectionItem_heading_title: {
    color: '#797979',
    fontSize: 16
  },
  sectionItem_heading_icon: {
    marginRight: 5,
    color: '#737373'
  },
  sectionItem_heading_icon2: {
    marginRight: 10,
    color: theme.palette.common.grey,
  },
  sectionItem_content: {
    flexWrap: 'wrap',
  },
  sectionItem_rating: {
    //flexWrap: 'wrap',
    flexWrap: 'nowrap', // changing to nowrap for fixing iOS mobile review data crossing the boundaries
    margin: '10px 0',
    padding: '0px 0 5px',
    '&:last-child': {
      marginBottom: 0,
      borderBottom: 'none',
    },
    borderBottom: '1px solid #eee',
  },
  sectionItem_text_button: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  cardFrame: {
    position: 'relative',
    // border: '1px solid',
    // borderColor: theme.palette.primary.main,
    borderRadius: 6,
    padding: '15px 0 8px',
    // marginBottom: 10,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '-2%',
      width: '104%',
    },
  },
  cardText: {
    fontSize: '0.815rem',
    fontWeight: 500,
  },
  cardTag: {
    ...theme.typography.cardTag,
    width: 'auto',
    padding: '0px 8px',
    marginRight: 15,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '4px 0 0 4px',
    fontWeight: 500
  },
  infoIcon: {
    '&, &:hover': {
      borderRadius: '0 4px 4px 0',
      background: theme.palette.primary.dark,
      padding: '4.5px 8px',
    }

  },
  cardinfoPopoverText: {
    background: theme.palette.primary.light,
    padding: theme.spacing(2),
  },
  chip: {
    fontSize: '0.815rem',
    fontWeight: 500,
    width: 'auto',
    padding: '2px 8px',
    margin: '6px 10px 0 0',
    backgroundColor: '#f5f5f5',
    // color: theme.palette.primary.main,
    color: '#797979',
    borderRadius: 18,
  },
  icon: {
    marginRight: 8,
    color: theme.palette.common.blackDark,
  },
  cardIcon: {
    marginRight: 8,
  },
  dayChip: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    width: 22,
    height: 22,
    lineHeight: '22px',
    padding: '0 5px',
    borderRadius: 2,
    marginRight: 10,
    textAlign: 'center',
    '@media (max-width:600px)': {
      height: 20,
      fontSize: 12
    }
  },
  flexDirectionColMobile: {
    '@media (max-width:600px)': {
      flexDirection: 'column'
    }
  },
  fontXlMobile: {
    '@media (max-width:600px)': {
      fontSize: 18
    }
  },
  fontLgMobile: {
    '@media (max-width:600px)': {
      fontSize: 16
    }
  },
  fontMdMobile: {
    '@media (max-width:600px)': {
      fontSize: 14
    }
  },
  fontSmMobile: {
    '@media (max-width:600px)': {
      fontSize: 12
    }
  },
  hideOnMobile: {
    '@media (max-width:600px)': {
      display: 'none'
    }
  },
  hideOnDesktop: {
    '@media (min-width:600px)': {
      display: 'none'
    }
  },
  dayChip_inactive: {
    color: theme.palette.common.grey,
    backgroundColor: theme.palette.grey[100],
    width: 22,
    height: 22,
    lineHeight: '22px',
    padding: '0 5px',
    borderRadius: 2,
    marginRight: 10,
    '@media (max-width:600px)': {
      height: 20,
      fontSize: 12
    }
  },
  highlight: {
    color: theme.palette.common.blackDark,
    fontWeight: 600,
    width: '100%',
    display: 'inline-block',
  },
  tileHeading: {
    ...theme.typography.tileHeading,
    fontSize: '0.9375rem',
    color: theme.palette.common.blackDark,
  },
  tileRatingText: {
    ...theme.typography.tileRatingText,
  },
  tileRatingStar: {
    fontSize: '1.2rem',
    color: theme.palette.common.star,
    marginRight: 4,
  },
  imageTag: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: "none",
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    overflow: 'hidden',
    margin: 0,
    borderRadius: 6,
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.25)',
  },
  imgWrapperTop: {
    position: 'absolute', 
    right: 10, 
    top: 10,
    '@media (max-width:600px)': {
      width: 40,
      height: 40,
      top: 0
    }
  },
  flexContainer: {
    justifyContent: 'center'
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  ellipsisPost2Lines: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 4,
    color: theme.palette.common.greyMedium,
  },
  moreButton: {
    fontSize: '0.9375rem',
    color: theme.palette.common.blackDark,
    textTransform: 'unset',
    width: '100%',
    position: 'relative',
    '&:before': {
      position: 'absolute',
      width: '100%',
      content: '""',
      borderTop: '1px solid #eee',
      top: '50%',
    },
    '& span:first-child': {
      backgroundColor: '#fff',
      width: 'auto',
      border: '1px solid #eee',
      borderRadius: 15,
      padding: '2px 10px',
      zIndex: 1,
    }
  },

  root: {
    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 60,
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
    },
  },
  pageBar: {
    // width: 860,
    padding: '8px 43px',
    flexBasis: 65,
    backgroundColor: theme.palette.common.greyLight,
    marginTop: 0,
    zIndex: 8,
    transition: '0.2s',
    position: 'sticky',
    top: 59,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      top: 0,
      padding: 0
    },
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 20,
  },
  pageButton_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  listRoot: {
    width: '100%',
    padding: '0 43px 30px',
    marginBottom: 50,
    '& .MuiListItem-root': {
      padding: 0,
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      marginBottom: 10,
      overflow: 'hidden',
      boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 10px 30px',
    },
  },
  panelHeading: {
    margin: 0,
    padding: '0 10px 8px',
    fontSize: 18,
    position: 'sticky',
    top: 104,
    //background: theme.palette.common.background,
    zIndex: 9,
  }



}));

