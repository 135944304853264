import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography } from '@material-ui/core'
import StarRateIcon from '@material-ui/icons/StarRate';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import CallIcon from '@material-ui/icons/Call';
import ApiHandler from '../../ApiHandler';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import LogoLoader from '../../assets/logo-loader.gif';
import CopyToClipboard from 'react-copy-to-clipboard';
import SnackBarGeneric from '../../components/SnackBarGeneric';

const FavouriteCompanies = (props) => {
    const { classes } = props;
    const [favCompaniesList, setFavCompaniesList] = React.useState([]);
    const jobseekerID = localStorage.getItem("jobseeker_id");
    const history = useHistory();
    const [refresh, doRefresh] = React.useState(false);
    const [totalFavCount, setTotalFavCount] = React.useState(0);
    const offset = 0;

    React.useEffect(() => {
        ApiHandler.getFavCompaniesList(offset, 10, jobseekerID, res => {
            if (res.status == 200) {
                setFavCompaniesList(res.data[0].results);
                if (res.data[0].totalCount.length !== 0) {
                    setTotalFavCount(res.data[0].totalCount[0].count);
                } else{
                    setTotalFavCount(0);
                }
            }
        })
    }, [refresh]);

    const fetchMoreData = () => {
        ApiHandler.getFavCompaniesList(offset + 10, 10, jobseekerID, res => {
            if (res.status == 200) {
                setFavCompaniesList([...favCompaniesList, res.data[0].results]);
            }
        })

    }

    const companyNameFormatted = (value) => {
        if (value) {
            let companyNameArray = value.company_name ? value.company_name.split("-") : "";

            // remove location after ':' and remove pvt ltd etc and remove content following '('
            let companyNameFinal = companyNameArray ? companyNameArray[0].split(':')[0].split("(")[0].replace("Pvt. Ltd.", "").replace("Private Limited", "").replace("Pvt Ltd.", "").replace("Pvt. Ltd", "").replace("Pvt Ltd", "").replace("pvt ltd", "").replace("Ltd", "").replace("Ltd.", "").replace("Limited", "").replace("ltd", "").trim() : "";
            return companyNameFinal
        }
    }


    const locationFormatted = (value) => {
        if (value) {

            let companyNameArray = value.company_name ? value.company_name.split("-") : "";
            let location = companyNameArray[0].includes(':') ? companyNameArray[0].split(':')[companyNameArray[0].split(':').length - 1] : companyNameArray[companyNameArray.length - 1]
            return location
        }
    }

    const viewCompanyDetails = (item) => {
        if (item.company_id) {
            sessionStorage.setItem("pageStateOnReturn", "goBack")
            history.push({
                pathname: `/${"CompanyProfile/" + companyNameFormatted(item) + "-" + item.company_id}`,
            })
        }
    }


    //Follow button functionality starts
    const [isUnfollowDialogOpen, setIsUnfollowDialogOpen] = React.useState(false);
    const [companyIdToUnfollow, setCompanyIdToUnfollow] = React.useState('');

    const handleUnfollowDialogClose = () => {
        setIsUnfollowDialogOpen(false);
    }
    const handleUnfollow = () => {

        let payload = {
            jobseeker_id: jobseekerID,
            liked: false,
            company_id: companyIdToUnfollow
        }
        ApiHandler.followCompanyToggle(payload, response => {
            if (response.status == 200) {
                doRefresh(p => !p)
                setIsUnfollowDialogOpen(false);
            } else {
                var ErrorMessage = 'Oops! Something went wrong. Please try again.';
                if (response.response) {
                    let statusCode = response.response.status;
                    if (statusCode === 500) {
                        ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
                    } else if (statusCode === 404) {
                        ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    } else if (statusCode === 400) {
                        ErrorMessage = response.response.data.message;
                    } else {
                        ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    }
                } else if (response.message === 'Network Error') {
                    ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
                } else {
                    ErrorMessage = 'Oops! Something went wrong. Please try again.';
                }
                console.log(ErrorMessage);
            }
        })
    }

    // snackbar callback starts
    const [snackBarMessage, setSnackBarMessage] = React.useState('')
    const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
    const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
    const handleSnackBar = (message) => {
        setCanSnackBarOpen(true);
        setSnackBarMessage(message);
        doRefreshSnackBar(prev => !prev)
    }
    // snackbar callback ends
    return (
        <>
            <InfiniteScroll
                dataLength={favCompaniesList.length}
                next={fetchMoreData}
                hasMore={favCompaniesList.length < totalFavCount}
                loader={<div style={{
                    width: '100%', height: 80, display: 'flex', justifyContent: 'center', alignItems: 'flex-start'
                }}>
                    <img alt="meet animated logo loader" style={{ width: 40 }} src={LogoLoader} />
                </div>}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        { }
                    </p>
                }
                scrollableTarget="scrollableDiv"
                scrollThreshold="0.7%"
            >
                <Grid container justifyContent='flex-start' className={`${classes.favCompDiv} scrollableDiv`}>
                    {favCompaniesList.map(res => {
                        return <Grid key={res.company_id} className={classes.favCompContainer} container item xs={11} sm={10} md={6} direction='column'>
                            <Grid item container wrap='nowrap' style={{minHeight: '16vh'}} alignItems='center' onClick={() => viewCompanyDetails(res)} >
                                <Grid container alignItems="center" className={classes.imgWrapper} justifyContent='center' xs={4} item>
                                    <img src={res.company_image_url} className={classes.imageTag} />
                                </Grid>
                                <Grid style={{ padding: '5px 0', marginLeft: 8 }} xs={10} item container direction='column'>
                                    <Typography component='h2' style={{margin: '0 5px'}} variant='body1'>{companyNameFormatted(res)}</Typography>
                                    <Typography style={{ display: 'flex', alignItems: 'center', margin: '0 1px' }} component='p' variant='body2'><StarRateIcon className={classes.tileRatingStar} /><span>{res.avg_rating}</span></Typography>
                                    <Typography style={{ display: 'flex', alignItems: 'center' }} component='p' variant='body2'><LocationOnOutlinedIcon color='primary' style={{ fontSize: '0.9rem', margin: '0 2px' }} /><span>{locationFormatted(res)}</span></Typography>
                                    <Typography style={{ display: 'flex', alignItems: 'center', color: 'rgb(251, 83, 94)', margin: '0 6px' }} component='p' variant='body2'><span>{res.sector_name}</span></Typography>
                                    <Typography style={{ color: '#797979', margin: '0 6px' }} component='p' variant='body2'>{res.number_of_employees} employees . {res.number_of_female_employees} women <span></span></Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ margin: 'auto', padding: '10px 40px', borderTop: '1px solid rgb(245, 245, 245)' }} item container justifyContent='space-between'>
                                {res.company_mobile ? <a href={`tel:${res.company_mobile}`}><CallIcon fontSize='small' color='action' /></a> : <CallIcon fontSize='small' color='primary' />}
                                <span style={{ color: 'rgb(245, 245, 245)', fontWeight: 100 }}>|</span>
                                <FavoriteIcon fontSize='small' color="primary" onClick={() => { setIsUnfollowDialogOpen(true); setCompanyIdToUnfollow(res.company_id) }} />
                                <span style={{ color: 'rgb(245, 245, 245)', fontWeight: 100 }}>|</span>
                                <CopyToClipboard
                                    text={window.location.origin + "/CompanyProfile/" + companyNameFormatted(res) + locationFormatted(res) + "-" + res.company_id}
                                >
                                    <ShareIcon color="action" onClick={() => {
                                        handleSnackBar(`Link copied. Share Now!!`)
                                    }} />
                                </CopyToClipboard>
                            </Grid>
                        </Grid>
                    })}
                </Grid>
            </InfiniteScroll>
            {/* Unfollow confirmation dialog */}

            <Dialog onClose={handleUnfollowDialogClose} aria-labelledby="customized-dialog-title" open={isUnfollowDialogOpen} maxWidth={'sm'}>

                <IconButton aria-label="close" className={classes.closeButton} onClick={handleUnfollowDialogClose}>
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers style={{ paddingTop: 50 }}>
                    <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5' }}>
                        क्या आप sure हैं आप इस company को unfollow करना चाहते हैं? ऐसा करने से आपके पास इस company में नई openings के बारे में notification नहीं आएंगे
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button autoFocus
                        color="primary"
                        size="medium"
                        style={{ backgroundColor: '#fff', color: '#14967b', textTransform: 'capitalize', width: '140px' }}
                        onClick={handleUnfollow}
                    >
                        Unfollow
                    </Button>
                    <Button autoFocus
                        color="primary"
                        size="medium"
                        style={{ backgroundColor: '#14967b', color: '#fff', textTransform: 'capitalize', width: '140px' }}
                        onClick={handleUnfollowDialogClose}
                    >
                        Keep Following
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBarGeneric id="snackBarMessage" canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
        </>
    )
}

export default FavouriteCompanies