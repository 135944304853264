import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import AnalyticsHandler from '../AnalyticsHandler';
//import SortByDrawer from './SortByDrawer';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    background: theme.palette.common.white,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15);',
    '& .MuiToolbar-gutters': {
      padding: '0 10px'
    },
    marginTop: 58,
    //marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  sortByLocationBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    minWidth: 100,
    height: 32,
    border: '1px solid',
    borderColor: theme.palette.primary.dark,
    borderRadius: 25,
    padding: '0 10px',
    '& .sortByLocationBox__icon': {
      color: theme.palette.primary.dark,
      fontSize: '1.25rem',
    }
  },
  sortByLocationBox__location: {
    color: theme.palette.primary.dark,
    fontSize: '0.875rem',
    padding: '0 5px'
  },
  activeSortBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '0 5px',
    marginRight: 5,
    minWidth: 40,
    width: 'auto',
    height: 30,
    textTransform: 'capitalize',
    fontSize: '1rem',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '& svg': {
      color: theme.palette.primary.contrastText,
      marginRight: 3,
    }
  },
  inactiveSortBtn: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    padding: '0 5px',
    marginRight: 5,
    minWidth: 40,
    width: 'auto',
    textTransform: 'capitalize',
    fontSize: '1rem',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    boxShadow: 'none',
    '& svg': {
      color: theme.palette.primary.main,
      marginRight: 3,
    }
  },
  title: {
    //...theme.typography.title15,
    paddingRight: 5
  }
}))

export default function TopAppBar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [sortByText, setSortByText] = useState(props.modeOfWork);
  const history = useHistory();
  const location = useLocation();
  const [goBackToPrev, setGoBackToPrev] = useState(location && location.state && location.state.goBackToPrev ? location.state.goBackToPrev : false) // var that detects if the user clicked on see similar role openings button on job detail page
  // const [showBackBtn, setShowBackBtn] = useState(false);
  const sortByTextArray = ['All', 'मेरे पास', 'घर से']; // sort btns text
  const [selectedIndex, setSelectedIndex] = useState(props.modeOfWork === "everyWhere" ? 0 : props.modeOfWork === "nearMe" ? 1 : 2); // index of the selected sort button

  const sortClickCommonFn = (sortCriteria, trackID) => {
    setSortByText(sortCriteria);
    AnalyticsHandler.track(trackID);
    props.setModeOfWork(sortCriteria);
    sessionStorage.setItem("modeOfWork", sortCriteria);//mode of work is set in the session storage here
  }

  const handleSortBtnClick = (event, index) => {
    setSelectedIndex(index);
    switch (index) {
      case 0:
        // setSortByText("everyWhere");
        // AnalyticsHandler.track('SortByDrawer_everywhere_BtnClick');
        sortClickCommonFn("everyWhere", "SortByDrawer_everywhere_BtnClick");
        break;
      case 1:
        sortClickCommonFn("nearMe", "SortByDrawer_nearMe_BtnClick");
        break;
      case 2:
        sortClickCommonFn("workFromHome", "SortByDrawer_wfh_BtnClick");
        break;
    }
  }

  const handleBackBtnClick = () => {
    if (goBackToPrev) {
      history.goBack(); // if user comes on the jobs list page by clicking the view similar openings button on job detail page then go back to prev page (job detail page)
    } else {
      history.push({
        pathname: `/`,
      });
      sessionStorage.setItem("pageStateOnReturn", "default") // to change the jobs page to default state
    }
  }

  // React.useLayoutEffect(() => {
  //   let isRoleSpecificJobsPage = window.location.pathname.includes("findwork")
  //   if (isRoleSpecificJobsPage) {
  //     setShowBackBtn(true);
  //   } 
  // }, [])

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);

  const handleDrawer = () => {
    AnalyticsHandler.track('TopAppBar_openSortDrawer_BtnClick');
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // whenever location is denied or when a near me button is clicked location permission is not there, default location sorting to everywhere
  useEffect(() => {
    if (props.isLocationDenied && selectedIndex === 1) {
      console.log("near me clicked on location denied")
      setSelectedIndex(0);
      setSortByText("everyWhere");
      props.setModeOfWork("everyWhere");
      sessionStorage.setItem("modeOfWork", "everyWhere");//mode of work is set in the session storage here
    }
  }, [props.isLocationDenied, selectedIndex])

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}
      // ref={trackInitialRenderRef}
      >
        <Toolbar variant="dense">
          {props.displayLocationBox ?
            <>
              {props.showBackBtn ? // if role specific jobs list page then show a back button else do not show
                <IconButton label="Back"
                  onClick={handleBackBtnClick}
                  style={{ position: 'relative', zIndex: 9, marginLeft: '-16px' }}>
                  <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
                </IconButton>
                : null}

              {/* <div className={classes.sortByLocationBox} id="mode_of_work" onClick={handleDrawer}>
                <PinDropOutlinedIcon className="sortByLocationBox__icon" />
                <div className={classes.sortByLocationBox__location}>{sortByText == "everyWhere" ? "कहीं भी" : (sortByText == "workFromHome" ? "घर से" : "मेरे पास")}</div>
                <ArrowDropDownRoundedIcon className="sortByLocationBox__icon" />
              </div> */}
              <Typography variant="subtitle1" color="secondary" className={classes.title}>Location 👉</Typography>
              <Button
                variant="contained"
                className={selectedIndex === 0 ? classes.activeSortBtn : classes.inactiveSortBtn}
                selected={selectedIndex === 0}
                onClick={(event) => { handleSortBtnClick(event, 0) }}>
                {sortByTextArray[0]}
              </Button>
              <Button
                variant="contained"
                className={selectedIndex === 1 ? classes.activeSortBtn : classes.inactiveSortBtn}
                selected={selectedIndex === 1}
                onClick={(event) => { handleSortBtnClick(event, 1) }}>
                <PinDropOutlinedIcon fontSize='small' />
                {sortByTextArray[1]}
              </Button>
              {props.showWFHOption &&
                <Button
                  variant="contained"
                  className={selectedIndex === 2 ? classes.activeSortBtn : classes.inactiveSortBtn}
                  selected={selectedIndex === 2}
                  onClick={(event) => { handleSortBtnClick(event, 2) }}>
                  <HomeOutlinedIcon fontSize='small' />
                  {sortByTextArray[2]}
                </Button>
              }

            </>
            : <React.Fragment>
              <IconButton id="backButton" label="Back"
                onClick={() => {
                  AnalyticsHandler.track('TopAppBar_backButton_BtnClick');
                  props.showDefaultJobsPageState && props.showDefaultJobsPageState()
                }}
                style={{ position: 'absolute', zIndex: 9, left: 0 }}>
                <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
              </IconButton>

              {/* <Typography variant="h4" className={classes.navTabBar} style={{ color: theme.palette.common.blackDark, paddingLeft: 30 }}>
                All Positions
              </Typography> */}

              <Typography
                variant="subtitle1"
                color="secondary"
                className={classes.title}
                style={{ paddingLeft: 30 }}
              >Location 👉</Typography>
              <Button
                variant="contained"
                className={selectedIndex === 0 ? classes.activeSortBtn : classes.inactiveSortBtn}
                selected={selectedIndex === 0}
                onClick={(event) => { handleSortBtnClick(event, 0) }}>
                {sortByTextArray[0]}
              </Button>

              <Button
                variant="contained"
                className={selectedIndex === 2 ? classes.activeSortBtn : classes.inactiveSortBtn}
                selected={selectedIndex === 2}
                onClick={(event) => { handleSortBtnClick(event, 2) }}>
                <HomeOutlinedIcon fontSize='small' />
                {sortByTextArray[2]}
              </Button>
            </React.Fragment>
          }

          <div className={classes.grow} />
          {/* {!props.displaySearchBar ?
            <IconButton id="search_button" color="primary" onClick={() => {
              AnalyticsHandler.track('TopAppBar_search_BtnClick');
              // props.setDisplaySearchBar(true)
              if (props.refSeeAllJobsButton.current)
                props.refSeeAllJobsButton.current.click();
            }}>
              <SearchIcon />
            </IconButton> : null
          } */}
        </Toolbar>
      </AppBar>

      {/* <SortByDrawer
        canDrawerOpen={canDrawerOpen}
        refresh={refresh}
        setSortByText={setSortByText}
        sortByText={sortByText}
        setForRefreshOnly={setForRefreshOnly}
        setCanDrawerOpen={setCanDrawerOpen}
        doRefresh={doRefresh}
        isLocationDenied={props.isLocationDenied}
      /> */}

    </React.Fragment>
  )
}

