import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';

import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import WorkOutlineRoundedIcon from '@material-ui/icons/WorkOutlineRounded';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import StarsOutlinedIcon from '@material-ui/icons/StarsOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import Popover from '@material-ui/core/Popover';

import { Helmet } from 'react-helmet-async';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import '../../style/App.css';
import SignInUpDrawer from '../../components/SignInDrawer';
import ApiHandler from '../../ApiHandler';
import AnalyticsHandler from '../../AnalyticsHandler';
import ListLoader from '../errorsAndLoaders/ListLoader';
import SnackBarGeneric from '../../components/SnackBarGeneric';
import SeeAllReviews from '../jobs/SeeAllReviews';
import { JobsListItemOnCompanyPage } from '../jobs/JobsListItem';

import { VacancyIcon, EmployeesOutlinedIcon, EmployeesIcon, BenefitsIcon, OtherRequirementsIcon, ReviewsOutlinedIcon, FemaleOutlinedIcon, GenderOutlinedIcon, SkillsOutlinedIcon, OverallRatingIcon } from '../../components/SvgIcons';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   '& div[class*="section-"]':{
  //     '&:nth-last-of-type(2)': {
  //       paddingBottom: 70,
  //     }
  //   }
  // },
  tabBar: {
    alignItems: 'left',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.14)',
  },
  navTabBar: {
    flexGrow: 1,
    minHeight: 40,
  },
  navTab: {
    textTransform: 'capitalize',
    padding: 0,
    margin: ' 0 10px',
    minHeight: 40,
    minWidth: 100,
    [theme.breakpoints.down('md')]: {
      minHeight: 40,
      minWidth: 90,
    },
  },
  indicator: {
    height: 2,
  },
  labelIcon: {
    fontWeight: 400,
    color: "#AFAFAF",
    [theme.breakpoints.down('md')]: {
      color: theme.palette.grey[600],
      marginBottom: '0!important',
      fontSize: 12,
      '& .MuiTab-wrapper > *:first-child': {
        marginBottom: 0
      }
    },
  },
  selected: {
    fontWeight: 700,
    color: theme.palette.primary.dark,
  },

  appBar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.16)',
    top: 'auto',
    bottom: 0,
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em'
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 20,
  },
  pageButton_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  section: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: '100px 15px 20px',
  },
  sectionItem: {
    padding: '8px 0px',
    '&:last-child': {
      border: 'none',
    }
  },
  sectionItem_heading: {
    flexWrap: 'nowrap',
    marginBottom: '5px'
  },
  sectionItem_heading_icon: {
    marginRight: 5,
  },
  sectionItem_heading_icon2: {
    marginRight: 10,
    color: theme.palette.common.grey,
  },
  sectionItem_content: {
    flexWrap: 'wrap',
  },
  sectionItem_rating: {
    //flexWrap: 'wrap',
    flexWrap: 'nowrap', // changing to nowrap for fixing iOS mobile review data crossing the boundaries
    margin: '10px 0',
    padding: '0px 0 5px',
    '&:last-child': {
      marginBottom: 0,
      borderBottom: 'none',
    },
    borderBottom: '1px solid #eee',
  },
  sectionItem_text_button: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  cardFrame: {
    position: 'relative',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 6,
    padding: '15px 0 8px',
    marginBottom: 10,
    marginLeft: '-2%',
    width: '104%',
  },
  cardText: {
    fontSize: '0.815rem',
    fontWeight: 500,
  },
  cardTag: {
    ...theme.typography.cardTag,
    width: 'auto',
    padding: '2px 8px',
    marginRight: 15,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '4px 0 0 4px',
  },
  infoIcon: {
    '&, &:hover': {
      borderRadius: '0 4px 4px 0',
      background: theme.palette.primary.dark,
      padding: '6px 8px',
    }

  },
  cardinfoPopoverText: {
    background: theme.palette.primary.light,
    padding: theme.spacing(2),
  },
  chip: {
    fontSize: '0.815rem',
    fontWeight: 500,
    width: 'auto',
    padding: '2px 8px',
    margin: '6px 10px 0 0',
    backgroundColor: '#f5f5f5',
    color: theme.palette.primary.main,
    borderRadius: 4,
  },
  icon: {
    marginRight: 8,
    color: theme.palette.common.blackDark,
  },
  cardIcon: {
    marginRight: 8,
  },
  dayChip: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    width: 22,
    height: 22,
    lineHeight: '22px',
    padding: '0 5px',
    borderRadius: 2,
    marginRight: 10,
  },
  dayChip_inactive: {
    color: theme.palette.common.grey,
    backgroundColor: theme.palette.grey[100],
    width: 22,
    height: 22,
    lineHeight: '22px',
    padding: '0 5px',
    borderRadius: 2,
    marginRight: 10,
  },
  highlight: {
    color: theme.palette.common.blackDark,
    fontWeight: 600,
    width: '100%',
    display: 'inline-block',
  },
  tileHeading: {
    ...theme.typography.tileHeading,
    fontSize: '0.9375rem',
    color: theme.palette.common.blackDark,
  },
  tileRatingText: {
    ...theme.typography.tileRatingText,
  },
  tileRatingStar: {
    fontSize: '1.2rem',
    color: theme.palette.common.star,
    marginRight: 4,
  },
  imageTag: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: "none",
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    overflow: 'hidden',
    margin: 0,
    borderRadius: 6,
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.25)',
  },
  flexContainer: {
    justifyContent: 'center'
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  ellipsisPost2Lines: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 4,
    color: theme.palette.common.greyMedium,
  },
  moreButton: {
    fontSize: '0.9375rem',
    color: theme.palette.common.blackDark,
    textTransform: 'unset',
    width: '100%',
    position: 'relative',
    '&:before': {
      position: 'absolute',
      width: '100%',
      content: '""',
      borderTop: '1px solid #eee',
      top: '50%',
    },
    '& span:first-child': {
      backgroundColor: '#fff',
      width: 'auto',
      border: '1px solid #eee',
      borderRadius: 15,
      padding: '2px 10px',
      zIndex: 1,
    }
  },
  listRoot: {
    width: '100%',
    padding: '0 10px 60px',
    marginBottom: 50,
    '& .MuiListItem-root': {
      padding: 0,
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      marginBottom: 10,
      overflow: 'hidden',
      boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
    }
  },

}));

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}


export default function CompanyDetail({ match }) {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const [navValue, setNavValue] = React.useState(0);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);
  const [response, setResponse] = React.useState([]);
  const [companyID, setCompanyID] = React.useState(null);
  const [favCompanies, setFavCompanies] = React.useState([]);
  const [reviewsList, setReviewsList] = useState(null);
  const [reviewsCount, setReviewsCount] = useState(0);
  const companyRef = useRef(null);
  const jobsRef = useRef(null);
  const jobseekerID = localStorage.getItem("jobseeker_id");
  const [isFavCompany, setIsFavCompany] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openedPopoverId, setOpenedPopoverId] = React.useState(null);

  document.body.style.overflowY = "scroll";

  const [isRatingsOpen, setIsRatingsOpen] = React.useState(false);

  const closeRatingsModal = () => {
    setIsRatingsOpen(false);
  }

  const handleSalaryInfoClick = (event, i) => {
    AnalyticsHandler.track('CompanyDetails_jobCard_salaryInfo_BtnClick');
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(`infoSalary_id${i}`)
  };

  const handleSalaryInfoClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null)
  };

  const [doFollow, setDoFollow] = React.useState(true);

  //const open = Boolean(anchorEl);
  //const id = open ? 'simple-popover' : undefined;

  const scrollToCompany = () => companyRef.current.scrollIntoView();
  const scrollToJobs = () => jobsRef.current.scrollIntoView();

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
  //const [isSignedIn, setIsSignedIn] = React.useState(true);
  //just for testing

  const checkSignedInStatus = () => {
    return isSignedIn
  }

  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
  };

  //dialog box for create profile button--starts
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {
    AnalyticsHandler.track('CompanyDetail_createProfile_BtnClick');
    setIsDialogOpen(true);
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  }
  //dialog box for create profile button--ends
  //dialog box for create profile button--starts
  const [isUnfollowDialogOpen, setIsUnfollowDialogOpen] = React.useState(false);

  const handleUnfollowDialogClose = () => {
    setIsUnfollowDialogOpen(false);
  }
  //dialog box for create profile button--ends

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  React.useLayoutEffect(() => {
    AnalyticsHandler.track('CompanyDetail_PageLoad');
    setIsLoading(true);

    let idArray = match.params._id.split('-');
    let company_id = idArray[idArray.length - 1];
    setCompanyID(company_id);
    let payload = {
      company_id: company_id,
    }

    if (location && location.state && location.state.latitude && location.state.longitude) {
      payload['latitude'] = location.state.latitude;
      payload['longitude'] = location.state.longitude;
    }

    ApiHandler.getCompanyDetails(payload, response => {
      if (response.status == 200) {
        let arr_temp = response.data;
        setResponse(arr_temp[0]);

        setIsLoading(false);

        ApiHandler.getReviewsByCompany(0, 10, company_id, (response) => {
          if (response.status === 200) {
            setReviewsList(response.data[0].results);
            setReviewsCount(response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0);
          } else {
            console.log("some error occurred.");
          }
        })

      } else {
        console.log("error in getting a successful response");
        history.push({
          pathname: `/error`,
        });
      }
    })
  }, []);

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const handleSnackBar = (message) => {
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev)
  }
  // snackbar callback ends

  //sort bar bg effect on scroll starts
  let lastScrollY = 0;
  let scrolling = false;

  const handleScrollChange = () => {
    lastScrollY = window.scrollY;
    if (companyRef && companyRef.current && lastScrollY > companyRef.current.clientHeight - 120) {
      scrolling = true;
    } else {
      scrolling = false
    }
    if (!scrolling && companyRef && companyRef.current) {
      window.requestAnimationFrame(() => {
        setNavValue(0);
      });
    } else if (scrolling && companyRef && companyRef.current) {
      window.requestAnimationFrame(() => {
        setNavValue(1);
      });
    }
  };

  React.useEffect(() => {
    if (response && response.is_favourite)
      setDoFollow(!response.is_favourite);
  }, [response]);
  //setting follow button 

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);
  //sort bar bg effect on scroll ends

  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  let dt = response && response.job_postings ? response.job_postings.map((posting, index) => {
    let responseDate = posting.job_created_at ? new Date(posting.job_created_at) : null;
    let jobUpdatedDate = posting.job_created_at ? responseDate.getDate() + " " + months[responseDate.getMonth()] + ", " + responseDate.getFullYear() : null
    return jobUpdatedDate
  }) : null

  let companyNameArray = response.company_name ? response.company_name.split("-") : "";

  // remove location after ':' and remove pvt ltd etc and remove content following '('
  let companyNameFinal = companyNameArray ? companyNameArray[0].split(':')[0].split("(")[0].replace("Pvt. Ltd.", "").replace("Private Limited", "").replace("Pvt Ltd.", "").replace("Pvt. Ltd", "").replace("Pvt Ltd", "").replace("pvt ltd", "").replace("Ltd", "").replace("Ltd.", "").replace("Limited", "").replace("ltd", "").trim() : "";

  let companyInitialsArr = companyNameFinal ? companyNameFinal.split(" ") : "";
  let companyInitial1 = companyInitialsArr && companyInitialsArr[0] ? companyInitialsArr[0].substring(0, 1) : "";
  let companyInitial2 = companyInitialsArr && companyInitialsArr[1] ? companyInitialsArr[1].substring(0, 1) : "";
  let companyInitials = companyInitial1 + companyInitial2;

  let companyNameWithLocation = response.distric ? "-" + response.distric : response.city ? "-" + response.city : "";

  let percentageOfWomen = response.number_of_female_employees && response.number_of_employees ? Math.round((response.number_of_female_employees / response.number_of_employees) * 100) : null;

  let ratingsText = response.ratings_count ? response.ratings_count === 1 ? "rating" : "ratings" : null;
  let reviewsText = response.reviews_count ? response.reviews_count === 1 ? "review" : "reviews" : null;

  // let jobDetailPathArr = response && response.job_postings && response.company_name ?
  //   response.job_postings.map((posting, index) => {
  //     let path = `/jobDetail/${posting.job_title.replace('-', ' ').replace(/ +/g, '-').replace("/", "-").replace("?", "-").replace("(", "").replace(")", "") + "-" + response.company_name.replace('-', ' ').replace(/ +/g, '-').replace('/', "-").replace('?', "-")}`;

  //     let pathArr = path.split(':');

  //     let jobDetailPath = pathArr[0] + (posting.job_location ? posting.job_location.replace(/ +/g, '-') : "") + "-" + posting.job_id;
  //     return jobDetailPath;

  //   }) : null;

  let jobPostingsCount = response && response.job_postings ? response.job_postings.length : 0;

  //Follow button functionality starts
  const handleFollow = () => {
    //  setDoFollow(!response.is_favourite);    
    if (doFollow) response.no_of_followers = response.no_of_followers + 1;
    else response.no_of_followers = response.no_of_followers > 0 ? response.no_of_followers - 1 : 0;

    let payload = {
      // company_id,
      // invite_id: props.hit.invite_id,
      // shortlist_status: !selected,
      jobseeker_id: jobseekerID,
      liked: !isFavCompany,
      company_id: response.company_id
    }
    // if (props.signInStatus) {
    //  setGotResponse(false);
    ApiHandler.followCompanyToggle(payload, response => {
      if (response.status == 200) {
        setIsFavCompany(prev => !prev);
        setIsUnfollowDialogOpen(false);
      } else {
        var ErrorMessage = 'Oops! Something went wrong. Please try again.';
        if (response.response) {
          let statusCode = response.response.status;
          if (statusCode === 500) {
            ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
          } else if (statusCode === 404) {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          } else if (statusCode === 400) {
            ErrorMessage = response.response.data.message;
            //ErrorMessage = 'Oops! Something went wrong. Please try again.';
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
        } else if (response.message === 'Network Error') {
          ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
        } else {
          ErrorMessage = 'Oops! Something went wrong. Please try again.';
        }
        //handleAlert(ErrorMessage, 'error');
        console.log(ErrorMessage);
        // setGotResponse(true);
      }
    })
    // }
  }
  const [isBackIconDisplayed, setIsBackIconDisplayed] = React.useState(true);

  //if pageStateOnReturn is null then set it to default so that ui back btn click will navigate to jobs main page. This handles the case when user lands directly on this page via a link.  
  React.useEffect(() => {
    let isBrowserHistory = sessionStorage.getItem("pageStateOnReturn");
    if (!isBrowserHistory) {
      sessionStorage.setItem("pageStateOnReturn", "default");
      setIsBackIconDisplayed(false);
    }
  }, []);

  React.useEffect(() => {
    ApiHandler.getFavCompaniesList(0, 10, jobseekerID, res => {
      if (res.status == 200) {
        setFavCompanies(res.data[0].results);
      }
    })
  }, []);

  React.useEffect(() => {
    const checkIsFav = (value) => {
      if (value.company_id == companyID) {
        return true
      } else {
        return false
      }
    }
    if (favCompanies) {
      let isFav = favCompanies.some(checkIsFav);
      setIsFavCompany(isFav)
    }
  }, [favCompanies]);


  return (
    <div className={classes.root}>
      <>
      <Helmet>
        <title>{"View jobseeker feedback for their employers | Meetworks"}</title>
        <meta name="description" content={"Find feedback posted by jobseekers for their employers on the company environment, salary and future growth prospects "} />
        <meta
        name="keywords"
        content={"ratings and reviews, student feedback, online job search sites, job portal for freshers"}/>
      </Helmet>
      </>
      {isLoading
        ? <div style={{
          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
        }}>
          <ListLoader setListLoaderRef={setListLoaderRef} isText={"none"} />
        </div>
        :
        <>
          {/* Top App Bar */}
          <AppBar position="fixed" className={classes.tabBar}>
            <Toolbar variant="dense" style={{ padding: 0 }}>
              <div className={classes.grow} style={{ width: '100%' }}>
                <div className={classes.sectionMobile} style={{ display: "flex" }}>
                  {isBackIconDisplayed ? // if user lands directly on this page via link then don't show back arrow
                    <IconButton id="backButton_companyDetail" label="Back"
                      onClick={() => {
                        AnalyticsHandler.track('CompanyDetail_back_BtnClick');
                        if (sessionStorage.getItem("pageStateOnReturn") === "default") { // if default then go to jobs page and reset the the page state to default 
                          history.push({
                            pathname: `/`,
                            // state: {
                            //   pageStateOnReturn: "default",
                            // }
                          });
                        } else { // else go to prev page which is jobs list page for specific roles
                          history.goBack();
                        }
                      }}
                      style={{ position: 'absolute', zIndex: 9, paddingLeft: 10, top: 0, left: 10 }}>
                      <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
                    </IconButton>
                    : null
                  }
                  <Grid
                    item xs={12}
                    container
                    direction='column'
                    justifyContent="center"
                    alignItems='flex-start'>
                    <Typography
                      variant="subtitle1"
                      className={classes.ellipsisPost2Lines}
                      style={{ color: theme.palette.common.blackDark, lineHeight: '1.2', textAlign: 'center', width: '100%', padding: '0 46px' }}>
                      {companyNameFinal}
                    </Typography>
                  </Grid>
                  {isFavCompany ? <FavoriteIcon color="primary" style={{ position: 'absolute', top: '26%', right: '10%' }} onClick={() => setIsUnfollowDialogOpen(true)} /> : <FavoriteBorderIcon color="primary" style={{ position: 'absolute', top: '26%', right: '10%' }} onClick={handleFollow} />}
                  <CopyToClipboard
                    // text={window.location.origin + match.url}
                    text={window.location.origin + "/CompanyProfile/" + companyNameFinal + companyNameWithLocation + "-" + response.company_id}
                  >
                    <IconButton
                      id="shareButton"
                      style={{ position: 'absolute', top: 0, right: 10, padding: '12px 0' }}
                      onClick={() => {
                        AnalyticsHandler.track('CompanyDetail_shareURL_BtnClick');
                        handleSnackBar(`Link copied. Share Now!!`)
                      }}>
                      <ShareOutlinedIcon color="primary" />
                    </IconButton>
                  </CopyToClipboard>
                </div>
              </div>
            </Toolbar>
          </AppBar>

          {/* tabbar */}
          {jobPostingsCount > 0 &&
            <AppBar position="fixed" className={classes.tabBar} style={{ zIndex: 1200, top: 48 }}>
              <Toolbar variant="dense" style={{ minHeight: 40 }}>
                <div className={classes.grow} style={{ width: '100%' }}>
                  <div className={classes.sectionMobile} style={{ display: "flex" }}>
                    <Tabs
                      value={navValue}
                      onChange={handleNavChange}
                      scrollButtons="on"
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="navigation header bar"
                      className={classes.navTabBar}
                      classes={{ flexContainer: classes.flexContainer, indicator: classes.indicator }}
                    >
                      <Tab id="company_tab_mobile_id" label="Company" onClick={() => {
                        AnalyticsHandler.track('CompanyDetail_company_TabClick');
                        scrollToCompany()
                      }} className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }}  {...a11yProps(0)} />
                      <Tab id="jobs_mobile_tab_id" label={`Openings`} onClick={() => {
                        AnalyticsHandler.track('CompanyDetail_openings_TabClick');
                        scrollToJobs()
                      }} className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yProps(1)} />
                    </Tabs>
                  </div>
                </div>
              </Toolbar>
            </AppBar>}

          {/* About Company */}
          <div id="companyRef_id" ref={companyRef} className={classes.section} style={{ position: 'relative', paddingBottom: 20, paddingTop: jobPostingsCount === 0 ? 60 : undefined }}>

            {response ? <Grid
              item xs
              container
              justifyContent="center"
              alignItems="flex-start"
              direction="row"
              className={classes.sectionItem}
              style={{ marginBottom: 0, border: 'none' }}>
              {response ?
                <Grid
                  item xs={9}
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={3}
                >
                  <Typography variant="h1" style={{ paddingBottom: 5 }}>{companyNameFinal}</Typography>

                  {response.avg_rating ?
                    <Grid
                      item xs
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{
                        padding: '0 0 5px',
                      }}
                    >
                      <StarRoundedIcon className={classes.tileRatingStar} />
                      <Typography variant="h3" >
                        {response.avg_rating ? response.avg_rating : ""}
                        <span style={{ color: theme.palette.primary.dark, fontSize: 12 }}>
                          {response.ratings_count ? " (" + response.ratings_count + " " + ratingsText + ")" : null}
                        </span>
                      </Typography>
                    </Grid> : null}

                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}
                    style={{ padding: '0 0 5px' }}
                  >
                    {/* location */}
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <LocationOnOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} style={{ color: "#797979", fontSize: '1rem', marginBottom: 2 }} />
                      <Typography variant="subtitle1">{response.distric ? response.distric : (response.city ? response.city : "")}, {response.state ? response.state : ""}

                        {/* <span>·</span>
                            {response.distance ? Math.trunc(response.distance) + "  km away" : ""} */}

                      </Typography>
                    </Grid>

                    {response.number_of_employees ?
                      <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                        <EmployeesOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} style={{ color: "#797979", fontSize: '1rem', marginBottom: 2 }} />
                        <Typography variant="subtitle1">{response.number_of_employees ? response.number_of_employees + " employees" : ""}</Typography>
                      </Grid> : null}

                    {response.number_of_employees && response.number_of_female_employees ?
                      <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                        <FemaleOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} style={{ color: "#797979", fontSize: '1rem', marginBottom: 2 }} />
                        <Typography variant="subtitle1">
                          {/* wherever the % of women employees is 1 or less than 1%, we don’t show the text in brackets and just write # women/ woman employees/employee */}
                          {response.number_of_female_employees === 1 ? response.number_of_female_employees + " woman"
                            : response.number_of_female_employees + " women"}
                          {percentageOfWomen && percentageOfWomen > 1 ? ` (${percentageOfWomen}% of employees)` : response.number_of_female_employees === 1 ? ` employee` : ` employees`}</Typography>
                      </Grid> : null}

                    {response.sector_name ?
                      <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                        <CategoryOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} style={{ color: "#797979", fontSize: '1rem', marginBottom: 2 }} />
                        <Typography variant="subtitle1" style={{ color: "#797979", fontWeight: 400 }}> industry:
                          <span style={{ color: response.sector_color_code ? "#" + response.sector_color_code : "inherit", fontWeight: 500 }}> {" " + response.sector_name}</span></Typography>
                      </Grid> : null}

                  </Grid>
                </Grid> : null}

              {/* Company Logo  */}
              <Grid
                item xs={3}
                container
                justifyContent="flex-end"
                alignItems="flex-start"
                style={{ marginTop: '-10px' }}
              >
                <div className={classes.imgWrapper} style={{ marginRight: '-12px' }}>
                  {response.company_image_url ?
                    <img className={classes.imageTag} alt="company logo"
                      onLoad={(event) => { event.target.style.opacity = 1 }}
                      onError={(event) => { event.target.nextElementSibling.style.opacity = 1 }}
                      src={response.company_image_url}
                      style={{ opacity: 0 }} />
                    :
                    <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>
                  }
                  <Typography variant="h3" style={{ position: 'absolute', textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem', opacity: 0 }}>{companyInitials}</Typography>


                  {/* {response.company_image_url ?
                    <img className={classes.imageTag} src={response.company_image_url} alt="Company Logo" />
                    : <Typography variant="h3" style={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: '2rem' }}>{companyInitials}</Typography>} */}
                </div>
              </Grid>
            </Grid> : null}

            {/* <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}
                  style={{ padding: !response.number_of_employees || response.number_of_female_employees ? 0 : '20px 0' }} // for mvp in which follow is disabled
                >

              {/* follow unfollow */}
            {/* <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                    {response.no_of_followers
                      ? <Grid item xs container justifyContent="flex-start" alignItems="center" >
                        <FavoriteBorderOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                        <Typography variant="body1">{response.no_of_followers} followers</Typography>
                      </Grid>
                      : <Grid item xs container justifyContent="flex-start" alignItems="center" >
                        <FavoriteBorderOutlinedIcon fontSize="small" className={classes.sectionItem_heading_icon} style={{ color: theme.palette.common.grey }} />
                        <Typography variant="body1" style={{ color: theme.palette.common.grey }}>Be the first one to follow.</Typography>
                      </Grid>
                    }

                    <IconButton id="followButton" label="follow" onClick={!isSignedIn ? handleDrawer : handleFollow}>
                      <Typography variant="body1" className={classes.sectionItem_text_button}>{doFollow ? "Follow" : "Unfollow"}</Typography>
                    </IconButton>
                  </Grid> */}

            {/* </Grid> */}

            {/* Company Description */}
            {response.company_description ?
              <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>

                <Grid container justifyContent="space-between" alignItems='center' style={{ marginBottom: 5 }}>
                  <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading}
                    style={{ marginBottom: 0 }} //to align the call HR btn
                  >

                    <DescriptionOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                    <Typography variant="h4" color="primary">Company का Work </Typography>
                  </Grid>
                  {response.company_mobile ?
                    <div>
                      <a href={"tel:" + response.company_mobile} target="self" style={{ textDecoration: 'none' }}>
                        <Button
                          id="CallButton"
                          size="medium"
                          style={{ backgroundColor: '#fff', color: theme.palette.primary.dark, textTransform: 'capitalize', width: 'auto', padding: 0, fontSize: '0.815rem' }}
                          onClick={() => {
                            AnalyticsHandler.track('CompanyDetail_callHR_BtnClick')
                          }
                          }
                        >
                          Call HR
                        </Button>
                      </a>
                    </div>
                    : null}
                </Grid>

                <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                  <Typography variant="body1">{response.company_description}</Typography>
                </Grid>
              </Grid> : null}

            {/* Company Ratings */}
            {response.ratings_count ?
              <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                {response.ratings_count ?
                  <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                    <StarsOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                    <Typography variant="h4" color="primary">Ratings</Typography>
                  </Grid> : null}
                {response.avg_rating ?
                  <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                    <Grid item xs container justifyContent="flex-start" alignItems="center" >
                      <OverallRatingIcon fontSize="small" className={classes.sectionItem_heading_icon} style={{ color: theme.palette.common.blackDark, marginRight: 10, }} />
                      <Typography variant="subtitle1">Overall</Typography>
                    </Grid>

                    <Grid
                      item xs
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      <StarRoundedIcon className={classes.tileRatingStar} />
                      <Typography variant="subtitle1">{response.avg_rating}</Typography>
                    </Grid>
                  </Grid> : null}
                {response.environment_rating ?
                  <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                    <Grid item xs container justifyContent="flex-start" alignItems="center" >
                      <EmployeesIcon fontSize="small" className={classes.sectionItem_heading_icon2} />
                      <Typography variant="body1">Company का माहौल</Typography>
                    </Grid>
                    <Typography variant="body2" style={{ color: theme.palette.common.grey }}>{response.environment_rating}</Typography>
                  </Grid> : null}
                {response.growth_rating ?
                  <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                    <Grid item xs container justifyContent="flex-start" alignItems="center" >
                      <EqualizerIcon fontSize="small" className={classes.sectionItem_heading_icon2} />
                      <Typography variant="body1">Career का विकास </Typography>
                    </Grid>
                    <Typography variant="body2" style={{ color: theme.palette.common.grey }}>{response.growth_rating}</Typography>
                  </Grid> : null}
                {response.salary_rating ?
                  <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                    <Grid item xs container justifyContent="flex-start" alignItems="center" >
                      <AccountBalanceWalletIcon fontSize="small" className={classes.sectionItem_heading_icon2} />
                      <Typography variant="body1">Salary </Typography>
                    </Grid>
                    <Typography variant="body2" style={{ color: theme.palette.common.grey }}>{response.salary_rating}</Typography>
                  </Grid> : null}

                {/* <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                <Grid item xs container justifyContent="flex-start" alignItems="center" >
                  <LocalLibraryIcon fontSize="small" className={classes.sectionItem_heading_icon2} />
                  <Typography variant="body1">Learning Opportunities</Typography>
                </Grid>
                <Typography variant="body2" style={{ color: theme.palette.common.grey }}>4.1 (FILL IN HERE)</Typography>
              </Grid>
              <Grid item xs container justifyContent="space-between" alignItems="center" className={classes.sectionItem_heading} >
                <Grid item xs container justifyContent="flex-start" alignItems="center" >
                  <SupervisorAccountIcon fontSize="small" className={classes.sectionItem_heading_icon2} />
                  <Typography variant="body1">My Manager</Typography>
                </Grid>
                <Typography variant="body2" style={{ color: theme.palette.common.grey }}>4.1 (FILL IN HERE)</Typography>
              </Grid> */}
              </Grid>
              : null}

            {/* -------Reviews-------*/}
            {response && response.reviews_count ?
              <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                <Grid item xs container justifyContent="space-between" alignItems="center" >
                  <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                    <ReviewsOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                    <Typography variant="h4" color="primary">यहाँ के employees का अनुभव
                      {/* <span style={{ color: theme.palette.common.grey }}>
                    {response.reviews_count >= 5 ? "(5 reviews)" : response.reviews_count === 1 ? "(1 review)" : "(" + response.reviews_count + " reviews)"}</span> */}
                    </Typography>
                  </Grid>
                  {reviewsList && reviewsList.length > 5 ?
                    <div>
                      <Button
                        id="CallButton"
                        size="medium"
                        style={{ backgroundColor: '#fff', color: theme.palette.primary.dark, width: 'auto', padding: 0, fontSize: '0.815rem', justifyContent: 'flex-end', textTransform: 'unset' }}
                        onClick={() => { setIsRatingsOpen(true) }}
                      >
                        See all
                      </Button>
                    </div>
                    : null}
                </Grid>

                {reviewsList && reviewsList.length > 0 && reviewsList.filter((item, index) => index < 5).map((item, index) =>
                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem_rating} key={index}>
                    {/* {item.quality_score ?
                      <Rating
                        name="company_rating"
                        defaultValue={item.quality_score}
                        precision={0.5}
                        icon={<StarRoundedIcon fontSize="small" />}
                        readOnly
                        style={{ marginBottom: 10 }}
                      /> : null} */}

                    {item.review_data && item.review_data.jobRole ?
                      <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>💼 मेरा काम: </span> {item.review_data.jobRole}
                      </Typography> : null
                    }

                    {item.review_data && item.review_data.goodThing ?
                      <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>🙂 मुझे अच्छा लगा : </span>
                        {item.review_data.goodThing}
                      </Typography> : null
                    }

                    {item.review_data && item.review_data.badThing ?
                      <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>🙁 मुझे ठीक नहीं लगा: </span>
                        {item.review_data.badThing}
                      </Typography> : null}

                    {item.review ?
                      <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>
                        🤓 मेरा अनुभव:
                        {/* ✍️ मेरा अनुभव:  */}
                      </span>
                        {item.review}
                      </Typography> : null}

                    {/* Button for more reviews */}
                    {index === 4 && reviewsCount > 5 ?
                      <Grid item xs={12} container justifyContent="center">
                        <Button
                          id="MoreReviewsButton"
                          size="medium"
                          className={classes.moreButton}
                          onClick={() => { setIsRatingsOpen(true) }}
                        >
                          see all
                          <ChevronRightRoundedIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                        </Button>
                        {isRatingsOpen &&
                          <SeeAllReviews
                            companyID={companyID}
                            closeRatingsModal={closeRatingsModal}
                          />}
                      </Grid>
                      : null}
                  </Grid>)}
              </Grid> : null}

            {/* -------Address-------*/}
            {response && response.company_address ?
              <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                  <MarkunreadMailboxOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                  <Typography variant="h4" color="primary">Company Headquarter Address</Typography>
                </Grid>
                <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                  <Typography variant="body1">{response.company_address}</Typography>
                </Grid>
              </Grid> : null}

            {/* to set scroll ref for company section tab */}
            <div style={{ position: 'absolute', bottom: 80 }} ref={jobsRef}></div>

          </div>

          {/* Open Jobs at this Company */}
          {response && jobPostingsCount > 0 ?
            // <div className={classes.section} style={{ marginTop: 5, paddingTop: 20, paddingBottom: response && response.mapped_role ? 20 : 70 }}>
            <>
              <Grid container justifyContent="space-between" alignItems='baseline'>
                <Typography variant="h1" style={{ color: theme.palette.primary.main, marginBottom: 10, padding: '20px 10px 0', fontWeight: 600, fontSize: 24 }}>Openings at this company</Typography>
              </Grid>
              {/* job postings */}
              <List className={classes.listRoot} disablePadding>
                {jobPostingsCount > 0 && response.job_postings && response.job_postings.map((posting, index) => (
                  <JobsListItemOnCompanyPage
                    hit={response.job_postings[index]}
                    companyNameFinal={companyNameFinal}
                    companyInitials={companyInitials}
                    key={index}
                  />


                  // <Grid
                  //   container
                  //   direction="column"
                  //   alignItems="flex-start"
                  //   className={classes.cardFrame}
                  //   style={{ paddingBottom: 0 }}
                  //   key={index}>
                  //   {posting.job_title ? <Grid
                  //     item xs
                  //     container
                  //     justifyContent="space-between"
                  //     alignItems="center"
                  //     style={{ marginBottom: 12, padding: '0 10px' }}>
                  //     <Grid
                  //       item xs={9}
                  //       container
                  //       justifyContent="flex-start"
                  //       alignItems="center">
                  //       <Typography variant="h1">{posting.job_title} </Typography>
                  //     </Grid>
                  //     <CopyToClipboard text={window.location.origin + jobDetailPathArr[index]} style={{ position: 'absolute', right: 10, top: 15, padding: 0 }}>
                  //       <IconButton id="shareButton" onClick={() => {
                  //         AnalyticsHandler.track('CompanyDetails_jobCard_shareURL_BtnClick');
                  //         handleSnackBar(`Link copied. Share Now!!`)
                  //       }}>
                  //         <ShareOutlinedIcon color="primary" />
                  //       </IconButton>
                  //     </CopyToClipboard>
                  //   </Grid> : null}
                  //   {posting.job_salary_amount ?
                  //     <Grid
                  //       item xs={12}
                  //       container
                  //       justifyContent="flex-start"
                  //       alignItems="center"
                  //       style={{ marginBottom: 12, padding: '0 10px' }}>
                  //       <Grid
                  //         item xs={12}
                  //         container
                  //         justifyContent="flex-start"
                  //         alignItems="center">
                  //         <Typography
                  //           className={`${classes.cardTag} ${classes.ellipsis}`}
                  //           style={{ maxWidth: '90%', width: 'unset', marginRight: 0 }}>{posting.job_salary_amount ? posting.job_salary_amount : " "}{" "}<span style={{ fontWeight: 400, fontSize: 12, }}>{posting.variable_salary_unit ? posting.variable_salary_unit : ""}</span>
                  //         </Typography>
                  //         <IconButton className={classes.infoIcon} id={`infoSalaryBtn${index}`} aria-describedby={`infoSalary_id${index}`} variant="contained" color="primary" onClick={(e) => {
                  //           handleSalaryInfoClick(e, index)
                  //         }}>
                  //           <InfoOutlinedIcon style={{ fontSize: '1rem', color: 'white' }}></InfoOutlinedIcon>
                  //         </IconButton>

                  //         <Popover
                  //           id={`infoSalary_id${index}`}
                  //           open={openedPopoverId === `infoSalary_id${index}`}
                  //           anchorEl={anchorEl}
                  //           onClose={handleSalaryInfoClose}
                  //           anchorOrigin={{
                  //             vertical: 'bottom',
                  //             horizontal: 'center',
                  //           }}
                  //           transformOrigin={{
                  //             vertical: 'top',
                  //             horizontal: 'center',
                  //           }}
                  //         >
                  //           <Typography className={classes.cardinfoPopoverText}>{posting.job_salary ? posting.job_salary : ""}</Typography>
                  //         </Popover>

                  //       </Grid>
                  //     </Grid> : null}
                  //   <Grid
                  //     item xs={12}
                  //     container
                  //     justifyContent="flex-start"
                  //     alignItems="center"
                  //     style={{ marginBottom: 10, padding: '0 10px' }}>
                  //     <WorkOutlineRoundedIcon fontSize="small" className={classes.icon} />
                  //     {posting.opportunity_type ?
                  //       <Typography className={classes.cardText} >
                  //         {posting.opportunity_type === "Job" ? "Full-time"
                  //           : posting.opportunity_type === "Freelance" ? "Part-time"
                  //             : posting.opportunity_type}</Typography>
                  //       : null}

                  //     {(posting.opportunity_type && posting.internship_duration) || (posting.opportunity_type && posting.no_of_vacancies) ? <span style={{ margin: '0 10px' }}>·</span> : null}
                  //     {posting.internship_duration ? <Typography className={classes.cardText}>{posting.internship_duration}</Typography> : null}
                  //     {posting.internship_duration && posting.no_of_vacancies ? <span style={{ margin: '0 10px' }}>·</span> : null}
                  //     {posting.no_of_vacancies ? <Typography className={classes.cardText}>{posting.no_of_vacancies} {posting.no_of_vacancies == 1 ? "Opening" : "Openings"} </Typography> : null}
                  //   </Grid>

                  //   {posting.job_location && !posting.isWorkFromHome ? <Grid
                  //     item xs={12}
                  //     container
                  //     justifyContent="flex-start"
                  //     alignItems="center"
                  //     style={{ marginBottom: 5, padding: '0 10px' }}>
                  //     <LocationOnOutlinedIcon fontSize="small" className={classes.icon} />
                  //     <Typography className={classes.cardText}>{posting.job_location}</Typography>
                  //     {posting.job_created_at ?
                  //       <Typography variant="caption" style={{ marginLeft: 'auto' }}>Updated: <span>{dt[index]}</span></Typography>
                  //       : null}

                  //   </Grid> : null}
                  //   {posting.isWorkFromHome ? <Grid
                  //     item xs={12}
                  //     container
                  //     justifyContent="flex-start"
                  //     alignItems="center"
                  //     style={{ marginBottom: 5, padding: '0 10px' }}>
                  //     <LocationOnOutlinedIcon fontSize="small" className={classes.icon} />
                  //     <Typography className={classes.cardText}>घर से</Typography>

                  //     {posting.job_created_at ?
                  //       <Typography variant="caption" style={{ marginLeft: 'auto' }}>Updated: <span>{dt[index]}</span></Typography>
                  //       : null}

                  //   </Grid> : null}

                  //   <Grid item xs={12} container justifyContent="center">
                  //     <div style={{ width: '100%', height: 1, borderTop: '1px solid', borderColor: theme.palette.primary.main }}></div>
                  //     <Button
                  //       id="companyDetailButton"
                  //       size="medium"
                  //       style={{
                  //         backgroundColor: '#fff', fontSize: '0.9375rem',
                  //         color: theme.palette.primary.dark, textTransform: 'unset', width: '100%'
                  //       }}
                  //       onClick={() => {
                  //         AnalyticsHandler.track('CompanyDetails_jobCard_viewDetails_BtnClick');
                  //         history.push({
                  //           pathname: `${jobDetailPathArr[index]}`,
                  //         });
                  //         sessionStorage.setItem("pageStateOnReturn", "goBack")// so that when back arrow is tapped on jobdetail page, the user returns to this page in history
                  //       }}
                  //     >
                  //       Details देखें
                  //       {/* <ChevronRightRoundedIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} /> */}
                  //     </Button>
                  //   </Grid>
                  // </Grid>
                )
                )}
              </List>
              {/* </div>  */}
            </>
            : null}

          {/* Button for Similar jobs in this role */}
          {/* {response && response.mapped_role ?
            <div className={classes.section} style={{ marginTop: 0, paddingTop: 0, paddingBottom: 70 }}>
              <Grid item xs={12} container justifyContent="center">
                <Button
                  id="MoreButton"
                  size="medium"
                  style={{
                    backgroundColor: '#fff', fontSize: '0.9375rem',
                    color: theme.palette.common.blackDark, textTransform: 'unset', width: '100%'
                  }}
                  onClick={() => {//send role and back button flag via location object to role specific jobs list page
                    history.push({
                      pathname: `/findwork`,
                      state: {
                        role: response.mapped_role,
                        goBackToPrev: true,
                      }
                    });
                  }
                  }
                >
                  और {response.mapped_role} openings देखें
                  <ChevronRightRoundedIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                </Button>
              </Grid>
            </div>
            : null} */}

          {/* Bottom App Bar */}

          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar variant="dense" style={{ width: '100%', justifyContent: 'space-evenly' }}>
              {jobPostingsCount > 0 && <Button
                id="ApplyButton"
                size="medium"
                style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, textTransform: 'lowercase', width: 'auto' }}
                onClick={() => {
                  AnalyticsHandler.track('CompanyDetail_viewOpenings_BtnClick');
                  scrollToJobs();
                }}
              >
                इस company में openings देखें
              </Button>}
              {jobPostingsCount === 0 ?
                <>
                  <Button
                    id="findWork"
                    size="medium"
                    style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, textTransform: 'capitalize', width: '140px' }}
                    onClick={(() => {
                      AnalyticsHandler.track('CompanyDetail_findWork_BtnClick');
                      history.push({
                        pathname: `/`,
                      });
                    })}
                  >
                    Find Work
                  </Button>

                  <Button
                    id="createProfile"
                    size="medium"
                    style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, textTransform: 'capitalize', width: '140px' }}
                    // onClick={handleDialogOpen}
                    onClick={(() => {
                      AnalyticsHandler.track('CompanyDetail_createProfile_BtnClick');
                      history.push({
                        pathname: `/profile`,
                      });
                    })}
                  >
                    Create Profile
                  </Button>
                </>
                : null}

            </Toolbar>
          </AppBar>
        </>
      }

      {/* Unfollow confirmation dialog */}

      <Dialog onClose={handleUnfollowDialogClose} aria-labelledby="customized-dialog-title" open={isUnfollowDialogOpen} maxWidth={'sm'}>

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleUnfollowDialogClose}>
          <CloseIcon />
        </IconButton>

        <DialogContent dividers style={{ paddingTop: 50 }}>
          <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5' }}>
            क्या आप sure हैं आप इस company को unfollow करना चाहते हैं? ऐसा करने से आपके पास इस company में नई openings के बारे में notification नहीं आएंगे
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
            <Button autoFocus
              color="primary"
              size="medium"
              style={{ backgroundColor: theme.palette.primary.contrastText, color: theme.palette.primary.dark, textTransform: 'capitalize', width: '140px' }}
              onClick={handleFollow}
              >
              Unfollow
            </Button>
            <Button autoFocus
              color="primary"
              size="medium"
              style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, textTransform: 'capitalize', width: '140px' }}
              onClick={handleUnfollowDialogClose}
              >
              Keep Following
            </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog box */}
      <Dialog onClose={handleDialogClose} aria-labelledby="customized-dialog-title" open={isDialogOpen} maxWidth={'sm'}>

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>

        <DialogContent dividers style={{ paddingTop: 50 }}>
          <Typography gutterBottom variant="h3" style={{ lineHeight: '1.5' }}>
            आपको Meeत के whatsapp channel से connect किया जा रहा है, जहां आपको profile बनाने के instructions मिलेंगे।
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <a
            href="https://api.whatsapp.com/send?phone=919580899412&text=My%20Website"
            style={{ textDecoration: 'none' }}>
            <Button autoFocus
              color="primary"
              size="medium"
              style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, textTransform: 'capitalize', width: '140px' }}>
              Okay
            </Button>
          </a>
        </DialogActions>
      </Dialog>

      <SnackBarGeneric id="snackBarMessage" canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />

      <SignInUpDrawer
        canDrawerOpen={canDrawerOpen}
        refresh={refresh}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshOnly}
        setCanDrawerOpen={setCanDrawerOpen}
        doRefresh={doRefresh}
      />
    </div>
  )
}