import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LogoLoader from '../../assets/logo-loader.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 'unset',
    display: 'flex',
    justifyContent: 'center',
    //position: 'relative',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    // height: 'calc(100vh - 250px)',
    height: '100%',
    marginBottom: theme.spacing(2),
    '& .paper': {
      boxSizing: 'border-box',
      padding: theme.spacing(0),
      margin: 'auto',
      maxWidth: 830,
      maxHeight: 300,
      width: 830,
      height: 300,
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      background: theme.palette.common.greyLight,
      borderRadius: '10px',
      height: '100%',
      position: 'absolute',
      top: 0,
      zIndex: 2,
      display: 'none',
    },
    '&.animate .paper': {
      animation: 'bgColorFade 0.15s ease-in-out infinite alternate',
      display: 'flex'
    },
    '&.animate div': {
      //animation: 'bgColorFade 0.5s ease-in-out infinite alternate',
      display: 'flex'
    }
  },

  leftSection: {
    height: '100%',
    padding: 0,
    position: 'relative',
    background: '#D5F5ED',
    overflow: 'hidden',
    maxWidth: '23%',
    flexBasis: '23%',
  },

  RightSection: {
    //height: '100%',
    maxHeight: '100%',
    paddingTop: 10,
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  image: {
    width: '100%',
    height: 450,
    overflow: 'hidden'
  },
  img: {
    margin: 'auto',
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: 'unset',
    //position: 'absolute',
  },
  avatarWrapper: {
    width: '100%',
    height: 300,
    borderRadius: 0,
    //paddingTop: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    margin: 0,
  },
  lastActive: {
    background: '#EEE',
    width: '20%',
    height: '10px',
    borderRadius: 5,
  },
  ph_namePlate: {
    background: '#EEE',
    width: '60%',
    height: '20px',
    borderRadius: 5,
    marginBottom: 25,
  },
  ph_text: {
    background: '#EEE',
    width: '40%',
    height: '15px',
    borderRadius: 5,
    marginBottom: 10,
    marginLeft: 10,
  },
  ph_subtext: {
    background: '#EEE',
    width: '60%',
    height: '12px',
    borderRadius: 5,
    marginBottom: 20,
    marginLeft: 10,
  },
  loaderShuffle: {
    background: theme.palette.common.greyLight,
    //background: 'radial-gradient(circle, rgba(252,252,252,0) 0%, rgba(252,252,252,1) 67%, rgba(252,252,252,1) 100%)',
    borderRadius: '10px',
    border: '1px solid #eee',
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 2,
    display: 'none',
    '&.animate': {
      //animation: 'bgColorFade 0.5s ease-in-out infinite alternate',
      display: 'flex'
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 50,
    },
  }

}));

const ListLoader = (props) => {
  const classes = useStyles();
  const loaderRef = React.useRef(null);

  React.useEffect(() => {
    props.setListLoaderRef(loaderRef);
  }, [loaderRef])
  return (
    <div id="cardWrapper1" className={classes.root}
      ref={loaderRef}
      style={{ flexDirection: 'column' }}>
      <Grid item xs={12} className={classes.loaderShuffle} container direction="column" justifyContent="center" alignItems="center">
        <img alt="meet animated logo loader" style={{ width: props.gifSize ? props.gifSize: 50 }} src={LogoLoader} />
        <Typography className="loaderText" variant="body2" style={{ marginTop: 10, display: props.isText ? props.isText: 'block' }}>मुस्कुराइए, आप Meeत के साथ हैं :)</Typography>
      </Grid>
    </div>
  )
}

export default ListLoader;