import React, { useState, useEffect, useRef } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Helmet } from 'react-helmet-async';

import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import BottomAppBar from '../../components/BottomAppBar';
import '../../style/App.css';
import SignInUpDrawer from '../../components/SignInDrawer';
import LogoutDrawer from '../../components/LogoutDrawer';
import ApiHandler from '../../ApiHandler';
import AnalyticsHandler from '../../AnalyticsHandler';
import ListLoader from '../errorsAndLoaders/ListLoader';
import { ThemeConsumer } from 'styled-components';
import SnackBarGeneric from '../../components/SnackBarGeneric';

import AppliedJobs from '../Profile/AppliedJobs';
import JobInvites from '../Profile/JobInvites';

import { useStyles } from '../Profile/styles';
// import NoResultsImage from '../../assets/no_results.png';
import Wallpaper from '../../assets/Iconwallpaper2.png';

import logo from '../../assets/logo_header.png';
import { Badge, Dialog, DialogContent } from '@material-ui/core';
import Notifications from '../../components/Notifications';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tab-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                // <Box p={3}>
                <div>{children}</div>
                // </Box>
            )}
        </div>
    );
}

function TabPanelSec(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tab-dec-${index}`}
            aria-labelledby={`scrollable-force-tab-sec-${index}`}
            {...other}
        >
            {value === index && (
                // <Box p={3}>
                <div>{children}</div>
                // </Box>
            )}
        </div>
    );
}

function a11yPrimaryTabProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function a11yProps(index) {
    return {
        id: `secondary-tab-${index}`,
        'aria-controls': `secondary-tabpanel-${index}`,
    };
}

const useStylesPrivate = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        minHeight: 'calc(100vh - 0px)',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 55,
        backgroundAttachment: 'fixed',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
        },
    },
    pageBar: {
        //width: 860,
        width: '100%',
        padding: '8px 43px',
        flexBasis: 50,
        backgroundColor: theme.palette.common.greyLight,
        marginTop: 0,
        zIndex: 8,
        transition: '0.2s',
        position: 'sticky',
        top: 59,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            top: 0,
            padding: 0
        },
    },
    pageTitle: {
        color: "white",
        fontSize: "48px",
        fontWeight: 'bold',
        textShadow: "4px 4px 4px rgba(144, 144, 144, 0.16)",
        margin: '2em 0 0.2em',
    },
    pageSubTitle: {
        color: theme.palette.common.white,
        width: '60%',
        fontSize: "32px",
    },


    pageInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingTop: 'calc(80vh - 500px);',
        alignItems: 'center',
        flexDirection: 'column',
        height: '75vh',
    },
    pageInfoText: {
        color: "#555",
        lineHeight: '2em'
    },
    pageButton: {
        textTransform: "none",
        width: 100,
        height: 35,
        marginTop: 20,
    },
    pageButton_pri: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },


    sectionDesktop: {
        display: 'none',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column'
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },




}));

export default function ProfilePage() {
    const classes = useStyles();
    const privateClasses = useStylesPrivate();
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const [navValue, setNavValue] = React.useState(1);
    const pageStateOnReturn = sessionStorage.getItem("pageStateOnReturn");
    const [primaryTabValue, setPrimaryTabValue] = React.useState(pageStateOnReturn === "appliedJobs" ? 1 : pageStateOnReturn === "jobInvites" ? 2 : 0);
    const [secondaryTabValue, setSecondaryTabValue] = React.useState(4);


    const [isLoading, setIsLoading] = React.useState(true);

    //Api integration

    let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
    //let sessionCookie = document.cookie.indexOf('connect.sid') === -1 ? null : true;
    const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
    const matchesMinHeight = useMediaQuery('(min-height:450px)');

    // for controlling open event -->starts
    const [openInst, setOpenInst] = React.useState(false);
    const [inputValInst, setInputValInst] = React.useState("");
    const handleOpenInst = () => {
        if (inputValInst.length > 0) {
            setOpenInst(true);
        }
    };

    const [openCourse, setOpenCourse] = React.useState(false);
    const [inputValCourse, setInputValCourse] = React.useState("");
    const handleOpenCourse = () => {
        if (inputValCourse.length > 0) {
            setOpenCourse(true);
        }
    };
    // for controlling open event -->ends


    let jobseekerID = localStorage.getItem("jobseeker_id");

    document.body.style.overflow = "hidden"

    const handleSignedInStatus = (v) => {
        setIsSignedIn(v);
    }

    const handleNavChange = (event, newValue) => {
        setNavValue(newValue);
    };

    const handlePrimaryTabValueChange = (event, newValue) => {
        if (document.getElementById("saveAndCancelBtns") && document.getElementById("saveAndCancelBtns").style.display !== "none"
            //&& !isSaveBtnDisabled
        ) {
            denyTabChange()
            return
        }
        // if (newValue === 0) {
        //     AnalyticsHandler.track('Profile_myResume_TabClick');
        //     setTimeout(() => {
        //         document.body.style.overflow = "hidden"
        //     }, 10)
        // } else {
        if (newValue === 0) {
            AnalyticsHandler.track('Profile_appliedJobs_TabClick');
        } else if (newValue === 1) {
            AnalyticsHandler.track('Profile_invitesReceived_TabClick');
        }
        setTimeout(() => {
            document.body.style.overflowY = "scroll"
        }, 10)
        // }
        setPrimaryTabValue(newValue);
    };

    const clearLocalStorageAndSignOutFE = () => {
        localStorage.removeItem('is_logged_in');
        localStorage.removeItem('jobseeker_id');
        localStorage.removeItem('profile_completed');
        setIsSignedIn(false);
        enableTabChange();
        setTimeout(() => { // to reset the form values if session expires while form filling
            window.location.reload();
        }, 1000)
    }

    const handleLogout = (caseParam) => {
        ApiHandler.logout(function (res) {
            if (res.status === 200) {
                AnalyticsHandler.track('Profile_logout_Success');
                AnalyticsHandler.reset();
                clearLocalStorageAndSignOutFE();
                if (caseParam === "onDeleteAccount") {
                    handleDialogClose();
                    // handleSnackBar("Your account has been deleted successfully.");
                    handleSnackBar("Done");
                    enableTabChange();
                    //reset edu vars
                    setInstituteNameArray([]);
                    setQualTypeArray([]);
                    setCourseNameArray([]);
                    setSpecializationNameArray([]);
                    setGraduationYearInputArray([]);
                    //reset exp vars
                    setJobRoleArray([]);
                    setExpType([]);
                    setJobDesc([]);
                    setIsCurrentJob([]);
                    setJobStartDate([]);
                    setJobEndDate([]);
                    setIsUnpaid([]);
                    setFixedSalaryInput([]);
                    setVariableSalaryInput([]);
                    setVariableSalaryUnitInput([]);
                    setCompanyNameArray([]);
                    setIsWFH([]);
                    setWorkLocations([]);
                    setWorkLatLongs([]);
                    setEnvRatings([]);
                    setGrowthRatings([]);
                    setLearningRatings([]);
                    setFacilityRatings([]);
                    setLocationRatings([]);
                    setManagerRatings([]);
                    setSalaryRatings([]);
                    setReviewTags([]);
                    setIsReviewPublic([]);
                    setGoodReviews([]);
                    setBadReviews([]);
                    setAudioReviews([]);
                    setVideoReviews([]);
                    setOverallRatings([]);
                    setAvgRatings([]);
                    return
                }
                handleConfDialogClose();
                handleSnackBar("You have logged out successfully.")
            } else if (res.response) { // on error
                if (res.response.data.message === "Session Expire") {
                    clearLocalStorageAndSignOutFE();
                    handleSnackBar("Your Session expired. Please login again.", "error")
                } else {
                    handleSnackBar("Something went wrong. Please try again.", "error")
                }
                if (process.env.REACT_APP_NODE_ENV === "development") { // for development env do the logout without auth on click
                    clearLocalStorageAndSignOutFE();
                    if (caseParam === "onDeleteAccount") {
                        handleDialogClose();
                        handleSnackBar("Your account has been deleted successfully.");
                        return
                    } else if (caseParam === "logoutOnSessionExpire") {
                        handleSnackBar("Your Session expired. Please login again.", "error");
                        return
                    }
                    handleConfDialogClose();
                    handleSnackBar("You have logged out successfully.")
                }
            }
        })
    }

    // drawer callback starts
    const [refresh, doRefresh] = React.useState(false);
    const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
    const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
    const handleDrawer = () => {
        setCanDrawerOpen(true)
        doRefresh(prev => !prev)
    }
    // drawer callback ends

    // Settings drawer callback starts
    const [forRefreshSettingsOnly, setForRefreshSettingsOnly] = React.useState(false);
    const [canSettingsDrawerOpen, setCanSettingsDrawerOpen] = React.useState(false);
    const [refreshSettings, doRefreshSettings] = React.useState(false);

    const handleSettingsDrawer = () => {
        setCanSettingsDrawerOpen(true)
        doRefreshSettings(prev => !prev)
    }
    // Settings drawer callback ends

    // snackbar callback starts
    const [snackBarMessage, setSnackBarMessage] = React.useState('')
    const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
    const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
    const [snackBarMessageSeverity, setSnackBarMessageSeverity] = React.useState("success");
    const handleSnackBar = (message, severity) => {
        if (severity) {
            setSnackBarMessageSeverity(severity);
        } else {
            setSnackBarMessageSeverity("success");
        }
        setCanSnackBarOpen(true);
        setSnackBarMessage(message);
        doRefreshSnackBar(prev => !prev);
    }
    // snackbar callback ends

    //notification start
    const [openNotificationDialog, setOpenNotificationDialog] = React.useState(false);
    const [notificationBadgeCount, setNotificationBadgeCount] = React.useState(0);

    const closeNotificationDialog = () => {
        setOpenNotificationDialog(false);
    }

     //fetch all notifications
  React.useEffect(() => {
    let jobseekerID = localStorage.getItem("jobseeker_id");
    let payload = {
      "offset": 0,
      "limit": 100,
      "jobseeker_id": jobseekerID
    }
    ApiHandler.getAllNotifications(payload, res => {
      let unreadNotif = 0;
      if (res.status == 200) {
        unreadNotif = res.data[0].results.filter(val => !val.is_read).length;
        setNotificationBadgeCount(unreadNotif);
      }
    })
  }, []);

    return (

        <React.Fragment>
            <Helmet>
                <title>{primaryTabValue == 0 ? "View list of jobs applied by jobseekers | Meetworks" : "Explore employer invites received for jobseekers | Meetworks"}</title>
                <meta name="description" content={primaryTabValue == 0 ? "Explore list of applied jobs and find opportunities that match your skills and interests as you navigate through a curated selection of jobs" : "Explore invites received from employers and experience professional invitations that open doors to your dream career"} />
                <meta
                    name="keywords"
                    content={primaryTabValue == 0 ? "work from home jobs for freshers, online job search sites, meetworks, jobs for freshers near me" : "job vacancy for freshers, careers for freshers, online job search sites, work invitations"} />
            </Helmet>
            {isSignedIn ?
                <>
                    {/* Top App Bar on Mobile */}
                    <AppBar position="fixed" style={{ background: '#fff' }}>
                        <Toolbar variant="dense" style={{ padding: 0 }}>
                            <div style={{ width: '100%' }}>
                                <div className={privateClasses.sectionMobile}>
                                    <Button
                                        style={{ display: 'flex' }}
                                        id="meetLogo_id"
                                        onClick={() => {
                                            history.push({
                                                pathname: '/'
                                            });
                                        }}>
                                        <img alt="meet logo" style={{ width: 80, marginRight: '11%', paddingLeft: 10 }} src={logo} />
                                    </Button>
                                    <Grid
                                        item xs={9} md={11}
                                        container
                                        direction='column'
                                        justifyContent="center"
                                        alignItems='flex-start'>

                                    </Grid>
                                    {isSignedIn && <IconButton className={classes.mobileNotificationIcon} aria-label="show 10 new notifications" color="inherit">
                                        <Badge badgeContent={notificationBadgeCount} color="error">
                                            <NotificationsOutlinedIcon color='action' onClick={() => setOpenNotificationDialog(true)} />
                                        </Badge>
                                    </IconButton>}
                                    <IconButton
                                        id="shareButton"
                                        style={{ position: 'absolute', top: 0, right: 10, zIndex: 9, padding: '12px 0' }}
                                        onClick={() => {
                                            if (isSignedIn) handleSettingsDrawer()
                                            else handleDrawer()
                                        }}
                                    >
                                        <SettingsOutlinedIcon />
                                    </IconButton>

                                    {/* <IconButton
                  id="shareButton"
                  style={{ position: 'absolute', top: 0, right: 50, zIndex: 9, padding: '12px 0' }}
                // onClick={() => {

                // }}
                >
                  <NotificationsNoneOutlinedIcon />
                </IconButton> */}
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>

                    {/* Secondary App Bar */}
                    <AppBar position="fixed" className={`${classes.tabBarSecondary}`} >
                        <Toolbar variant="dense" style={{
                            width: secondaryTabValue !== 4 ? '100%' : '100%', minHeight: 40
                        }} >

                            <div className={classes.grow} style={{ width: '100%' }}>
                                <div className={classes.sectionMobile} style={{ display: "flex" }}>
                                    <Tabs
                                        value={primaryTabValue}
                                        onChange={handlePrimaryTabValueChange}
                                        scrollButtons="on"
                                        indicatorColor="secondary"
                                        //textColor="secondary"
                                        aria-label="navigation header bar"
                                        className={`${classes.navTabBarSecondary}`}
                                        classes={{ flexContainer: classes.flexContainer, indicator: classes.indicator }}
                                    >
                                        <Tab label="Applied Jobs" className={classes.navTabSecondary} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yPrimaryTabProps(0)} />
                                        <Tab label="Invites Received" className={classes.navTabSecondary} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yPrimaryTabProps(1)} />
                                        {/* <Tab label="Followed Companies" className={classes.navTab} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} {...a11yPrimaryTabProps(3)} /> */}
                                    </Tabs>
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>

                    <TabPanel value={primaryTabValue} index={0}>
                        <AppliedJobs />
                    </TabPanel>
                    <TabPanel value={primaryTabValue} index={1}>
                        <JobInvites />
                    </TabPanel>
                </>
                :
                <div className={classes.pageInfo}
                    style={{
                        // paddingTop: matchesMinHeight ? '70px' : 0,
                        paddingTop: 0,
                        height: '92vh',
                        justifyContent: 'center',
                        backgroundImage: `url(${Wallpaper})`,
                        backgroundColor: '#fcfcfc'
                    }}
                >
                    {/* <img src={NoResultsImage} style={{ width: matchesMinHeight ? 200 : 100 }} /> */}
                    <Typography className={classes.pageInfoText} variant="subtitle1">Please sign in to view applied jobs and invites received.</Typography>
                    <Button
                        id="Search_all_positions"
                        variant="contained"
                        className={`${classes.pageButton} ${classes.pageButton_pri}`}
                        style={{ width: 170 }}
                        onClick={() => {
                            AnalyticsHandler.track('Profile_signIn_BtnClick');
                            handleDrawer();
                        }}
                    >
                        Sign in
                    </Button>


                </div>
            }
            {/* Notification starts */}
            <Dialog
                open={openNotificationDialog}
                onClose={closeNotificationDialog}
                fullScreen
            >
                <DialogContent classes={{ root: classes.notificationDialogRoot }}>
                    <Grid style={{ height: 60, position: 'fixed', top: 0, left: 0, backgroundColor: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.17)' }} alignItems='center' container>
                        <ArrowBackIcon style={{ margin: '0 20px 0 10px' }} onClick={() => setOpenNotificationDialog(false)} />
                        <Typography variant='body1' >Notifications</Typography>
                    </Grid>
                    <Notifications classes={classes} localIsLoggedIn={isSignedIn} setNotificationBadgeCount={setNotificationBadgeCount} setNotificationBar={setOpenNotificationDialog} variant='mobile' />
                </DialogContent>
            </Dialog>
            {/* <div className={classes.bottomBarMobile}> */}
            <BottomAppBar
                isSignedIn={isSignedIn}
                setIsSignedIn={handleSignedInStatus}
                handleDrawer={handleDrawer}
                handleSettingsDrawer={handleSettingsDrawer}
                navValue={navValue}
                setNavValue={setNavValue}
            >
            </BottomAppBar>
            {/* </div> */}
            <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} severity={snackBarMessageSeverity} />

            <SignInUpDrawer
                canDrawerOpen={canDrawerOpen}
                refresh={refresh}
                isSignedIn={isSignedIn}
                setIsSignedIn={handleSignedInStatus}
                setForRefreshOnly={setForRefreshOnly}
                setCanDrawerOpen={setCanDrawerOpen}
                doRefresh={doRefresh}
            />

            <LogoutDrawer
                canDrawerOpen={canSettingsDrawerOpen}
                refresh={refreshSettings}
                isSignedIn={isSignedIn}
                setIsSignedIn={handleSignedInStatus}
                setForRefreshOnly={setForRefreshSettingsOnly}
                setCanDrawerOpen={setCanSettingsDrawerOpen}
                doRefresh={doRefreshSettings}
                handleSnackBar={handleSnackBar}
            />

        </React.Fragment>
    )
}