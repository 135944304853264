import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '@material-ui/lab/Rating';

import StarRoundedIcon from '@material-ui/icons/StarRounded';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import SvgIcon from '@material-ui/core/SvgIcon';

import '../../style/App.css';
import ListLoader from '../errorsAndLoaders/ListLoader';
import ApiHandler from '../../ApiHandler';
import InfiniteScroll from "react-infinite-scroll-component";
import LogoLoader from '../../assets/logo-loader.gif';


function EmployeesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,17c0,0.55-0.45,1-1,1H7c-0.55,0-1-0.45-1-1l0-0.61 c0-1.18,0.68-2.26,1.76-2.73C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43L0,17 c0,0.55,0.45,1,1,1l3.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1 c0.4,0.68,0.63,1.46,0.63,2.29V18l3.5,0c0.55,0,1-0.45,1-1L24,16.43z M12,6c1.66,0,3,1.34,3,3c0,1.66-1.34,3-3,3s-3-1.34-3-3 C9,7.34,10.34,6,12,6z" />
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 74,
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  tabBar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.14)',
  },
  navTabBar: {
    flexGrow: 1,
  },
  navTab: {
    textTransform: 'capitalize',
    padding: 0,
    margin: ' 0 15px',
    minHeight: 50,
    minWidth: 100,
    color: theme.palette.common.grey,
    ...theme.typography.h3,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      minHeight: 50,
      minWidth: 'auto',
    },
  },
  listRoot: {
    width: '100%',
    padding: '0 10px 30px',
    marginBottom: 0,
    '& .MuiListItem-root': {
      padding: 0,
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      marginBottom: 10,
      overflow: 'hidden',
      boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
    }
  },


  appBar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.16)',
    top: 'auto',
    bottom: 0,
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em'
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 20,
  },
  pageButton_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  section: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    //padding: '60px 23px 40px',
  },
  sectionItem: {
    padding: '20px 0px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[100],
    '&:last-child': {
      border: 'none',
    }
  },
  sectionItem_heading: {
    flexWrap: 'nowrap',
    marginBottom: '20px'
  },
  sectionItem_heading_icon: {
    marginRight: 5,
  },
  sectionItem_heading_icon2: {
    marginRight: 10,
    color: theme.palette.common.grey,
  },
  sectionItem_content: {
    flexWrap: 'wrap',
  },
  sectionItem_rating: {
    //flexWrap: 'wrap',
    flexWrap: 'nowrap', // changing to nowrap for fixing iOS mobile review data crossing the boundaries
    '&:last-child': {
      marginBottom: 0,
      borderBottom: 'none',
    },
  },
  sectionItem_RatingBorder: {
    margin: '10px 0',
    padding: '10px 0 5px',
    borderBottom: '1px solid #eee'
  },
  sectionItem_text_button: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  tileRatingText: {
    ...theme.typography.tileRatingText,
  },
  tileRatingStar: {
    fontSize: '1.2rem',
    color: theme.palette.common.star,
    marginRight: 4,
  },
  imageTag: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: "none",
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 144,
    height: 194,
    overflow: 'hidden',
    margin: 0,
    borderRadius: 6,
    border: '1px solid #E5E5E5',
    //boxShadow: '0 1px 6px rgba(0, 0, 0, 0.25)',
  },

  TfRoot: {
    width: '100%',
    marginBottom: 30,
    background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  inputRootAc: {
    padding: '0 9px!important',
  },
  tfLabel: {
    ...theme.typography.body1,
    color: theme.palette.common.blackDark,
    marginBottom: 12,
  },
  datePickerInput: {
    '& .MuiInputBase-input': {
      height: '3.429em',
      width: '100%',
      borderRadius: 4,
      background: theme.palette.common.greyLight,
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  selectRoot: {
    width: '100%',
    marginBottom: 30,
    background: theme.palette.common.greyLight,
    borderRadius: 25,
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 15px',
    },
    '& .MuiSelect-select:focus': {
      borderRadius: 25,
    },
    '& input': {
      height: 48,
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  fabBtnWrapper: {
    position: 'fixed',
    right: 0,
    width: 'auto',
    bottom: 70,
    paddingRight: 23,
  },
  fabDisabled: {
    minWidth: 70,
    height: 32,
    textTransform: 'capitalize',
    background: "white",
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
  },
  fabActive: {
    minWidth: 70,
    height: 32,
    textTransform: 'capitalize',
    background: theme.palette.primary.dark,
    color: "white",
  },
  highlight: {
    color: theme.palette.common.blackDark,
    fontWeight: 600,
    width: '100%',
    display: 'inline-block',
  },
}));

export default function SeeAllReviews(props) {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();

  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);
  const [response, setResponse] = React.useState([]);

  const [jobsList, setJobsList] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageOffset, setPageOffset] = React.useState(0);
  const [jobsCount, setJobsCount] = React.useState(null);
  const [vacanciesCount, setVacanciesCount] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);
  const [refreshInfiniteScroll, setRefreshInfiniteScroll] = React.useState(true)

  document.body.style.overflow = "hidden";

  // get jobs list with infinitescroll as fetchMoreData is called and setRefreshInfiniteScroll triggers the api call---> starts
  const fetchMoreData = () => {
    console.log("fetchMoreData")
    let page = 1;
    page = currentPage + 1;
    setCurrentPage(page);
    setPageOffset((page - 1) * 10);
    setRefreshInfiniteScroll(prev => !prev);
  }

  React.useEffect(() => {

    const handleMoreJobsList = () => {
      let limit = 10, offset = pageOffset, company_id = props.companyID;

      ApiHandler.getReviewsByCompany(offset, limit, company_id, response => {
        if (response.status == 200) {
          if (jobsList === null) {
            setJobsList(response.data[0].results);
          } else {
            setJobsList(prevState => prevState.concat(response.data[0].results))
            //console.log(jobsList.concat(response.data[0].results));
          }
          setJobsCount(response.data[0].totalCount[0].count);
          //setVacanciesCount(response.data[0].total_vacancies[0].count);
        }
        else {
          console.log("error in getting a successful response for the list of jobs")
        }
      })

    }

    if (jobsList && jobsList.length >= jobsCount) {
      console.log(1)
      setHasMore(false);
    } else if (currentPage > 1) {
      console.log(2)
      handleMoreJobsList();
    }
  }, [refreshInfiniteScroll])

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  React.useEffect(() => {
    setIsLoading(true);
    let company_id = props.companyID
    ApiHandler.getReviewsByCompany(0, 10, company_id, (response) => {
      if (response.status === 200) {
        setJobsList(response.data[0].results);
        setJobsCount(response.data[0].totalCount[0].count);
        setIsLoading(false);

        //setHasMore(false)//temporary
      } else {
        console.log("some error occurred.");
        setIsLoading(false);
      }
    })
  }, [])

  return (
    <div style={{
      position: 'fixed',
      left: 0,
      width: '100%',
      padding: '10px 23px',
      zIndex: 9999,
      background: 'white',
      top: 0,
      //height: 588,
      height: '100vh',
      overflow: 'hidden'
    }}>
      <React.Fragment>
        <AppBar position="fixed" className={classes.tabBar}>
          <Toolbar variant="dense" style={{ width: '100%' }} >

            <IconButton edge="start" className={classes.menuButton} aria-label="back button"
              onClick={() => {
                //props.changeEnvRating(null, props.index);
                props.closeRatingsModal();
              }
              }>
              <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
            </IconButton>
            <Typography variant="h4" className={classes.navTabBar} style={{ color: theme.palette.primary.main }}>
              यहाँ के employees का अनुभव
            </Typography>

          </Toolbar>
        </AppBar>

        {isLoading
          ? <div style={{
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
          }}>
            <ListLoader setListLoaderRef={setListLoaderRef} />
          </div>
          :
          <React.Fragment>
            <Grid
              container
              className={classes.section}
              direction="column" style={{ marginTop: 10, paddingTop: 28 }}>
            </Grid>

            {jobsList && jobsList.length > 0 &&
              <div
                id="scrollableDiv"
                //style={{ width: '100%', marginBottom: 50 }}
                style={{
                  height: '92vh',
                  overflow: 'auto',
                  display: 'flex',
                  width: '100%', marginBottom: 50
                }}

              >
                <InfiniteScroll
                  dataLength={jobsList.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<div style={{
                    width: '100%', height: 80, display: 'flex', justifyContent: 'center', alignItems: 'flex-start'
                  }}>
                    {/* <CircularProgress style={{ color: '#ccc' }} /> */}
                    <img alt="meet animated logo loader" style={{ width: 40 }} src={LogoLoader} />
                  </div>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      { }
                    </p>
                  }
                  scrollableTarget="scrollableDiv"
                //scrollThreshold="70%"
                //   style={{ display: 'flex', 
                //   flexDirection: 'column-reverse' 
                // }}
                >

                  {jobsList.map((item, index) =>
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" direction="column" className={item.review_data && (item.review_data.jobRole || item.review_data.goodThing || item.review_data.badThing || item.review) ? `${classes.sectionItem_rating} ${classes.sectionItem_RatingBorder}` : `${classes.sectionItem_rating}`} key={index}>
                      {item.quality_score ?
                        <Rating
                          name="company_rating"
                          defaultValue={item.quality_score}
                          precision={0.5}
                          icon={<StarRoundedIcon fontSize="small" />}
                          readOnly
                          style={{ marginBottom: 10 }}
                        /> : null}

                      {item.review_data && item.review_data.jobRole ?
                        <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>💼 मेरा काम </span> {item.review_data.jobRole}
                        </Typography> : null
                      }

                      {item.review_data && item.review_data.goodThing ?
                        <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>🙂 मुझे अच्छा लगा </span>
                          {item.review_data.goodThing}
                        </Typography> : null
                      }

                      {item.review_data && item.review_data.badThing ?
                        <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>🙁 मुझे ठीक नहीं लगा </span>
                          {item.review_data.badThing}
                        </Typography> : null}

                      {item.review ?
                        <Typography variant="body1" style={{ marginBottom: 15, whiteSpace: 'pre-line' }}><span className={classes.highlight}>
                          👓 मेरा अनुभव
                          {/* ✍️ मेरा अनुभव:  */}
                        </span>
                          {item.review}
                        </Typography> : null}

                    </Grid>
                  )
                  }

                </InfiniteScroll>
              </div>
            }

          </React.Fragment>
        }
      </React.Fragment>
    </div >
  )
}