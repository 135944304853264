import React from 'react';
import { updateUserLastSeenAt, sendDeviceTokenToFCM, subscribeDeviceToTopic } from './ApiHandler';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import SnackBarGeneric from './components/SnackBarGeneric';

//autocomplete common functions
export const handleAutoCompleteInputChange = (array, setArray, index) => {
  if (array.length !== 0) {
    let arrayTemp = [...array];
    let temp_value = "";
    arrayTemp[index] = temp_value;
    setArray(arrayTemp);
  } else {
    setArray([""]);
  }
}

export const onChange1_AC = (array, setArray, index, newValue) => {
  if (array.length === 0) {
    setArray([newValue]);
  } else {
    let arrayTemp = [...array];
    arrayTemp[index] = newValue;
    setArray(arrayTemp);
    //console.log(arrayTemp)
  }
}

export const onChange2_AC = (array, setArray, index, newValue1, newValue2) => {
  if (array.length === 0) {
    setArray([newValue1]);
  } else {
    let arrayTemp = [...array];
    arrayTemp[index] = newValue2;
    setArray(arrayTemp);
    //console.log(arrayTemp)
  }
}

export const updateLastSeenAt = () => {
  let jobseekerID = localStorage.getItem("jobseeker_id");
  let date = new Date().getTime() / 1000;
  let last_seen_at = date.toFixed();

  let data = {
    jobseeker_id: jobseekerID,
    last_seen_at,
  }

  updateUserLastSeenAt(data, response => {
    // if (response.status == 200) {
    //   //console.log(response)
    // }
    // else {
    //   console.log("error in getting a successful response")
    // }
  })
}

// Push notification related functions

//our app's Firebase project configuration
export const firebaseConfig = {
  apiKey: "AIzaSyAeFrIpRkxfwYlVCCO-Fk2Yn6h47komV5c",
  authDomain: "meet-2-staging.firebaseapp.com",
  projectId: "meet-2-staging",
  storageBucket: "meet-2-staging.appspot.com",
  messagingSenderId: "254248855159",
  appId: "1:254248855159:web:b9372ed22037ec23238872",
  measurementId: "G-CRSKTXKXKQ"
};
export const initializeFirebase = () => {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const _firebaseConfig = firebaseConfig;

  //initialize the firebase App
  const app = initializeApp(_firebaseConfig);
}

export const askForPermissioToReceiveNotifications = () => {
  //our app's Firebase project configuration
  const _firebaseConfig = firebaseConfig;
  // Initialize Firebase
  const app = initializeApp(_firebaseConfig);

  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);

  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Retrieve a registration token for use with FCM.
      getToken(messaging, { vapidKey: 'BPsNnmSEAlBUxaTUgznNA_yu_Q0lF62xrLbDDxs2KlyLZy6LttjbwKtbI1hOOltdk5WscxaAeXXhiixL7b-v05g' }).then((currentToken) => {
        if (currentToken) {
          console.log(currentToken);
          // Send the token to your server and update the UI if necessary
          let jobseekerID = localStorage.getItem("jobseeker_id");
          let data = {
            jobseeker_id: jobseekerID,
            device_token: currentToken
          }
          sendDeviceTokenToFCM(data, response => {
            if (response.status == 200) {
              let payload = {
                token: [currentToken],
                topic: "Meet_Job_Updates",
              }
              subscribeDeviceToTopic(payload, res => {
                if (res.status == 200) {
                  console.log(res)
                  console.log("currentToken for subscribe topic", currentToken)
                } else {
                  console.log("error in getting a successful response")
                }
              })
            }
            else {
              console.log("error in getting a successful response")
            }
          })
          // ...
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      })
    } else {
      console.log('Unable to get permission to notify.');
    }
  });

}

// Handle incoming messages. Called when:
// - a message is received while the app has focus
export function ReceiveMessage() {

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const handleSnackBar = (message) => {
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev)
  }
  // snackbar callback ends

  React.useEffect(() => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      handleSnackBar("😊" + payload.notification.body)
    });
  }, [])

  return (
    <>
      <SnackBarGeneric
        canSnackBarOpen={canSnackBarOpen}
        refresh={refreshSnackBar}
        message={snackBarMessage} />
        {/* <div>message</div> */}
    </>

  )
}

