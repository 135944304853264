import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  EmailShareButton,
  FacebookShareButton, FacebookIcon,
  LinkedinShareButton, LinkedinIcon,
  WhatsappShareButton, WhatsappIcon,
} from "react-share";

const useStyles = makeStyles(theme => ({
  flexContainer: {
    height: 60,
    flexGrow: 1,
  },
  drawerPaper: {
    borderRadius: '20px 20px 0px 0px',
  },
  drawer: {
    width: 'auto',
    height: 'auto',
    minHeight: 300,
    paddingTop: 40,
    background: '#FFFFFF',
    boxShadow: ' 0px -4px 8px rgba(0, 0, 0, 0.12)',
    borderRadius: '20px 20px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  drawerCloseBtn: {
    alignSelf: 'flex-end',
    margin: 8,
  },
  drawerBackBtn: {
    position: 'absolute',
    alignSelf: 'flex-start',
    margin: 8,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 300,
    marginTop: 20,
  },
  TfRoot: {
    width: '100%',
    marginBottom: 45,
    //marginBottom: 25,
    background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  TfBlockRoot: {
    //width: '100%',
    //marginBottom: 20,
    borderRadius: 4,
    background: theme.palette.common.greyLight,
    '& input': {
      height: 48,
      width: 48,
      textAlign: 'center',
      '&:focus': {
        background: theme.palette.primary.light,
      },
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'transparent',
    },
  },
  drawerActionButton: {
    width: '100%',
    maxWidth: 200,
    height: 36,
    marginBottom: 15,
    backgroundColor: '#14967B',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#14967B',
    },
  },
  linkButton: {
    textTransform: 'unset',
    color: '#14967B',
  },
  inputRootAc: {
    padding: '0 9px!important',
  },
  checkboxLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
  },
  fcRoot: {
    marginRight: 3,
  },
  hrefLink: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
    }
  }
}));

export default function SignInUpDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState("");
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);

  const resetSignInDrawer = () => {

  }

  const resetErrorMsg = () => {
    setIsAlertOpen(false);
    setTimeout(() => {
      setErrorMessage("");
    }, 200);
  }



  const handleCloseDrawerAndRemain = () => {
    // history.push({
    //   pathname: '/'
    // });
    props.setCanDrawerOpen(false);
    props.doRefresh(prev => !prev);
    resetSignInDrawer();
  }

  //Drawer starts
  const [drawerState, setDrawerState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (open === false) {
      // setMobileInput("");
      // setCheckedTc(true);
      // setCheckedNotifs(true);
      // setToSignIn(true);
      // setIsOTPStage(false);
      // setSignInStage(false);
      // resetOTPTimer();
      // resetErrorMsg();
    }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };
  //Drawer ends

  const signIn = (anchor) => {
    return (
      <div className={classes.drawer} id="sign_in_section" role="presentation">
        {/* <IconButton
          id="drawerclose_btn_id"
          className={classes.drawerCloseBtn}
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}> <CloseIcon style={{ color: '#999', cursor: 'pointer' }}></CloseIcon></IconButton> */}

        <React.Fragment>
          <Typography variant="h6" color="primary" style={{ marginBottom: 20, fontWeight: 500 }}>
            Share this job
            {/* अगर आपका Meeत पे account है login करें */}
          </Typography>


          <Grid
            item xs
            container
            justifyContent="center"
            alignItems='center'>

            <WhatsappShareButton
              url={props.jobUrl}>
              <WhatsappIcon size={36} round={true} />
            </WhatsappShareButton>

            <FacebookShareButton
              url={props.jobUrl}
            // quote={"CampersTribe - World is yours to explore"}
            // hashtag="#camperstribe"
            >
              <FacebookIcon size={36} round={true} />
            </FacebookShareButton>

            <LinkedinShareButton
              url={props.jobUrl}>
              <LinkedinIcon size={36} round={true} />
            </LinkedinShareButton>
          </Grid>



          <form noValidate autoComplete="off" className={classes.form}>
            <TextField
              required
              id="mobileInput_id"
              value={props.jobUrl}
              inputProps={{ maxLength: 10 }}
              // onInput={(e) => {
              //   let newVal = e.target.value.replace(/[^0-9]/g, '');
              //   // setMobileInput(newVal);
              //   // resetErrorMsg();
              // }}
              variant="outlined"
              placeholder="Mobile number डालें"
              classes={{ root: classes.TfRoot }}

            />

            <>
              <CopyToClipboard text={props.jobUrl}>
                <Button
                  id="send_otp_btn_id"
                  variant="contained"
                  //onClick={handleSendOTP}
                  className={classes.drawerActionButton}
                //disabled={isSendOTPDisabled}
                >
                  Copy Link
                </Button>

              </CopyToClipboard>

            </>

          </form>
        </React.Fragment>

      </div>
    )
  }

  const icon = <CheckBoxOutlineBlankIcon style={{ color: "#E2E2E2" }} fontSize="small" />;
  const checkedIcon = <CheckBoxIcon style={{ color: "#5BC2A8" }} fontSize="small" />;

  // open drawer on trigger from parent component
  useEffect(() => {
    if (props.canShareDrawerOpen === true) {
      setDrawerState({ ...drawerState, ['bottom']: true });
    }
    else {
      setDrawerState({ ...drawerState, ['bottom']: false });
    }
  }, [props.refreshShareDrawer]);

  return (
    <div>
      <Drawer anchor='bottom'
        open={drawerState['bottom']}
        onClose={toggleDrawer('bottom', false)}
        style={{ background: 'none' }} classes={{ paper: classes.drawerPaper }}>
        {signIn('bottom')}
      </Drawer>
    </div>
  );
}