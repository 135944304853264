import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Paper, TextField, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import ApiHandler from '../../ApiHandler';

const useStyles = makeStyles((theme) => ({
  bgOverlay: {
    background: 'rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  searchBarOuterWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    transform: 'scale(1)',
    zIndex: 9,
    marginTop: 60,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: 60,
    },
  },
  searchBarJobsOuterWrapper: {
    marginTop: 0,
    // width: '80vw'
  },
  searchBarWrapper: {
    display: "flex",
    flexDirection: "row",
    background: "white",
    width: 400,
    height: 40,
    // paddingLeft: 10,
    // paddingRight: 5,
    borderRadius: 4,
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    "&.shrink": {
      height: 40,
    },
    [theme.breakpoints.down('md')]: {
      width: '98%',
      height: 36,
    },
  },
  searchBarIconWrapper: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
    height: '100%',
    width: 45,
    minWidth: 25,
    textTransform: "none",
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  icon: {
    //color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    color: '#999',
    fontSize: 16,
    marginTop: 5,
  },
  comboBoxRoot: {
    marginTop: 0,
    border: '0!important',
    "& .MuiFormLabel-root": {
      color: "black",
      fontWeight: "700",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#5BC2A8",
      fontWeight: "700",
    },
  },
  inputRoot: {
    padding: '0 10px 0 0!important',
    borderColor: 'transparent!important',
    fontSize: 14,
    //   "& .MuiIconButton-label": { 
    //     display: "flex",  
    // },
    "& *, &:hover > *": {
      borderColor: 'transparent!important',
    },

  },
  inputRootPL: {
    padding: '0 2px 0 2px!important',
  },

  popupIndicator: {
    display: 'none'
  },

  input: {
    height: 40,
    maxWidth: '98%',
    "&::-webkit-input-placeholder": {
      color: 'rgba(0, 0, 0, 0.6)',
      opacity: 1,

    },
    [theme.breakpoints.down('md')]: {
      height: 36,
    },
  },
  popper: {
    marginTop: 16,
  },
  paper: {
    borderRadius: 10,
  },
  option: {
    textTransform: 'capitalize',
  },
  TfRoot: {
    width: '100%',
    marginBottom: 20,
    '& > div': {
      display: 'block'
    },
    '& input': {
      fontSize: 12,
      padding: '10px 10px 10px 5px',
      border: 'none',
      //height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: 'none',
    },
  },
}));

export default function SearchBar(props) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = React.useState("");

  const filterBySearchValue = (event) => {
    var selected = event.target.value;
    props.setStartedTyping(true);
    setSearchValue(event.target.value);
    props.setSearchTerm(selected);
  }

  React.useEffect(() => {
    if (props.searchTerm === "") {
      setSearchValue("");
    }
  }, [props.searchTerm])

  return (
    <div className={classes.searchBarOuterWrapper}>
      <div className={`${classes.searchBarWrapper} ${classes.shrink}`} style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)' }}
      // onClick={()=>{props.setStartedTyping(true);}}
      >
        <TextField
          id="job_role_search"
          type="search"
          variant="outlined"
          placeholder="Positions ढूंढें"
          value={searchValue}
          onChange={filterBySearchValue}
          classes={{ root: classes.TfRoot }}
          autoFocus
          autoComplete="off"
        />
        <div className={classes.searchBarIconWrapper}>
          <SearchIcon style={{ fontSize: 25 }} />
        </div>

        {/* <Autocomplete
          id="jobseekersBox"
          options={invitedList}
          getOptionLabel={(option) => option.jobseeker_name.toLowerCase()}
          onChange={(event, newvalue) => {
            if (newvalue) {
              //alert("onchange triggered and newvalue entered")
              console.log(newvalue._id)
              props.setJobseekerIDSearched(newvalue._id);
            } else {
              //alert("not new search value")
              props.setJobseekerIDSearched(null)
            }
          }}
          loading={isLoading}
          style={{ flex: 1 }}
          classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator, option: classes.option }}
          renderInput={(params) => <TextField {...params} value="" variant="outlined" placeholder="Job role ढूंढें"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress style={{ color: '#ccc' }} size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
          }
        /> */}
      </div>
    </div>
  );
}

export function SearchBarJobs(props) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = React.useState("");

  const filterBySearchValue = (event) => {
    var selected = event.target.value;
    setSearchValue(selected);
    props.setSearchTerm(selected);
  }

  React.useEffect(() => {
    if (props.searchTerm === "") {
      setSearchValue("");
    }
  }, [props.searchTerm])

  return (
    <div className={classes.searchBarJobsOuterWrapper}>
      <div className={`${classes.searchBarWrapper}`} style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)' }}
      // onClick={()=>{props.setStartedTyping(true);}}
      >
        <TextField
          id="job_role_search"
          type="search"
          variant="outlined"
          placeholder={props.placeholder || "Jobs by Title, Role and sector"}
          value={searchValue}
          onChange={filterBySearchValue}
          classes={{ root: classes.TfRoot }}
          autoFocus
          autoComplete="off"
          InputProps={{
            readOnly: props.readOnly || false,
          }}
        />
        <div className={classes.searchBarIconWrapper}
          onClick={() => { if (props.triggerSearch) props.triggerSearch(prev => !prev); }}>
          <SearchIcon style={{ fontSize: 25 }} />
        </div>
      </div>
    </div>
  );
}

export function SearchBarJobsAutocomplete(props) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = React.useState("");
  const [searchAutocompleteValue, setSearchAutocompleteValue] = React.useState("");
  const [inputBlur, setInputBlur] = React.useState(true);

  const searchInputChangeHandler = (event, newInputValue, reason) => {
    setSearchValue(newInputValue);
    props.setSearchTerm(newInputValue);
    if (!props.isMobile) { // do not pass for mobile component.
      if (newInputValue.length > 0 && !props.isSearchActive) props.setIsSearchActive(true);
      else if (newInputValue.length == 0 && props.isSearchActive) props.setIsSearchActive(false);
    }
  }

  const [autocompleteList, setAutocompleteList] = React.useState([]);
  const handleSearchAutocompleteList = () => {
    let queryParam = searchValue;
    ApiHandler.getSearchAutocompleteList(queryParam, response => {
      if (response.status == 200) {
        let arr_temp = response.data;
        setAutocompleteList(arr_temp);
      }
      else {
        console.log("error in getting a successful response")
      }
    })
  }

  React.useEffect(() => {
    if (searchValue === "") setAutocompleteList([])
    else if(searchValue.length > 2) handleSearchAutocompleteList()
  }, [searchValue])

  // React.useEffect(() => {
  //   if (props.searchTerm === "") {
  //     setSearchValue("");
  //   }
  // }, [props.searchTerm])

  return (
    <Autocomplete
      id="job_role_search"
      freeSolo
      autoFocus
      clearOnBlur={false}
      disableClearable={false}
      options={autocompleteList ? autocompleteList : []}
      getOptionLabel={(option) => option.data || ""}
      style={{ flex: 1 }}
      value={searchAutocompleteValue}
      onChange={(event, newValue) => {
        event.preventDefault();
        
        if (newValue) {
          if (typeof newValue === 'string') {
            setSearchAutocompleteValue({
              data: newValue,
            });
            setSearchValue(newValue);
            props.setSearchTerm(newValue);
          } else {
            setSearchAutocompleteValue(newValue);
            setSearchValue(newValue.data);
            props.setSearchTerm(newValue.data);
          }
          
          if (props.isMobile && props.triggerSearch) props.triggerSearch(prev => !prev);
          setInputBlur(false);
        }
      }}
      inputValue={searchValue}
      onInputChange={searchInputChangeHandler}
      onBlur={(event) => {
        //alert(event.target.value)
        if (inputBlur && searchValue === "") {
          setSearchValue(props.searchTerm);
          //alert("blur")
        } else if (!inputBlur && searchValue === "") {
          setSearchValue(searchAutocompleteValue.data);
        }
      }}
      classes={{ root: classes.comboBoxRoot, inputRoot: `${classes.inputRoot} ${classes.inputRootPL}`, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}
      renderInput={(params) => <TextField {...params} value="" variant="outlined" placeholder={props.placeholder}
        InputLabelProps={{
          shrink: true,
        }} 
        onKeyDown={(e)=> {
          if(e.keyCode === 13){
            if (props.isSearchActive) props.handleSearchClick()
          }
        }}
        />
      }
      renderOption={(option) => {

        return (
          <Grid container alignItems="flex-start" direction="column">
            {!props.isMobile ? <Grid container alignItems="flex-start">
              {option.data} <span style={{ margin: '0px 5px' }}>·</span> <div style={{ color: 'grey', fontSize: 12, marginTop: 2 }}>{option.type}</div>
            </Grid> :
            <>
             <Grid container alignItems="flex-start">
                {option.data} 
              </Grid>
              <Grid container alignItems="flex-start">
                <span style={{ color: 'grey', fontSize: 12 }}>{option.type}</span>
              </Grid>
            </>             
            }
          </Grid>
        )

      }}
    />
  );
}