import React, { useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useHistory, useLocation } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import { Helmet } from 'react-helmet-async';

import { useStyles } from '../jobs/styles';
import '../../style/App.css';
import ApiHandler from '../../ApiHandler';
import {CompaniesListItem} from '../jobs/JobsListItem';
import ListLoader from '../errorsAndLoaders/ListLoader';
import InfiniteScroll from "react-infinite-scroll-component";

import LogoLoader from '../../assets/logo-loader.gif';

let count1 = 0, count2 = 0, count3 = 0;

const useStylesPrivate = makeStyles((theme)=>({
  pageBar1: {
    // width: 860,
    padding: '8px 43px',
    flexBasis: 40,
    backgroundColor: theme.palette.common.greyLight,
    marginTop: 0,
    zIndex: 8,
    transition: '0.2s',
    position: 'sticky',
    top: 59,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      top: 0,
      padding: 0
    },
  },
}))

export default function CompaniesByLocation({match}) {
  const location = useLocation();
  const classes = useStyles();
  const classesPrivate = useStylesPrivate();
  const theme = useTheme();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);
  const [latLong, setLatLong] = React.useState(location && location.state && location.state.latLong ? location.state.latLong : { lat: 26.8489293, long: 80.802425 });

  const [isBackIconDisplayed, setIsBackIconDisplayed] = React.useState(true);

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);

  const jobseekerID = localStorage.getItem("jobseeker_id");
  let cityFromUrl = match.params._location;

  // const handleSignedInStatus = (v) => {
  //   setIsSignedIn(v);
  // }

  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  // get jobs list---> starts
  const [jobsList, setJobsList] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageOffset, setPageOffset] = React.useState(0);
  const [jobsCount, setJobsCount] = React.useState(null);
  const [vacanciesCount, setVacanciesCount] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);
  const [refreshInfiniteScroll, setRefreshInfiniteScroll] = React.useState(true);

  // get jobs list with infinitescroll as fetchMoreData is called and setRefreshInfiniteScroll triggers the api call---> starts
  const fetchMoreData = () => {
    let page = 1;
    page = currentPage + 1;
    setCurrentPage(page);
    setPageOffset((page - 1) * 10);
    setRefreshInfiniteScroll(prev => !prev);
  }

  React.useEffect(() => {
    //alert("offset change")

    const handleMoreJobsList = () => {
      let limit = 10, offset = pageOffset;

      //to reset offset at the time of new api call during scroll when the results from prev api get exhausted
      if (jobsList && jobsList.length === count1) {
        setCurrentPage(1);
        offset = 0;
      } else if (jobsList && jobsList.length === count1 + count2) {
        setCurrentPage(1);
        offset = 0;
      }

      ApiHandler.getCompaniesByDistrict(cityFromUrl, pageOffset, limit, response => {
        if (response.status == 200) {
          //console.log(response.data[0].results);
          if (jobsList === null) {
            setJobsList(response.data[0].results);
          }
          else {
            setJobsList(prevState => prevState.concat(response.data[0].results))
            //console.log(jobsList.concat(response.data[0].results));
          }
          setJobsCount(response.data[0].totalCount[0].count);
          
        }
        else {
          console.log("error in getting a successful response for the list of jobs")
        }
      })
    }

    if (jobsList && jobsList.length >= jobsCount) {
      setHasMore(false);
    } else if (currentPage > 1) {
      handleMoreJobsList();
    }
  }, [refreshInfiniteScroll])

  // get jobs list on page load---> starts
  React.useEffect(() => {
    let limit = 10, offset= 0;
    ApiHandler.getCompaniesByDistrict(cityFromUrl, offset, limit, response => {
      if (response.status == 200) {
        // debugger;
        var arr_temp = response.data[0].results;
        setJobsList(arr_temp);
        // debugger;
        //console.log("Total jobs:::::" + response.data[0].totalCount[0].count);
        setJobsCount(response.data && response.data[0] && response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0);
        
        if (response && response.data[0] && response.data[0].totalCount[0] && response.data[0].totalCount[0].count && response.data[0].totalCount[0].count < 10) {
          setHasMore(false);
        }
        setIsLoading(false);
      }
      else {
        console.log("error in getting a successful response")
      }
    })
  }, [])
  // get jobs list on page load---> ends

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  //sort bar bg effect on scroll starts
  const pageBarRef = React.useRef(null);
  let lastScrollY = 0;
  let scrolling = false;

  const handleScrollChange = () => {
    lastScrollY = window.scrollY;
    if (lastScrollY > 60) {
      scrolling = true
    } else {
      scrolling = false
    }
    if (!scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        pageBarRef.current.style.background = '#f5f5f5';
        pageBarRef.current.style.boxShadow = "none";
        pageBarRef.current.style.flexBasis = "40px";
      });
    } else if (scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        pageBarRef.current.style.background = '#f5f5f5';
        pageBarRef.current.style.boxShadow = "0px 5px 5px rgba(0,0,0,0.05)";
        pageBarRef.current.style.flexBasis = "35px";
      });
    }
  };

  React.useEffect(() => {
    executeScroll();
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);
  //sort bar bg effect on scroll ends

  return (
    <React.Fragment>
      <Helmet>
        <title>{"Companies in " + cityFromUrl}</title>
        <meta name="description" content={"View jobseeker feedback of local employers on the company environment, salary and future growth prospects."} />
      </Helmet>
      <meta
        name="keywords"
        content={"local employers, nearby companies, careers for freshers, job portal for freshers"}/>
      {/* Top App Bar */}
      <AppBar position="fixed" className={classes.tabBar}>
        <Toolbar variant="dense" style={{ padding: 0 }}>
          <div className={classes.grow} style={{ width: '100%' }}>
            <div className={classes.sectionMobile} style={{ display: "flex" }}>
              <IconButton id="backButton_jobDetail" label="Back"
                onClick={() => {
                  //AnalyticsHandler.track('JobDetail_back_BtnClick');
                  if (sessionStorage.getItem("pageStateOnReturn") === "default") { // if default then go to jobs page and reset the the page state to default 
                    history.push({
                      pathname: `/`,
                    });
                  } else { // else go to prev page which is jobs list page for specific roles
                    history.goBack();
                  }
                }}
                style={{ position: 'relative', zIndex: 9, paddingLeft: 10 }}>
                {isBackIconDisplayed // if user lands directly on this page via link then don't show back arrow
                  ? <ArrowBackRoundedIcon style={{ color: theme.palette.common.grey }} />
                  : null
                }
              </IconButton>
              <Grid
                item xs={9} md={11}
                container
                direction='column'
                justifyContent="center"
                alignItems='flex-start'>
                <Typography
                  variant="subtitle1"
                  className={classes.ellipsisPost2Lines}
                  style={{ color: theme.palette.common.blackDark, lineHeight: '1.2', textAlign: 'center', width: '100%' }}>
                  {cityFromUrl == "Work From Home" ? cityFromUrl + " Companies": "Companies in " + cityFromUrl}
                </Typography>
              </Grid>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <div className={classes.root}
        ref={scrollRef}
      >
        {/* <Grid container
          alignItems="center" className={classesPrivate.pageBar1} ref={pageBarRef} style={{top: 48}}>
          <Grid item xs={12} container
            justifyContent="flex-start"
            alignItems="center">

            {jobsCount && jobsCount > 0 &&
              <Typography variant="h2" color="primary" className={classes.panelHeading} >
               {jobsCount && jobsCount === 1 ? "company" : "compaines"}
              </Typography>
            }

          </Grid>
        </Grid> */}

        {/* For the purpose of skipping first render in useffect */}
        {/* <div ref={trackInitialRenderRef} style={{ display: 'none' }}></div>
        <div ref={trackInitialRenderRefForLatLong} style={{ display: 'none' }}></div> */}

        {isLoading
          ? <div style={{
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
          }}>
            <ListLoader setListLoaderRef={setListLoaderRef} />
          </div>
          :
          <React.Fragment>
            {jobsList && jobsList.length > 0 &&
              <div style={{ width: '100%' }}>
                <InfiniteScroll
                  dataLength={jobsList.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<div style={{
                    width: '100%', height: 80, display: 'flex', justifyContent: 'center', alignItems: 'flex-start'
                  }}>
                    <img alt="meet animated logo loader" style={{ width: 40 }} src={LogoLoader} />
                  </div>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      { }
                    </p>
                  }
                  scrollThreshold="0.7"
                >

                  <List className={classes.listRoot} disablePadding>
                    {jobsList.map((hit, index) =>
                    (<CompaniesListItem
                      hit={jobsList[index]}
                      signInStatus={isSignedIn}
                      key={index}
                      lat={latLong.lat}
                      long={latLong.long}
                      //isWorkFromHome={jobsList[index].isWorkFromHome}
                      pageStateOnReturn={"search"}
                    />)
                    )
                    }
                  </List>
                </InfiniteScroll>
              </div>
            }
          </React.Fragment>
        }

      </div>

    </React.Fragment>
  )
}