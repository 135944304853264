import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    common: {
      blackDark: '#282828',
      blackLight: '#1d1d1d',
      grey: '#797979',
      greyMedium: '#bcbcbc',
      greyLight: '#f5f5f5',
      red: '#CD212A',
      star: '#FCBD01',
      yellowDark: '#FAB116',
      yellowLight: '#FFF5D1',
      redDark: '#DA2932',
      redLight: '#FFEBEE',
      purpleDark: '#7256b3',
      orangeDark: '#f9962d',
      blueDark: '#5e96dd',
    },
    primary: {
      main: '#5BC2A8',
      dark: '#14967B',
      light: '#D5F5ED',
      contrastText: '#fff',
    },
    secondary: {
      //main: '#fab116',
      main: '#14967B',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Mukta", "Arial", "sans-serif"',
    h1: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: "0.0075em",
    },
    h2: {
      fontSize: '1.15rem',
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: "0.0075em",
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: "0.0075em",
    },
    h4: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: "0.0075em",
    },
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '0.815rem',
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.815rem',
      fontWeight: 500,
    },
    caption: {
      fontSize: '0.75rem',
    },
    title15: {
      fontSize: '0.9375rem', 
      fontWeight: 500,
    },
    tileHeading: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    tileSubHeading: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    tileRatingText: {
      fontSize: '0.75rem',
      fontWeight: 300,
    },
    tileRatingStar: {
      fontSize: '1rem',
    },
    cardTag: {
      fontSize: '1rem',
      fontWeight: 700,
    }
  },
});