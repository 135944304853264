import React from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
  successBar: {
    '& > div': {
      borderLeft: '5px solid #14967B'
    }
  },
  errorBar: {
    '& > div': {
      borderLeft: '10px solid #d11e1e'
    }
  }
}));

function FeedbackAlert(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [selected, setSelected] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState({
    open: false,
    Transition: Slide,
  });

  const handleSnackBarOpen = () => {
    setOpenSnackBar({
      open: true,
      Transition: Slide,
    });
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar({
      ...openSnackBar,
      open: false,
    });
  }

  React.useEffect(() => {
    if (props.canAlertOpen === true) {
      handleSnackBarOpen();
    }
  }, [props.refresh])

  return (
    <Snackbar
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      open={openSnackBar.open}
      autoHideDuration={4000}
      onClose={handleSnackBarClose}
      TransitionComponent={openSnackBar.Transition}
      key={openSnackBar.Transition.name}
      message={props.alertMessage}
      action={
        <IconButton id="close_snack_bar" size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      classes={{ root: props.alertType === 'success' ? classes.successBar : classes.errorBar}}
    >
    </Snackbar>
  );
}

export default FeedbackAlert;