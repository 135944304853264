import React from 'react';
import { askForPermissioToReceiveNotifications } from '../../Helpers';

function App() {
  const NotificationButton = () => (
    <div style={{ display: 'flex', width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <button onClick={askForPermissioToReceiveNotifications} style={{padding: 10, fontSize: 16}}>
        Click me if you wish to receive notifications!
      </button>
    </div>

  );


  // navigator.serviceWorker.getRegistrations().then(function(registrations) {
  //   for(let registration of registrations) {
  //    registration.unregister()
  //  } })

  return (
    <div className="App">
      {NotificationButton()}
    </div>
  );
}

export default App;