import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { HelmetProvider } from 'react-helmet-async';

import './style/index.css';
import meetTheme from './style/JobAppTheme';
import Routes from './routes/Routes';
import 'tachyons';
import { ReceiveMessage, initializeFirebase, askForPermissioToReceiveNotifications} from './Helpers';

//require("dotenv").config();

// initializeFirebase();
// askForPermissioToReceiveNotifications();


ReactDOM.render(
  <HelmetProvider>
    <MuiThemeProvider theme={meetTheme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>

      {/* <ReceiveMessage/> */}
    </MuiThemeProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

// initializeFirebase();
// ReceiveMessage();
// askForPermissioToReceiveNotifications();