import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

import { BohotKharabIcon, BohotKharabGreyIcon, KharabIcon, KharabGreyIcon, ThikThakIcon, ThikThakGreyIcon, AchhaIcon, AchhaGreyIcon, BohotAchhaIcon, BohotAchhaGreyIcon } from '../../components/SvgIcons';
import BohotAchhaImg from '../../assets/GIFs/Very-happy.gif';
import AchhaImg from '../../assets/GIFs/Happy.gif';
import ThikThakImg from '../../assets/GIFs/Neutral-2.gif';
import KharabImg from '../../assets/GIFs/Sad.gif';
import BohotKharabImg from '../../assets/GIFs/Angry.gif';

const useStyles = makeStyles((theme) => ({

    svgContainer: {
        display: 'flex',
        position: 'relative',
        width: 60,
        height: 54,
        margin: 10,
        boxSizing: 'border-box'
    },
    svgContainerSmall: {
        display: 'flex',
        position: 'relative',
        width: 10,
        height: 10,
        margin: '10px 28px 10px 0',
        boxSizing: 'border-box'
    },
    svgIcon: {
        fontSize: '3rem',
        width: '1rem',
        height: '1rem',
        marginLeft: '-4px',
    },
    svgIconSmall: {
        fontSize: '3rem',
        width: '1rem',
        height: '1rem',
        transform: 'scale(0.6)',
        transformOrigin: 'top',
        paddingTop: 0,
        marginLeft: '-2px',
    },
    svgIconSmallInactive: {
        fontSize: '3rem',
        width: '1rem',
        height: '1rem',
        transform: 'scale(0.5)',
        transformOrigin: 'top',
        paddingTop: 7,
        marginLeft: 0,
    },
    svgIconInactive: {
        transform: 'scale(0.8)',
        paddingTop: 7,
        marginLeft: 0,
    },
    gifImage: {
        width: 'auto',
        height: '100%',
        maxWidth: '116%',
        marginLeft: '-4px'
    }
}))

export default function CustomRatings(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [isActive, setIsActive] = React.useState([
        props.value === 1 ? true : false,
        props.value === 2 ? true : false,
        props.value === 3 ? true : false,
        props.value === 4 ? true : false,
        props.value === 5 ? true : false]);

    const [isAnimate, setIsAnimate] = React.useState([false, false, false, false, false]);

    const handleEmojiClick = (n) => {
        if (!props.onChange) return;
        if (props.type === "animated") handleAnimatedEmojiClick(n);
        else if (props.type === "static") handleStaticEmojiClick(n);
    }

    const handleAnimatedEmojiClick = (n) => {
        let _isAnimate = [...isAnimate];
        let i;
        for (i = 0; i < isActive.length; i++) {
            if (i === n) {
                _isAnimate[i] = true;
            } else if (i !== n) {
                _isAnimate[i] = false;
            }
        }
        setIsAnimate(_isAnimate);
        setIsActive([false, false, false, false, false]);
        props.onChange(n + 1);
    }

    const handleStaticEmojiClick = (n) => {
        let _isActive = [...isActive];
        let i;
        for (i = 0; i < isActive.length; i++) {
            if (i === n) {
                _isActive[i] = true;
            } else if (i !== n) {
                _isActive[i] = false;
            }
        }
        setIsActive(_isActive);
        props.onChange(n + 1);
    }

    return (
        <>
            <div
                className={props.size === "large" ? classes.svgContainer : classes.svgContainerSmall}
                onClick={() => { handleEmojiClick(0)
                    // if (props.type === "animated") handleAnimatedEmojiClick(0);
                    // else if (props.type === "static") handleStaticEmojiClick(0);
                }}>
                {props.value === 1 && !isAnimate[0]
                    ? <BohotKharabIcon
                        className={props.size === "large" ? classes.svgIcon : classes.svgIconSmall} />
                    : isAnimate[0] ? <img className={classes.gifImage} src={BohotKharabImg} />
                        : <BohotKharabGreyIcon
                            className={props.size === "large" ? `${classes.svgIcon} ${classes.svgIconInactive}` : `${classes.svgIconSmallInactive}`} />
                }
            </div>
            <div
                className={props.size === "large" ? classes.svgContainer : classes.svgContainerSmall}
                onClick={() => { handleEmojiClick(1)}}>
                {props.value === 2 && !isAnimate[1]
                    ? <KharabIcon
                        className={props.size === "large" ? classes.svgIcon : classes.svgIconSmall} />
                    : isAnimate[1] ? <img className={classes.gifImage} src={KharabImg} />
                        : <KharabGreyIcon
                            className={props.size === "large" ? `${classes.svgIcon} ${classes.svgIconInactive}` : `${classes.svgIconSmallInactive}`} />
                }
            </div>
            <div
                className={props.size === "large" ? classes.svgContainer : classes.svgContainerSmall}
                onClick={() => { handleEmojiClick(2)}}>
                {props.value === 3 && !isAnimate[2]
                    ? <ThikThakIcon
                        className={props.size === "large" ? classes.svgIcon : classes.svgIconSmall} />
                    : isAnimate[2] ? <img className={classes.gifImage} src={ThikThakImg} />
                        : <ThikThakGreyIcon
                            className={props.size === "large" ? `${classes.svgIcon} ${classes.svgIconInactive}` : `${classes.svgIconSmallInactive}`} />
                }
            </div>
            <div
                className={props.size === "large" ? classes.svgContainer : classes.svgContainerSmall}
                onClick={() => { handleEmojiClick(3)}}>
                {props.value === 4 && !isAnimate[3]
                    ? <AchhaIcon
                        className={props.size === "large" ? classes.svgIcon : classes.svgIconSmall} />
                    : isAnimate[3] ? <img className={classes.gifImage} src={AchhaImg} />
                        : <AchhaGreyIcon
                            className={props.size === "large" ? `${classes.svgIcon} ${classes.svgIconInactive}` : `${classes.svgIconSmallInactive}`} />
                }
            </div>
            <div
                className={props.size === "large" ? classes.svgContainer : classes.svgContainerSmall}
                onClick={() => {handleEmojiClick(4)}}>
                {props.value === 5 && !isAnimate[4]
                    ? <BohotAchhaIcon
                        className={props.size === "large" ? classes.svgIcon : classes.svgIconSmall} />
                    : isAnimate[4] ? <img className={classes.gifImage} src={BohotAchhaImg} />
                        : <BohotAchhaGreyIcon
                            className={props.size === "large" ? `${classes.svgIcon} ${classes.svgIconInactive}` : `${classes.svgIconSmallInactive}`} />
                }
            </div>
        </>
    )
} 