import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import Typography from '@material-ui/core/Typography';
//import Button from '@material-ui/core/Button';
import { Icon, Paper, TextField, Button, Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import SearchBar from '../jobs/SearchBarDummy';
import '../../style/App.css';
import global_isSignedIn from '../../components/globalVars/global_isSignedIn';
import SignInUpDrawer from '../../components/SignInDrawer';
import ApiHandler from '../../ApiHandler';
import { FollowedCompaniesListItem } from '../jobs/JobsListItem';
import ListLoader from '../errorsAndLoaders/ListLoader';
import InfiniteScroll from "react-infinite-scroll-component";

import NoResultsImage from '../../assets/no_followed_companies.svg';
import LogoLoader from '../../assets/logo-loader.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 74,
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('md')]: {
      paddingTop: 74,
    },
  },
  pageBar: {
    width: 860,
    padding: '8px 43px',
    flexBasis: 65,
    backgroundColor: "transparent",
    marginTop: 0,
    zIndex: 8,
    transition: '0.2s',
    position: 'sticky',
    top: 59,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      top: 0,
      padding: 0
    },
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em'
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 20,
  },
  pageButton_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  listRoot: {
    width: '100%',
    padding: '0 10px 30px',
    marginBottom: 50,
    '& .MuiListItem-root': {
      padding: 0,
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      marginBottom: 10,
      overflow: 'hidden',
      boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.07)',
    }
  },
  panelHeading: {
    margin: '0 2px 8px',
    color: theme.palette.common.grey,
  }
}));

export default function AppliedJobs(props) {
  const location = useLocation();
  const classes = useStyles();
  const [navValue, setNavValue] = React.useState(0);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);

  const refSeeAllJobsButton = React.useRef(null);
  const [isLocationDenied, setIsLocationDenied] = React.useState(true);

  const matchesMinHeight = useMediaQuery('(min-height:500px)');

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);

   let jobseekerID = localStorage.getItem("jobseeker_id");

   document.body.style.overflowY = "scroll";

  const checkSignedInStatus = () => {
    return isSignedIn
  }
  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  const handleGoToJobsClick = () => {
    history.push({
      pathname: `/`,
    });
  }

  // get jobs list---> starts
  const [jobsList, setJobsList] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageOffset, setPageOffset] = React.useState(0);
  const [jobsCount, setJobsCount] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);
  const [role, setRole] = React.useState('Sales');
  const [modeOfWork, setModeOfWork] = React.useState('everyWhere');
  const [latLong, setLatLong] = React.useState({ lat: (location && location.state && location.state.lat ? location.state.lat : null), long: (location && location.state && location.state.long ? location.state.long : null) })
  const [refreshInfiniteScroll, setRefreshInfiniteScroll] = React.useState(true)

  // get jobs list with infinitescroll as the offset changes---> starts
  const fetchMoreData = () => {
    let page = 1;
    page = currentPage + 1;
    setCurrentPage(page);
    setPageOffset((page - 1) * 10);
    setRefreshInfiniteScroll(prev => !prev);
  }

  React.useEffect(() => {
    //alert("offset change")
    const handleMoreJobsList = () => {
      let payload = {
        jobseeker_id: jobseekerID,
        limit: 10,
        offset: pageOffset,
      }

      ApiHandler.getFavCompanies(payload, response => {
        if (response.status == 200) {
          //console.log(response.data[0].results);
          if (jobsList === null) {
            setJobsList(response.data[0].results);
          }
          else {
            setJobsList(prevState => prevState.concat(response.data[0].results))
            //console.log(jobsList.concat(response.data[0].results));
          }
          setJobsCount(response.data && response.data[0] && response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 10);
        }
        else {
          console.log("error in getting a successful response for the list of followed companies")
        }
      })
    }

    if (jobsList && jobsList.length >= jobsCount) {
      setHasMore(false);
    } else if (currentPage > 1) {
      handleMoreJobsList();
    }
  }, [pageOffset])

  React.useEffect(() => {
    // debugger;
    var data = {
      jobseeker_id: jobseekerID,
      limit: 10,
      offset: 0,
    }

    ApiHandler.getFavCompanies(data, response => {
      if (response.status == 200) {
        // debugger;
        var arr_temp = response.data[0].results;
        setJobsList(arr_temp);
        // debugger;
        //console.log("Total jobs:::::" + response.data[0].totalCount[0].count);
        setJobsCount(response && response.data[0] && response.data[0].totalCount[0] && response.data[0].totalCount[0].count ? response.data[0].totalCount[0].count : 0);
        if (response && response.data[0] && response.data[0].totalCount[0] && response.data[0].totalCount[0].count && response.data[0].totalCount[0].count< 10) {
          setHasMore(false);
        }
      }
      else {
        console.log("error in getting a successful response")
      }
    })
  }, [])
  // get jobs list on page load---> ends

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  //sort bar bg effect on scroll starts
  const pageBarRef = React.useRef(null);
  let lastScrollY = 0;
  let scrolling = false;

  const handleScrollChange = () => {
    lastScrollY = window.scrollY;
    if (lastScrollY > 60) {
      scrolling = true
    } else {
      scrolling = false
    }
    if (!scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        pageBarRef.current.style.background = 'transparent';
        pageBarRef.current.style.boxShadow = "none";
        pageBarRef.current.style.flexBasis = "65px";
      });
    } else if (scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        pageBarRef.current.style.background = '#f9f9f9';
        pageBarRef.current.style.boxShadow = "0px 5px 5px rgba(0,0,0,0.05)";
        pageBarRef.current.style.flexBasis = "50px";
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);
  //sort bar bg effect on scroll ends

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const [displaySearchBar, setDisplaySearchBar] = React.useState(true);
  const [displayAllJobs, setDisplayAllJobs] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState(null);
  const [doRefreshJobs, setDoRefreshJobs] = React.useState(true);

  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  return (
    <React.Fragment>
      <div className={classes.root}
        ref={scrollRef}
      >
        {/* <Grid container
          alignItems="center" className={classes.pageBar} ref={pageBarRef}>
          <Grid item xs={12} container
            justifyContent="center"
            alignItems="center">
            <SearchBar setOnSearchClick={setOnSearchClick} role={role}></SearchBar>
          </Grid>
        </Grid> */}

        {isLoading
          ? <div style={{
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
          }}>
            <ListLoader setListLoaderRef={setListLoaderRef} />
          </div>
          :
          <React.Fragment>
            {jobsList && jobsList.length > 0 &&
              <div style={{ width: '100%' }}>
                <InfiniteScroll
                  dataLength={jobsList.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<div style={{
                    width: '100%', height: 80, display: 'flex', justifyContent: 'center', alignItems: 'flex-start'
                  }}>
                    <img alt="meet animated logo loader" style={{ width: 40 }} src={LogoLoader} />
                  </div>}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      { }
                    </p>
                  }
                  scrollThreshold="70%"
                >
                  <List className={classes.listRoot} disablePadding>
                    <Typography variant="body2" className={classes.panelHeading}>
                    {jobsList.length === 1 ? `${jobsCount} followed company` : jobsList.length > 1 ? `सभी ${jobsCount} followed companies` : null}
                      </Typography>
                    {jobsList.map((hit, index) =>
                    (<FollowedCompaniesListItem
                      hit={jobsList[index]}
                      signInStatus={isSignedIn}
                      key={index}
                      lat={latLong.lat}
                      long={latLong.long}
                    />)
                    )
                    }
                  </List>
                </InfiniteScroll>
              </div>
            }
            {jobsList && jobsList.length === 0 &&
              (<div className={classes.pageInfo}
                style={{ paddingTop: matchesMinHeight ? '70px' : 0 }}
              >
                <img src={NoResultsImage} style={{ width: matchesMinHeight ? 200 : 100 }} />
                <Typography className={classes.pageInfoText} variant="subtitle1">आप ने कोई company अभी follow नहीं की है।  </Typography>
                <Button
                  variant="contained"
                  className={`${classes.pageButton} ${classes.pageButton_pri}`}
                  style={{ width: 170 }}
                  onClick={handleGoToJobsClick}
                >
                  Go to companies
                </Button>
              </div>)
            }
          </React.Fragment>
        }

      </div>
      {/* <SignInUpDrawer
        canDrawerOpen={canDrawerOpen}
        refresh={refresh}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshOnly}
        setCanDrawerOpen={setCanDrawerOpen}
        doRefresh={doRefresh}
      /> */}
    </React.Fragment>
  )
}